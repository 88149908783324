import React, { useState, ChangeEvent, FormEvent } from 'react';
import Common from '../../../Util/Common';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import DialogConfirm from '../../Dialogbox/DialogConfirm';
import {
  Column,
  ColumnModel,
  DetailRow,
  ExcelExport,
  ExcelExportProperties,
  Filter,
  GridComponent,
  Inject,
  PdfExport,
  PdfExportProperties,
  Sort,
  Toolbar,
  ColumnsDirective,
  ColumnDirective,
  FilterSettingsModel,
  Group,
  Resize,
} from '@syncfusion/ej2-react-grids';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
interface Props {}

const { REACT_APP_ENDPOINT_LEAVESERVICE, REACT_APP_ENDPOINT_CORESERVICE } =
  process.env;

export default class LeaveLiabilityReport extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;
  public toolbarOptions: any = ['ExcelExport', 'PdfExport'];
  private grid: GridComponent | null;
  toastObj: any;

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
    this.grid = null;
  }

  componentDidMount(): void {
    this.GenerateReport();
  }

  state = {
    dataSource: undefined,
    isLoading: false,
    ProgressHandle: false,
  };

  public Filter: any = {
    type: 'CheckBox',
  };

  toolbarClick = (args: any) => {
    switch (args.item.id) {
      case 'gridleaveliability_pdfexport':
        this.grid?.pdfExport();
        break;
      case 'gridleaveliability_excelexport':
        this.grid?.excelExport();
        break;
      case 'gridleaveliability_csvexport':
        this.grid?.csvExport();
        break;
    }
  };

  private GenerateReport() {
    if (document.getElementById('asOnDate')) {
      if ((document.getElementById('asOnDate') as any).value == '') {
        // DialogConfirm.showDialog({
        //   content:
        //     '<p class="dialog-contain">Sorry As On Date is mandatory information. <p class="dialog-contain">Please provide As On Date to continue.</p>',
        // });
        this.toastObj = ToastUtility.show({
          cssClass: 'e-toast-warning',
          icon: 'e-warning toast-icons',
          showCloseButton: true,
          content:
            'Sorry As On Date is mandatory information.<br> Please provide As On Date to continue.',
          target: '#basic_card',
          position: { X: 'Center', Y: 'Top' },
          width: 'auto',
          timeOut: 3000,
        });
        return;
      }
    }

    Common.ShowSpinner();

    let payload: any = {
      AsOnDate: Common.formatDate(
        new Date((document.getElementById('asOnDate') as any).value),
        'yyyy-MM-dd'
      ),
    };

    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_LEAVESERVICE}/LeaveLiabilityReport/generate`,
      payload,
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((data: any) => {
        let jdataFlat: any = undefined;
        jdataFlat = Common.flattenJSON(data, {}, '', null, [], [], '_');
        if (this.grid) {
          this.grid.dataSource = jdataFlat;
          this.grid.refresh();
        } else {
          this.setState({ dataSource: jdataFlat });
        }
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {
        Common.HideSpinner();
      });
  }

  render() {
    const orderidRules = { required: true, number: true };
    const filterSettings: FilterSettingsModel = { type: 'Excel' };
    const groupOptions = {
      showGroupedColumn: false,
      columns: ['LeaveTypeName'],
    };

    const handleChange = (e: any) => {
      this.GenerateReport();
    };
    return (
      <>
        <div className='col-2 py-2'>
          <div id='divAsOnDate'>
            <DatePickerComponent
              id='asOnDate'
              name='asOnDate'
              format={this.user.settings.formats.DateShort}
              showClearButton={false}
              // cssClass='e-hidedate-icon'
              // openOnFocus={true}
              showTodayButton={true}
              placeholder='As on Date *'
              style={{ fontSize: '15px' }}
              value={new Date()}
              onChange={handleChange}
              className='.e-input-group.e-control-wrapper.e-float-input .e-float-text::'
              floatLabelType='Auto'
            />
          </div>
        </div>
        <div className='col-12 py-2'>
          <GridComponent
            id='gridleaveliability'
            dataSource={this.state.dataSource}
            allowGrouping={true}
            // allowPaging={true}
            allowResizing={true}
            allowFiltering={true}
            allowSorting={true}
            allowMultiSorting={true}
            // editSettings={editSettings}

            height='calc(86vh - 40vh)'
            filterSettings={filterSettings}
            toolbar={this.toolbarOptions}
            toolbarClick={this.toolbarClick.bind(this)}
            groupSettings={groupOptions}
            allowExcelExport={true}
            allowPdfExport={true}
            ref={(scope) => {
              this.grid = scope;
            }}
          >
            <ColumnsDirective>
              <ColumnDirective
                field='EmployeeName'
                headerText='Employee'
                width='160'
                minWidth='10'
                validationRules={orderidRules}
                allowResizing={true}
              ></ColumnDirective>
              <ColumnDirective
                field='LeaveTypeName'
                headerText='Leave Type'
                width='100'
                minWidth='10'
                textAlign='Left'
              ></ColumnDirective>
              <ColumnDirective
                field='Department'
                headerText='Department'
                width='120'
                minWidth='10'
                validationRules={orderidRules}
              ></ColumnDirective>
              <ColumnDirective
                field='AppointmentDate'
                headerText='Appointment Date'
                textAlign='Right'
                width='140'
                minWidth='50'
                type='datetime'
                format={this.user.settings.formats.DateShort}
                validationRules={orderidRules}
              ></ColumnDirective>
              <ColumnDirective
                field='BasicSalary'
                headerText='Basic Salary'
                width='110'
                minWidth='50'
                textAlign='Right'
                format={
                  this.user.settings.formats.Decimal
                    ? 'N' +
                      this.user.settings.formats.Decimal.split('.')[1].length
                    : 'N2'
                }
              ></ColumnDirective>
              <ColumnDirective
                field='BF'
                headerText='Leave BF Days'
                width='120'
                minWidth='50'
                format={'N2'}
                textAlign='Right'
              ></ColumnDirective>
              <ColumnDirective
                field='Entitlement'
                headerText='Entitlement'
                width='100'
                minWidth='50'
                format={'N2'}
                textAlign='Right'
              ></ColumnDirective>
              <ColumnDirective
                columns={[
                  {
                    field: 'YTDAccruedLeave',
                    headerText: 'Accrued Leave Days',
                    width: 140,
                    minWidth: 30,
                    textAlign: 'Right',
                    format: 'N2',
                  },
                  {
                    field: 'YTDAccruedLeaveValue',
                    headerText: 'Accrued Leave Value(Amount)',
                    width: 195,
                    minWidth: 30,
                    format: this.user.settings.formats.Decimal
                      ? 'N' +
                        this.user.settings.formats.Decimal.split('.')[1].length
                      : 'N2',
                    textAlign: 'Right',
                  },
                ]}
                headerText='Leave Accrued'
                textAlign='Center'
              ></ColumnDirective>
              <ColumnDirective
                columns={[
                  {
                    field: 'YTDLeaveTaken',
                    headerText: 'Leave Taken Days',
                    width: 130,
                    minWidth: 30,
                    textAlign: 'Right',
                    format: 'N2',
                  },
                  {
                    field: 'YTDLeaveTakenValue',
                    headerText: 'Leave Taken Value(Amount)',
                    width: 180,
                    minWidth: 30,
                    format: this.user.settings.formats.Decimal
                      ? 'N' +
                        this.user.settings.formats.Decimal.split('.')[1].length
                      : 'N2',
                    textAlign: 'Right',
                  },
                ]}
                headerText='Leave Consumed'
                textAlign='Center'
              ></ColumnDirective>
              <ColumnDirective
                columns={[
                  {
                    field: 'LeaveBalance',
                    headerText: 'Leave Balance Days',
                    textAlign: 'Right',
                    width: 140,
                    minWidth: 30,
                    format: 'N2',
                  },
                  {
                    field: 'LeaveBalanceValue',
                    headerText: 'Leave Balance Value(Amount)',
                    width: 195,
                    minWidth: 30,
                    format: this.user.settings.formats.Decimal
                      ? 'N' +
                        this.user.settings.formats.Decimal.split('.')[1].length
                      : 'N2',

                    textAlign: 'Right',
                  },
                ]}
                headerText='Accrued Leave Balance'
                textAlign='Center'
              ></ColumnDirective>
              <ColumnDirective
                columns={[
                  {
                    field: 'PendingAccrued',
                    headerText: 'Pending Accrued Days',
                    textAlign: 'Right',
                    width: 155,
                    minWidth: 30,
                    format: 'N2',
                  },
                  {
                    field: 'PendingAccruedValue',
                    headerText: 'Pending Accrued Value(Amount)',
                    width: 210,
                    minWidth: 30,
                    format: this.user.settings.formats.Decimal
                      ? 'N' +
                        this.user.settings.formats.Decimal.split('.')[1].length
                      : 'N2',
                    textAlign: 'Right',
                  },
                ]}
                headerText='Leave Days Yet To Accrue'
                textAlign='Center'
              ></ColumnDirective>
            </ColumnsDirective>
            <Inject
              services={[
                Resize,
                Group,
                Sort,
                Toolbar,
                Filter,
                ExcelExport,
                PdfExport,
              ]}
            />
          </GridComponent>
        </div>
      </>
    );
  }
}
