import React from 'react';
import './ChangePassword.css';
import Common from '../../../Util/Common';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import DialogConfirm from '../../Dialogbox/DialogConfirm';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';

interface Props {
  handleCancelClick?: any;
}

const { REACT_APP_ENDPOINT_TOKENS, REACT_APP_ENDPOINT_EMPLOYEESERVICE } =
  process.env;

export default class ChangePassword extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;
  private toastObj: any;

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
  }

  state = {
    chngpassword: '',
    pwdConfirmed: false,
    chngnewpassword: '',
    chngconfirmpassword: '',
  };

  componentDidMount() {}

  render() {
    const onFocusFunction = (event: any) => {
      event.target.classList.remove('textbox-border-error');
      event.target.style.border = 'none';
    };

    const handleOnSyncTextChange = (args: any): void => {
      let key: string = args.target.name;
      let value: string = args.value;

      this.setState({ [key]: value });
    };

    const ValidateForm = () => {
      const inputs = [
        document.querySelector<HTMLInputElement>('#changepwdemail'),
        document.querySelector<HTMLInputElement>('#chngpassword'),
        document.querySelector<HTMLInputElement>('#chngnewpassword'),
        document.querySelector<HTMLInputElement>('#chngconfirmpassword'),
      ];

      inputs.forEach((input) => {
        if (input) {
          input.classList.remove('textbox-border-error');

          if (input.value.trim() === '') {
            input.classList.add('textbox-border-error');
          } else {
            input.classList.add('textbox-class');
          }
        }
      });
    };
    const IsValid = (): boolean => {
      ValidateForm();
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!this.user.email.trim()) {
        const TargetInput =
          document.querySelector<HTMLInputElement>('#changepwdemail');
        if (TargetInput) {
          TargetInput.style.borderBottom = '2px solid red';
          if (!emailPattern.test(TargetInput.value)) {
            this.toastObj = ToastUtility.show({
              cssClass: 'e-toast-warning',
              icon: 'e-warning toast-icons',
              showCloseButton: true,
              content: 'Please Enter Corract format of Email ..!',
              target: '#basic_card',
              position: { X: 'Center', Y: 'Top' },
              width: 'auto',
              timeOut: 3000,
            });
          }
        }
        return false;
      } else if (!this.state.chngpassword.trim()) {
        const TargetInput =
          document.querySelector<HTMLInputElement>('#chngpassword');
        if (TargetInput) {
          TargetInput.style.borderBottom = '2px solid red';
        }
        return false;
      } else if (!this.state.chngnewpassword.trim()) {
        const TargetInput =
          document.querySelector<HTMLInputElement>('#chngnewpassword');
        if (TargetInput) {
          TargetInput.style.borderBottom = '2px solid red';
        }
        return false;
      } else if (!this.state.chngconfirmpassword.trim()) {
        const TargetInput = document.querySelector<HTMLInputElement>(
          '#chngconfirmpassword'
        );
        if (TargetInput) {
          TargetInput.style.borderBottom = '2px solid red';
        }
        return false;
      } else if (
        this.state.chngnewpassword.trim() !==
        this.state.chngconfirmpassword.trim()
      ) {
        // const TargetInput = document.querySelector<HTMLInputElement>(
        //   '#chngconfirmpassword'
        // );
        // if (TargetInput) {
        //   TargetInput.style.borderBottom = '2px solid red';
        // }
        this.toastObj = ToastUtility.show({
          cssClass: 'e-toast-warning',
          icon: 'e-warning toast-icons',
          showCloseButton: true,
          content: 'New password and the confirmation passwords do not match.',
          target: '#basic_card',
          position: { X: 'Center', Y: 'Top' },
          timeOut: 3000,
          width: 'auto',
        });
        return false;
      }

      return true;
    };

    const onCancelClick = (e: any) => {
      if (this.props.handleCancelClick) this.props.handleCancelClick(e);
    };
    const handleOkayToastClick = () => {
      Common.removeUserSession();
      window.location.href = '/';
    };
    const onSaveClick = (e: any) => {
      ValidateForm();

      if (IsValid() === false) return false;

      let payload: any = {
        password: this.state.chngpassword,
        newPassword: this.state.chngnewpassword,
        confirmNewPassword: this.state.chngconfirmpassword,
      };

      Common.ApiCallAsync(
        'put',
        '' +
          REACT_APP_ENDPOINT_EMPLOYEESERVICE +
          '/api/personal/change-password',
        payload,
        Common.getToken() || '',
        null,
        this.token.tenant
      )
        .then((response: any) => {
          //return response.data;
          this.toastObj = ToastUtility.show({
            cssClass: 'e-toast-success',
            icon: 'far fa-circle-check',
            showCloseButton: true,
            content:
              '<p>Password has been updated. <br /><br />You will be redirected to the login page to access your account with your new password.</p>',
            target: '#basic_card',
            position: { X: 'Center', Y: 'Top' },
            timeOut: 0,
            width: 'auto',
            buttons: [
              {
                model: { content: 'Ok' },
                click: () => handleOkayToastClick(),
            },
            ],
          });
          // DialogConfirm.showDialog({
          //   content:
          //     '<p class="dialog-contain">Password has been updated. <br /><br />You will be redirected to the login page to access your account with your new password.</p>',
          //   OkEvent() {
          //     Common.removeUserSession();
          //     window.location.href = '/';
          //   },
          // });
        })
        // .then((data: any) => {

        // })
        .catch((error: any) => {
          const TargetInput =
            document.querySelector<HTMLInputElement>('#chngpassword');
          if (TargetInput) {
            TargetInput.value = '';
            ValidateForm();
            TargetInput.style.borderBottom = '2px solid red';
          }
          console.error(error);
        })
        .finally(() => {});
    };

    return (
      <>
        <div id='divChangepassword' className='py-2'>
          <div className='panelheight'>
            <div className='row'>
              <div className='col-12'>
                {/* <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                  Email
                </label>
                <span className='mandatoryinfo'>*</span> */}
                <TextBoxComponent
                  id='changepwdemail'
                  name='changepwdemail'
                  type='email'
                  onFocus={onFocusFunction}
                  placeholder={'Email *'}
                  floatLabelType='Auto'
                  autocomplete='off'
                  value={this.user.email}
                  //onChange={handleOnSyncTextChange}
                  disabled={true}
                />
              </div>
            </div>
            {/* {!this.state.pwdConfirmed ? ( */}
            <div className='row'>
              <div className='col-12'>
                {/* <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                  Current Password
                </label>
                <span className='mandatoryinfo'>*</span> */}
                <TextBoxComponent
                  className='input-text-border'
                  id='chngpassword'
                  name='chngpassword'
                  type='password'
                  onFocus={onFocusFunction}
                  placeholder={'Enter current password *'}
                  floatLabelType='Auto'
                  autocomplete='off'
                  value={this.state.chngpassword}
                  onChange={handleOnSyncTextChange}
                />
              </div>
            </div>
            {/* ) : ( */}
            <>
              <div className='row'>
                <div className='col-12'>
                  {/* <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                    New Password
                  </label>
                  <span className='mandatoryinfo'>*</span> */}
                  <TextBoxComponent
                    className='input-text-border'
                    id='chngnewpassword'
                    name='chngnewpassword'
                    type='password'
                    onFocus={onFocusFunction}
                    placeholder={'Enter new password *'}
                    floatLabelType='Auto'
                    autocomplete='off'
                    value={this.state.chngnewpassword}
                    onChange={handleOnSyncTextChange}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  {/* <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                    Confirm New Password
                  </label>
                  <span className='mandatoryinfo'>*</span> */}
                  <TextBoxComponent
                    className='input-text-border'
                    id='chngconfirmpassword'
                    name='chngconfirmpassword'
                    type='password'
                    onFocus={onFocusFunction}
                    placeholder={'Confirm new password *'}
                    floatLabelType='Auto'
                    autocomplete='off'
                    value={this.state.chngconfirmpassword}
                    onChange={handleOnSyncTextChange}
                  />
                </div>
              </div>
            </>
            {/* )} */}
          </div>
          <div className='e-footer-content-temp'>
            <button
              id='btnCancel'
              className='e-control e-btn e-lib flat-button e-flat'
              onClick={onCancelClick}
            >
              Cancel
            </button>
            <button
              id='btnAccept'
              className='e-control e-btn e-lib flat-button e-primary e-flat'
              onClick={onSaveClick}
            >
              Update Password
            </button>
          </div>
        </div>
      </>
    );
  }
}
