import * as React from 'react';
import './TeamReportList.css';
import Common from '../../Util/Common';
import LinkList from '../LinkList/LinkList';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import {
  Dialog,
  DialogComponent,
  DialogUtility,
} from '@syncfusion/ej2-react-popups';
import PayrollVarianceReport from '../Reports/Payroll/PayrollVarianceReport';
import LeaveLiabilityReport from '../Reports/Leave/LeaveLiabilityReport';

const { REACT_APP_ENDPOINT_CORESERVICE } = process.env;

interface Props {
  jsonDataViewer: any;
}

export default class TeamReportList extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;
  private dataEmployee: any;
  private dataPayroll: any;
  private dataLeave: any;

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');

    this.dataEmployee = this._structure.Reports.filter(
      (x: any) =>
        x.ReportGroupId === this._structure.ReportsGroup.Employee.Id &&
        x.IsESS === false
    );

    this.dataPayroll = this._structure.Reports.filter(
      (x: any) =>
        x.ReportGroupId === this._structure.ReportsGroup.Payroll.Id &&
        x.IsESS === false
    );

    this.dataLeave = this._structure.Reports.filter(
      (x: any) =>
        x.ReportGroupId === this._structure.ReportsGroup.Leave.Id &&
        x.IsESS === false
    );
  }

  state = { showPayrollVariance: false, showLeaveLiability: false };

  componentDidMount(): void {}

  public DialogButtonModel: any = [
    {
      buttonModel: {
        content: Common.toTitleCase('Close'),
        cssClass: 'flat-button',
      },
      click: () => {
        if (this.state.showPayrollVariance === true) {
          this.setState({ showPayrollVariance: false });

          // setTimeout(() => {
          let fromprd: any = document.querySelectorAll(
            '#frompayrollvariancereport-periodctrls'
          );
          let toprd: any = document.querySelectorAll(
            '#topayrollvariancereport-periodctrls'
          );
          let isignorezerovariance: any = document.querySelectorAll(
            '#divIsIgnoreZeroVariance'
          );
          if (fromprd.length > 0) {
            fromprd.forEach((p: any) => {
              p.remove();
            });
          }
          if (toprd.length > 0) {
            toprd.forEach((p: any) => {
              p.remove();
            });
          }
          if (isignorezerovariance.length > 0) {
            isignorezerovariance.forEach((p: any) => {
              p.remove();
            });
          }
          // }, 1000);
        } else if (this.state.showLeaveLiability === true) {
          console.log('Close');
          this.setState({ showLeaveLiability: false });

          let AsOnDate: any = document.querySelectorAll('#divAsOnDate');

          if (AsOnDate.length > 0) {
            AsOnDate.forEach((p: any) => {
              p.remove();
            });
          }
        }
      },
    },
  ];

  render() {
    const onLinkItemClick = (e: any) => {
      if (e.id == 'payroll_variance') {
        this.setState({
          showPayrollVariance: true,
        });
      } else if (e.id == 'leave_liability') {
        this.setState({
          showLeaveLiability: true,
        });
      }
    };

    const dialogContent = (e: any) => {
      var content: any;
      if (this.state.showPayrollVariance == true) {
        content = (
          <>
            <div className='row align-items-center'>
              <PayrollVarianceReport />
            </div>
          </>
        );
      } else if (this.state.showLeaveLiability == true) {
        content = (
          <>
            <div className='row align-items-center'>
              <LeaveLiabilityReport />
            </div>
          </>
        );
      }
      return content;
    };
    return (
      <>
        <div id='divteamreportlist'>
          <div className='row'>
            <div className='col-md-8 '>My Favorites</div>
            <div className='col-md-4 d-flex justify-content-end'>
              <div className='w-100 searchBox-custom-report'>
                <div className='col-md-12 d-flex'>
                  <div className='custom-report-searchdiv'>
                    <span
                      className=' searchInput-custom-report e-input-group e-control-wrapper e-inherit px-1'
                      style={{ background: 'none', border: 'none' }}
                    >
                      <TextBoxComponent
                        // ref={(scope) => {
                        //   textboxObj = scope;
                        // }}
                        placeholder='Search Reports'
                        showClearButton={true}
                        // onChange={onSearchTextChange}
                        title='Search Reports'
                        autocomplete='off'
                      ></TextBoxComponent>
                      <span
                        id={`teamreportlist-search`}
                        className='searchIcon-custom-report e-icons e-search e-input-group-icon'
                        title='Search'
                      ></span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>&nbsp;</div>
          </div>
          <div className='row'>
            <div className='col-md-2 divcustomreportlist-divClass d-none'>
              <label className=''>Employee Reports</label>
              <div className='row mt-3'>
                <div className='col-md-12'>
                  <div id='teamreportlist-employee'>
                    <LinkList
                      key={Math.random()}
                      id='teamreportlinklist-employee'
                      jsonDataViewer={this.props.jsonDataViewer}
                      dataSource={this.dataEmployee}
                      fields={{ id: 'Id', text: 'Text', iconCss: 'IconCss' }}
                      onLinkItemClick={(e: any) => onLinkItemClick(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-2'>
              <label className=''>Payroll Reports</label>
              <div className='row mt-3'>
                <div className='col-md-12'>
                  <div id='teamreportlist-payroll'>
                    <LinkList
                      key={Math.random()}
                      id='teamreportlinklist-payroll'
                      jsonDataViewer={this.props.jsonDataViewer}
                      dataSource={this.dataPayroll}
                      fields={{ id: 'Id', text: 'Text', iconCss: 'IconCss' }}
                      onLinkItemClick={(e: any) => onLinkItemClick(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-2'>
              <label className=''>Leave Reports</label>
              <div className='row mt-3'>
                <div className='col-md-12'>
                  <div id='teamreportlinklist-leave'>
                    <LinkList
                      key={Math.random()}
                      id='teamreportlinklist-leave'
                      jsonDataViewer={this.props.jsonDataViewer}
                      dataSource={this.dataLeave}
                      fields={{ id: 'Id', text: 'Text', iconCss: 'IconCss' }}
                      onLinkItemClick={(e: any) => onLinkItemClick(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-2  d-none'>
              <label className=''>Attendance Reports</label>
            </div>
            <div className='col-md-2  d-none'>
              <label className=''>Performance Reports</label>
            </div>
            <div className='col-md-2  d-none'>
              <label className=''>Training Reports</label>
            </div>
          </div>
        </div>
        {this.state.showPayrollVariance && (
          <DialogComponent
            id='defaultDialog-payrollvariancereport'
            showCloseIcon={true}
            visible={this.state.showPayrollVariance}
            width={'90vw'}
            height={'90vh'}
            close={() => {
              this.setState({ showPayrollVariance: false });
              // setTimeout(() => {
              let fromprd: any = document.querySelectorAll(
                '#frompayrollvariancereport-periodctrls'
              );
              let toprd: any = document.querySelectorAll(
                '#topayrollvariancereport-periodctrls'
              );
              let isignorezerovariance: any = document.querySelectorAll(
                '#divIsIgnoreZeroVariance'
              );

              if (fromprd.length > 0) {
                fromprd.forEach((p: any) => {
                  p.remove();
                });
              }
              if (toprd.length > 0) {
                toprd.forEach((p: any) => {
                  p.remove();
                });
              }
              if (isignorezerovariance.length > 0) {
                isignorezerovariance.forEach((p: any) => {
                  p.remove();
                });
              }
              // }, 1000);
            }}
            content={dialogContent}
            isModal={true}
            header={'Employee Payroll Variance'}
            buttons={this.DialogButtonModel}
            statelessTemplates={[]}
          ></DialogComponent>
        )}
        {this.state.showLeaveLiability && (
          <DialogComponent
            id='defaultDialog-leaveliabilityreport'
            showCloseIcon={true}
            visible={this.state.showLeaveLiability}
            width={'90vw'}
            height={'95vh'}
            close={() => {
              this.setState({ showLeaveLiability: false });

              let AsOnDate: any = document.querySelectorAll('#divAsOnDate');

              if (AsOnDate.length > 0) {
                AsOnDate.forEach((p: any) => {
                  p.remove();
                });
              }
            }}
            content={dialogContent}
            isModal={true}
            header={'Leave Liability'}
            buttons={this.DialogButtonModel}
            statelessTemplates={[]}
          ></DialogComponent>
        )}
      </>
    );
  }
}
