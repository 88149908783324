// * -----------------------------------------------------------------------------------------------------------------1.Aknowlege

// * -----------------------------------------------------------------------------------------------------------------2.Initialize
import * as React from 'react';
import Common from '../../Util/Common';
import { Dialog } from '@syncfusion/ej2-react-popups';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';

// * ------------------------------------------------------------------------------------------------------------------3.Style

const { REACT_APP_ENDPOINT_CORESERVICE, REACT_APP_ENDPOINT_PAYROLLSERVICE } =
  process.env;

interface Props {
  isgroupselected: any;
}

export default class AddBankBranch extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;
  private allocation_id: string = '';
  private txtCode: any = null;
  private txtName: any = null;
  private txtWebsite: any = null;
  private txtSwiftCode: any = null;
  private txtContactPerson: any = null;
  private txtContactNo: any = null;
  private txtSortCode: any = null;

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');

    // this.InitializeData();
  }

  // * ------------------------------------------------------------------------------------------------------------------5.State
  state = {
    jsonBank: undefined,
    treeviewfields: undefined,
    showDialog: false,
    addnew: true,
    delkey: '',
    code: '',
    name: '',
    groupWebsite: '',
    groupSwifCode: '',
    contactPerson: '',
    contactNo: '',
    sortCode: '',
  };

  // * ------------------------------------------------------------------------------------------------------------------Event Handler
  componentDidMount(): void {}

  public GetState() {
    return this.state;
  }
  onFocusFunction = (event: any) => {
    event.target.classList.remove('textbox-border-error');
  };
  render() {
    return (
      <>
        <div className='row align-items-center'>
          <div
            className={`col-md-${
              this.props.isgroupselected === true ? '12' : '6'
            } py-2`}
          >
            {/* <label style={{ paddingBottom: '7px' }}>Code</label>
            <span className='mandatoryinfo'>*</span> */}
            <TextBoxComponent
              className='input-text-border'
              id='code'
              name='code'
              type='text'
              placeholder={'Code *'}
              floatLabelType='Auto'
              onFocus={this.onFocusFunction}
              autocomplete='off'
              ref={(obj: any) => (this.txtCode = obj)}
              value={this.state.code}
              // required={true}
              onChange={(e: any) => {
                this.setState({ code: e.target.value });
              }}
            />
          </div>
          <div
            className={`col-md-${
              this.props.isgroupselected === true ? '12' : '6'
            } py-2`}
          >
            {/* <label style={{ paddingBottom: '7px' }}>Name</label>
            <span className='mandatoryinfo'>*</span> */}
            <TextBoxComponent
              className='input-text-border'
              id='name'
              name='name'
              //   key={Math.random()}
              type='text'
              placeholder={'Name *'}
              floatLabelType='Auto'
              onFocus={this.onFocusFunction}
              autocomplete='off'
              ref={(obj: any) => (this.txtName = obj)}
              value={this.state.name}
              // required={true}
              onChange={(e: any) => {
                this.setState({ name: e.target.value });
              }}
            />
          </div>
        </div>
        {this.props.isgroupselected ? (
          <div className='row align-items-center'>
            <div className='col-md-12 py-2 d-none'>
              {/* <label style={{ paddingBottom: '7px' }}>Website</label> */}
              <TextBoxComponent
                className='input-text-border'
                id='groupWebsite'
                name='groupWebsite'
                type='text'
                placeholder={'Website'}
                floatLabelType='Auto'
                onFocus={this.onFocusFunction}
                autocomplete='off'
                ref={(obj: any) => (this.txtWebsite = obj)}
                value={this.state.groupWebsite}
                onChange={(e: any) => {
                  this.setState({ groupWebsite: e.target.value });
                }}
              />
            </div>
            <div className='col-md-12 py-2'>
              {/* <label style={{ paddingBottom: '7px' }}>Swift Code</label> */}
              <TextBoxComponent
                className='input-text-border'
                id='groupWSwiftCode'
                name='groupWSwiftCode'
                type='text'
                placeholder={'Swift Code'}
                floatLabelType='Auto'
                onFocus={this.onFocusFunction}
                autocomplete='off'
                ref={(obj: any) => (this.txtSwiftCode = obj)}
                value={this.state.groupSwifCode}
                onChange={(e: any) => {
                  this.setState({ groupSwifCode: e.target.value });
                }}
              />
            </div>
          </div>
        ) : (
          <div className='row align-items-center'>
            <div className='col-md-6 py-2 d-none'>
              {/* <label style={{ paddingBottom: '7px' }}>Contact Person</label> */}
              <TextBoxComponent
                className='input-text-border'
                id='contactPerson'
                name='contactPerson'
                type='text'
                placeholder={'Contact Person'}
                floatLabelType='Auto'
                onFocus={this.onFocusFunction}
                autocomplete='off'
                ref={(obj: any) => (this.txtContactPerson = obj)}
                value={this.state.contactPerson}
                onChange={(e: any) => {
                  this.setState({ contactPerson: e.target.value });
                }}
              />
            </div>
            <div className='col-md-6 py-2 d-none'>
              {/* <label style={{ paddingBottom: '7px' }}>Contact No.</label> */}
              <TextBoxComponent
                className='input-text-border'
                id='contactNo'
                name='contactNo'
                type='text'
                placeholder={'Contact No.'}
                floatLabelType='Auto'
                onFocus={this.onFocusFunction}
                autocomplete='off'
                ref={(obj: any) => (this.txtContactNo = obj)}
                value={this.state.contactNo}
                onChange={(e: any) => {
                  this.setState({ contactNo: e.target.value });
                }}
              />
            </div>
            <div className='col-md-6 py-2'>
              {/* <label style={{ paddingBottom: '7px' }}>Sort Code</label> */}
              <TextBoxComponent
                className='input-text-border'
                id='sortCode'
                name='sortCode'
                type='text'
                placeholder={'Sort Code'}
                floatLabelType='Auto'
                onFocus={this.onFocusFunction}
                autocomplete='off'
                ref={(obj: any) => (this.txtSortCode = obj)}
                value={this.state.sortCode}
                onChange={(e: any) => {
                  this.setState({ sortCode: e.target.value });
                }}
              />
            </div>
          </div>
        )}
      </>
    );
  }
}
