import * as React from 'react';
import './Reports.css';
import Common from '../../Util/Common';
import {
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from '@syncfusion/ej2-react-navigations';
import CustomReportList from './CustomReportList';
import TeamReportList from './TeamReportList';
import MyReportList from './MyReportList';
import StatutoryReportList from './StatutoryReportList';

const { REACT_APP_ENDPOINT_CORESERVICE } = process.env;

interface Props {
  jsonDataViewer: any;
}

export default class Reports extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
  }

  state = {};

  componentDidMount(): void {}

  render() {
    return (
      <>
        <div id='divreports' className='h-100'>
          <label style={{ fontSize: '20px', fontWeight: '500' }}>Reports</label>
          <div className='row'>
            <div className='col-12 d-flex'></div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <TabComponent
                // className='tabline'
                id='tabreports'
                heightAdjustMode='Auto'
                enablePersistence={true}
              >
                <TabItemsDirective>
                  <TabItemDirective
                    headerText={'My Reports'}
                    content={() => (
                      <MyReportList
                        jsonDataViewer={this.props.jsonDataViewer}
                      />
                    )}
                  />
                  <TabItemDirective
                    headerText={'Team Reports'}
                    content={() => (
                      <TeamReportList
                        jsonDataViewer={this.props.jsonDataViewer}
                      />
                    )}
                  />
                  <TabItemDirective
                    headerText={'Custom Reports'}
                    content={() => (
                      <CustomReportList
                        jsonDataViewer={this.props.jsonDataViewer}
                      />
                    )}
                  />
                  <TabItemDirective
                    headerText={'Statutory Reports'}
                    content={() => (
                      <StatutoryReportList
                        jsonDataViewer={this.props.jsonDataViewer}
                      />
                    )}
                  />
                </TabItemsDirective>
              </TabComponent>
            </div>
          </div>
        </div>
      </>
    );
  }
}
