import {
  AnimationSettingsModel,
  DialogUtility,
} from '@syncfusion/ej2-react-popups';
import * as React from 'react';
import './stylesdialog.css';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { generate } from '@syncfusion/ej2-react-schedule';
export interface DialogBoxProps {
  isConfirm?: boolean;
  isPrompt?: boolean;
  title?: string;
  content?: string;
  okCaption?: string;
  closeCaption?: string;
  xPosition?: string;
  yPosition?: string;
  width?: string;
  height?: string;
  closeOnEscape?: boolean;
  showCloseIcon?: boolean;
  animationSettings?: AnimationSettingsModel;
  btncssClass?: string;
  dialogcssClass?: string;
  CloseEvent?: () => void;
  OkEvent?: () => void;
  onPromptInput?: (value: string) => void;
}

class DialogConfirm extends React.Component<DialogBoxProps> {
  private dialogObj: any;

  static defaultProps = {
    isConfirm: false,
    isPrompt: false,
    title: 'Information',
    content:
      '<p  class="dialog-contain">Are you sure you want to perform this action?</p>',
    okCaption: 'OK',
    closeCaption: 'CANCEL',
    xPosition: 'center',
    yPosition: 'top',
    width: '480px',
    isValidate: true,
    height: 'auto',
    closeOnEscape: false,
    showCloseIcon: false,
    // btncssClass: 'e-flat',
    dialogcssClass: 'custom-dialog',
  };

  constructor(props: DialogBoxProps) {
    super(props);
    this.dialogObj = null;
    console.log('Constructor called');
  }

  componentDidMount() {
    console.log('Component mounted');
  }

  componentWillUnmount() {
    if (this.dialogObj) {
      this.dialogObj.hide();
    }
  }

  static showDialog(config: DialogBoxProps) {
    const {
      isConfirm = false,
      isPrompt = false,
      title = 'Aruti',
      content = '<p  class="dialog-contain">Are you sure you want to perform this action?</p>',
      okCaption = 'OK',
      closeCaption = 'CANCEL',
      xPosition = 'center',
      yPosition = 'top',
      width = '480px',
      height = 'auto',
      closeOnEscape = true,
      showCloseIcon = false,
      btncssClass = 'e-flat',
      dialogcssClass = 'custom-dialog',
      OkEvent = () => {},
      CloseEvent = () => {},
      onPromptInput = () => {},
    } = config;

    let dialogObj: any;
    let dialogObj2: any;

    if (isConfirm == false) {
      dialogObj = DialogUtility.alert({
        animationSettings: { effect: 'Zoom' },
        title,
        content: content,
        okButton: {
          text: okCaption,
          icon: 'button',
          cssClass: btncssClass,
          click: () => {
            //CloseEvent();
            OkEvent();
            if (dialogObj) dialogObj.hide();
          },
        },
        position: { X: xPosition, Y: yPosition },
        width: width,
        height: height,
        closeOnEscape: closeOnEscape,
        showCloseIcon: showCloseIcon,
        cssClass: dialogcssClass,
      });
    } else if (isConfirm == true) {
      if (isPrompt == false) {
        dialogObj = DialogUtility.confirm({
          title: title,
          content: content,
          animationSettings: { effect: 'Zoom' },
          okButton: {
            text: okCaption,
            icon: 'button',
            cssClass: btncssClass,
            click: () => {
              OkEvent();
              if (dialogObj) dialogObj.hide();
            },
          },
          cancelButton: {
            text: closeCaption,
            icon: 'button',
            cssClass: btncssClass,
            click: () => {
              CloseEvent();
              if (dialogObj) dialogObj.hide();
            },
          },
          position: { X: xPosition, Y: yPosition },
          width: width,
          height: height,
          closeOnEscape: closeOnEscape,
          showCloseIcon: showCloseIcon,
          cssClass: dialogcssClass,
        });
      } else if (isPrompt == true) {
        dialogObj = DialogUtility.confirm({
          title: title,
          // content: `${content},
          //   '<p class="prompt-label">Enter reason for rejection:</p><input id="inputValue" type="text" class="prompt-input e-input" placeholder="Enter here..."  />'`,
          content: `${content}          
          <input id="inputValue" type="text" class="prompt-input e-input" placeholder="Place Your Message here..." rows="3" />`,

          animationSettings: { effect: 'Zoom' },
          okButton: {
            text: okCaption,
            icon: 'button',
            cssClass: btncssClass,
            click: () => {
              const remark = (
                document.getElementById('inputValue') as HTMLInputElement
              ).value;
              if (onPromptInput) {
                onPromptInput(remark);
              }
              if (remark) {
                OkEvent && OkEvent();
                dialogObj.hide();
              } else {
                dialogObj2 = DialogUtility.alert({
                  title: 'Alert',
                  content: 'Please enter valid information..!',
                  animationSettings: { effect: 'Zoom' },

                  okButton: {
                    text: 'Close',
                    cssClass: 'flat-button',
                    click: () => {
                      if (dialogObj2) dialogObj2.hide('fdgfgf');
                      dialogObj.show();
                      if (remark) {
                        if (dialogObj) dialogObj.hide();
                      } else {
                        dialogObj.show();
                      }
                    },
                  },
                  width: '300px',
                  height: 'auto',
                  cssClass: 'custom-alert-dialog',
                });
              }
            },
          },
          cancelButton: {
            text: closeCaption,
            icon: 'button',
            cssClass: btncssClass,
            click: () => {
              CloseEvent();
              if (dialogObj) dialogObj.hide();
            },
          },
          position: { X: xPosition, Y: yPosition },
          width: width,
          height: height,
          closeOnEscape: closeOnEscape,
          showCloseIcon: showCloseIcon,
          cssClass: dialogcssClass,
        });
      }
    }

    if (dialogObj) {
      dialogObj.show();
    }
  }

  render() {
    return <></>;
  }
}

export default DialogConfirm;
