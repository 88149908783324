import React from 'react';
import Common from '../../Util/Common';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { Console } from 'console';
import DialogConfirm from '../Dialogbox/DialogConfirm';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
interface props {
  id: string;
  title: string;
  // handleCancel: any;
  // handleAccept: any;
  code?: any;
  name?: any;
  startdate?: any;
  enddate?: any;
  guid?: any;
}
const { REACT_APP_ENDPOINT_CORESERVICE, REACT_APP_ENDPOINT_PAYROLLSERVICE } =
  process.env;

class CreatePeriod extends React.Component<props> {
  private user: any;
  private token: any = null;
  private _structure: any;
  toastObj: any;

  constructor(props: any) {
    super(props);
    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
  }

  componentDidMount() {}

  // onChange(args: any): void {
  //   let key: string = args.target.name;
  //   let value: string = args.target.value;
  //   this.setState({ [key]: value });
  // }

  // btnHandleClick(args: any) {
  //   if (args.target.id.toString().toLowerCase() == 'btnaccept') {
  //     if (document.getElementById('pr_code')) {
  //       if ((document.getElementById('pr_code') as any).value == '') {
  //  DialogConfirm.showDialog({
  //    content:
  //      '<p class="dialog-contain">Sorry code is mandatory information. <p class="dialog-contain">Please provide code to continue.</p>',
  //  });
  //         return;
  //       }
  //     }

  //     if (document.getElementById('pr_name')) {
  //       if ((document.getElementById('pr_name') as any).value == '') {
  //  DialogConfirm.showDialog({
  //    content:
  //     '<p class="dialog-contain">Sorry name is mandatory information. <p class="dialog-contain">Please provide name to continue.</p>',
  //  });
  //         return;
  //       }
  //     }

  //     if (document.getElementById('prstartdate')) {
  //       if ((document.getElementById('prstartdate') as any).value == '') {
  //  DialogConfirm.showDialog({
  //    content:
  //      '<p class="dialog-contain">Sorry start date is mandatory information. <p class="dialog-contain">Please provide start date to continue.</p>',
  //  });
  //         return;
  //       }
  //     }

  //     if (document.getElementById('prenddate')) {
  //       if ((document.getElementById('prenddate') as any).value == '') {
  //  DialogConfirm.showDialog({
  //    content:
  //      '<p class="dialog-contain">Sorry end date is mandatory information. <p class="dialog-contain">Please provide end date to continue.</p>',
  //  });
  //         return;
  //       }
  //     }

  //     if (document.getElementById('prstartdate') && document.getElementById('prenddate')) {
  //       if (new Date((document.getElementById('prenddate') as any).value).getTime() < new Date((document.getElementById('prstartdate') as any).value).getTime()) {
  //  DialogConfirm.showDialog({
  //    content:
  //      '<p class="dialog-contain">Sorry end date cannot be less than the start date. <p class="dialog-contain">Please provide correct dates to continue.</p>'
  //  });
  //         return;
  //       }
  //     }

  //     // this.SavePeriod(
  //     //   (document.getElementById('pr_code') as any).value,
  //     //   (document.getElementById('pr_name') as any).value,
  //     //   new Date((document.getElementById('prstartdate') as any).value),
  //     //   new Date((document.getElementById('prenddate') as any).value));
  //   }
  // }
  ValidateForm = () => {
    const inputs = [
      document.querySelector<HTMLInputElement>('#pr_code'),
      document.querySelector<HTMLInputElement>('#pr_name'),
      document.querySelector<HTMLInputElement>('#prstartdate'),
      document.querySelector<HTMLInputElement>('#prenddate'),
    ];

    inputs.forEach((input) => {
      if (input) {
        input.classList.remove('textbox-border-error');

        if (input.value.trim() === '') {
          input.classList.add('textbox-border-error');
        } else {
          input.classList.add('textbox-class');
        }
      }
    });
  };
  public async SavePeriod() {
    if (document.getElementById('pr_code')) {
      this.ValidateForm();
      if ((document.getElementById('pr_code') as any).value == '') {
        // DialogConfirm.showDialog({
        //   content:
        //     '<p class="dialog-contain">Sorry code is mandatory information. <p class="dialog-contain">Please provide code to continue.</p>',
        // });
        const TargetInput =
          document.querySelector<HTMLInputElement>('#pr_code');
        if (TargetInput) {
          TargetInput.style.borderBottom = '2px solid red';
        }
        return;
      }
    }

    if (document.getElementById('pr_name')) {
      if ((document.getElementById('pr_name') as any).value == '') {
        // DialogConfirm.showDialog({
        //   content:
        //     '<p class="dialog-contain">Sorry name is mandatory information. <p class="dialog-contain">Please provide name to continue.</p>',
        // });
        const TargetInput =
          document.querySelector<HTMLInputElement>('#pr_name');
        if (TargetInput) {
          TargetInput.style.borderBottom = '2px solid red';
        }
        return;
      }
    }

    if (document.getElementById('prstartdate')) {
      if ((document.getElementById('prstartdate') as any).value == '') {
        // DialogConfirm.showDialog({
        //   content:
        //     '<p class="dialog-contain">Sorry start date is mandatory information. <p class="dialog-contain">Please provide start date to continue.</p>',
        // });
        const TargetInput =
          document.querySelector<HTMLInputElement>('#prstartdate');
        if (TargetInput) {
          TargetInput.style.borderBottom = '2px solid red';
        }
        return;
      }
    }

    if (document.getElementById('prenddate')) {
      if ((document.getElementById('prenddate') as any).value == '') {
        // DialogConfirm.showDialog({
        //   content:
        //     '<p class="dialog-contain">Sorry end date is mandatory information. <p class="dialog-contain">Please provide end date to continue.</p>',
        // });
        const TargetInput =
          document.querySelector<HTMLInputElement>('#prenddate');
        if (TargetInput) {
          TargetInput.style.borderBottom = '2px solid red';
        }
        return;
      }
    }

    if (
      document.getElementById('prstartdate') &&
      document.getElementById('prenddate')
    ) {
      if (
        new Date(
          (document.getElementById('prenddate') as any).value
        ).getTime() <
        new Date(
          (document.getElementById('prstartdate') as any).value
        ).getTime()
      ) {
        // DialogConfirm.showDialog({
        //   content:
        //     '<p class="dialog-contain">Sorry end date cannot be less than the start date. <p class="dialog-contain">Please provide correct dates to continue.</p>',
        // });
        this.toastObj = ToastUtility.show({
          cssClass: 'e-toast-warning',
          icon: 'e-warning toast-icons',
          showCloseButton: true,
          content:
            'Sorry end date cannot be less than the start date.<br>Please provide correct dates to continue.',
          target: '#basic_card',
          position: { X: 'Center', Y: 'Top' },
          width: 'auto',
          timeOut: 3000,
        });
        return;
      }
    }

    let finalresponse: any = null;
    let payload: any = {
      code: (document.getElementById('pr_code') as any).value,
      name: (document.getElementById('pr_name') as any).value,
      startDate: Common.formatDate(
        new Date((document.getElementById('prstartdate') as any).value),
        'yyyy-MM-dd'
      ),
      endDate: Common.formatDate(
        new Date((document.getElementById('prenddate') as any).value),
        'yyyy-MM-dd'
      ),
      statusId: this._structure.PeriodStatusId.Open,
    };
    await Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/period/add`,
      payload,
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        finalresponse = response;
        return response;
      })
      .catch((error: any) => {
        console.error(error);
        // if (
        //   error.response &&
        //   error.response.data &&
        //   error.response.data.messages &&
        //   error.response.data.messages.length > 0
        // ) {
        //  DialogConfirm.showDialog({

        //    content: `<p class="dialog-contain">${error.response.data.messages.join('<br>')}</p>`,

        //  });
        // }
      })
      .finally(() => {});
    if (finalresponse) {
      return finalresponse;
    }
  }

  // private SavePeriod(code: any, name: any, stdate: Date, eddate: Date) {
  //   let payload: any = {
  //     code: code,
  //     name: name,
  //     startDate: Common.formatDate(stdate, 'yyyy-MM-dd'),
  //     endDate: Common.formatDate(eddate, 'yyyy-MM-dd'),
  //     statusId: this._structure.PeriodStatusId.Open
  //   }

  //   Common.ApiCallAsync(
  //     'POST',
  //     `${REACT_APP_ENDPOINT_CORESERVICE}/period/add`,
  //     payload,
  //     Common.getToken() || '',
  //     this.user,
  //     this.token.tenant
  //   )
  //     .then((response: any) => {
  //       return response.data;
  //     })
  //     .then((response: any) => {
  //       this.props.handleAccept(response);
  //       console.log(response);
  //     })
  //     .catch((error: any) => {
  //       console.error(error);
  //     })
  //     .finally(() => { });
  // }
  onFocusFunction = (event: any) => {
    event.target.classList.remove('textbox-border-error');
  };
  onCodeCreated = () => {
    const input = document.querySelector(
      'input#mobileNo'
    ) as HTMLInputElement | null;
    if (input) {
      input.addEventListener('input', this.handleCodeInputChange);
    }
  };

  handleCodeInputChange = (e: Event) => {
    const input = e.target as HTMLInputElement;
    input.value = input.value.replace(/[^0-9]/g, '');
    if (input.value.length > 20) {
      input.value = input.value.slice(0, 20);
    }
  };
  render() {
    return (
      <>
        <div id={this.props.id}>
          <div className='row align-items-center'>
            <div className='col-6 py-2'>
              {/* <label style={{ paddingBottom: '7px' }}>Code</label>
              <span className='mandatoryinfo'>*</span> */}
              <TextBoxComponent
                className='input-text-border'
                id='pr_code'
                name='pr_code'
                type='text'
                placeholder='Enter Code *'
                floatLabelType='Auto'
                created={this.onCodeCreated}
                onFocus={this.onFocusFunction}
                // required={true}
                value={this.props.code}
                // maxLength={20}
              />
            </div>
            <div className='col-6 py-2'>
              {/* <label style={{ paddingBottom: '7px' }}>Name</label>
              <span className='mandatoryinfo'>*</span> */}
              <TextBoxComponent
                className='input-text-border'
                id='pr_name'
                name='pr_name'
                type='text'
                placeholder='Enter Name *'
                floatLabelType='Auto'
                onFocus={this.onFocusFunction}
                autocomplete='off'
                value={this.props.name}
                // required={true}
              />
            </div>
          </div>
          <div className='row align-items-center'>
            <div className='col-6 py-2'>
              {/* <label style={{ paddingBottom: '7px' }}>Start Date</label>
              <span className='mandatoryinfo'>*</span> */}
              <DatePickerComponent
                id='prstartdate'
                format={this.user.settings.formats.DateShort}
                showClearButton={false}
                cssClass='e-hidedate-icon'
                openOnFocus={true}
                onFocus={this.onFocusFunction}
                showTodayButton={false}
                placeholder='Start Date *'
                floatLabelType='Auto'
                value={this.props.startdate}
              />
            </div>
            <div className='col-6 py-2'>
              {/* <label style={{ paddingBottom: '7px' }}>End Date</label>
              <span className='mandatoryinfo'>*</span> */}
              <DatePickerComponent
                id='prenddate'
                format={this.user.settings.formats.DateShort}
                showClearButton={false}
                onFocus={this.onFocusFunction}
                cssClass='e-hidedate-icon'
                openOnFocus={true}
                showTodayButton={false}
                placeholder='End Date *'
                floatLabelType='Auto'
                value={this.props.enddate}
              />
            </div>
          </div>
          {/* <div className='row' style={{ textAlign: 'right', display: 'flex', float: 'right' }}>
            <div className='col-12 py-2' style={{ marginTop: '10px' }} >
              <button className='flat-button' id='btnCancel' onClick={this.props.handleCancel}>Cancel</button>
              <button className='flat-button' id='btnAccept' onClick={this.btnHandleClick.bind(this)} >Accept</button>
            </div>
          </div> */}
        </div>
      </>
    );
  }
}

export default CreatePeriod;
