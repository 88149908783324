import * as React from 'react';
import './MyReportList.css';
import Common from '../../Util/Common';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ListBoxComponent } from '@syncfusion/ej2-react-dropdowns';
import { ListViewComponent } from '@syncfusion/ej2-react-lists';
import LinkList from '../LinkList/LinkList';

const { REACT_APP_ENDPOINT_CORESERVICE } = process.env;

interface Props {
  jsonDataViewer: any;
}

export default class MyReportList extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;
  private dataEmployee: any;
  private dataPayroll: any;
  private dataLeave: any;

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');

    this.dataEmployee = this._structure.Reports.filter(
      (x: any) =>
        x.ReportGroupId === this._structure.ReportsGroup.Employee.Id &&
        x.IsESS === true
    );

    this.dataPayroll = this._structure.Reports.filter(
      (x: any) =>
        x.ReportGroupId === this._structure.ReportsGroup.Payroll.Id &&
        x.IsESS === true
    );

    this.dataLeave = this._structure.Reports.filter(
      (x: any) =>
        x.ReportGroupId === this._structure.ReportsGroup.Leave.Id &&
        x.IsESS === true
    );
  }

  state = {};

  componentDidMount(): void {}

  render() {
    let data: { [key: string]: Object }[] = [
      {
        text: 'Account Settings',
        iconCss: 'fa-regular fa-star',
      },
      { text: 'Address Book', iconCss: 'fa-regular fa-star' },
      { text: 'Delete', iconCss: 'fa-regular fa-star' },
      { text: 'Forward', iconCss: 'fa-regular fa-star' },
      { text: 'Reply', iconCss: 'fa-regular fa-star' },
      { text: 'Reply All', iconCss: 'fa-regular fa-star' },
      {
        text: 'Save All Attachments',
        iconCss: 'fa-regular fa-star',
      },
      { text: 'Save As', iconCss: 'fa-regular fa-star' },
      { text: 'Touch/Mouse Mode', iconCss: 'fa-regular fa-star' },
      { text: 'Undo', iconCss: ' fa-regular fa-star' },
    ];

    let fields: object = { text: 'text', iconCss: 'iconCss' };

    return (
      <>
        <div id='divmyreportlist'>
          <div className='row'>
            <div className='col-md-8'>My Favorites</div>
            <div className='col-md-4 d-flex justify-content-end'>
              <div className='w-100 searchBox-custom-report'>
                <div className='col-md-12 d-flex'>
                  <div className='custom-report-searchdiv'>
                    <span
                      className='searchInput-custom-report e-input-group e-control-wrapper e-inherit px-1'
                      style={{ background: 'none', border: 'none' }}
                    >
                      <TextBoxComponent
                        // ref={(scope) => {
                        //   textboxObj = scope;
                        // }}
                        placeholder='Search Reports'
                        showClearButton={true}
                        // onChange={onSearchTextChange}
                        title='Search Reports'
                        autocomplete='off'
                      ></TextBoxComponent>
                      <span
                        id={`myreportlist-search`}
                        className='searchIcon-custom-report e-icons e-search e-input-group-icon'
                        title='Search'
                      ></span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>&nbsp;</div>
          </div>
          <div className='row d-none'>
            <div className='col-md-2 '>
              <label className=''>Employee Reports</label>
              <div className='row mt-3'>
                <div className='col-md-12'>
                  <div id='myreportlist-employee'>
                    <LinkList
                      key={Math.random()}
                      id='myreportlinklist-employee'
                      jsonDataViewer={this.props.jsonDataViewer}
                      dataSource={this.dataEmployee}
                      fields={{ id: 'Id', text: 'Text', iconCss: 'IconCss' }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-2'>
              <label className=''>Payroll Reports</label>
              <div className='row mt-3'>
                <div className='col-md-12'>
                  <div id='myreportlist-payroll'>
                    <LinkList
                      key={Math.random()}
                      id='myreportlinklist-payroll'
                      jsonDataViewer={this.props.jsonDataViewer}
                      dataSource={this.dataPayroll}
                      fields={{ id: 'Id', text: 'Text', iconCss: 'IconCss' }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-2'>
              <label className=''>Leave Reports</label>
              <div className='row mt-3'>
                <div className='col-md-12'>
                  <div id='myreportlist-leave'>
                    <LinkList
                      key={Math.random()}
                      id='myreportlinklist-employee'
                      jsonDataViewer={this.props.jsonDataViewer}
                      dataSource={this.dataLeave}
                      fields={{ id: 'Id', text: 'Text', iconCss: 'IconCss' }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-2'>
              <label className=''>Attendance Reports</label>
            </div>
            <div className='col-md-2'>
              <label className=''>Performance Reports</label>
            </div>
            <div className='col-md-2'>
              <label className=''>Training Reports</label>
            </div>
          </div>
        </div>
      </>
    );
  }
}
