// * -----------------------------------------------------------------------------------------------------------------1.Aknowlege

// * -----------------------------------------------------------------------------------------------------------------2.Initialize
import * as React from 'react';
import './AllocationList.css';
import Common from '../../Util/Common';
import { Dialog } from '@syncfusion/ej2-react-popups';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';

// * ------------------------------------------------------------------------------------------------------------------3.Style

const { REACT_APP_ENDPOINT_CORESERVICE, REACT_APP_ENDPOINT_PAYROLLSERVICE } =
  process.env;

interface Props {
  jsonDataViewer: any;
  editingnode: any;
}

export default class Allocation extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;
  private allocation_id: string = '';
  private allocation_hasCode: boolean = false;
  private allocation_hasCustomCode: boolean = false;

  private txtCode: any = null;
  private txtName: any = null;
  private txtCustomCode: any = null;

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');

    this.InitializeData();
  }

  // * ------------------------------------------------------------------------------------------------------------------5.State
  state = {
    alloccode: this.props.editingnode ? this.props.editingnode.alloccode : '',
    allocname: this.props.editingnode ? this.props.editingnode.allocname : '',
    alloccustomcode: this.props.editingnode
      ? this.props.editingnode.alloccustomcode
      : '',
    allocdesc: '',
  };

  // * ------------------------------------------------------------------------------------------------------------------Event Handler
  componentDidMount(): void {}

  public InitializeData() {
    this.allocation_id = this.props.jsonDataViewer
      ? this.props.jsonDataViewer.id.toLowerCase()
      : '';

    switch (this.allocation_id) {
      case 'branch':
        break;

      case 'department':
        break;

      case 'job':
      case 'position':
        this.allocation_hasCode = true;
        break;

      case 'grade':
      case 'level':
        this.allocation_hasCode = true;
        break;

      case 'costcenter':
        this.allocation_hasCode = true;
        if (this.props.editingnode['isgroup'] === false) {
          this.allocation_hasCustomCode = true;
        }

        break;

      default:
        break;
    }
  }

  public GetState() {
    return this.state;
  }
  onFocusFunction = (event: any) => {
    event.target.classList.remove('textbox-border-error');
  };
  render() {
    let cnfDlg: Dialog;

    return (
      <>
        <div id='divAllocation' className='py-2'>
          {this.allocation_hasCode === true ? (
            <div className='row align-items-center'>
              <div className='col-12 py-2'>
                {/* <label style={{ paddingBottom: '7px' }}>Code</label>
                <span className='mandatoryinfo'>*</span> */}
                <TextBoxComponent
                  className='input-text-border'
                  id='alloccode'
                  name='alloccode'
                  type='text'
                  placeholder={'Code *'}
                  floatLabelType='Auto'
                  autocomplete='off'
                  onFocus={this.onFocusFunction}
                  ref={(obj: any) => (this.txtCode = obj)}
                  value={this.state.alloccode}
                  onChange={(e: any) => {
                    this.setState({ alloccode: e.target.value });
                    // setTimeout(() => {
                    //   this.txtCode.focus();
                    // }, 100);
                  }}
                />
              </div>
            </div>
          ) : null}
          <div className='row align-items-center'>
            <div className='col-12 py-2'>
              {/* <label style={{ paddingBottom: '7px' }}>Name</label>
              <span className='mandatoryinfo'>*</span> */}
              <TextBoxComponent
                className='input-text-border'
                id='allocname'
                name='allocname'
                //key={Math.random()}
                type='text'
                placeholder={'Name *'}
                floatLabelType='Auto'
                onFocus={this.onFocusFunction}
                autocomplete='off'
                //autoFocus={true}
                ref={(obj: any) => (this.txtName = obj)}
                value={this.state.allocname}
                onChange={(e: any) => {
                  this.setState({ allocname: e.target.value });
                  // setTimeout(() => {
                  //   this.txtName.focus();
                  // }, 100);
                }}
              />
            </div>
          </div>
          {this.allocation_hasCustomCode === true ? (
            <div className='row align-items-center'>
              <div className='col-12 py-2'>
                {/* <label style={{ paddingBottom: '7px' }}>Custom Code</label> */}
                <TextBoxComponent
                  className='input-text-border'
                  id='alloccustomcode'
                  name='alloccustomcode'
                  type='text'
                  placeholder={'Custom Code'}
                  floatLabelType='Auto'
                  onFocus={this.onFocusFunction}
                  autocomplete='off'
                  ref={(obj: any) => (this.txtCustomCode = obj)}
                  value={this.state.alloccustomcode}
                  onChange={(e: any) => {
                    this.setState({ alloccustomcode: e.target.value });
                    // setTimeout(() => {
                    //   this.txtCustomCode.focus();
                    // }, 100);
                  }}
                />
              </div>
            </div>
          ) : null}
        </div>
      </>
    );
  }
}
