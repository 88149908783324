import * as React from 'react';
import Common from '../../Util/Common';
import LinkList from '../LinkList/LinkList';
import {
  Dialog,
  DialogComponent,
  DialogUtility,
} from '@syncfusion/ej2-react-popups';
import StatutoryReport from './StatutoryReport';
interface Props {
  jsonDataViewer: any;
}
export default class StatutoryReportList extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;
  private dataStatutory: any;

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
    console.log(this._structure.Reports);

    this.dataStatutory = this._structure.Reports.filter(
      (x: any) =>
        x.ReportGroupId === this._structure.ReportsGroup.Statutory.Id &&
        x.IsESS === false &&
        (x.Country === this.user.countryName ||
          x.Country === '' ||
          x.Country === undefined)
    );
  }

  state = {
    strReportId: '',
    strReportName: '',
    showNssfReport: false,
    showNhifReport: false,
    showTaxReport: false,
    showWcfReport: false,
    showHousingLevyReport: false,
    showNitaReport: false,
    showNhifKenyaReport: false,
    showNssfKenyaReport: false,
  };

  public DialogButtonModel: any = [
    {
      buttonModel: {
        content: Common.toTitleCase('Cancel'),
        cssClass: 'flat-button',
      },
      click: () =>
        this.setState({
          strReportId: '',
          strReportName: '',
          showNssfReport: false,
          showNhifReport: false,
          showTaxReport: false,
          showWcfReport: false,
          showHousingLevyReport: false,
          showNitaReport: false,
          showNhifKenyaReport: false,
          showNssfKenyaReport: false,
        }),
    },
  ];

  render() {
    const onLinkItemClick = (e: any) => {
      this.setState({
        strReportId: e.id,
      });
      if (e.id == 'NSSF_Report') {
        this.setState({
          showNssfReport: true,
          strReportName: 'NSSF Report',
        });
      } else if (e.id == 'NHIF_Report') {
        this.setState({
          showNhifReport: true,
          strReportName: 'NHIF Report',
        });
      } else if (e.id == 'Tax_Report') {
        this.setState({
          showTaxReport: true,
          strReportName: 'Tax Report',
        });
      } else if (e.id == 'WCF_Report') {
        this.setState({
          showWcfReport: true,
          strReportName: 'WCF Report',
        });
      } else if (e.id == 'Housing_Levy_Report') {
        this.setState({
          showHousingLevyReport: true,
          strReportName: 'Housing Levy Report',
        });
      } else if (e.id == 'NITA_Report') {
        this.setState({
          showNitaReport: true,
          strReportName: 'NITA Report',
        });
      } else if (e.id == 'NHIF_Report_Kenya') {
        this.setState({
          showNhifKenyaReport: true,
          strReportName: 'NHIF Report',
        });
      } else if (e.id == 'NSSF_Report_Kenya') {
        this.setState({
          showNssfKenyaReport: true,
          strReportName: 'NSSF Report',
        });
      }
    };

    const dialogContent = (e: any) => {
      var content: any;

      content = (
        <>
          <div className='row align-items-center'>
            <StatutoryReport reportid={this.state.strReportId} />
          </div>
        </>
      );
      return content;
    };

    return (
      <>
        <div id='divstatutoryreportlist'>
          <div className='row'>
            <div className='col-md-2'>
              <div className='row mt-3'>
                <div className='col-md-12'>
                  <div id='statutoryreportlist'>
                    <LinkList
                      key={Math.random()}
                      id='statutoryreportlinklist'
                      jsonDataViewer={this.props.jsonDataViewer}
                      dataSource={this.dataStatutory}
                      fields={{ id: 'Id', text: 'Text', iconCss: 'IconCss' }}
                      onLinkItemClick={(e: any) => onLinkItemClick(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.showNssfReport && (
          <DialogComponent
            id='defaultDialog-nssfreport'
            showCloseIcon={true}
            visible={this.state.showNssfReport}
            width={'90vw'}
            height={'90vh'}
            close={() => {
              this.setState({ showNssfReport: false });
            }}
            content={dialogContent}
            isModal={true}
            header={this.state.strReportName}
            buttons={this.DialogButtonModel}
            statelessTemplates={[]}
          ></DialogComponent>
        )}
        {this.state.showNhifReport && (
          <DialogComponent
            id='defaultDialog-nhifreport'
            showCloseIcon={true}
            visible={this.state.showNhifReport}
            width={'90vw'}
            height={'90vh'}
            close={() => {
              this.setState({ showNhifReport: false });
            }}
            content={dialogContent}
            isModal={true}
            header={this.state.strReportName}
            buttons={this.DialogButtonModel}
            statelessTemplates={[]}
          ></DialogComponent>
        )}
        {this.state.showTaxReport && (
          <DialogComponent
            id='defaultDialog-taxreport'
            showCloseIcon={true}
            visible={this.state.showTaxReport}
            width={'90vw'}
            height={'90vh'}
            close={() => {
              this.setState({ showTaxReport: false });
            }}
            content={dialogContent}
            isModal={true}
            header={this.state.strReportName}
            buttons={this.DialogButtonModel}
            statelessTemplates={[]}
          ></DialogComponent>
        )}
        {this.state.showWcfReport && (
          <DialogComponent
            id='defaultDialog-wcfreport'
            showCloseIcon={true}
            visible={this.state.showWcfReport}
            width={'90vw'}
            height={'90vh'}
            close={() => {
              this.setState({ showWcfReport: false });
            }}
            content={dialogContent}
            isModal={true}
            header={this.state.strReportName}
            buttons={this.DialogButtonModel}
            statelessTemplates={[]}
          ></DialogComponent>
        )}
        {this.state.showHousingLevyReport && (
          <DialogComponent
            id='defaultDialog-housinglevyreport'
            showCloseIcon={true}
            visible={this.state.showHousingLevyReport}
            width={'90vw'}
            height={'90vh'}
            close={() => {
              this.setState({ showHousingLevyReport: false });
            }}
            content={dialogContent}
            isModal={true}
            header={this.state.strReportName}
            buttons={this.DialogButtonModel}
            statelessTemplates={[]}
          ></DialogComponent>
        )}
        {this.state.showNitaReport && (
          <DialogComponent
            id='defaultDialog-nitareport'
            showCloseIcon={true}
            visible={this.state.showNitaReport}
            width={'90vw'}
            height={'90vh'}
            close={() => {
              this.setState({ showNitaReport: false });
            }}
            content={dialogContent}
            isModal={true}
            header={this.state.strReportName}
            buttons={this.DialogButtonModel}
            statelessTemplates={[]}
          ></DialogComponent>
        )}
        {this.state.showNhifKenyaReport && (
          <DialogComponent
            id='defaultDialog-nhifkenyareport'
            showCloseIcon={true}
            visible={this.state.showNhifKenyaReport}
            width={'90vw'}
            height={'90vh'}
            close={() => {
              this.setState({ showNhifKenyaReport: false });
            }}
            content={dialogContent}
            isModal={true}
            header={this.state.strReportName}
            buttons={this.DialogButtonModel}
            statelessTemplates={[]}
          ></DialogComponent>
        )}
        {this.state.showNssfKenyaReport && (
          <DialogComponent
            id='defaultDialog-nssfkenyareport'
            showCloseIcon={true}
            visible={this.state.showNssfKenyaReport}
            width={'90vw'}
            height={'90vh'}
            close={() => {
              this.setState({ showNssfKenyaReport: false });
            }}
            content={dialogContent}
            isModal={true}
            header={this.state.strReportName}
            buttons={this.DialogButtonModel}
            statelessTemplates={[]}
          ></DialogComponent>
        )}
      </>
    );
  }
}
