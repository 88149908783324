/*
  ---------------------------------------------------------------------------------------------------------------INIT - DO NOT EDIT
*/
import React, { ReactNode, useEffect } from 'react';
import * as ReactDOMServer from 'react-dom/server';

/*
  ----------------------------------------------------------------------------------------------------------LIB IMPORT
*/

// SAMPLE
// import { [...] } from '@syncfusion/...';
import axios from 'axios';
import { ContextMenuItemModel } from '@syncfusion/ej2-react-grids';
import { MenuItemModel } from '@syncfusion/ej2-react-navigations';
import { DateTime } from '@syncfusion/ej2-react-charts';
import DialogConfirm from '../Components/Dialogbox/DialogConfirm';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
/*
  ----------------------------------------------------------------------------------------------------------3.Style IMPORT
*/

// SAMPLE
// import './filename.css';

/*
----------------------------------------------------------------------------------------------------------UNCOMMENT IF XML2JS ARE REQUIRED
*/
const parser = require('xml2js').Parser({
  explicitRoot: false,
  explicitArray: false,
});

/*
----------------------------------------------------------------------------------------------------------DOTENV VAR DECLARATION
*/
const {
  REACT_APP_ENDPOINT_API,
  REACT_APP_ENDPOINT_TOKENS,
  REACT_APP_ENDPOINT_PORT,
} = process.env;

// * SUHAIL - 2023-04-05 - 10
export interface JsonTree {
  object: number;
  field: number;
  fieldValue: string;
  value: string;
  rFieldId: number;
  rFieldValue: string;
  idx: number;
  parentidx: number;
  children?: JsonTree[];
  subMenuJsonList?: JsonTree[];
}

export interface SaveDto {
  object: number;
  field: number;
  value: string;
  unique: boolean;
  table: string;
}

export interface GridColumnStructure {
  idx: number;
  object: number;
  label: string;
  source: string;
  width: number | undefined;
}

export interface HTMLReportHeader {
  reportTitle?: string;
  companyName?: string;
}

/*
----------------------------------------------------------------------------------------------------------CLASS DECLARATION
*/
class Common extends React.Component {
  static toastObj: any;
  constructor(props: any) {
    super(props);

    /*
      ----------------------------------------------------------------------------------------------------------EVENT BINDING
    */
    // SAMPLE ON EVENT BINDING
    // this.onInputFocus = this.onInputFocus.bind(this);
  }

  /*
    ----------------------------------------------------------------------------------------------------------5.State
    // NAME - DATE - TIME SPENT
    // SAMPLE // DIMITRI - 2023-02-17 - 2.00 
  */
  state = {
    //{...}
  };

  /*
  ----------------------------------------------------------------------------------------------------------OP. AT MOUNT
    // NAME - DATE - TIME SPENT
    // SAMPLE // DIMITRI - 2023-02-17 - 2.00
  */
  componentDidMount() {
    // TODO ACTIONS
  }

  /*
  ----------------------------------------------------------------------------------------------------------4.Api
    // NAME - DATE - TIME SPENT
    // SAMPLE // DIMITRI - 2023-02-17 - 2.00
    */
  private MODEL_API(): any {
    // TODO API
  }

  /*
  ----------------------------------------------------------------------------------------------------------READER
  // NAME - DATE - TIME SPENT
  // SAMPLE // DIMITRI - 2023-02-17 - 2.00
  */
  private MODEL_READER(): any {
    // TODO READER
  }

  /*
  ----------------------------------------------------------------------------------------------------------6.Adapter
  // NAME - DATE - TIME SPENT
  // SAMPLE // DIMITRI - 2023-02-17 - 2.00
  */
  private MODEL_ADAPTER(): any {
    // TODO ADAPTER
  }

  /*
  ----------------------------------------------------------------------------------------------------------COMMON METHODS 
  // NAME - DATE - TIME SPENT
  // SAMPLE // DIMITRI - 2023-02-17 - 2.00
  // * SUHAIL - 2023-02-20 - 00:10
  // * SUHAIL - 2023-02-23 - 1.00
  // * SUHAIL - 2023-02-24 - 0.10
  */
  public static ApiCallSync(
    method: string,
    getpath: string,
    requestdata?: any,
    token: string = ''
  ) {
    let url = new URL(REACT_APP_ENDPOINT_API + getpath);
    url.port = '' + REACT_APP_ENDPOINT_PORT;
    axios({
      method: method,
      url: url.toString(), //'' + REACT_APP_ENDPOINT_API + getpath,
      data: requestdata,
      headers: {
        Authorization: `${
          token !== null || token !== '' ? `Bearer ${token}` : null
        } `,
      },
    })
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => {
        console.error(`error response : ${error}`);
        throw error;
      });
  }

  public static async ApiCallAsync(
    method: string,
    getpath: string,
    requestdata?: any,
    token: string = '',
    usr: any = null,
    tenant: any = null,
    responseType: any = null
  ) {
    let url = new URL(REACT_APP_ENDPOINT_API + getpath);
    url.port = '' + REACT_APP_ENDPOINT_PORT;
    return await axios({
      method: method,
      url: '' + REACT_APP_ENDPOINT_API + getpath,
      data: requestdata,
      headers: {
        Authorization: `${
          token !== null || token !== '' ? `Bearer ${token}` : null
        } `,
        //user: usr !== null ? JSON.stringify(usr) : null,
        user: null,
        tenant: tenant !== null ? tenant : null,
      },
      responseType: responseType,
    })
      .then((response: any) => {
        return response;
      })
      .catch(async (error: any) => {
        let finalresponse: any = null;
        console.error(error);
        if (
          error.response &&
          error.response.status == 401 &&
          error.response.data &&
          error.response.data.error &&
          (error.response.data.error === 'invalid_token' ||
            error.response.data.error === 'Invalid Refresh Token.')
        ) {
          let tkn: string = '';
          let rtkn: string = '';
          await axios({
            method: 'POST',
            url:
              '' +
              REACT_APP_ENDPOINT_API +
              REACT_APP_ENDPOINT_TOKENS +
              '/refresh',
            data: { token: token, refreshToken: this.getItem('refreshtoken') },
            headers: { tenant: tenant !== null ? tenant : null },
          })
            .then(async (response: any) => {
              let tkn: string = response.data.token;
              let rtkn: string = response.data.refreshToken;
              Common.setItem('token', tkn);
              Common.setItem('refreshtoken', rtkn);

              return await this.ApiCallAsync(
                method,
                getpath,
                requestdata,
                tkn,
                usr,
                tenant
              )
                .then((response: any) => {
                  finalresponse = response;
                  return response;
                })
                .catch((error: any) => {
                  console.error(error);
                });
            })
            .catch((error: any) => {
              console.error(error);
            });
        } else {
          console.error(`error response : ${error}`);
          if (
            error.response &&
            error.response.data &&
            error.response.data.messages &&
            error.response.data.messages.length > 0
          ) {
            // DialogConfirm.showDialog({
            //   content: `<p class="dialog-contain">${error.response.data.messages.join(
            //     '<br>'
            //   )}</p>`,
            // });
            this.toastObj = ToastUtility.show({
              cssClass: 'e-toast-error',
              icon: 'far fa-circle-xmark',
              showCloseButton: true,
              content: `${error.response.data.messages.join('<br>')}`,
              target: '#basic_card',
              position: { X: 'Center', Y: 'Top' },
              width: 'auto',
              timeOut: 5000,
            });
            //console.log('error Common Page', error);
          } //else if (
          //   //COMMENT ELSE IF PART WHEN WENT LIVE FOR SECURITY RISK
          //   error.response &&
          //   error.response.data &&
          //   error.response.data.messages &&
          //   error.response.data.messages.length <= 0 &&
          //   error.response.data.exception
          // ) {
          //  DialogConfirm.showDialog({
          //    content: `<p class="dialog-contain">${error.response.data.exception}</p>`,
          //  });
          // }
          throw error;
        }
        if (finalresponse) {
          return finalresponse;
        }
      });
  }

  // return the token from the session storage
  // * SUHAIL - 2023-02-23 - 0.10
  public static getToken() {
    return sessionStorage.getItem('token');
  }

  // return the token from the session storage
  // * SUHAIL - 2023-02-24 - 0.10
  public static getUser() {
    return sessionStorage.getItem('user');
  }

  // * SUHAIL - 2023-03-28 - 10
  public static getStructure() {
    //return sessionStorage.getItem('structure');
    if (sessionStorage.getItem('user') !== null) {
      let _user = JSON.parse(sessionStorage.getItem('user')!);
      return JSON.stringify(_user.structure);
    } else {
      return null;
    }
  }

  // * SUHAIL - 2023-05-12 - 10
  public static getFullJson() {
    return sessionStorage.getItem('fulljson');
  }

  public static setItem(key: string, value: any) {
    if (typeof value === 'string') {
      return sessionStorage.setItem(key, value);
    } else {
      return sessionStorage.setItem(key, JSON.stringify(value));
    }
  }

  public static getItem(key: string) {
    return sessionStorage.getItem(key);
  }

  // set the token and user from the session storage
  // * SUHAIL - 2023-02-23 - 0.20
  // * SUHAIL - 2023-03-28 - 10
  public static setUserSession = (
    token: string,
    user: any,
    structure: any,
    fulljson: any
  ) => {
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('user', JSON.stringify(user));
    sessionStorage.setItem('structure', JSON.stringify(structure));
    sessionStorage.setItem('fulljson', JSON.stringify(fulljson));
  };

  // remove the token and user from the session storage
  // * SUHAIL - 2023-02-23 - 0.20
  public static removeUserSession = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('structure');
    sessionStorage.removeItem('fulljson');
    localStorage.removeItem('tabdataviewer-tabcomponent');
  };

  private static LocalStoreKeys: string[] = [];

  public static ValidateLocalStore = (getKeyFromLocalStore: string) => {
    this.LocalStoreKeys.push(getKeyFromLocalStore);

    const hasGrdleaveKey = this.LocalStoreKeys.some((key) =>
      key.startsWith('grdleave_')
    );

    if (hasGrdleaveKey) {
      console.log(
        'Key is Found in locastorage start eith   grdleave_ is":',
        this.LocalStoreKeys
      );
    } else {
      localStorage.clear();
    }
  };

  // * SUHAIL - 2023-03-29 - 20
  public static FindJsonByIndex(jsons: any, idx: number): any {
    let js: any = jsons.find((x: any) => x.idx === idx);
    if (js !== undefined) {
      return js;
    }

    if (jsons.length === 0) {
      return null;
    }

    let jsnfinal: any = null;
    jsons.forEach((jsn: any) => {
      let js1: any = this.FindJsonByIndex(jsn.children, idx);
      if (js1 !== null) {
        jsnfinal = js1;
      }
    });
    return jsnfinal;
  }

  // * SUHAIL - 2023-03-29 - 20
  // * SUHAIL - 2023-04-03 - 60
  public static FindJsonByObjectId(
    jsons: any,
    id: number,
    user: any,
    fld: number = 0,
    rfld: number = 0
  ): any {
    try {
      //let js: any = jsons.filter((x: any) => x.object === id);
      let js: any = jsons.filter((x: any) =>
        x.object === id && fld > 0
          ? x.field === fld
          : x.object === id && rfld > 0
          ? x.rFieldId === rfld
          : x.object === id
      );
      // if (js !== undefined) {
      //   return js;
      // }
      if (js.length > 0 && (user == null || js.length == 1)) {
        return js[0];
      } else if (js.length > 0 && user !== null && js.length > 1) {
        if (fld > 0) {
          let jsFld: any = js.filter((x: any) => x.field === fld);
          if (jsFld.length > 0) {
            if (jsFld.length == 1) {
              return jsFld[0];
            } else {
              js = jsFld;
            }
          } else {
            return null;
          }
        }

        if (rfld > 0) {
          let jsFld: any = js.filter((x: any) => x.rFieldId === rfld);
          if (jsFld.length > 0) {
            if (jsFld.length == 1) {
              return jsFld[0];
            } else {
              js = jsFld;
            }
          } else {
            return null;
          }
        }

        let js2: any = js.filter((x: any) => x.field === user.language);
        if (js2.length > 0) {
          return js2[0];
        }

        js2 = js.filter((x: any) => x.field === 101);
        if (js2.length > 0) {
          return js2[0];
        }
      }

      if (jsons.length === 0) {
        return null;
      }

      let jsnfinal: any = null;
      jsons.forEach((jsn: any) => {
        let js1: any = this.FindJsonByObjectId(
          jsn.children,
          id,
          user,
          fld,
          rfld
        );
        if (js1 !== null) {
          jsnfinal = js1;
        }
      });
      return jsnfinal;
    } catch (error: any) {
      console.error(
        `error in FindJsonByObjectId for objectid : ${id}; error : ${error}`
      );
    }
  }

  // * SANDEEP - 2023-10-06 - 20
  public static FindJsonByObjectValue(
    jsons: any,
    value: string,
    user: any,
    fld: number = 0,
    rfld: number = 0,
    ignoreCase: boolean = false
  ): any {
    try {
      //let js: any = jsons.filter((x: any) => x.object === id);
      let js: any = jsons.filter((x: any) =>
        ignoreCase === false
          ? x.value === value && fld > 0
            ? x.field === fld
            : x.value === value && rfld > 0
            ? x.rFieldId === rfld
            : x.value === value
          : x.value.toLowerCase() === value.toLowerCase() && fld > 0
          ? x.field === fld
          : x.value.toLowerCase() === value.toLowerCase() && rfld > 0
          ? x.rFieldId === rfld
          : x.value.toLowerCase() === value.toLowerCase()
      );
      // if (js !== undefined) {
      //   return js;
      // }
      if (js.length > 0 && (user == null || js.length == 1)) {
        return js[0];
      } else if (js.length > 0 && user !== null && js.length > 1) {
        if (fld > 0) {
          let jsFld: any = js.filter((x: any) => x.field === fld);
          if (jsFld.length > 0) {
            if (jsFld.length == 1) {
              return jsFld[0];
            } else {
              js = jsFld;
            }
          } else {
            return null;
          }
        }

        if (rfld > 0) {
          let jsFld: any = js.filter((x: any) => x.rFieldId === rfld);
          if (jsFld.length > 0) {
            if (jsFld.length == 1) {
              return jsFld[0];
            } else {
              js = jsFld;
            }
          } else {
            return null;
          }
        }

        let js2: any = js.filter((x: any) => x.field === user.language);
        if (js2.length > 0) {
          return js2[0];
        }

        js2 = js.filter((x: any) => x.field === 101);
        if (js2.length > 0) {
          return js2[0];
        }
      }

      if (jsons.length === 0) {
        return null;
      }

      let jsnfinal: any = null;
      jsons.forEach((jsn: any) => {
        let js1: any = this.FindJsonByObjectValue(
          jsn.children,
          value,
          user,
          fld,
          rfld,
          ignoreCase
        );
        if (js1 !== null) {
          jsnfinal = js1;
        }
      });
      return jsnfinal;
    } catch (error: any) {
      console.error(
        `error in FindJsonByObjectValue for valueid : ${value}; error : ${error}`
      );
    }
  }

  // * SUHAIL - 2023-03-29 - 20
  public static FindJsonByRelationFieldId(
    jsons: any,
    id: number,
    user: any = null
  ): any {
    // let js: any = jsons.find((x: any) => x.rFieldId === id);
    // if (js !== undefined) {
    //   return js;
    // }
    let js: any = jsons.filter((x: any) => x.rFieldId === id);

    if (js.length > 0 && (user == null || js.length == 1)) {
      return js[0];
    } else if (js.length > 0 && user !== null && js.length > 1) {
      let js2: any = js.filter((x: any) => x.field === user.language);
      if (js2.length > 0) {
        return js2[0];
      }

      js2 = js.filter((x: any) => x.field === 101);
      if (js2.length > 0) {
        return js2[0];
      }
    }
    if (jsons.length === 0) {
      return null;
    }

    let jsnfinal: any = null;
    jsons.forEach((jsn: any) => {
      let js1: any = this.FindJsonByRelationFieldId(jsn.children, id, user);
      if (js1 !== null) {
        jsnfinal = js1;
      }
    });
    return jsnfinal;
  }

  // * SUHAIL - 2023-04-03 - 70
  public static FindJsonByFieldId(jsons: any, id: number): any {
    let js: any = jsons.find((x: any) => x.field === id);
    if (js !== undefined) {
      return js;
    }

    if (jsons.length === 0) {
      return null;
    }

    let jsnfinal: any = null;
    jsons.forEach((jsn: any) => {
      let js1: any = this.FindJsonByFieldId(jsn.children, id);
      if (js1 !== null) {
        jsnfinal = js1;
      }
    });
    return jsnfinal;
  }

  // * SUHAIL - 2023-04-03 - 70
  public static GetJsonByRelationFieldId(jsons: any, id: number): any {
    let jsnfinal: any = [];

    let js: any = jsons.filter((x: any) => x.rFieldId === id);
    if (js.length > 0) {
      js.forEach((j: any) => {
        jsnfinal.push(j);
      });
    }

    if (jsons.length === 0) {
      return null;
    }

    // let jsnfinal: any = null;
    jsons.forEach((jsn: any) => {
      let js1: any = this.GetJsonByRelationFieldId(jsn.children, id);
      if (js1 !== null) {
        js1.forEach((j: any) => {
          jsnfinal.push(j);
        });
      }
    });
    return jsnfinal;
  }

  public static GetContextMenu(
    cntxmenu: any,
    structure: any,
    user: any,
    skipforobjectids: string[] = [],
    skipifnochildren: boolean = false,
    defaultPreMenuItems: any = [],
    skipdefaultpremenuitemsforobjectid: number = 0,
    idx: number = 0,
    extrafieldforid: string = ''
  ) {
    let cntxMenuItems: ContextMenuItemModel[] | MenuItemModel[] | undefined =
      [];
    let cntmenu: any = cntxmenu.filter(
      (x: any) =>
        x.rFieldId === structure.Element.Child ||
        x.rFieldId === structure.Element.Group ||
        x.value.toLowerCase() === 'group' ||
        x.rFieldId === structure.MenuElements.CntxtMenuElements.CntxMenuObject
    );
    if (cntmenu.length > 0) {
      cntmenu.forEach((x: any) => {
        let cntxMenuItemsFlter: any = cntxMenuItems!.filter((xx: any) =>
          extrafieldforid
            ? xx.id === x.object.toString() + '-' + x[extrafieldforid]
            : xx.id === x.object.toString()
        );
        if (cntxMenuItemsFlter.length <= 0) {
          idx = idx + 1;
          if (
            skipforobjectids.length > 0 &&
            skipforobjectids.includes(x.object.toString()) //x.object.toString() === skipforobjectid.toString()
          ) {
            return;
          }
          let sprtr: boolean =
            x.value.toLowerCase() === 'separator' ? true : false;

          if (cntxmenu.filter((xx: any) => xx.object === x.object).length > 1) {
            let itm: any = Common.FindJsonByObjectId(cntxmenu, x.object, user);
            if (itm !== null) {
              if (
                itm.value.toLowerCase() !== 'group' &&
                x.rFieldId !==
                  structure.MenuElements.CntxtMenuElements.CntxMenuObject
              ) {
                let subitms: any =
                  x.rFieldId === structure.Element.Group ||
                  x.value.toLowerCase() === 'group'
                    ? this.GetContextMenu(
                        itm.children,
                        structure,
                        user,
                        skipforobjectids,
                        skipifnochildren,
                        defaultPreMenuItems,
                        skipdefaultpremenuitemsforobjectid,
                        idx,
                        extrafieldforid
                      )
                    : [];
                if (
                  skipifnochildren === false ||
                  subitms.length > 0 ||
                  x.rFieldId === structure.Element.Child
                ) {
                  let sbitm: any = subitms;
                  if (
                    subitms.length > 0 &&
                    skipdefaultpremenuitemsforobjectid > 0 &&
                    x.object.toString() ===
                      skipdefaultpremenuitemsforobjectid.toString()
                  ) {
                    sbitm = [...subitms];
                  } else if (
                    // subitms.length > 0 &&
                    // defaultPreMenuItems.length > 0
                    x.rFieldId === structure.Element.Group
                  ) {
                    //sbitm = [...defaultPreMenuItems, ...subitms];
                    let tmpitm: any = defaultPreMenuItems.map((x: any) => {
                      return {
                        ...x,
                        id: x.id.toString().startsWith('__')
                          ? x.id.toString() +
                            itm.object +
                            '--' +
                            itm.value +
                            idx.toString()
                          : x.id.toString(),
                      };
                    });
                    sbitm = [...tmpitm, ...subitms];
                  }
                  cntxMenuItems!.push({
                    text: sprtr ? '' : itm.value,
                    //id: itm.object.toString(),
                    id: extrafieldforid
                      ? itm.object.toString() + '-' + itm[extrafieldforid]
                      : itm.object.toString(),
                    separator: sprtr,
                    iconCss:
                      itm.children.filter(
                        (x: any) =>
                          x.rFieldId ===
                            structure.MenuElements.CntxtMenuElements
                              .CntxMenuItemType &&
                          x.value.toLowerCase() === 'checkbox'
                      ).length > 0
                        ? 'checkbox'
                        : '',
                    //items: subitms,
                    items: sbitm,
                    // items:
                    //   x.rFieldId === structure.Element.Group ||
                    //   x.value.toLowerCase() === 'group'
                    //     ? this.GetContextMenu(
                    //         itm.children,
                    //         structure,
                    //         user,
                    //         skipforobjectid
                    //       )
                    //     : [],
                  });
                }
              } else {
                cntxMenuItems = this.GetContextMenu(
                  itm.children,
                  structure,
                  user,
                  skipforobjectids,
                  skipifnochildren,
                  defaultPreMenuItems,
                  skipdefaultpremenuitemsforobjectid,
                  idx,
                  extrafieldforid
                );
              }
            }
          } else {
            if (
              x.value.toLowerCase() !== 'group' &&
              x.rFieldId !==
                structure.MenuElements.CntxtMenuElements.CntxMenuObject
            ) {
              let subitms: any =
                x.rFieldId === structure.Element.Group ||
                x.value.toLowerCase() === 'group'
                  ? this.GetContextMenu(
                      x.children,
                      structure,
                      user,
                      skipforobjectids,
                      skipifnochildren,
                      defaultPreMenuItems,
                      skipdefaultpremenuitemsforobjectid,
                      idx,
                      extrafieldforid
                    )
                  : [];
              if (
                skipifnochildren === false ||
                subitms.length > 0 ||
                x.rFieldId === structure.Element.Child
              ) {
                let sbitm: any = subitms;
                if (
                  subitms.length > 0 &&
                  skipdefaultpremenuitemsforobjectid > 0 &&
                  x.object.toString() ===
                    skipdefaultpremenuitemsforobjectid.toString()
                ) {
                  sbitm = [...subitms];
                } else if (
                  // subitms.length > 0 &&
                  // defaultPreMenuItems.length > 0
                  x.rFieldId === structure.Element.Group
                ) {
                  //sbitm = [...defaultPreMenuItems, ...subitms];
                  let tmpitm: any = defaultPreMenuItems.map((xx: any) => {
                    return {
                      ...xx,
                      id: xx.id.toString().startsWith('__')
                        ? xx.id.toString() +
                          x.object +
                          '--' +
                          x.value +
                          idx.toString()
                        : xx.id.toString(),
                    };
                  });
                  sbitm = [...tmpitm, ...subitms];
                }
                cntxMenuItems!.push({
                  text: sprtr ? '' : x.value,
                  //id: x.object.toString(),
                  id: extrafieldforid
                    ? x.object.toString() + '-' + x[extrafieldforid]
                    : x.object.toString(),
                  separator: sprtr,
                  iconCss:
                    x.children.filter(
                      (x: any) =>
                        x.rFieldId ===
                          structure.MenuElements.CntxtMenuElements
                            .CntxMenuItemType &&
                        x.value.toLowerCase() === 'checkbox'
                    ).length > 0
                      ? 'checkbox'
                      : '',
                  //items: subitms,
                  items: sbitm,
                  // items:
                  //   x.rFieldId === structure.Element.Group ||
                  //   x.value.toLowerCase() === 'group'
                  //     ? this.GetContextMenu(
                  //         x.children,
                  //         structure,
                  //         user,
                  //         skipforobjectid
                  //       )
                  //     : [],
                });
              }
            } else {
              cntxMenuItems = this.GetContextMenu(
                x.children,
                structure,
                user,
                skipforobjectids,
                skipifnochildren,
                defaultPreMenuItems,
                skipdefaultpremenuitemsforobjectid,
                idx,
                extrafieldforid
              );
            }
          }
        }
      });

      return cntxMenuItems;
    } else {
      return [];
    }
  }

  public static GetCustomContextMenu(
    cntxmenu: any,
    field_id: string,
    field_text: string,
    field_isgroup: string,
    field_isseparator: string,
    field_icon: string,
    field_type: string,
    field_children: string,
    skipforobjectids: string[] = [],
    skipifnochildren: boolean = false,
    defaultPreMenuItems: any = [],
    skipdefaultpremenuitemsforobjectid: number = 0,
    idx: number = 0,
    extrafieldforid: string = ''
  ) {
    let cntxMenuItems: ContextMenuItemModel[] | MenuItemModel[] | undefined =
      [];

    cntxmenu.forEach((x: any) => {
      let field_childrenCustom: string = field_children;
      if (x['subMenuJsonList']) {
        field_childrenCustom = 'subMenuJsonList';
      }
      let isgrp: boolean = x[field_isgroup];
      if (!isgrp) {
        isgrp = x[field_isgroup.toLowerCase()] ?? false;
      }
      let cntxMenuItemsFlter: any = cntxMenuItems!.filter((xx: any) =>
        extrafieldforid
          ? xx[field_id] === x[field_id].toString() + '-' + x[extrafieldforid]
          : xx[field_id] === x[field_id].toString()
      );
      if (cntxMenuItemsFlter.length <= 0) {
        idx = idx + 1;
        if (
          skipforobjectids.length > 0 &&
          skipforobjectids.includes(x[field_id].toString())
        ) {
          return;
        }
        let sprtr: boolean = x[field_isseparator];
        //if (x[field_isgroup] === false) {
        let subitms: any =
          isgrp === true && x[field_childrenCustom].length > 0
            ? this.GetCustomContextMenu(
                x[field_childrenCustom],
                field_id,
                field_text,
                field_isgroup,
                field_isseparator,
                field_icon,
                field_type,
                field_children,
                skipforobjectids,
                skipifnochildren,
                defaultPreMenuItems,
                skipdefaultpremenuitemsforobjectid,
                idx,
                extrafieldforid
              )
            : [];
        if (skipifnochildren === false || subitms.length > 0) {
          let sbitm: any = subitms;
          if (
            subitms.length > 0 &&
            skipdefaultpremenuitemsforobjectid > 0 &&
            x[field_id].toString() ===
              skipdefaultpremenuitemsforobjectid.toString()
          ) {
            sbitm = [...subitms];
          } else if (isgrp === true && x[field_childrenCustom].length > 0) {
            //sbitm = [...defaultPreMenuItems, ...subitms];
            let tmpitm: any = defaultPreMenuItems.map((xx: any) => {
              return {
                ...xx,
                field_id: xx.id.toString().startsWith('__')
                  ? xx.id.toString() +
                    x.object +
                    '--' +
                    x.value +
                    idx.toString()
                  : xx.id.toString(),
              };
            });
            sbitm = [...tmpitm, ...subitms];
          }
          cntxMenuItems!.push({
            text: sprtr ? '' : x[field_text],
            id: extrafieldforid
              ? x[field_id].toString() + '-' + x[extrafieldforid]
              : x[field_id].toString(),
            separator: sprtr,
            iconCss: x[field_type] ? 'checkbox' : x[field_icon] ?? '',
            items: sbitm,
          });
        }
        // } else {
        //   cntxMenuItems = this.GetCustomContextMenu(
        //     x.children,
        //     field_id,
        //     field_text,
        //     field_isgroup,
        //     field_isseparator,
        //     field_type,
        //     field_children,
        //     skipforobjectids,
        //     skipifnochildren,
        //     defaultPreMenuItems,
        //     skipdefaultpremenuitemsforobjectid,
        //     idx,
        //     extrafieldforid
        //   );
        // }
      }
    });

    return cntxMenuItems;
  }

  public static GetSelectedContextMenuRootMenu(
    item: any,
    maxRootID: number = 0
  ) {
    let rootMenu: any = item;
    if (
      item.parentObj &&
      item.parentObj.text &&
      item.parentObj.id.toString().includes('-')
        ? Number(item.parentObj.id.toString().split('-')[0]) > maxRootID
        : Number(item.parentObj.id) > maxRootID
    ) {
      rootMenu = this.GetSelectedContextMenuRootMenu(item.parentObj, maxRootID);
    }
    return rootMenu;
  }

  public static toCamelCase(str: string): string {
    //return str.charAt(0).toLowerCase() + str.slice(1).toLowerCase();
    return str
      .split('.')
      .map((s: string) => {
        return s.charAt(0).toLowerCase() + s.slice(1);
      })
      .join('.');
  }

  public static toTitleCase(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  public static toTitleCaseAll(str: string): string {
    return str
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase())
      .join(' ');
  }

  // * SUHAIL - 2023-04-07 - 10
  public static ClearStatusBar() {
    let statusbar: any = document.getElementById(
      'layout-footer-right-div'
    ) as Element;
    if (statusbar !== null) {
      statusbar.innerText = '';
    }
  }

  // * SUHAIL - 2023-04-07 - 20
  public static SetStatusBar(msg: string) {
    let statusbar: any = document.getElementById(
      'layout-footer-right-div'
    ) as Element;
    if (statusbar !== null) statusbar.innerText = msg;
  }

  // * SUHAIL - 2023-05-09 - 20
  public static ShowSpinner(targetDivId: string = '') {
    let spinner: HTMLElement = document.getElementById(
      'rootspinner'
    ) as HTMLElement;
    if (spinner !== null) {
      spinner.style.visibility = 'visible';
      if (targetDivId) {
        let targetDiv: HTMLElement = document.getElementById(
          targetDivId
        ) as HTMLElement;
        let divarea: any = targetDiv.getBoundingClientRect();
        spinner.style.top = `${divarea.top.toString()}px`;
        spinner.style.left = `${divarea.left.toString()}px`;
        spinner.style.width = `${divarea.width.toString()}px`;
        spinner.style.height = `${divarea.height.toString()}px`;
      } else {
        spinner.style.top = `0px`;
        spinner.style.left = `0px`;
        spinner.style.width = `100%`;
        spinner.style.height = `100%`;
      }
    }
  }

  // * SUHAIL - 2023-05-09 - 20
  public static HideSpinner(targetDivId: string = '') {
    let spinner: HTMLElement = document.getElementById(
      'rootspinner'
    ) as HTMLElement;
    if (spinner !== null) {
      spinner.style.visibility = 'hidden';
      if (targetDivId) {
        let targetDiv: HTMLElement = document.getElementById(
          targetDivId
        ) as HTMLElement;
        let divarea: any = targetDiv.getBoundingClientRect();
        spinner.style.top = `${divarea.top.toString()}px`;
        spinner.style.left = `${divarea.left.toString()}px`;
        spinner.style.width = `${divarea.width.toString()}px`;
        spinner.style.height = `${divarea.height.toString()}px`;
      } else {
        spinner.style.top = `0px`;
        spinner.style.left = `0px`;
        spinner.style.width = `100%`;
        spinner.style.height = `100%`;
      }
    }
  }

  // * SUHAIL - 2023-05-25 - 10
  //public static datescolumns: string[] = ['date', 'startdate', 'enddate'];
  public static DatesColumns(_structure: any): string[] {
    return Object.keys(_structure.DateColumns).map((x: any) => x.toLowerCase());
  }

  public static FindJsonByIdKeyChildren(
    jsons: any,
    id: any,
    idfield: string,
    childrenfield: string,
    id2: any = null,
    id2field: string = ''
  ): any {
    let js: any = jsons.filter((x: any) => x[`${idfield}`] == id);
    if (id2) {
      js = js.filter(
        (x: any) => x[`${id2field}`] == null || x[`${id2field}`] == id2
      );
    }
    if (js.length > 0) {
      return js[0];
    }

    if (jsons.length === 0) {
      return null;
    }

    let jsnfinal: any = null;
    jsons.forEach((jsn: any) => {
      if (jsn[`${childrenfield}`] && jsn[`${childrenfield}`].length > 0) {
        let js1: any = this.FindJsonByIdKeyChildren(
          jsn[`${childrenfield}`],
          id,
          idfield,
          childrenfield,
          id2,
          id2field
        );
        if (js1 !== null) {
          jsnfinal = js1;
        }
      }
    });
    return jsnfinal;
  }

  public static FindJsonIndexArrayByIdKeyChildren(
    jsons: any,
    id: any,
    idfield: string,
    childrenfield: string,
    idx: any = 0
  ): any {
    let rslt: any = { json: null, indexarray: [] };
    let js: any = jsons.filter((x: any) => x[`${idfield}`] == id);

    if (js.length > 0) {
      rslt.indexarray.push(jsons.indexOf(js[0]));
      rslt.json = js[0];
      return rslt;
    }

    if (jsons.length === 0) {
      return null;
    }

    let jsnfinal: any = null;
    //let i: number = -1;
    jsons.forEach((jsn: any, idx: any) => {
      if (jsnfinal) {
        return jsnfinal;
      }
      if (jsn[`${childrenfield}`] && jsn[`${childrenfield}`].length > 0) {
        //i++;
        let js1: any = this.FindJsonIndexArrayByIdKeyChildren(
          jsn[`${childrenfield}`],
          id,
          idfield,
          childrenfield,
          idx
        );
        if (js1 !== null && js1.json !== null) {
          js1.indexarray.push(idx);
          //rslt.json = js1;
          jsnfinal = js1;
          //return rslt;
        }
      }
    });
    return jsnfinal;
  }

  public static GetAllJsonByIdKeyChildren(
    jsons: any,
    id: any,
    idfield: string,
    childrenfield: string
  ): any {
    let jsnfinal: any = [];

    let js: any = jsons.filter((x: any) => x[`${idfield}`] == id);
    if (js.length > 0) {
      js.forEach((j: any) => {
        jsnfinal.push(j);
      });
    }

    if (jsons.length === 0) {
      return null;
    }

    jsons.forEach((jsn: any) => {
      if (jsn[`${childrenfield}`] && jsn[`${childrenfield}`].length > 0) {
        let js1: any = this.GetAllJsonByIdKeyChildren(
          jsn[`${childrenfield}`],
          id,
          idfield,
          childrenfield
        );
        if (js1 !== null) {
          js1.forEach((j: any) => {
            jsnfinal.push(j);
          });
        }
      }
    });
    return jsnfinal;
  }

  public static FindParentJsonByIdKeyChildren(
    jsons: any,
    id: any,
    idfield: string,
    childrenfield: string,
    parentobj: any = null // parentid: number = 0
  ): any {
    let js: any = jsons.filter((x: any) => x[`${idfield}`] == id);

    if (js.length > 0) {
      return parentobj;
    }

    if (jsons.length === 0) {
      return null;
    }

    let jsnfinal: any = null;
    jsons.forEach((jsn: any) => {
      if (jsn[`${childrenfield}`] && jsn[`${childrenfield}`].length > 0) {
        let js1: any = this.FindParentJsonByIdKeyChildren(
          jsn[`${childrenfield}`],
          id,
          idfield,
          childrenfield,
          //jsn[idfield]
          jsn
        );
        if (js1 !== null) {
          jsnfinal = js1;
        }
      }
    });
    return jsnfinal;
  }

  public static DeleteRestJsonById(
    jsons: any,
    id: number,
    idfield: string,
    childrenfield: string,
    parentid: number
  ): any {
    let js: any = jsons.filter((x: any) => x[`${idfield}`] == id);

    if (js.length > 0) {
      let jsdel: any = jsons.filter((x: any) => x[`${idfield}`] != id);
      if (jsdel.length > 0) {
        jsdel.forEach((x: any) => {
          jsons.splice(jsons.indexOf(x), 1);
        });
      }
      return jsons;
    }

    if (jsons.length === 0) {
      return null;
    }

    let jsnfinal: any = null;
    jsons.forEach((jsn: any) => {
      if (jsn[`${childrenfield}`] && jsn[`${childrenfield}`].length > 0) {
        return this.DeleteRestJsonById(
          jsn[`${childrenfield}`],
          id,
          idfield,
          childrenfield,
          jsn.id
        );
      }
    });
    return jsnfinal;
  }

  public static getDate(date: Date) {
    return Common.formatDate(date, 'yyyyMMdd');
  }

  // * SANDEEP - 2023-05-30 - 30
  public static formatDate(date: Date, pattern: string) {
    if (!pattern) {
      pattern = 'dd-MMM-yyyy';
    }
    let day = date.getDate(),
      month = date.getMonth(),
      year = date.getFullYear(),
      hour = date.getHours(),
      minute = date.getMinutes(),
      second = date.getSeconds(),
      miliseconds = date.getMilliseconds(),
      dow = date.getDay(),
      h = hour % 12,
      hh = this.twoDigitPad(h.toString()),
      HH = this.twoDigitPad(hour.toString()),
      mm = this.twoDigitPad(minute.toString()),
      ss = this.twoDigitPad(second.toString()),
      aaa = hour < 12 ? 'AM' : 'PM',
      EEEE = date.toLocaleString('default', { weekday: 'long' }),
      EEE = date.toLocaleString('default', { weekday: 'short' }),
      dd = this.twoDigitPad(day),
      M = month + 1,
      MM = this.twoDigitPad(M.toString()),
      MMMM = date.toLocaleString('default', { month: 'long' }),
      MMM = date.toLocaleString('default', { month: 'short' }),
      yyyy = year + '',
      yy = yyyy.substring(2),
      yyy = yyyy.substring(1);

    pattern = pattern
      .replace('hh', hh)
      .replace('h', h.toString())
      .replace('HH', HH)
      .replace('H', hour.toString())
      .replace('mm', mm)
      .replace('m', minute.toString())
      .replace('ss', ss)
      .replace('s', second.toString())
      .replace('S', miliseconds.toString())
      .replace('dd', dd)
      .replace('d', day.toString())
      .replace('dow', dow.toString())

      .replace('EEEE', EEEE)
      .replace('EEE', EEE)
      .replace('yyyy', yyyy)
      .replace('yyy', yyy)
      .replace('yy', yy)
      .replace('aaa', aaa);

    if (pattern.indexOf('MMM') > -1) {
      pattern = pattern.replace('MMMM', MMMM).replace('MMM', MMM);
    } else if (pattern.indexOf('MM') > -1) {
      pattern = pattern.replace('MM', MM).replace('M', M.toString());
    }
    return pattern;
  }

  public static FormatNumber(value: number, format: string) {
    var num = '';
    //if (value) {
    if (format.includes('.')) {
      var digits = format.split('.')[1].length;
      num = parseFloat(value.toString()).toFixed(digits);
    } else {
      num = parseFloat(value.toString()).toFixed(0);
    }
    num = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    //}
    return num;
  }

  public static RemoveTimezoneOffsetFromDate(date: Date) {
    // return new Date(
    //   new Date(date).setMinutes(
    //     date.getMinutes() + date.getTimezoneOffset() * -1
    //   )
    // );
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  }

  public static twoDigitPad(num: any) {
    return num < 10 ? '0' + num : num;
  }

  public static flattenObject(
    obj: { [key: string]: any },
    attrib: string
  ): Array<any> {
    const result: Array<any> = [];

    for (const key in obj) {
      const value: any = obj[key];

      if (typeof value === 'object' && value !== null) {
        result.push(...this.flattenObject(value, attrib));
      } else if (key === attrib) {
        result.push(value);
      }
    }

    return result;
  }

  public static flattenJSON = (
    obj: any = {},
    res: any = {},
    extraKey: string = '',
    user: any = null,
    DatesColumn: string[] = [],
    flatArray: any = [],
    separator: string = '.'
  ) => {
    for (const key in obj) {
      extraKey = extraKey.replaceAll('.', separator);
      if (
        Array.isArray(obj) &&
        !isNaN(Number((extraKey + key).replaceAll(separator, ''))) &&
        JSON.stringify(res) !== '{}'
      ) {
        flatArray.push(res);
        res = {};
      }
      if (typeof obj[key] !== 'object') {
        if (
          DatesColumn &&
          DatesColumn.length > 0 &&
          DatesColumn.includes(key.toLowerCase()) === true &&
          user
        ) {
          res[extraKey + key] = Common.formatDate(
            new Date(obj[key]),
            user?.settings.formats.DateShort
          );
        } else {
          if (isNaN(Number(extraKey.split(separator).slice(0, 1)))) {
            res[extraKey + key] = obj[key];
          } else {
            res[extraKey.split(separator).slice(1).join(separator) + key] =
              obj[key];
          }
        }
      } else if (obj[key] === null) {
        //res[extraKey + key] = null;
        if (isNaN(Number(extraKey.split(separator).slice(0, 1)))) {
          res[extraKey + key] = null;
        } else {
          res[extraKey.split(separator).slice(1).join(separator) + key] = null;
        }
      } else {
        this.flattenJSON(
          obj[key],
          res,
          `${extraKey}${key}${separator}`,
          user,
          DatesColumn,
          flatArray,
          separator
        );
      }
    }
    if (Array.isArray(obj) && JSON.stringify(res) !== '{}' && extraKey === '') {
      flatArray.push(res);
      res = {};
    }
    if (flatArray.length > 0) {
      return flatArray;
    } else {
      return res;
    }
  };

  public static flattenToTreeView(arr: string[], rootName: string = '') {
    let result: any = [];
    arr.forEach((x: any) => {
      if (x.includes('.') == true) {
        let spltarray: any = x.split('.');
        let itm: any = spltarray.pop();

        let flter: any = result.filter(
          (p: any) => p.id === `${spltarray[0]}-grp`
        );
        if (flter.length <= 0) {
          let obj: any = {
            id: `${spltarray[0]}-grp`,
            text: `${spltarray[0]}`,
            children: [],
            iconCss: '',
            imageUrl: '',
          };
          let objchild: any = {
            id: `${x}`,
            text: `${itm}`,
            children: [],
            iconCss: '',
            imageUrl: '',
          };
          obj.children.push(objchild);
          result.push(obj);
        } else {
          spltarray.shift();
          spltarray.forEach((grp: any) => {
            let fltr: any = flter[0].children.filter(
              (j: any) => j.id === `${grp}-grp`
            );
            if (fltr.length <= 0) {
              let obj: any = {
                id: `${grp}-grp`,
                text: `${grp}`,
                children: [],
                iconCss: '',
                imageUrl: '',
              };
              // let objchild: any = {
              //   id: `${x}`,
              //   text: `${itm}`,
              //   children: [],
              //   iconCss: '',
              //   imageUrl: '',
              // };
              // obj.children.push(objchild);
              flter[0].children.push(obj);

              flter = flter[0].children.filter(
                (j: any) => j.id === `${grp}-grp`
              );
            } else {
              flter = fltr;
            }
          });

          let objchild: any = {
            id: `${x}`,
            text: `${itm}`,
            children: [],
            iconCss: '',
            imageUrl: '',
          };

          flter[0].children.push(objchild);
        }
      } else {
        if (result.length <= 0) {
          let obj: any = {
            id: `${rootName}`,
            text: `${rootName}`,
            children: [],
            iconCss: '',
            imageUrl: '',
          };
          result.push(obj);
        }

        let obj: any = {
          id: `${rootName}.${x}`,
          text: `${rootName}.${x}`,
          children: [],
          iconCss: '',
          imageUrl: '',
        };

        let fltr: any = result.filter((x: any) => x.id === rootName);
        if (fltr.length > 0) {
          fltr[0].children.push();
        }
      }
    });
    return result;
  }

  public static parseJwt(token: string) {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  }

  public static base64ToBlob(base64String: string, contentType: string) {
    const byteCharacters = atob(base64String); // Decode the Base64 string
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  public static getSubstringBetweenTwoChars(
    text: string,
    char1: string,
    char2: string
  ) {
    return text.slice(text.indexOf(char1) + 1, text.lastIndexOf(char2));
  }

  public static GetContextMenuFromXML(
    cntxmenu: any,
    idx: number = 0
  ): ContextMenuItemModel[] {
    let cntxMenuItems: ContextMenuItemModel[] = [];
    cntxmenu = Array.isArray(cntxmenu) ? cntxmenu : [cntxmenu];
    cntxmenu.forEach((x: any) => {
      idx = idx + 1;
      cntxMenuItems.push({
        text: x.$.type === 'separator' ? '' : x.$.text,
        id: x.$.id ?? idx.toString(),
        separator: x.$.type === 'separator',
        items: x.Item ? this.GetContextMenuFromXML(x.Item, idx) : [],
        iconCss:
          x.$.type === 'separator'
            ? ''
            : x.$.iconCss
            ? x.$.iconCss
            : `noicon {${x.$.columnname}}`,
      });
    });

    return cntxMenuItems;
  }

  static validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  public static GetContractType(usr: any, itemtype: any) {
    return usr.commondata.filter((g: any) => g.itemType == itemtype);
  }

  public static GetHTMLReportHeaderStart(params: HTMLReportHeader) {
    if (!params.reportTitle) params.reportTitle = 'Aruti Report';
    if (!params.companyName) params.companyName = 'Aruti Demo Company';
    let s: string = '';
    s += '<document>';
    s += '<head>';
    s += '<title>' + params.reportTitle + '</title>';
    s += '<style type="text/css">';
    s +=
      ' body {background: rgb(204,204,204); font-family: Verdana, Geneva, Tahoma, sans-serif; font-size:11px}';
    s +=
      ' #page {background: white; display: block; margin: 0 auto; margin-bottom: 0.5cm; box-shadow: 0 0 0.5cm rgba(0,0,0,0.5); }';
    s += ' #page[size="A4"] {width: 21cm; min-height: 29.7cm;}';
    s += ' table {font-size: 11px} th {font-weight:bold}';
    s +=
      ' @media print {body, #page {margin: 0; box-shadow: 0 0 0cm rgba(0,0,0,0)}}';
    s +=
      ' #printable { display: block;  padding-left: 20px; padding-right: 10px; padding-top: 10px; padding-bottom: 10px}';
    s +=
      ' @media print {#non-printable { display: none; } #printable { display: block; padding:0px;}}';
    s += ' .d-flex {display: flex}';
    s += ' .font-bold {font-weight: bold}';
    s += ' .font-9 {font-size: 9px}';
    s += ' .font-10 {font-size: 10px}';
    s += ' .font-11 {font-size: 11px}';
    s += ' .font-12 {font-size: 12px}';
    s += ' .font-14 {font-size: 14px}';
    s += ' .font-16 {font-size: 16px}';
    s += ' .mt-20 {margin-top:20px}';
    s += ' .mb-10 {margin-bottom:10px}';
    s += ' .mb-20 {margin-bottom:20px}';
    s += ' .w-100 {width:100%}';
    s += ' .text-center {text-align:center}';
    s += ' .text-right {text-align:right}';
    s += ' .pageheader {display: table-header-group;}';
    s += ' .pagefooter {display: table-footer-group;}';
    s += '</style>';
    s += '<script>printdiv = () => {window.print();}</script>';
    s += '</head>';
    s += '<body>';
    s += '<div id="non-printable" class="w-100 text-center">';
    s +=
      '<button style="position:fixed; bottom:0; left:calc(50vw - 50px); padding:5px; min-width:100px; background-color:lightgray" onclick="printdiv();">Print</button>';
    s += '</div>';
    s += '<div id="page" size="A4" >';
    s += '<div id="printable" >';

    s += '<table class="w-100">';
    s += '<thead class="pageheader">';
    s += '<tr>';
    s += '<td>';
    s +=
      '<div class="font-16 font-bold text-center">' +
      params.companyName +
      '</div>';
    s +=
      '<div class="font-14 font-bold text-center">' +
      params.reportTitle +
      '</div>';

    return s;
  }

  public static GetHTMLReportHeaderEnd() {
    let s: string = '';
    s += '</td>';
    s += '</tr>';
    s += '</thead>';
    s += '<tbody>';
    s += '<tr>';
    s += '<td>';

    return s;
  }

  public static GetHTMLReportFooter() {
    let s: string = '';
    s += '</td>';
    s += '</tr>';
    s += '</tbody>';
    s += '<tfoot class="pagefooter">';
    s += '<tr>';
    s += '<td>';
    s += '</td>';
    s += '</tr>';
    s += '</tfoot>';
    s += '</table>';
    s += '</div>';
    s += '</div>';
    s += '</body>';
    s += '</document>';

    return s;
  }

  public static getDatesInRange(startDate: any, endDate: any) {
    if (startDate == null && endDate == null) {
      return [];
    }

    const start = this.RemoveTimezoneOffsetFromDate(new Date(startDate));

    const end = this.RemoveTimezoneOffsetFromDate(new Date(endDate));

    const date = new Date(start.getTime());

    const dates = [];

    while (date <= end) {
      dates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }

    return dates;
  }

  public static getMonthNames(shortname: boolean): any {
    var months: any = [];
    for (let i = 0; i < 12; i++) {
      var obj = { id: 0, name: '' };
      obj.id = i + 1;
      if (shortname === true) {
        obj.name = this.formatDate(new Date(2000, i, 1), 'MMM');
      } else {
        obj.name = this.formatDate(new Date(2000, i, 1), 'MMMM');
      }
      months.push({ id: obj.id, name: obj.name });
    }
    return months;
  }

  public static getDaysNumber(
    customvalue: any = undefined,
    ontop: boolean = false,
    yeardays: boolean = false
  ): any {
    var days: any = [];
    let loopnum: number = 31;

    if (yeardays === true) {
      loopnum = 366;
    }

    if (customvalue !== undefined && customvalue !== '') {
      if (ontop === true) {
        days.push({ id: 0, name: customvalue });
      }
    }

    for (let i = 1; i <= loopnum; i++) {
      days.push({ id: i, name: i.toString() });
    }

    if (customvalue !== undefined && customvalue !== '') {
      if (ontop === false) {
        if (yeardays === true) {
          days.push({ id: 367, name: customvalue });
        } else {
          days.push({ id: 32, name: customvalue });
        }
      }
    }

    return days;
  }

  /*
  ----------------------------------------------------------------------------------------------------------MAIN 
  // NAME - DATE - TIME SPENT
  // SAMPLE // DIMITRI - 2023-02-17 - 2.00
  */
  public MODEL_MAIN(): any {
    // TODO MAIN
  }
  public static blankguid: string = '00000000-0000-0000-0000-000000000000';

  public static GetListFromStructure(structureitem: any) {
    let list: any[] = [];
    for (let i = 0; i < Object.entries(structureitem).length; i++) {
      let item: any = Object.entries(structureitem)[i][1];
      list.push(item);
    }
    return list;
  }
}

export default Common;
