import React, { useEffect } from 'react';

const LoginCallback = () => {
  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);

    const code = params.get('code');

    if (code) {
      if (window.opener) {
        window.opener.postMessage({ type: 'oauth-code', code }, '*');
        window.close();
      }
    } else {
      console.error('No authorization code found in the URL');
      window.close();
    }
  }, []);

  return (
    <div>
      <h2>OAuth Redirect Handler</h2>
      {/* You can show a loading spinner or message here */}
    </div>
  );
};

export default LoginCallback;
