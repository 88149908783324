// * -----------------------------------------------------------------------------------------------------------------1.Aknowlege

// * -----------------------------------------------------------------------------------------------------------------2.Initialize
import * as React from 'react';
import Common from '../../Util/Common';
import {
  ColumnDirective,
  ColumnsDirective,
  DialogEditEventArgs,
  Edit,
  EditSettingsModel,
  GridComponent,
  IEditCell,
  Inject,
  Toolbar,
} from '@syncfusion/ej2-react-grids';
import SelectorSearch from '../Layout/SelectorSearch';
import PayHeads from './PayHeads';
import {
  PaneDirective,
  PanePropertiesModel,
  PanesDirective,
  SplitterComponent,
} from '@syncfusion/ej2-react-layouts';
import './PayheadList.css';
import {
  ButtonPropsModel,
  Dialog,
  DialogComponent,
} from '@syncfusion/ej2-react-popups';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import {
  DropDownListComponent,
  DropDownListModel,
} from '@syncfusion/ej2-react-dropdowns';
import { DataManager, Query } from '@syncfusion/ej2-data';
import {
  IProgressValueEventArgs,
  ProgressBarComponent,
} from '@syncfusion/ej2-react-progressbar';
import * as signalR from '@microsoft/signalr';
import DialogConfirm from '../Dialogbox/DialogConfirm';
import { SwitchComponent } from '@syncfusion/ej2-react-buttons';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';

// * ------------------------------------------------------------------------------------------------------------------3.Style

const { REACT_APP_ENDPOINT_PAYROLLSERVICE, REACT_APP_ENDPOINT_API } =
  process.env;

interface Props {
  selectorData: any;
  defaultHeadTypeId: string[];
  defaultTypeOfId: string[];
  showPageHeader?: boolean;
  allowGroupingHead?: boolean;
}

export default class PayheadList extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;
  public toolbarOptions: any = ['Add', 'Edit', 'Delete'];
  private grdHead: GridComponent | any = undefined;
  private grdFormula: GridComponent | any = undefined;
  private grdSimple: GridComponent | any = undefined;
  private grdInExcess: GridComponent | any = undefined;
  private bottomsplitter: SplitterComponent | any = undefined;
  private selRecord: any = null;
  private grdHeadId = 'payheadlist-grid';
  private grdFormulaId = 'phformulalist-grid';
  private grdSimpleid = 'phsimleslablalist-grid';
  private grdInExcessid = 'phinexcessslablalist-grid';
  private selectedSlabType: any = null;
  private DefaultHeadCalcType: any = [];
  private buttons: ButtonPropsModel[];
  public connection: any;

  private child: PayHeads | any;

  private groupOptionsHead: any = {
    //showGroupedColumn: true,
    captionTemplate: '<div className="grouphead" >${key}</div>',
    columns: ['headTypeName'],
    showDropArea: false,
  };

  private groupOptions: any = {
    showGroupedColumn: true,
    columns: ['effectiveDate'],
    showDropArea: false,
  };
  toastObj: any;

  constructor(props: any) {
    super(props);
    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');

    this.child = React.createRef();

    this.buttons = [{}];

    this.DefaultHeadCalcType.push(
      this._structure.CalcType.NetPay.id,
      this._structure.CalcType.DefinedSalary.id,
      this._structure.CalcType.TotalEarning.id,
      this._structure.CalcType.TotalDeduction.id,
      this._structure.CalcType.Taxable_Earning_Total.id,
      this._structure.CalcType.Employer_Contribution_Payable.id,
      this._structure.CalcType.Rounding_Adjustment.id,
      this._structure.CalcType.Non_Taxable_Earning_Total.id,
      this._structure.CalcType.Non_Cash_Benefit_Total.id,
      this._structure.CalcType.Net_Pay_Rounding_Adjustment.id,
      this._structure.CalcType.Total_Taxable_Income_Reducer.id,
      this._structure.CalcType.Total_Tax_Relief.id
    );
  }

  static defaultProps = {
    showPageHeader: false,
    defaultHeadTypeId: [],
    defaultTypeOfId: [],
    allowGroupingHead: false,
  };

  // * ------------------------------------------------------------------------------------------------------------------5.State
  state = {
    jsonAllPayhead: undefined,
    jsonPayhead: undefined,
    jsonFormula: undefined,
    jsonSlabType: undefined,

    _changePayHead: undefined,
    showProgress: false,
    progress: 0,
    totalcount: 0,
    totalpct: 0,
  };

  // * ------------------------------------------------------------------------------------------------------------------Event Handler
  componentDidMount(): void {
    if (!this.state.jsonPayhead) {
      let pl: any = { showOnlyActive: false };
      // if (this.props.defaultHeadTypeId) {
      //   pl['headTypeId'] = this.props.defaultHeadTypeId;
      // }
      // if (this.props.defaultTypeOfId) {
      //   pl['typeOfId'] = this.props.defaultTypeOfId;
      // }
      this.GetPayHead(pl);
      this.GetSlabType();
    }

    let port = `${REACT_APP_ENDPOINT_PAYROLLSERVICE}`.split('/')[0];
    let url = `${REACT_APP_ENDPOINT_API}${port}/progressHub?userId=${this.user.id}`;
    console.log(url);

    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(url)
      .withAutomaticReconnect()
      .build();

    this.connection
      .start()
      .then(() => {
        console.log('Connected to SignalR hub');
        // this.connection.invoke('RequestProgress', this.user.id, 0, 0)
        //   .catch((err: any) => console.error('Error invoking RequestProgress:', err));
      })
      .catch((err: any) =>
        console.error('Error connecting to SignalR hub:', err)
      );

    // Handle the connection ID sent from the server
    this.connection.on('ReceiveConnectionId', (connectionId: any) => {
      console.log('Received connection ID:', connectionId);
      this.setState({ connectionId });
    });

    this.connection.on('ReceiveProgress', (cnt: any, totalcnt: any) => {
      console.log('Received progress update:', cnt, totalcnt);
      const pct = (cnt / totalcnt) * 100;
      this.setState({ progress: cnt, totalcount: totalcnt, totalpct: pct });
    });
  }

  componentWillUnmount() {
    if (this.connection) {
      this.connection.stop();
    }
  }

  public GetPayHead(payload: any) {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payhead/listheads`,
      payload,
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        let f1: any[] = response;
        if (this.props.defaultHeadTypeId!.length > 0) {
          f1 = f1.filter((x: any) =>
            this.props.defaultHeadTypeId?.includes(x.headTypeId)
          );
        }
        if (this.props.defaultTypeOfId.length > 0) {
          f1 = f1.filter((x: any) =>
            this.props.defaultTypeOfId?.includes(x.typeOfId)
          );
        }
        this.setState({
          jsonAllPayhead: response,
          jsonPayhead: f1,
        });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  public GetSlabType() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payhead/getpayheadslabtype`,
      {},
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        this.setState({
          jsonSlabType: response,
        });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  private SetDialogCSS(dialog: Dialog) {
    for (let i = 0; i < dialog.buttons.length; i++) {
      let _primary = dialog.buttons[i].buttonModel?.isPrimary;

      let _model = {
        content: Common.toTitleCase(
          dialog.buttons[i].buttonModel?.content?.toLowerCase() == 'save'
            ? 'Accept'
            : `${dialog.buttons[i].buttonModel?.content}`
        ),
        cssClass: 'flat-button',
        isPrimary: _primary,
      };
      dialog.buttons[i].buttonModel = _model;
    }
    dialog.buttons.reverse();
  }
  ValidateForm = () => {
    const inputs = [
      document.querySelector<HTMLInputElement>('#code'),
      document.querySelector<HTMLInputElement>('#name'),
      document.querySelector<HTMLInputElement>('#ph_Htype'),
      document.querySelector<HTMLInputElement>('#ph_TypOf'),
      document.querySelector<HTMLInputElement>('#ph_calcid'),
      document.querySelector<HTMLInputElement>('#dtpEffective'),
      document.querySelector<HTMLInputElement>('#ph_formulavalue'),
    ];

    inputs.forEach((input) => {
      if (input) {
        input.classList.remove('textbox-border-error');

        if (input.value.trim() === '') {
          input.classList.add('textbox-border-error');
        } else {
          input.classList.add('textbox-class');
        }
      }
    });
  };
  private actionBegin = (e: any) => {
    let iHeadTypeId: any = 0;
    let iTypeOfId: any = 0;
    let iCalcTypeId: any = 0;
    let orgfrml: string = '';
    let newfrml: string = '';
    this.ValidateForm();
    if (e.requestType === 'save') {
      if (
        document.getElementById('code') !== undefined &&
        (document.getElementById('code') as any).value == ''
      ) {
        e.requestType = 'add';
        e.cancel = true;
        // DialogConfirm.showDialog({
        //   content:
        //     '<p class="dialog-contain">Please enter code to continue.</p>',
        // });
        const TargetInput = document.querySelector<HTMLInputElement>('#code');
        if (TargetInput) {
          TargetInput.style.borderBottom = '2px solid red';
        }
        return;
      } else if (
        document.getElementById('name') !== undefined &&
        (document.getElementById('name') as any).value == ''
      ) {
        e.requestType = 'add';
        e.cancel = true;
        // DialogConfirm.showDialog({
        //   content:
        //     '<p class="dialog-contain">Please enter name to continue.</p>',
        // });
        const TargetInput = document.querySelector<HTMLInputElement>('#name');
        if (TargetInput) {
          TargetInput.style.borderBottom = '2px solid red';
        }
        return;
      } else if (
        document.getElementById('ph_Htype') !== undefined &&
        Number((document.getElementById('ph_Htype') as any).value) <= 0
      ) {
        e.requestType = 'add';
        e.cancel = true;
        // DialogConfirm.showDialog({
        //   content:
        //     '<p class="dialog-contain">Please select head type to continue.</p>',
        // });
        const TargetInput =
          document.querySelector<HTMLInputElement>('#ph_Htype');
        if (TargetInput) {
          TargetInput.style.borderBottom = '2px solid red';
        }
        return;
      } else if (
        document.getElementById('ph_TypOf') !== undefined &&
        Number((document.getElementById('ph_TypOf') as any).value) <= 0
      ) {
        e.requestType = 'add';
        e.cancel = true;
        // DialogConfirm.showDialog({
        //   content:
        //     '<p class="dialog-contain">Please select type of to continue.</p>',
        // });
        const TargetInput =
          document.querySelector<HTMLInputElement>('#ph_TypOf');
        if (TargetInput) {
          TargetInput.style.borderBottom = '2px solid red';
        }
        return;
      } else if (
        document.getElementById('ph_calcid') !== undefined &&
        Number((document.getElementById('ph_calcid') as any).value) <= 0
      ) {
        e.requestType = 'add';
        e.cancel = true;
        // DialogConfirm.showDialog({
        //   content:
        //     '<p class="dialog-contain">Please select calculation type to continue.</p>',
        // });
        const TargetInput =
          document.querySelector<HTMLInputElement>('#ph_calcid');
        if (TargetInput) {
          TargetInput.style.borderBottom = '2px solid red';
        }
        return;
      }

      iHeadTypeId = (document.getElementById('ph_Htype') as any)
        .ej2_instances[0].itemData.id;
      iTypeOfId = (document.getElementById('ph_TypOf') as any).ej2_instances[0]
        .itemData.id;
      iCalcTypeId = (document.getElementById('ph_calcid') as any)
        .ej2_instances[0].itemData.id;

      if (
        (Number(iCalcTypeId) ==
          Number(this._structure.CalcType.FlatRateOthers.id) ||
          Number(iCalcTypeId) ==
            Number(this._structure.CalcType.ComputedValue.id) ||
          Number(iCalcTypeId) ==
            Number(
              this._structure.CalcType.AsComputedOnWithINEXCESSOFTaxSlab.id
            )) &&
        document.getElementById('dtpEffective') !== undefined &&
        isNaN(
          Number(
            new Date((document.getElementById('dtpEffective') as any).value)
          )
        )
      ) {
        e.requestType = 'add';
        e.cancel = true;
        // DialogConfirm.showDialog({
        //   content:
        //     '<p class="dialog-contain">Please select effective date.</p>',
        // });
        const TargetInput =
          document.querySelector<HTMLInputElement>('#dtpEffective');
        if (TargetInput) {
          TargetInput.style.borderBottom = '2px solid red';
        }
        return;
      }

      switch (Number(iCalcTypeId)) {
        case this._structure.CalcType.FlatRateOthers.id:
          // if (
          //   document.getElementById('ph_amount') !== undefined &&
          //   Number(
          //     (document.getElementById('ph_amount') as any).ej2_instances[0]
          //       .value
          //   ) == 0
          // ) {
          //   e.requestType = 'add';
          //   e.cancel = true;
          //  DialogConfirm.showDialog({
          //    content: '<p class="dialog-contain">Please enter amount to continue.</p>',
          //  });
          //   return;
          // }
          // if (
          //   isNaN(
          //     (document.getElementById('ph_amount') as any).ej2_instances[0]
          //       .value
          //   )
          // ) {
          //   e.requestType = 'add';
          //   e.cancel = true;
          //  DialogConfirm.showDialog({
          //    content: '<p class="dialog-contain">Please enter valid value to continue.</p>',
          //  });
          //   return;
          // }
          break;

        case this._structure.CalcType.ComputedValue.id:
        case this._structure.CalcType.AsComputedOnWithINEXCESSOFTaxSlab.id:
          let frml: HTMLElement = document.getElementById(
            'ph_formula'
          ) as HTMLElement;
          let frmlvalue: HTMLInputElement = document.getElementById(
            'ph_formulavalue'
          ) as HTMLInputElement;
          if (frml && frmlvalue) {
            frmlvalue.value = frml.textContent!;
          }

          if (
            document.getElementById('ph_formulavalue') !== undefined &&
            (document.getElementById('ph_formulavalue') as any).value == ''
          ) {
            e.requestType = 'add';
            e.cancel = true;
            // DialogConfirm.showDialog({
            //   content:
            //     '<p class="dialog-contain">Please enter formula to continue.</p>',
            // });
            const TargetInput =
              document.querySelector<HTMLInputElement>('#ph_formulavalue');
            if (TargetInput) {
              TargetInput.style.borderBottom = '2px solid red';
            }
            return;
          }

          let _formula = (document.getElementById('ph_formulavalue') as any)
            .value;

          newfrml = _formula
            .replaceAll('&nbsp;', ' ')
            .replaceAll('ph[', '[ph#');
          orgfrml = (document.getElementById('ph_orignalformulavalue') as any)
            .value;

          let _pattern = /\[(.*?)\]/g;
          let new_formula = _formula
            .replaceAll('&nbsp;', ' ')
            .replaceAll('ph[', '[ph#')
            .replace(_pattern, '1');

          try {
            eval(new_formula);
          } catch {
            // DialogConfirm.showDialog({
            //   content:
            //     '<p class="dialog-contain">Please enter correct formula to continue.</p>',
            // });
            this.toastObj = ToastUtility.show({
              cssClass: 'e-toast-warning',
              icon: 'e-warning toast-icons',
              showCloseButton: true,
              content: 'Please enter correct formula to continue.',
              target: '#basic_card',
              position: { X: 'Center', Y: 'Top' },
              width: 'auto',
              timeOut: 3000,
            });
            e.requestType = 'add';
            e.cancel = true;
            return;
          }
          break;
      }

      e.cancel = true;
      //e.type = 'actionComplete';
      //e.requestType = '';

      /* A2X-1814 -- START */
      if (orgfrml !== '' && newfrml !== orgfrml) {
        this.setState({ showProgress: true });
      }
      /* A2X-1814 -- END */

      this.child.current.HeadAdd().then((response: any) => {
        if (response) {
          if (e.form!.id.startsWith(this.grdHeadId)) {
            let jsn: any = this.state.jsonPayhead;
            if (jsn) {
              let idx = 0;
              if (!e.data.id) {
                jsn.push(response);
              } else {
                let fltr: any = jsn.filter((x: any) => x.id === response.id);
                if (fltr.length > 0) {
                  idx = jsn.indexOf(fltr[0]);
                  jsn[idx] = response;
                }
              }
              e.cancel = false;
              e.type = 'actionComplete';
              e.requestType = '';
              this.grdHead.refresh();
              //this.grdFormula.refresh();
              if (e.data.id) {
                setTimeout(() => {
                  this.grdHead.selectRow(idx);
                }, 1000);
              }
              // let dlg: HTMLElement = document.getElementById(
              //   `${this.grdHeadId}_dialogEdit_wrapper`
              // ) as HTMLElement;
              // if (dlg) {
              //   dlg.parentElement!.style.display = 'none';
              // }
              let dlgs: any = document.querySelectorAll(
                `#${this.grdHeadId}_dialogEdit_wrapper`
              );

              if (dlgs && dlgs.length > 0) {
                dlgs.forEach((dlg: any) => {
                  dlg.parentElement!.style.display = 'none';
                });
              }
            }

            //this.setState({ jsonPayhead: data });
          } else if (e.form!.id.startsWith(this.grdFormulaId)) {
            let jsn: any = this.state.jsonPayhead;
            let idx = 0;
            if (jsn) {
              let fltr: any = jsn.filter(
                (x: any) => x.id === this.selRecord.id
              );
              if (fltr.length > 0) {
                idx = jsn.indexOf(fltr[0]);
                jsn[idx].payHeadTran = response;

                // if (!e.data.id) {
                //   if (!jsn[idx].payHeadTran) {
                //     jsn[idx].payHeadTran = [];
                //   }
                //   jsn[idx].payHeadTran.unshift(response);
                // } else {
                //   let idx2 = 0;
                //   let fltr2: any = jsn[idx].payHeadTran.filter(
                //     (x: any) => x.id === response.id
                //   );
                //   if (fltr2.length > 0) {
                //     idx2 = jsn[idx].payHeadTran.indexOf(fltr2[0]);
                //     jsn[idx].payHeadTran[idx2] = response;
                //   }
                // }

                // e.cancel = false;
                // e.type = 'actionComplete';
                // e.requestType = '';
                // this.grdFormula.refresh();
                let dlgs: any = document.querySelectorAll(
                  `#${this.grdFormulaId}_dialogEdit_wrapper`
                );

                if (dlgs && dlgs.length > 0) {
                  dlgs.forEach((dlg: any) => {
                    dlg.parentElement!.style.display = 'none';
                  });
                }
                this.grdHead.selectRow(0);
                this.grdHead.selectRow(idx);
              }
            }
            // this.setState({ jsonPayhead: data });
            // setTimeout(() => {
            //   this.grdHead.selectRow(idx);
            // }, 1000);
          } else if (e.form!.id.startsWith(this.grdSimpleid)) {
          } else if (e.form!.id.startsWith(this.grdInExcessid)) {
          }
        } else {
          console.log('error', e.type);

          e.requestType = 'add';
          e.cancel = true;
          return;
        }
      });
    } else if (e.requestType === 'delete') {
      if (e.tr[0].parentElement.parentElement.id.startsWith(this.grdHeadId)) {
        if (this.DefaultHeadCalcType.includes(e.data[0].calcTypeId)) {
          // DialogConfirm.showDialog({
          //   content:
          //     '<p class="dialog-contain">Sorry, You cannot delete default system heads.</p>',
          // });
          this.toastObj = ToastUtility.show({
            cssClass: 'e-toast-warning',
            icon: 'e-warning toast-icons',
            showCloseButton: true,
            content: 'Sorry, You cannot delete default system heads.',
            target: '#basic_card',
            position: { X: 'Center', Y: 'Top' },
            width: 'auto',
            timeOut: 3000,
          });
          e.cancel = true;
          return;
        }

        let payload: any = {
          id: e.data[0].id,
        };

        this.SaveSlab(
          payload,
          `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payhead/delete`
        ).then((response: any) => {
          if (response) {
          } else {
            this.grdFormula.dataSource.splice(
              this.grdFormula.selectedRowIndex,
              0,
              e.data[0]
            );
            this.grdFormula.refresh();
          }
        });
      } else if (
        e.tr[0].parentElement.parentElement.id.startsWith(this.grdFormulaId)
      ) {
        let payload: any = {
          id: e.data[0].id,
          payHeadId: e.data[0].payHeadId,
          effectiveDate: Common.RemoveTimezoneOffsetFromDate(
            new Date(e.data[0].effectiveDate)
          ),
        };

        this.SaveSlab(
          payload,
          `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payhead/deleteformulamount`
        ).then((response: any) => {
          if (response) {
          } else {
            this.grdFormula.dataSource.splice(
              this.grdFormula.selectedRowIndex,
              0,
              e.data[0]
            );
            this.grdFormula.refresh();
          }
        });
      }
    }
  };

  private actionComplete = (args: DialogEditEventArgs): void => {
    if (args.requestType === 'beginEdit' || args.requestType === 'add') {
      const dialog: Dialog = args.dialog as Dialog;
      let header: string = '';
      if (args.requestType === 'add') {
        header = 'Add New ';
      } else {
        header = 'Edit ';
      }
      if (args.form!.id.startsWith(this.grdHead.id)) {
        dialog.header = header + 'Pay Head';
      } else if (args.form!.id.startsWith(this.grdFormula.id)) {
        dialog.header = header + 'Formula';
      } else if (args.form!.id.startsWith(this.grdSimple.id)) {
        dialog.header = header + 'Simple Slab';
      } else if (args.form!.id.startsWith(this.grdInExcess.id)) {
        dialog.header = header + 'In Excess Slab';
      }
      this.SetDialogCSS(dialog);
    }
  };

  private actionBeginSimpleSlab = (e: any) => {
    if (
      e.requestType === 'save' &&
      (e.action === 'add' || e.action === 'edit')
    ) {
      let objvb: any = document.getElementById('valueBasis');
      if (objvb) {
        if (!isNaN(objvb.value)) {
          e.data.valueBasis = Number(objvb.value);
        }
      }

      if (!this.selectedSlabType) {
        // DialogConfirm.showDialog({
        //   content: '<p class="dialog-contain">Please select slab type.</p>',
        // });
        this.toastObj = ToastUtility.show({
          cssClass: 'e-toast-warning',
          icon: 'e-warning toast-icons',
          showCloseButton: true,
          content: 'Please select slab type.',
          target: '#basic_card',
          position: { X: 'Center', Y: 'Top' },
          width: 'auto',
          timeOut: 3000,
        });
        e.cancel = true;
        return;
      }
      if (this.selectedSlabType == '1' && e.data.valueBasis <= 0) {
        // DialogConfirm.showDialog({
        //   content:
        //     '<p class="dialog-contain">Sorry, Percentage should be greater than zero.</p>',
        // });
        this.toastObj = ToastUtility.show({
          cssClass: 'e-toast-warning',
          icon: 'e-warning toast-icons',
          showCloseButton: true,
          content: 'Sorry, Percentage should be greater than zero.',
          target: '#basic_card',
          position: { X: 'Center', Y: 'Top' },
          width: 'auto',
          timeOut: 3000,
        });
        e.cancel = true;
        return;
      }
      if (this.selectedSlabType == '1' && e.data.valueBasis > 100) {
        // DialogConfirm.showDialog({
        //   content:
        //     '<p class="dialog-contain">Sorry, Percentage should not be greater than 100.</p>',
        // });
        this.toastObj = ToastUtility.show({
          cssClass: 'e-toast-warning',
          icon: 'e-warning toast-icons',
          showCloseButton: true,
          content: 'Sorry, Percentage should not be greater than 100.',
          target: '#basic_card',
          position: { X: 'Center', Y: 'Top' },
          width: 'auto',
          timeOut: 3000,
        });
        e.cancel = true;
        return;
      }

      e.data.effectiveDate = new Date(
        Common.formatDate(
          e.data.effectiveDate,
          this.user.settings.formats.DateShort
        )
      );
      e.data.slabType = this.selectedSlabType;

      let payload: any = [
        {
          id: e.data.id,
          effectiveDate: Common.RemoveTimezoneOffsetFromDate(
            e.data.effectiveDate
          ),
          amountUpTo: e.data.amountUpTo,
          slabType: this.selectedSlabType,
          valueBasis: e.data.valueBasis,
          formula: '',
          payHeadId: this.selRecord.id,
        },
      ];

      this.SaveSlab(
        payload,
        `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payhead/addsimpleslab`
      ).then((response: any) => {
        if (response) {
          let jsn: any = this.state.jsonPayhead;
          let f1: any = jsn.filter((x: any) => x.id === this.selRecord.id);
          if (f1.length > 0) {
            jsn[jsn.indexOf(f1[0])].simpleSlabTran = response;
            // let f2: any = jsn[jsn.indexOf(f1[0])].simpleSlabTran.filter(
            //   (x: any) => x.id === e.data.id
            // );
            // if (f2.length > 0) {
            //   jsn[jsn.indexOf(f1[0])].simpleSlabTran[
            //     jsn[jsn.indexOf(f1[0])].simpleSlabTran.indexOf(f2[0])
            //   ] = payload;
            let idx: any = this.grdHead.selectedRowIndex;
            this.grdHead.selectRow(0);
            this.grdHead.selectRow(idx);
            // }
          }
        }
      });

      // if (e.data.id) {
      //   let jsn: any = this.state.jsonPayhead;
      //   let f1: any = jsn.filter((x: any) => x.id === this.selRecord.id);
      //   if (f1.length > 0) {
      //     let f2: any = jsn[jsn.indexOf(f1[0])].simpleSlabTran.filter(
      //       (x: any) => x.id === e.data.id
      //     );
      //     if (f2.length > 0) {
      //       jsn[jsn.indexOf(f1[0])].simpleSlabTran[
      //         jsn[jsn.indexOf(f1[0])].simpleSlabTran.indexOf(f2[0])
      //       ] = payload;
      //       let idx: any = this.grdHead.selectedRowIndex;
      //       this.grdHead.selectRow(0);
      //       this.grdHead.selectRow(idx);
      //     }
      //   }
      // }
    } else if (e.requestType === 'beginEdit') {
      this.selectedSlabType = e.rowData.slabType.toString();
    } else if (e.requestType === 'delete') {
      let payload: any = {
        id: e.data[0].id,
        payHeadId: e.data[0].payHeadId,
        amountUpTo: e.data[0].amountUpTo,
        effectiveDate: Common.RemoveTimezoneOffsetFromDate(
          new Date(e.data[0].effectiveDate)
        ),
      };

      this.SaveSlab(
        payload,
        `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payhead/deletesimpleslab`
      ).then((response: any) => {
        if (response) {
        } else {
          this.grdSimple.dataSource.splice(
            this.grdSimple.selectedRowIndex,
            0,
            e.data[0]
          );
          this.grdSimple.refresh();
        }
      });
    }
  };

  private actionCompleteSimpleSlab = (e: any) => {
    if (e.requestType === 'save' && e.action === 'edit') {
    }
  };
  // public slabTypeParams: IEditCell = {
  //   params: {
  //     actionComplete: () => false,
  //     allowFiltering: true,
  //     dataSource: new DataManager(this.state.jsonSlabType),
  //     fields: { text: 'name', value: 'name' },
  //     query: new Query(),
  //   },
  // };

  private actionBeginInExcessSlab = (e: any) => {
    if (
      e.requestType === 'save' &&
      (e.action === 'add' || e.action === 'edit')
    ) {
      let objrp: any = document.getElementById('ratePayable');
      if (objrp) {
        if (!isNaN(objrp.value)) {
          e.data.ratePayable = Number(objrp.value);
        }
      }

      if (Number(e.data.amountUpTo) <= 0) {
        e.requestType = 'add';
        e.cancel = true;
        // DialogConfirm.showDialog({
        //   content: '<p class="dialog-contain">Please enter Amount UpTo.</p>',
        // });
        this.toastObj = ToastUtility.show({
          cssClass: 'e-toast-warning',
          icon: 'e-warning toast-icons',
          showCloseButton: true,
          content: 'Please enter Amount UpTo.',
          target: '#basic_card',
          position: { X: 'Center', Y: 'Top' },
          width: 'auto',
          timeOut: 3000,
        });
        return;
      } else if (Number(e.data.ratePayable) > 100) {
        e.requestType = 'add';
        e.cancel = true;
        // DialogConfirm.showDialog({
        //   content:
        //     '<p class="dialog-contain">Sorry, Rate should not be greater than 100.</p>',
        // });
        this.toastObj = ToastUtility.show({
          cssClass: 'e-toast-warning',
          icon: 'e-warning toast-icons',
          showCloseButton: true,
          content: 'Sorry, Rate should not be greater than 100.',
          target: '#basic_card',
          position: { X: 'Center', Y: 'Top' },
          width: 'auto',
          timeOut: 3000,
        });
        return;
      }

      e.data.effectiveDate = new Date(
        Common.formatDate(
          e.data.effectiveDate,
          this.user.settings.formats.DateShort
        )
      );

      let prevRows: any = [];
      if (e.action === 'add') {
        let fltr: any = this.grdInExcess.dataSource.filter(
          (x: any) =>
            Common.formatDate(x.effectiveDate, 'yyyyMMdd') ===
              Common.formatDate(e.data.effectiveDate, 'yyyyMMdd') &&
            Number(x.amountUpTo) <= Number(e.data.amountUpTo)
        );
        if (fltr.length > 0) {
          let srt: any = fltr.sort(
            (a: any, b: any) => b.amountUpTo - a.amountUpTo
          );

          e.data.inExcessof = srt[0].amountUpTo;
          e.data.fixedAmount = Number(
            srt[0].fixedAmount +
              ((srt[0].amountUpTo - srt[0].inExcessof) * srt[0].ratePayable) /
                100
          );
        }
      } else if (e.action === 'edit') {
        let fltr: any = this.grdInExcess.dataSource.filter(
          (x: any) =>
            Common.formatDate(x.effectiveDate, 'yyyyMMdd') ===
              Common.formatDate(e.data.effectiveDate, 'yyyyMMdd') &&
            Number(x.amountUpTo) > Number(e.previousData.amountUpTo)
        );
        if (fltr.length > 0) {
          let srt: any = fltr.sort(
            (a: any, b: any) => a.amountUpTo - b.amountUpTo
          );

          this.grdInExcess.dataSource[e.rowIndex] = e.data;

          //previous row inexcess of
          this.grdInExcess.dataSource[
            this.grdInExcess.dataSource.indexOf(fltr[0])
          ].inExcessof = e.data.amountUpTo;

          //all previous row fixed amount
          fltr.forEach((prev: any) => {
            let prevrow: any =
              this.grdInExcess.dataSource[
                this.grdInExcess.dataSource.indexOf(prev)
              ];

            let currrow: any =
              this.grdInExcess.dataSource[
                this.grdInExcess.dataSource.indexOf(prev) - 1
              ];

            this.grdInExcess.dataSource[
              this.grdInExcess.dataSource.indexOf(prev)
            ].effectiveDate = Common.RemoveTimezoneOffsetFromDate(
              prevrow.effectiveDate
            );

            this.grdInExcess.dataSource[
              this.grdInExcess.dataSource.indexOf(prev)
            ].fixedAmount = Number(
              currrow.fixedAmount +
                ((currrow.amountUpTo - currrow.inExcessof) *
                  currrow.ratePayable) /
                  100
            );

            prevRows.push(
              this.grdInExcess.dataSource[
                this.grdInExcess.dataSource.indexOf(prev)
              ]
            );
          });

          this.grdInExcess.refresh();
        }
      }

      let payload: any[] = [
        {
          id: e.data.id,
          effectiveDate: Common.RemoveTimezoneOffsetFromDate(
            e.data.effectiveDate
          ),
          amountUpTo: e.data.amountUpTo,
          fixedAmount: e.data.fixedAmount,
          ratePayable: e.data.ratePayable,
          inExcessof: e.data.inExcessof,
          payHeadId: this.selRecord.id,
        },
      ];
      if (prevRows.length > 0) {
        payload.push(...prevRows);
      }

      this.SaveSlab(
        payload,
        `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payhead/addinexcessslab`
      ).then((response: any) => {
        if (response) {
          let jsn: any = this.state.jsonPayhead;
          let f1: any = jsn.filter((x: any) => x.id === this.selRecord.id);
          if (f1.length > 0) {
            jsn[jsn.indexOf(f1[0])].inexcessSlabTran = response;
            let idx: any = this.grdHead.selectedRowIndex;
            this.grdHead.selectRow(0);
            this.grdHead.selectRow(idx);
          }
        }
      });

      // if (e.data.id) {
      //   let jsn: any = this.state.jsonPayhead;
      //   let f1: any = jsn.filter((x: any) => x.id === this.selRecord.id);
      //   if (f1.length > 0) {
      //     let f2: any = jsn[jsn.indexOf(f1[0])].inexcessSlabTran.filter(
      //       (x: any) => x.id === e.data.id
      //     );
      //     if (f2.length > 0) {
      //       jsn[jsn.indexOf(f1[0])].inexcessSlabTran[
      //         jsn[jsn.indexOf(f1[0])].inexcessSlabTran.indexOf(f2[0])
      //       ] = payload;
      //       let idx: any = this.grdHead.selectedRowIndex;
      //       this.grdHead.selectRow(0);
      //       this.grdHead.selectRow(idx);
      //     }
      //   }
      // }
    } else if (e.requestType === 'delete') {
      let payload: any = {
        id: e.data[0].id,
        payHeadId: e.data[0].payHeadId,
        amountUpTo: e.data[0].amountUpTo,
        effectiveDate: Common.RemoveTimezoneOffsetFromDate(
          new Date(e.data[0].effectiveDate)
        ),
      };

      this.SaveSlab(
        payload,
        `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payhead/deleteinexcessslab`
      ).then((response: any) => {
        if (response) {
        } else {
          this.grdInExcess.dataSource.splice(
            this.grdInExcess.selectedRowIndex,
            0,
            e.data[0]
          );
          this.grdInExcess.refresh();
        }
      });
    }
  };

  private async SaveSlab(payload: any, url: string) {
    let finalresponse: any = null;

    await Common.ApiCallAsync(
      'POST',
      url,
      payload,
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        finalresponse = response;
      })
      .catch((error: any) => {
        console.error(error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.messages &&
          error.response.data.messages.length > 0
        ) {
          // DialogConfirm.showDialog({
          //   content: `<p class="dialog-contain">${error.response.data.messages.join(
          //     '<br>'
          //   )}</p>`,
          // });
          this.toastObj = ToastUtility.show({
            cssClass: 'e-toast-error',
            icon: 'far fa-circle-xmark',
            showCloseButton: true,
            content: `${error.response.data.messages.join('<br>')}`,
            target: '#basic_card',
            position: { X: 'Center', Y: 'Top' },
            width: 'auto',
            timeOut: 3000,
          });
          finalresponse = error;
        }
      })
      .finally(() => {});

    if (finalresponse) {
      return finalresponse;
    }
  }

  private showProgress() {
    return (
      <>
        <div>
          <ProgressBarComponent
            id='linear'
            type='Linear'
            showProgressValue={true}
            labelStyle={{ color: '#FFFFFF', fontWeight: 'bold' }}
            trackThickness={30}
            progressThickness={30}
            trackColor='transparent'
            progressCompleted={(args: IProgressValueEventArgs) => {
              args.value = 0;
              if (this.state._changePayHead) {
                let changeData: any = this.state._changePayHead;
                let payHeadData: any = this.state.jsonPayhead;
                payHeadData.filter((item: any) => {
                  if (item.id === changeData.id) {
                    item.isActive = !item.isActive;
                  }
                });

                this.setState({
                  totalcount: 0,
                  showProgress: false,
                  totalpct: 0,
                  jsonPayhead: payHeadData,
                });
              } else {
                this.setState({
                  totalcount: 0,
                  showProgress: false,
                  totalpct: 0,
                });
              }
            }}
            maximum={100}
            value={this.state.totalpct}
          ></ProgressBarComponent>
        </div>
      </>
    );
  }

  render() {
    const templateActive = (e: any) => {
      return (
        <>
          {e.length === 0 ? (
            ''
          ) : (
            <SwitchComponent
              id={`${e.id}-${e.column.field}`}
              data-item={e}
              checked={e.isActive}
              disabled={
                e.typeOfId === this._structure.TypeOf.Salary.id &&
                e.headTypeId ===
                  this._structure.HeadType.EarningforEmployee.id &&
                e.calcTypeId === this._structure.CalcType.DefinedSalary.id
                  ? true
                  : false
              }
              onChange={handleIsActiveChange}
            />
          )}
        </>
      );
    };

    const handleIsActiveChange = (args: any) => {
      let item: any = args.target['data-item'];
      this.setState({ _changePayHead: item });
      let message: any = '';

          let payload: any = {
            id: item.id,
            code: item.code,
            name: item.name,
            headTypeId: item.headTypeId,
            typeOfId: item.typeOfId,
            calcTypeId: item.calcTypeId,
            isActive: args.target.checked,
          };
          Common.ApiCallAsync(
            'POST',
            `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payhead/update`,
            payload,
            Common.getToken() || '',
            null,
            this.token.tenant
          )
            .then((resopnse: any) => {
              return resopnse.data;
            })
            .then((data: any) => {
              let _jsonPayhead: any = this.state.jsonPayhead;
              _jsonPayhead.filter((item: any) => {
                if (item.id === data.id) {
                  item.isActive = data.isActive;
                }
              });
              this.setState({
                jsonPayhead: _jsonPayhead,
              });
            })
            .catch((error: any) => {
              console.error(error);
            })
            .finally(() => {});
      if (args.target.checked) {
        message =
          '<p class="dialog-contain">You are about to make this payhead Active. Are you sure you want to Activate</p>';
      } else {
        message =
          '<p class="dialog-contain">You are about to make this payhead Inactive. Are you sure you want to Deactivate</p>';
      }
      // this.toastObj = ToastUtility.show({
      //   cssClass: 'e-toast-warning',
      //   icon: 'e-warning toast-icons',
      //   showCloseButton: true,
      //   content: message,
      //   target: '#basic_card',
      //   position: { X: 'Center', Y: 'Top' },
      //   width: 'auto',
      //   timeOut: 3000,
      // });
      DialogConfirm.showDialog({
        content: message,
        isConfirm: true,
        OkEvent: () => {
          let payload: any = {
            id: item.id,
            code: item.code,
            name: item.name,
            headTypeId: item.headTypeId,
            typeOfId: item.typeOfId,
            calcTypeId: item.calcTypeId,
            isActive: args.target.checked,
          };
          Common.ApiCallAsync(
            'POST',
            `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payhead/update`,
            payload,
            Common.getToken() || '',
            null,
            this.token.tenant
          )
            .then((resopnse: any) => {
              return resopnse.data;
            })
            .then((data: any) => {
              let _jsonPayhead: any = this.state.jsonPayhead;
              _jsonPayhead.filter((item: any) => {
                if (item.id === data.id) {
                  item.isActive = data.isActive;
                }
              });
              this.setState({
                jsonPayhead: _jsonPayhead,
              });
            })
            .catch((error: any) => {
              console.error(error);
            })
            .finally(() => {});
        },
      });
    
    };

    const amountTemplate = (props: any) => {
      switch (props.column.edit.parent.id) {
        case this.grdFormulaId:
          return this.grdHead.getSelectedRecords().length > 0 &&
            this.grdHead.getSelectedRecords()[0].calcTypeId === 7 &&
            props.amount ? (
            <>
              {Common.FormatNumber(
                props.column.field ? props[props.column.field] : 0,
                this.user.settings.formats.Decimal
              )}
            </>
          ) : (
            ''
          );
          break;

        case this.grdSimpleid:
        case this.grdInExcessid:
          return (
            <>
              {Common.FormatNumber(
                props.column.field ? props[props.column.field] : 0,
                this.user.settings.formats.Decimal
              )}
            </>
          );
          break;

        default:
          break;
      }
    };

    const editTemplateDate = (e: any) => {
      let date: any = e.effectiveDate ? e.effectiveDate : new Date();
      if (!e.effectiveDate) {
        if (e.column.edit.parent.dataSource.length > 0) {
          date = new Date(e.column.edit.parent.dataSource[0].effectiveDate);
        }
      }
      return (
        <DatePickerComponent
          id='dtpEffective'
          name='effectiveDate'
          format={this.user.settings.formats.DateShort}
          value={date}
          showClearButton={false}
          cssClass='e-hidedate-icon'
          openOnFocus={true}
          showTodayButton={false}
          style={{ fontSize: '15px' }}
        />
      );
    };

    const editTemplateAmount = (e: any) => {
      return (
        <NumericTextBoxComponent
          id={e.column.field}
          name={e.column.field}
          cssClass='text-right'
          placeholder='Enter Amount'
          value={!e[e.column.field] ? 0 : e[e.column.field]}
          decimals={
            this.user.settings.formats.Decimal.includes('.')
              ? this.user.settings.formats.Decimal.split('.').pop().length
              : null
          }
          currency={'USD'} // CURRENCY TO BE PICKED FROM COMPANY
          format={`c${
            this.user.settings.formats.Decimal.includes('.')
              ? this.user.settings.formats.Decimal.split('.').pop().length
              : 2
          }`}
          showSpinButton={false}
        />
      );
    };

    const topPane = () => {
      return (
        <>
          <GridComponent
            id={this.grdHeadId}
            dataSource={this.state.jsonPayhead}
            height='calc(100%)'
            toolbar={this.toolbarOptions}
            editSettings={editOptions}
            groupSettings={this.groupOptionsHead}
            allowGrouping={this.props.allowGroupingHead}
            allowTextWrap={true}
            selectedRowIndex={0}
            rowSelected={rowSelected}
            ref={(obj: any) => (this.grdHead = obj)}
            actionBegin={this.actionBegin.bind(this)}
            actionComplete={this.actionComplete.bind(this)}
          >
            <ColumnsDirective>
              <ColumnDirective
                field='code'
                headerText='Code'
                width='100'
                isPrimaryKey={true}
              />
              <ColumnDirective field='name' headerText='Name' width='200' />
              <ColumnDirective
                field='typeOfName'
                headerText='Type Of'
                width='200'
              />
              <ColumnDirective
                field='calcTypeName'
                headerText='Calculation Type'
                width='270'
              />
              {/* <ColumnDirective
                template={amountTemplate}
                headerText='Amount'
                width='170'
                format={
                  this.user.settings.formats.Decimal
                    ? 'N' +
                      this.user.settings.formats.Decimal.split('.')[1].length
                    : 'N2'
                }
                textAlign='Right'
              /> */}
              {/* <ColumnDirective
                template={formulaTemplate}
                headerText='Formula'
                width='280'
              /> */}
              <ColumnDirective
                field='headTypeName'
                headerText='Head Type'
                visible={false}
              />
              <ColumnDirective field='id' headerText='id' visible={false} />
              <ColumnDirective
                field='headTypeId'
                headerText='typeofid'
                visible={false}
              />
              <ColumnDirective
                field='typeOfId'
                headerText='typeOfId'
                visible={false}
              />
              <ColumnDirective
                field='calcTypeId'
                headerText='calcTypeId'
                visible={false}
              />
              <ColumnDirective
                field='isActive'
                headerText='Active'
                width={'55px'}
                type='boolean'
                // displayAsCheckBox={true}
                template={templateActive}
                textAlign='Center'
              />
            </ColumnsDirective>
            <Inject services={[Toolbar, Edit]} />
          </GridComponent>
          <SelectorSearch grid_id='payheadlist-grid' />
        </>
      );
    };

    const bottomPane = () => {
      return (
        <>
          <SplitterComponent
            id='payheadlist-bottomsplitter'
            height='calc(100%)'
            orientation='Horizontal'
            ref={(obj: any) => (this.bottomsplitter = obj)}
            delayUpdate={true}
            separatorSize={4}
          >
            <PanesDirective>
              {/* <PaneDirective size='50%' min='20%' content={bottomLeftPane} /> */}
            </PanesDirective>
          </SplitterComponent>
        </>
      );
    };

    const bottomLeftPane = () => {
      return (
        <>
          <GridComponent
            id={this.grdFormulaId}
            height='100%'
            toolbar={this.toolbarOptions}
            editSettings={editOptionsformula}
            allowTextWrap={true}
            sortSettings={{
              columns: [{ field: 'effectiveDate', direction: 'Descending' }],
            }}
            allowSorting={true}
            ref={(obj: any) => (this.grdFormula = obj)}
            actionBegin={this.actionBegin.bind(this)}
            actionComplete={this.actionComplete.bind(this)}
          >
            <ColumnsDirective>
              <ColumnDirective
                field='effectiveDate'
                headerText='Effective Date'
                width='20%'
                minWidth='100'
                isPrimaryKey={true}
                type='datetime'
                format={this.user.settings.formats.DateShort}
              />
              <ColumnDirective
                field='amount'
                template={amountTemplate}
                headerText='Amount'
                width='20%'
                minWidth='100'
                format={
                  this.user.settings.formats.Decimal
                    ? 'N' +
                      this.user.settings.formats.Decimal.split('.')[1].length
                    : 'N2'
                }
                textAlign='Right'
              />
              <ColumnDirective
                template={formulaTemplate}
                headerText='Formula'
                width='60%'
              />
            </ColumnsDirective>
          </GridComponent>
        </>
      );
    };

    const bottomRightPaneSimple = () => {
      const dropdownOnFocus = (e: any) => {
        if (e.event.type === 'focus' && e.event.sourceCapabilities !== null) {
          (document.getElementById(
            'slabtype'
          ) as any)!.ej2_instances[0].showPopup();
        }
      };

      const templateSlabtype = (e: any) => {
        return (
          <DropDownListComponent
            id='slabtype'
            name='slabtype'
            dataSource={this.state.jsonSlabType}
            // ref={(scope) => {
            //   this.lstHeadType = scope;
            // }}
            value={!e.slabType ? undefined : e.slabType.toString()}
            filterBarPlaceholder='Search Slab Type'
            allowFiltering={true}
            filterType='Contains'
            fields={{ value: 'id', text: 'name' }}
            change={(e: any) =>
              (this.selectedSlabType = e.itemData ? e.itemData.id : null)
            }
            placeholder='Select slab type'
            popupHeight='220px'
            showClearButton={true}
            style={{ fontSize: '15px' }}
            focus={dropdownOnFocus}
          />
        );
      };

      const itemSlabtype = (e: any) => {
        let itemValue: string = '';
        let fltr: any = (this.state.jsonSlabType! as []).filter(
          (x: any) => x.id == e.slabType
        );
        if (fltr.length > 0) {
          itemValue = fltr[0].name;
        }
        return <>{itemValue}</>;
      };

      return (
        <>
          <GridComponent
            id={this.grdSimpleid}
            height='100%'
            toolbar={[
              ...this.toolbarOptions,
              {
                text: 'Save',
                tooltipText: 'Save',
                prefixIcon: 'e-update',
                id: `${this.grdSimpleid}_update`,
              },
              'Cancel',
            ]}
            editSettings={editOptionsSimpleSlab}
            allowTextWrap={true}
            groupSettings={this.groupOptions}
            allowGrouping={true}
            sortSettings={{
              columns: [{ field: 'effectiveDate', direction: 'Descending' }],
            }}
            allowSorting={true}
            ref={(obj: any) => (this.grdSimple = obj)}
            actionBegin={this.actionBeginSimpleSlab}
            actionComplete={this.actionCompleteSimpleSlab}
          >
            <ColumnsDirective>
              <ColumnDirective
                field='effectiveDate'
                headerText='Effective Date'
                width='40'
                //isPrimaryKey={true}
                type='datetime'
                format={this.user.settings.formats.DateShort}
                editType='datepickeredit'
                editTemplate={editTemplateDate}
                validationRules={{ required: true }}
              />
              <ColumnDirective
                field='amountUpTo'
                template={amountTemplate}
                headerText='Amount Upto'
                width='70'
                format={
                  this.user.settings.formats.Decimal
                    ? 'N' +
                      this.user.settings.formats.Decimal.split('.')[1].length
                    : 'N2'
                }
                textAlign='Right'
                editType='numericedit'
                editTemplate={editTemplateAmount}
                validationRules={{ required: true }}
              />
              <ColumnDirective
                field='slabType'
                template={itemSlabtype}
                headerText='Slab Type'
                width='40'
                editType='dropdownedit'
                //edit={this.slabTypeParams}
                editTemplate={templateSlabtype}
                //validationRules={{ required: true }}
                dataSource={this.state.jsonSlabType}
              />
              <ColumnDirective
                field='valueBasis'
                template={amountTemplate}
                headerText='Value'
                width='40'
                editType='numericedit'
                editTemplate={editTemplateAmount}
                validationRules={{ required: true }}
              />
              {/* <ColumnDirective
                template={formulaTemplate}
                headerText='Formula'
                width='280'
              /> */}
            </ColumnsDirective>
          </GridComponent>
        </>
      );
    };

    const bottomRightPaneInExcess = () => {
      return (
        <>
          <GridComponent
            id={this.grdInExcessid}
            height='100%'
            toolbar={[
              ...this.toolbarOptions,
              {
                text: 'Save',
                tooltipText: 'Save',
                prefixIcon: 'e-update',
                id: `${this.grdInExcessid}_update`,
              },
              'Cancel',
            ]}
            editSettings={editOptionsSimpleSlab}
            allowTextWrap={true}
            groupSettings={this.groupOptions}
            allowGrouping={true}
            sortSettings={{
              columns: [{ field: 'effectiveDate', direction: 'Descending' }],
            }}
            allowSorting={true}
            ref={(obj: any) => (this.grdInExcess = obj)}
            actionBegin={this.actionBeginInExcessSlab}
            // actionComplete={this.actionCompleteSimpleSlab}
          >
            <ColumnsDirective>
              <ColumnDirective
                field='effectiveDate'
                headerText='Effective Date'
                width='40'
                type='datetime'
                format={this.user.settings.formats.DateShort}
                editType='datepickeredit'
                editTemplate={editTemplateDate}
                validationRules={{ required: true }}
              />
              <ColumnDirective
                field='inExcessof'
                template={amountTemplate}
                headerText='In Excess of'
                width='50'
                format={
                  this.user.settings.formats.Decimal
                    ? 'N' +
                      this.user.settings.formats.Decimal.split('.')[1].length
                    : 'N2'
                }
                textAlign='Right'
                // editType='numericedit'
                // editTemplate={editTemplateAmount}
                validationRules={{ required: true }}
                allowEditing={false}
                defaultValue='0'
              />
              <ColumnDirective
                field='amountUpTo'
                template={amountTemplate}
                headerText='Amount Upto'
                width='60'
                format={
                  this.user.settings.formats.Decimal
                    ? 'N' +
                      this.user.settings.formats.Decimal.split('.')[1].length
                    : 'N2'
                }
                textAlign='Right'
                editType='numericedit'
                editTemplate={editTemplateAmount}
                validationRules={{ required: true }}
              />
              <ColumnDirective
                field='fixedAmount'
                template={amountTemplate}
                headerText='Fixed Amount'
                width='40'
                format={
                  this.user.settings.formats.Decimal
                    ? 'N' +
                      this.user.settings.formats.Decimal.split('.')[1].length
                    : 'N2'
                }
                textAlign='Right'
                // editType='numericedit'
                // editTemplate={editTemplateAmount}
                validationRules={{ required: true }}
                allowEditing={false}
                defaultValue='0'
              />
              <ColumnDirective
                field='ratePayable'
                template={amountTemplate}
                headerText='Rate Payable'
                width='30'
                format={
                  this.user.settings.formats.Decimal
                    ? 'N' +
                      this.user.settings.formats.Decimal.split('.')[1].length
                    : 'N2'
                }
                textAlign='Right'
                editType='numericedit'
                editTemplate={editTemplateAmount}
                validationRules={{ required: true }}
              />
            </ColumnsDirective>
          </GridComponent>
        </>
      );
    };

    const editOptions: EditSettingsModel = {
      allowEditing: true,
      allowAdding: true,
      allowDeleting: true,
      showDeleteConfirmDialog: true,
      mode: 'Dialog',
      allowEditOnDblClick: false,
      template: (props: any) => {
        return (
          <PayHeads
            {...props}
            tableid='payhead'
            jsonPayHead={this.state.jsonAllPayhead}
            defaultHeadTypeId={this.props.defaultHeadTypeId}
            defaultTypeOfId={this.props.defaultTypeOfId}
            ref={this.child}
          />
        );
      },
    };

    const editOptionsformula: EditSettingsModel = {
      allowEditing: true,
      allowAdding: true,
      allowDeleting: true,
      showDeleteConfirmDialog: true,
      mode: 'Dialog',
      allowEditOnDblClick: false,
      template: (props: any) => {
        return (
          <PayHeads
            {...props}
            {...this.selRecord}
            formulaid={props.id}
            tableid='formula'
            jsonPayHead={this.state.jsonAllPayhead}
            defaultHeadTypeId={[]}
            defaultTypeOfId={[]}
            ref={this.child}
          />
        );
      },
    };

    const editOptionsSimpleSlab: EditSettingsModel = {
      allowEditing: true,
      allowAdding: true,
      allowDeleting: true,
      showDeleteConfirmDialog: true,
      mode: 'Normal',
      allowEditOnDblClick: false,
      newRowPosition: 'Top',
      // template: (props: any) => {
      //   return (
      //     <PayHeadSimpleSlab
      //       {...props}
      //       {...this.selRecord}
      //       formulaid={props.id}
      //       tableid='simpleslab'
      //       jsonPayHead={this.state.jsonPayhead}
      //       ref={this.child}
      //     />
      //   );
      // },
    };

    const formulaTemplate = (props: any) => {
      return this.grdHead.getSelectedRecords().length > 0 &&
        (this.grdHead.getSelectedRecords()[0].calcTypeId === 1 ||
          this.grdHead.getSelectedRecords()[0].calcTypeId === 2) ? (
        <>{props.formula}</>
      ) : (
        ''
      );
    };

    const rowSelected = (e: any) => {
      if (!e.data) return;
      this.selRecord = e.data;
      let dtTran: any = (this.state.jsonPayhead! as []).filter(
        (x: any) => x.id === this.selRecord.id
      );
      if (dtTran.length > 0) {
        // if (this.grdFormula) {
        //   this.grdFormula.dataSource = dtTran[0]['payHeadTran'];
        // }
        // if (this.grdSimple) {
        //   this.grdSimple.dataSource = dtTran[0]['simpleSlabTran'];
        // }
        // if (this.grdInExcess) {
        //   this.grdInExcess.dataSource = dtTran[0]['inexcessSlabTran'];
        // }

        if (this.selRecord.calcTypeId === 1) {
          let formulapane: PanePropertiesModel | any = {
            size: '50%',
            min: '10%',
            content: bottomLeftPane,
            collapsible: true,
          };

          let simplepane: PanePropertiesModel | any = {
            size: '50%',
            min: '10%',
            content: bottomRightPaneSimple,
            collapsible: true,
          };

          this.bottomsplitter.removePane(0);
          this.bottomsplitter.removePane(0);
          this.bottomsplitter.addPane(formulapane, 0);
          this.bottomsplitter.addPane(simplepane, 1);
        } else if (this.selRecord.calcTypeId === 2) {
          let formulapane: PanePropertiesModel | any = {
            size: '50%',
            min: '10%',
            content: bottomLeftPane,
            collapsible: true,
          };

          let inexcesspane: PanePropertiesModel | any = {
            size: '50%',
            min: '10%',
            content: bottomRightPaneInExcess,
            collapsible: true,
          };

          this.bottomsplitter.removePane(0);
          this.bottomsplitter.removePane(0);
          this.bottomsplitter.addPane(formulapane, 0);
          this.bottomsplitter.addPane(inexcesspane, 1);
        } else if (this.selRecord.calcTypeId === 7) {
          let formulapane: PanePropertiesModel | any = {
            size: '50%',
            min: '10%',
            content: bottomLeftPane,
            collapsible: true,
          };

          let blankpane: PanePropertiesModel | any = {
            size: '50%',
            min: '10%',
            content: '',
            collapsible: true,
          };

          this.bottomsplitter.removePane(0);
          this.bottomsplitter.removePane(0);
          this.bottomsplitter.addPane(formulapane, 0);
          this.bottomsplitter.addPane(blankpane, 1);
        } else {
          let blankpane: PanePropertiesModel | any = {
            size: '50%',
            min: '20%',
            content: '',
            collapsible: true,
          };

          this.bottomsplitter.removePane(0);
          this.bottomsplitter.removePane(0);
          this.bottomsplitter.addPane(blankpane, 0);
          this.bottomsplitter.addPane(blankpane, 1);
        }

        if (this.grdFormula) {
          this.grdFormula.dataSource = dtTran[0]['payHeadTran'];
        }
        if (this.grdSimple) {
          let jsonSimple: any = dtTran[0]['simpleSlabTran'];
          jsonSimple.forEach((x: any) => {
            x['effectiveDate'] = new Date(
              Common.formatDate(
                new Date(x['effectiveDate']),
                this.user.settings.formats.DateShort
              )
            );
          });
          this.grdSimple.dataSource = jsonSimple;
          ///this.grdSimple.refresh();
        }
        if (this.grdInExcess) {
          let jsonInExcess: any = dtTran[0]['inexcessSlabTran'];
          jsonInExcess.forEach((x: any) => {
            x['effectiveDate'] = new Date(
              Common.formatDate(
                new Date(x['effectiveDate']),
                this.user.settings.formats.DateShort
              )
            );
          });
          this.grdInExcess.dataSource = jsonInExcess;
        }
      } else {
        this.grdFormula.dataSource = undefined;
        if (this.grdSimple) {
          this.grdSimple.dataSource = undefined;
        }
        if (this.grdInExcess) {
          this.grdInExcess.dataSource = undefined;
        }
      }
    };

    return (
      <>
        <div id='divpayheadlist' className='h-100'>
          {this.props.showPageHeader && (
            <label className='tab-tilte-allowance '>
              {this.props.selectorData && this.props.selectorData.length > 0
                ? this.props.selectorData[0].name
                : ''}
            </label>
          )}

          <SplitterComponent
            id='payheadlist-splitter'
            height={`calc(100vh - ${
              this.props.allowGroupingHead ? '210' : '210'
            }px)`}
            orientation='Vertical'
            delayUpdate={true}
            separatorSize={7}
          >
            <PanesDirective>
              <PaneDirective
                size='60%'
                min='15%'
                content={topPane}
                collapsible={true}
              />
              <PaneDirective
                size='40%'
                min='15%'
                content={bottomPane}
                collapsible={true}
              />
            </PanesDirective>
          </SplitterComponent>
        </div>
        <div></div>
        {this.state.totalcount >= 2 ? (
          <>
            <DialogComponent
              id='dlgProgress'
              showCloseIcon={false}
              visible={true}
              width={'45vw'}
              height={'15vh'}
              content={this.showProgress.bind(this)}
              isModal={true}
              header={'Processing...'}
              statelessTemplates={[]}
              buttons={this.buttons}
            ></DialogComponent>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
}
