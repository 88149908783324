import * as React from 'react';
import Common from '../../Util/Common';
import './User.css';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';

const { REACT_APP_ENDPOINT_EMPLOYEESERVICE } = process.env;

interface datamodel {
  isAdd?: boolean;
  id?: string;
  firstName?: string;
  lastName?: string;
  userName?: string;
  password?: string;
  confirmPassword?: string;
  email?: string;
  isManager?: boolean;
  jsonRole?: any;
  roleName?: string;
  roleId?: string;
  handleCancelClick?: (e: any) => void;
  handleSaveClick?: (e: any) => void;
}
interface Props {}

export default class User extends React.Component<datamodel> {
  private user: any;
  private _structure: any;
  private token: any = null;
  private ddlRole: DropDownListComponent | any;
  private userRoleId: string = '';
  private employeeRoleId: string = '';
  private path: string = '';
  private toastObj: any;

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
    //this.state = Object.assign({}, props);

    this.path = window.location.protocol + '//' + window.location.hostname;
    if (window.location.port) {
      this.path += ':' + window.location.port;
    }
  }

  static defaultProps = {
    isManager: true,
  };

  state = {
    isAdd: this.props.isAdd ?? true,
    id: this.props.id ?? '',
    firstName: this.props.firstName ?? '',
    lastName: this.props.lastName ?? '',
    userName: this.props.userName ?? '',
    password: this.props.password ?? '',
    confirmPassword: this.props.confirmPassword ?? '',
    email: this.props.email ?? '',
    isManager: this.props.isManager ?? false,
    jsonRole: this.props.jsonRole ?? undefined,
    roleName: this.props.roleName ?? '',
    roleId: this.props.roleId ?? '',
    multicompanyEmail: false,
  };

  componentDidMount(): void {
    // let data: datamodel = this.state;
    this.employeeRoleId = this.props.jsonRole.filter(
      (x: any) => x.name === 'Employee'
    )[0].id;
    if (this.props.roleName) {
      this.userRoleId = this.props.jsonRole.filter(
        (x: any) => x.name === this.props.roleName
      )[0].id;
      this.setState({
        roleId: this.userRoleId,
      });
    }
  }

  public GetState() {
    return this.state;
  }

  onCheckboxChange(args: any): void {
    //let data: datamodel = this.state;
    let ctrl: HTMLElement = args.event.target.parentElement;
    let ctrlid: any = ctrl.getElementsByTagName('input');
    if (!ctrlid) return;
    ctrlid = 'u_' + ctrlid[0].id;

    let chk: any = (document.getElementsByClassName(ctrlid) as any)[0]
      .ej2_instances[0];

    let key: string = chk.id;
    let value: boolean = chk.checked;
    (document.getElementById(ctrlid) as HTMLInputElement).value =
      value.toString();
    this.ddlRole.enabled = chk.checked;

    this.setState({
      [key]: value,
      roleId: chk.checked
        ? this.userRoleId
          ? this.userRoleId
          : null
        : this.employeeRoleId,
    });
    if (chk.checked && !this.userRoleId) {
      this.ddlRole.value = null;
    }
  }
  onFocusFunction = (event: any) => {
    event.target.classList.remove('textbox-border-error');
  };
  render() {
    //let data: datamodel = this.state;

    const ontextChange = (e: any) => {
      let key: string = e.target.name;
      let value: string = e.value;

      this.setState({ [key]: value });
    };

    const IsValid = (): boolean => {
      ValidateForm();

      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (!this.state.firstName) {
        const TargetInput =
          document.querySelector<HTMLInputElement>('#firstName');
        if (TargetInput) {
          TargetInput.style.borderBottom = '2px solid red';
        }
        return false;
      } else if (!this.state.lastName) {
        const TargetInput =
          document.querySelector<HTMLInputElement>('#lastName');
        if (TargetInput) {
          TargetInput.style.borderBottom = '2px solid red';
        }
        return false;
      } else if (!this.state.userName) {
        const TargetInput =
          document.querySelector<HTMLInputElement>('#userName');
        if (TargetInput) {
          TargetInput.style.borderBottom = '2px solid red';
        }
        return false;
      } else if (this.state.userName.includes(' ')) {
        this.toastObj = ToastUtility.show({
          cssClass: 'e-toast-warning',
          icon: 'e-warning toast-icons',
          showCloseButton: true,
          content: 'Sorry, user name must not contain a space character.',
          target: '#basic_card',
          position: { X: 'Center', Y: 'Top' },
          width: 'auto',
          timeOut: 3000,
        });
        return false;
      } else if (!this.state.email) {
        const TargetInput = document.querySelector<HTMLInputElement>('#email');
        if (TargetInput) {
          TargetInput.style.borderBottom = '2px solid red';
          if (!emailPattern.test(TargetInput.value)) {
            this.toastObj = ToastUtility.show({
              cssClass: 'e-toast-warning',
              icon: 'e-warning toast-icons',
              showCloseButton: true,
              content: 'Please Enter Corract format of Email ..!',
              target: '#basic_card',
              position: { X: 'Center', Y: 'Top' },
              width: 'auto',
              timeOut: 3000,
            });
          }
        }
        return false;
      } else if (this.state.isAdd && !this.state.password) {
        const TargetInput =
          document.querySelector<HTMLInputElement>('#password');
        if (TargetInput) {
          TargetInput.style.borderBottom = '2px solid red';
        }
        return false;
      } else if (this.state.isAdd && this.state.password.includes(' ')) {
        this.toastObj = ToastUtility.show({
          cssClass: 'e-toast-warning',
          icon: 'e-warning toast-icons',
          showCloseButton: true,
          content: 'Sorry, password must not contain a space character.',
          target: '#basic_card',
          position: { X: 'Center', Y: 'Top' },
          width: 'auto',
          timeOut: 3000,
        });
        return false;
      } else if (this.state.isAdd && this.state.password.length < 6) {
        this.toastObj = ToastUtility.show({
          cssClass: 'e-toast-warning',
          icon: 'e-warning toast-icons',
          showCloseButton: true,
          content: 'Sorry, Password must be at least 6 characters.',
          target: '#basic_card',
          position: { X: 'Center', Y: 'Top' },
          width: 'auto',
          timeOut: 3000,
        });
        return false;
      } else if (this.state.isAdd && !this.state.confirmPassword) {
        const TargetInput =
          document.querySelector<HTMLInputElement>('#confirmPassword');
        if (TargetInput) {
          TargetInput.style.borderBottom = '2px solid red';
        }
        return false;
      } else if (
        this.state.isAdd &&
        this.state.password !== this.state.confirmPassword
      ) {
        this.toastObj = ToastUtility.show({
          cssClass: 'e-toast-warning',
          icon: 'e-warning toast-icons',
          showCloseButton: true,
          content: 'Sorry, Password and confirm password must be same.',
          target: '#basic_card',
          position: { X: 'Center', Y: 'Top' },
          width: 'auto',
          timeOut: 3000,
        });
        return false;
      } else if (!this.state.roleId) {
        const TargetInput = document.querySelector<HTMLInputElement>('#roleId');
        if (TargetInput) {
          TargetInput.style.borderBottom = '2px solid red';
        }
        return false;
      }

      return true;
    };

    const ValidateForm = () => {
      const inputs = [
        document.querySelector<HTMLInputElement>('#firstName'),
        document.querySelector<HTMLInputElement>('#lastName'),
        document.querySelector<HTMLInputElement>('#userName'),
        document.querySelector<HTMLInputElement>('#email'),
        document.querySelector<HTMLInputElement>('#password'),
        document.querySelector<HTMLInputElement>('#confirmPassword'),
        document.querySelector<HTMLInputElement>('#roleId'),
      ];

      inputs.forEach((input) => {
        if (input) {
          input.classList.remove('textbox-border-error');

          if (input.value.trim() === '') {
            input.classList.add('textbox-border-error');
          } else {
            input.classList.add('textbox-class');
          }
        }
      });
    };

    const onCancelClick = (e: any) => {
      if (this.props.handleCancelClick) this.props.handleCancelClick(e);
    };

    const onSaveClick = (e: any) => {
      if (IsValid() === false) return false;

      let selectedRole: any = (this.state.jsonRole as any).filter(
        (x: any) => x.id === this.state.roleId
      );

      if (selectedRole.length > 0) {
        selectedRole = selectedRole[0];
      }

      let payload: any = {
        id: this.state.isAdd ? null : this.state.id,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        userName: this.state.userName,
        password: this.state.password,
        confirmPassword: this.state.confirmPassword,
        phoneNumber: '',
        //isManager: e.data.isManager,
        // roleName: (this.state.jsonRole as any).filter(
        //   (x: any) => x.id === e.data.roleId
        // )[0].name,
        isManager:
          selectedRole.name.toString().toLowerCase() === 'employee'
            ? false
            : true,
        roleName: selectedRole.name,
        referer: this.path,
      };

      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_EMPLOYEESERVICE}/api/users`,
        payload,
        Common.getToken() || '',
        this.user,
        this.token.tenant
      )
        .then((response: any) => {
          return response.data;
        })
        .then((data: any) => {
          this.toastObj = ToastUtility.show({
            cssClass: 'e-toast-success',
            icon: 'far fa-circle-check',
            showCloseButton: true,
            content: !payload.id
              ? 'New user created Successfully...!'
              : 'User updated Successfully...!',
            target: '#basic_card',
            position: { X: 'Center', Y: 'Top' },
            width: 'auto',
            timeOut: 3000,
          });

          this.setState({
            isAdd: true,
            firstName: '',
            lastName: '',
            userName: '',
            password: '',
            confirmPassword: '',
            email: '',
            isManager: false,
            roleName: '',
            roleId: '',
          });

          if (this.props.handleSaveClick) this.props.handleSaveClick(data);
        })
        .catch((error: any) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.messages &&
            error.response.data.messages.length > 0 &&
            error.response.data.messages.filter((x: any) =>
              x.includes('same password')
            ).length > 0
          ) {
            this.setState({ multicompanyEmail: true });
          }
          console.error(error);
        })
        .finally(() => {});
    };

    return (
      <>
        <div id='divuseraddedit'>
          <div className='row align-items-center'>
            <div className='col-6 py-2 userlist-textbox'>
              {/* <label style={{ paddingBottom: '7px' }}>First Name</label>
            <span className='mandatoryinfo'>*</span> */}
              <TextBoxComponent
                className='input-text-border'
                onFocus={this.onFocusFunction}
                id='firstName'
                name='firstName'
                type='text'
                placeholder='Enter first name *'
                floatLabelType='Auto'
                autocomplete='off'
                value={this.state.firstName}
                onChange={ontextChange}
              />
            </div>
            <div className='col-6 py-2 userlist-textbox'>
              {/* <label style={{ paddingBottom: '7px' }}>Last Name</label>
            <span className='mandatoryinfo'>*</span> */}
              <TextBoxComponent
                className='input-text-border'
                onFocus={this.onFocusFunction}
                id='lastName'
                name='lastName'
                type='text'
                placeholder='Enter last name *'
                floatLabelType='Auto'
                autocomplete='off'
                value={this.state.lastName}
                onChange={ontextChange}
              />
            </div>
          </div>
          <div className='row align-items-center'>
            <div className='col-6 py-2 userlist-textbox'>
              {/* <label style={{ paddingBottom: '7px' }}>User Name</label>
            <span className='mandatoryinfo'>*</span> */}
              <TextBoxComponent
                className='input-text-border'
                id='userName'
                onFocus={this.onFocusFunction}
                name='userName'
                type='text'
                placeholder='Enter user name *'
                floatLabelType='Auto'
                autocomplete='off'
                value={this.state.userName}
                onChange={ontextChange}
                // enabled={
                //   this.state.isAdd === undefined ||
                //   this.state.isAdd === null ||
                //   this.state.isAdd
                // }
              />
            </div>
            <div className='col-6 py-2 userlist-textbox'>
              {/* <label style={{ paddingBottom: '7px' }}>Email</label>
            <span className='mandatoryinfo'>*</span> */}
              <TextBoxComponent
                className='input-text-border'
                id='email'
                name='email'
                onFocus={this.onFocusFunction}
                type='email'
                placeholder='Enter email *'
                floatLabelType='Auto'
                autocomplete='off'
                value={this.state.email}
                onChange={ontextChange}
              />
            </div>
          </div>
          {(this.state.isAdd || this.state.multicompanyEmail) && (
            <div className='row align-items-center'>
              <div className='col-6 py-2 userlist-textbox'>
                {/* <label style={{ paddingBottom: '7px' }}>Password</label>
              <span className='mandatoryinfo'>*</span> */}
                <TextBoxComponent
                  className='input-text-border'
                  id='password'
                  onFocus={this.onFocusFunction}
                  name='password'
                  type='password'
                  placeholder='Enter password *'
                  floatLabelType='Auto'
                  autocomplete='off'
                  value={this.state.password}
                  onChange={ontextChange}
                />
              </div>
              <div className='col-6 py-2 userlist-textbox'>
                {/* <label style={{ paddingBottom: '7px' }}>Confirm Password</label>
              <span className='mandatoryinfo'>*</span> */}
                <TextBoxComponent
                  className='input-text-border'
                  id='confirmPassword'
                  name='confirmPassword'
                  type='password'
                  onFocus={this.onFocusFunction}
                  placeholder='Enter confirm password *'
                  floatLabelType='Auto'
                  autocomplete='off'
                  value={this.state.confirmPassword}
                  onChange={ontextChange}
                />
              </div>
            </div>
          )}
          <div className='row align-items-center'>
            {/* <div className='col-6 py-2'>
            <CheckBoxComponent
              id='isManager'
              name='isManager'
              className='u_isManager'
              // checked={
              //   this.state.isAdd === undefined || this.state.isAdd === null || this.state.isAdd
              //     ? true
              //     : this.state.isManager
              // }
              checked={
                typeof this.state.isManager === 'boolean' ? this.state.isManager : true
              }
              label={'Manager'}
              change={this.onCheckboxChange.bind(this)}
              value=''
            />
            <input
              className='input-text-border'
              id='u_isManager'
              name='u_isManager'
              type='hidden'
              autoComplete='off'
              style={{ fontSize: '15px' }}
              // value={
              //   this.state.isAdd === undefined || this.state.isAdd === null || this.state.isAdd
              //     ? 'true'
              //     : this.state.isManager!.toString()
              // }
              value={
                typeof this.state.isManager === 'boolean'
                  ? this.state.isManager!.toString()
                  : 'true'
              }
            /> 
          </div>*/}
            <div className='col-6 py-2 userlist-textbox'>
              {/* <label style={{ paddingBottom: '7px' }}>User Role</label>
            <span className='mandatoryinfo'>*</span> */}
              <DropDownListComponent
                id='roleId'
                name='roleId'
                dataSource={this.state.jsonRole}
                // onFocus={this.onFocusFunction}
                ref={(obj) => {
                  this.ddlRole = obj;
                }}
                filterBarPlaceholder='Search Role'
                allowFiltering={true}
                filterType='Contains'
                fields={{ value: 'id', text: 'name' }}
                placeholder='Select Role *'
                floatLabelType='Auto'
                popupHeight='220px'
                showClearButton={true}
                value={this.state.roleId ? this.state.roleId : undefined}
                change={(e: any) => {
                  this.setState({
                    roleId: e.itemData ? e.itemData.id : undefined,
                  });
                }}
                //enabled={data.isManager === false ? false : true}
              />
            </div>
            {/* <span
            id='errorMessage'
            style={{
              color: 'red',
              maxWidth: '400px',
              height: 'auto',
              display: 'block',
              lineHeight: '20px',
              letterSpacing: '0.5px',
            }}
          ></span> */}
          </div>
          <div className='e-footer-content'>
            <div>
              <button
                id='btnCancel'
                className='e-control e-btn e-lib flat-button e-flat'
                onClick={onCancelClick}
              >
                Cancel
              </button>
              <button
                id='btnAccept'
                className='e-control e-btn e-lib flat-button e-primary e-flat'
                onClick={onSaveClick}
              >
                Accept
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}
