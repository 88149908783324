import React from 'react';
import './Favorite.css';
import Common from '../../../Util/Common';

interface Props {
  rootmenu: any;
  selectedmenu: any;
  updateJsonDataViewer?: any;
}

export default class Favorite extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
  }

  state = { jsonFavorite: undefined };

  componentDidMount() {
    if (!this.state.jsonFavorite) {
      this.GetFavorite();
    }
  }

  private GetFavorite() {
    let data: any;
    data = [
      {
        id: 'payslips',
        text: 'Payslips',
        iconCss: 'fa-solid fa-badge-dollar',
      },
      {
        id: 'leaveviewer',
        text: 'Leave Viewer',
        iconCss: 'fa-solid fa-check-to-slot',
      },
    ];

    this.setState({ jsonFavorite: data });
  }
  toggleResourceLinkCard() {
    const card = document.getElementById('favorite-card-id');
    const icon = document.querySelector('.show-down-arrow');

    if (card) {
      if (card.classList.contains('expanded')) {
        card.style.height = `${card.scrollHeight}px`;
        setTimeout(() => {
          card.style.height = '0px';
        }, 0);
        icon?.classList.replace('fa-angle-up', 'fa-angle-down');
      } else {
        card.style.height = '0px';
        setTimeout(() => {
          card.style.height = `${card.scrollHeight}px`;
        }, 0);
        icon?.classList.replace('fa-angle-down', 'fa-angle-up');
      }
      card.classList.toggle('expanded');
    }
  }
  render() {
    const onFavoriteClick = (e: any) => {
      let id: any = e.target.dataset.id;
      if (!id) {
        id = e.target.parentElement.dataset.id;
      }

      switch (id) {
        case 'payslips':
          UpdateDataViewer(this.user.isManager ? 4 : 3, true);
          break;

        case 'leaveviewer':
          UpdateDataViewer(this.user.isManager ? 6 : 5);
          break;

        default:
          break;
      }
    };

    const UpdateDataViewer = (
      tabindex: number,
      removeRemaining: boolean = false
    ) => {
      if (!this.user.employee) {
        //DialogUtility.alert('Sorry, You are not linked with any employee!');
        return;
      }

      let dv: any = this.user.employee;
      dv['name'] =
        this.user.employee.employeecode + ' : ' + this.user.employee.personName;

      dv['rootmenu'] = this.props.rootmenu;
      dv['rootmenu'] = this.props.selectedmenu;
      dv['selectedtabindex'] = removeRemaining ? 0 : tabindex;

      dv['tabset'] = [
        {
          name: 'Personal',
          order: 0,
        },
        {
          name: 'Dossier',
          order: 1,
        },
        {
          name: 'Contracts',
          order: 2,
        },
        {
          id: 'payheads',
          name: 'Pay Heads',
          order: 3,
        },
        {
          name: 'Pay Slips',
          order: 4,
        },
        {
          name: 'Timesheet',
          order: 5,
        },
        {
          name: 'Leaves',
          order: 6,
        },
        {
          name: 'Loans',
          order: 7,
        },
        {
          name: 'Membership',
          order: 8,
        },
        {
          name: 'Bank',
          order: 9,
        },
        {
          name: 'Obligation',
          order: 10,
        },
        {
          name: 'Disciplinary',
          order: 11,
        },
      ];
      if (!this.user.isManager) {
        dv['tabset'].splice(3, 1);
      }
      if (removeRemaining) {
        dv['tabset'] = dv['tabset'].slice(tabindex, tabindex + 1);
      }
      //   this.props.updateJsonDataViewer(
      //     this.props.rootmenu,
      //     this.props.selectedmenu,
      //     dv,
      //     true
      //   );
      this.props.updateJsonDataViewer(dv);
    };

    return (
      <div className='e-card e-custom-card mt-4 stickydivprev fadeUp favorite-card'>
        <div className='fadeUp favorite-card-header'>
      <div
          style={{
            display: 'flex',
              justifyContent: 'space-around',
            alignItems: 'center',
              width: '30%',
            }}
          >
            <i
              className='ps-2 fa-thin fa-star'
              style={{
                color: 'rgba(0, 0, 0, 0.54)',
                fontSize: '15px',
                marginRight: '10px',
              }}
            ></i>
            <span
              style={{
                fontSize: '16px',
                color: '#fc4c02',
                letterSpacing: '1px',
          }}
        >
          Favorites
            </span>
          </div>
          <i
            className='fa-regular fa-angle-up show-down-arrow'
            onClick={this.toggleResourceLinkCard}
            style={{ cursor: 'pointer' }}
          ></i>
        </div>

        <div
          id='favorite-card-id'
          className='e-card-content favorite-card-content expanded'
        >
          {this.state.jsonFavorite &&
            (this.state.jsonFavorite as any).map((x: any) => {
              return (
                <div className='row' key={x.id}>
                  <div className='col-12'>
                    <div
                      className='favorite-content-row'
                      data-id={x.id}
                      onClick={onFavoriteClick}
                    >
                      <div
                        style={{ marginLeft: '10px', display: 'inline-flex' }}
                      >
                        <i className='fa-solid fa-caret-right'></i>
                      </div>
                      <div
                        style={{ marginLeft: '10px', display: 'inline-block' }}
                        className={`font-22 ${x.iconCss}`}
                      ></div>
                      <div className='ps-2'>{x.text}</div>
                    </div>
                  </div>
                </div>
              );
            })}

          {/* <a
                          className='cursor-pointer'
                          onClick={() =>
                            UpdateDataViewer(this.user.isManager ? 6 : 5)
                          }
                        >
                          Apply Leave
                        </a> */}

          <div className='row py-2'>
            <div className='col-12'>
              {/* <a
                          className='cursor-pointer'
                          onClick={() =>
                            UpdateDataViewer(this.user.isManager ? 4 : 3)
                          }
                        >
                          View Payslip
                        </a> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
