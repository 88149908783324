import React from 'react';
import './UpcomingEvent.css';
import Common from '../../../Util/Common';
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  InfiniteScroll,
  Page,
} from '@syncfusion/ej2-react-grids';

const { REACT_APP_ENDPOINT_CORESERVICE } = process.env;

interface Props {}

export default class UpcomingEvent extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
  }

  state = { jsonData: undefined, showViewAll: false };

  componentDidMount() {
    if (!this.state.jsonData) {
      this.GetList();
    }
  }

  private GetList() {
    let payload: any = {
      fromDate: Common.RemoveTimezoneOffsetFromDate(new Date()),
    };

    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/holiday/get`,
      payload,
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        let viewall: boolean = response.data.length > 2;
        if (viewall) {
          document
            .querySelector('.divupcomingeventlist .upcomingeventlist-grid')
            ?.classList.add('overflowhidden');
        }
        this.setState({
          jsonData: response.data,
          showViewAll: viewall,
        });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }
  isToday = (dateString: string): boolean => {
    if (!dateString) {
      return false;
    }
    const inputDate = new Date(dateString);
    const today = new Date();

    return (
      inputDate.getFullYear() === today.getFullYear() &&
      inputDate.getMonth() === today.getMonth() &&
      inputDate.getDate() === today.getDate()
    );
  };

  render() {
    const columnTemplate = (e: any) => {
            const isEventToday = this.isToday(e.date);
      return (
        <>
          <div
            className={`event-card ${isEventToday ? 'highlight-today' : ''}`}
            // style={{ flexDirection: 'column', border: '1px solid black' }}
            data-id={e.id}
          >
            <div className='event-date-circle'>
              <span className='month'>
                {new Date(e.date)
                  .toLocaleString('default', { month: 'long' })
                  .slice(0, 3)}
              </span>
              <span className='day'>{new Date(e.date).getDate()}</span>
              </div>
            <div className='event-info'>
              <div className='event-name' title='Name'>
                {e.name}
              </div>
            </div>
          </div>
        </>
      );
    };

    const onViewAllClick = (e: any) => {
      //this.setState({ showViewAll: false });
      if (this.state.showViewAll) {
        document
          .querySelector('.divupcomingeventlist .upcomingeventlist-grid')
          ?.classList.remove('overflowhidden');
      } else {
        document.querySelector(
          '.divupcomingeventlist .upcomingeventlist-grid .e-content'
        )!.scrollTop = 0;
        document
          .querySelector('.divupcomingeventlist .upcomingeventlist-grid')
          ?.classList.add('overflowhidden');
      }
      this.setState({ showViewAll: !this.state.showViewAll });
    };

    return (
      <div className='control-pane divupcomingeventlist expanded'>
        <div className=''>
          <div className=''>
            <GridComponent
              id='upcomingeventlist-grid'
              className='upcomingeventlist-grid'
              dataSource={this.state.jsonData}
              // height={this.state.showViewAll ? '272px' : '310px'}
              height={'310px'}
              enableInfiniteScrolling={true}
              pageSettings={{ pageSize: 5 }}
              gridLines={'None'}
            >
              <ColumnsDirective>
                <ColumnDirective
                  headerText='Name'
                  field='Name'
                  template={columnTemplate}
                />
              </ColumnsDirective>
              <Inject services={[InfiniteScroll, Page]} />
            </GridComponent>
            {this.state.jsonData && (this.state.jsonData as any).length > 2 && (
              <div
                className='upcomingeventlist-viewall'
                onClick={onViewAllClick}
              >
                See {this.state.showViewAll ? 'more' : 'less'}
                <i
                  style={{ margin: '2px 10px', display: 'inline-block' }}
                  className={`ps-1 fa-solid fa-chevron-${
                    this.state.showViewAll ? 'down' : 'up'
                  }`}
                ></i>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
