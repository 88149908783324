import React from 'react';
import './ResourceLinkCard.css';
import Common from '../../../Util/Common';
import {
  AccordionComponent,
  AccordionItemDirective,
  AccordionItemsDirective,
} from '@syncfusion/ej2-react-navigations';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

const { REACT_APP_ENDPOINT_CORESERVICE } = process.env;

interface Props {
  rootmenu: any;
  selectedmenu: any;
  updateJsonDataViewer?: any;
}

export default class ResourceLinkCard extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
  }

  state = { jsonData: undefined };

  componentDidMount() {
    if (!this.state.jsonData) {
      this.GetList();
    }
  }

  public GetList() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/resourcelink/get`,
      { expiryDate: new Date(), includeBlankCategory: false },
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => response.data)
      .then((data: any) => {
        this.setState({ jsonData: data });
      })
      .catch((error: any) => {
        console.error(error);
      });
  }
  toggleResourceLinkCard() {
    const card = document.getElementById('divresourcelinkcard-grid');
    const icon = document.querySelector('.show-down-arrow1');

    if (card) {
      if (card.classList.contains('expanded')) {
        card.style.height = `${card.scrollHeight}px`;
        setTimeout(() => {
          card.style.height = '0px';
        }, 0);
        icon?.classList.replace('fa-angle-up', 'fa-angle-down');
      } else {
        card.style.height = '0px';
        setTimeout(() => {
          card.style.height = `${card.scrollHeight}px`;
        }, 0);
        icon?.classList.replace('fa-angle-down', 'fa-angle-up');
      }
      card.classList.toggle('expanded');
    }
  }
  render() {
    const icons = [
      'fa-solid fa-shield-check',
      'fa-solid fa-handshake',
      'fa-solid fa-chart-simple',
    ];
    const renderAccordionItems = () => {
      if (!this.state.jsonData) return null;

      return (this.state.jsonData as any).map((item: any, index: number) => (
        <AccordionItemDirective
          key={index}
          iconCss={icons[index % icons.length]}
          header={item.nameTitle}
          expanded={false}
          content={() => renderSubItems(item.subResourceLinks)}
                />
      ));
    };

    const renderSubItems = (subItems: any[]) => {
      if (!subItems || subItems.length === 0) return null;

      return (
        <ul style={{ paddingLeft: '20px', listStyle: 'none' }}>
          {subItems.map((subItem, index) => (
            <li key={index} style={{ marginBottom: '10px' }}>
              {subItem.description ? (
                <TooltipComponent
                  content={subItem.description}
                  position='TopCenter'
                >
                  <a
                    href={subItem.url}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {subItem.nameTitle}
                  </a>
                </TooltipComponent>
              ) : (
                <a href={subItem.url} target='_blank' rel='noopener noreferrer'>
                  {subItem.nameTitle}
                  </a>
              )}
            </li>
          ))}
        </ul>
      );
    };

    return (
      <div
        id='divResourcelinkCard'
        className='e-card mt-4 stickydiv fadeUp'
        style={{ marginTop: '20px', width: '100%' }}
      >
        <div
          className='e-card-header'
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: '14px',
            fontWeight: '500',
          }}
        >
          <div>
            <i
              className='pe-2 fa-brands fa-nfc-symbol'
            style={{ color: 'rgba(0, 0, 0, 0.54)' }}
            ></i>
            <span>Resource Links</span>
          </div>
          <i
            className='fa-regular fa-angle-up show-down-arrow1'
            onClick={this.toggleResourceLinkCard}
            style={{ cursor: 'pointer' }}
          ></i>
        </div>
        <div id='divresourcelinkcard-grid' className='expanded'>
          <div className='e-card-content'>
            <AccordionComponent>
              <AccordionItemsDirective>
                {renderAccordionItems()}
              </AccordionItemsDirective>
            </AccordionComponent>
          </div>
        </div>
      </div>
    );
  }
}
