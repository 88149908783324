import * as React from 'react';
import Common from '../../Util/Common';
import { Dialog, DialogComponent } from '@syncfusion/ej2-react-popups';
import {
  ColumnsDirective,
  ColumnDirective,
  VirtualScroll,
  Toolbar,
  RowDD,
  EditSettingsModel,
  GridComponent,
  Edit,
  Inject,
  DialogEditEventArgs,
} from '@syncfusion/ej2-react-grids';
import SelectorSearch from '../Layout/SelectorSearch';
import LeaveType from './LeaveType';
import LeaveTypeDetail from './LeaveTypeDetail';
import DialogConfirm from '../Dialogbox/DialogConfirm';
import './Leave.css';
import { SwitchComponent } from '@syncfusion/ej2-react-buttons';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';

const { REACT_APP_ENDPOINT_LEAVESERVICE, REACT_APP_ENDPOINT_CORESERVICE } =
  process.env;

interface Props {
  handleRefreshSidebar?: any;
}

export default class LeaveTypesList extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;
  public toolbarOptions: any = ['Add', 'Delete'];
  private grid: GridComponent | null;
  private selectedrowindex = -1;
  toastObj: any;

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
    this.grid = null;
  }

  state = {
    leavetypes: [],
    deldata: [],
    leavetypedetail: undefined,
  };

  componentDidMount(): void {
    if (this.state.leavetypes.length <= 0) {
      this.fetchleavetypes();
    }
  }

  private fetchleavetypes() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_LEAVESERVICE}/leavetype/getleavetypes`,
      { showOnlyActive: false },
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((resopnse: any) => {
        this.setState({ leavetypes: resopnse.data });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  groupOptions: any = {
    columns: [''],
    captionTemplate: '<div className="grouphead" >${key}</div>',
    showDropArea: false,
  };

  colorTemplate(data: any): any {
    return (
      <div
        id='color'
        style={{
          backgroundColor: data.color,
          width: '18px',
          height: '18px',
          borderRadius: '50%',
        }}
      ></div>
    );
  }

  private AddLeaveType(eData: any) {
    // if (eData.txtCode == undefined || eData.txtCode == '') {
    //   DialogConfirm.showDialog({
    //     content:
    //       '<p class="dialog-contain">Sorry, Code is mandatory information. Please provide code to continue.</p>',
    //   });
    //   return;
    // }

    // if (eData.txtName == undefined || eData.txtName == '') {
    //   DialogConfirm.showDialog({
    //     content:
    //       '<p class="dialog-contain">Sorry, Name is mandatory information. Please provide name to continue.</p>',
    //   });
    //   return;
    // }

    // if (eData.colorpicker !== undefined && eData.colorpicker == '') {
    // DialogConfirm.showDialog({
    //   content : '<p class="dialog-contain">Sorry, Color is mandatory information. Please select color code to continue.</p>'
    // })
    //   return;
    // }

    //'#'+(Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0')

    // let payload: any = {
    //   code: eData.txtCode,
    //   name: eData.txtName,
    //   color: eData.colorpicker,
    //   isPaid: eData.chkPaidLeave,
    //   isAccrued: eData.chkAccruedLeave,
    //   isShortLeave: eData.chkShortLeave,
    //   issueOnHoliday: eData.chkIssueOnHoliday,
    //   issueOnWeekend: eData.chkIssueOnWeekend,
    //   isEndDateOptional: eData.chkEndDateOptional,
    //   minDays: eData.mindays,
    //   maxDays: eData.maxdays,
    //   deductFromLeaveId: eData.leaveid,
    //   isExcuseLeave: false,
    // };

    let payload: any = {
      code: eData.txtCode,
      name: eData.txtName,
      color:
        '#' + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, '0'),
      isPaid: eData.chkPaidLeave,
      unitId: eData.unitid,
    };
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_LEAVESERVICE}/leavetype/add`,
      payload,
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        //let data = this.state.leavetypes;
        //(data as any).push(response);
        //this.setState({ leavetypes: data, leavetypedetail: undefined })
        //this.grid?.refresh();
        this.fetchleavetypes();

        Common.ApiCallAsync(
          'POST',
          `${REACT_APP_ENDPOINT_CORESERVICE}/dossiertype/view`,
          { leaveTypesId: response.id },
          Common.getToken() || '',
          null,
          this.token.tenant
        )
          .then((response: any) => {
            return response.data;
          })
          .then((data: any) => {
            let sidebar: any = JSON.parse(Common.getItem('sidebar')!);
            let s: any = sidebar.dynamicMenu.filter(
              (x: any) => x.id === 'correspondencemenu'
            );
            if (s.length > 0) {
              let sm: any = s[0].subMenuJsonList.filter(
                (x: any) => x.name === 'Leave'
              );
              s = s[0].dynamicNode.dynamicSelector.dynamicContextMenu;
              s = s.filter((x: any) => x.controlType === 2);
              if (s.length > 0) {
                s = s[0].subMenuJsonList.filter((x: any) => x.name === 'Leave');
                s = s[0].subMenuJsonList;

                if (data.length === 2 && s.length === 2) {
                  s[0].subMenuJsonList.push(data[0]);
                  s[1].subMenuJsonList.push(data[1]);

                  sm = sm[0].subMenuJsonList;
                  sm[0].subMenuJsonList.push(data[0]);
                  sm[1].subMenuJsonList.push(data[1]);

                  let t: any = sidebar.dynamicMenu.filter(
                    (x: any) => x.id === 'templatemenu'
                  );
                  if (t.length > 0) {
                    t = t[0].dynamicNode.dynamicSelector.dynamicContextMenu;
                    t = t.filter((x: any) => x.controlType === 2);
                    if (t.length > 0) {
                      t = t[0].subMenuJsonList.filter(
                        (x: any) => x.name === 'Leave'
                      );
                      t = t[0].subMenuJsonList;
                      t[0].subMenuJsonList.push(data[0]);
                      t[1].subMenuJsonList.push(data[1]);
                    }
                  }
                }

                Common.setItem('sidebar', sidebar);
                this.props.handleRefreshSidebar(true);
              }
            }

            return data;
          })
          .catch((error: any) => {
            console.error(error);
          })
          .finally(() => {});
      })
      .catch((error: any) => {
        console.error(error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.messages &&
          error.response.data.messages.length > 0
        ) {
          // DialogConfirm.showDialog({
          //   content: `<p class="dialog-contain">${error.response.data.messages.join(
          //     '<br>'
          //   )}</p>`,
          // });
          this.toastObj = ToastUtility.show({
            cssClass: 'e-toast-error',
            icon: 'far fa-circle-xmark',
            showCloseButton: true,
            content: `<p>${error.response.data.messages.join('<br>')}</p>`,
            target: '#basic_card',
            position: { X: 'Center', Y: 'Top' },
            width: 'auto',
            timeOut: 3000,
          });
        }
      })
      .finally(() => {});
  }

  ValidateForm = () => {
    const inputs = [
      document.querySelector<HTMLInputElement>('#txtCode'),
      document.querySelector<HTMLInputElement>('#txtName'),
    ];

    inputs.forEach((input) => {
      if (input) {
        input.classList.remove('textbox-border-error');

        if (input.value.trim() === '') {
          input.classList.add('textbox-border-error');
        } else {
          input.classList.add('textbox-class');
        }
      }
    });
  };
  private cellSave(e: any) {
    this.ValidateForm();
    if (e.requestType === 'save') {
      if (e.data.id !== undefined) {
      } else {
        if (e.data !== undefined) {
          if (e.data.txtCode == undefined || e.data.txtCode == '') {
            // DialogConfirm.showDialog({
            //   content:
            //     '<p class="dialog-contain">Sorry, Code is mandatory information. Please provide code to continue.</p>',
            // });
            const TargetInput =
              document.querySelector<HTMLInputElement>('#txtCode');
            if (TargetInput) {
              TargetInput.style.borderBottom = '2px solid red';
            }
            e.cancel = true;
            return;
          }

          if (e.data.txtName == undefined || e.data.txtName == '') {
            // DialogConfirm.showDialog({
            //   content:
            //     '<p class="dialog-contain">Sorry, Name is mandatory information. Please provide name to continue.</p>',
            // });
            const TargetInput =
              document.querySelector<HTMLInputElement>('#txtName');
            if (TargetInput) {
              TargetInput.style.borderBottom = '2px solid red';
            }
            e.cancel = true;
            return;
          }

          this.AddLeaveType(e.data);
        }
      }
    } else if (e.requestType === 'delete') {
      this.setState({ deldata: e.data[0] });
      e.data = [];
      DialogConfirm.showDialog({
        isConfirm: true,
        content:
          '<div class="row" style="display: flex;flex-direction: column;align-items: center;"><p class="dialog-contain">Are you sure you want to remove selected leave type?</p></div>',
        okCaption: 'Yes',
        closeCaption: 'No',
        OkEvent: this.confirmAction.bind(this),
      });
    } else if (e.requestType === 'beginEdit') {
    }
  }

  confirmAction = () => {
    if (this.state.deldata !== undefined) {
      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_LEAVESERVICE}/leavetype/deleteleavetype`,
        { id: (this.state.deldata as any).id },
        Common.getToken() || '',
        this.user,
        this.token.tenant
      )
        .then((response: any) => {
          return response.data;
        })
        .then((response: any) => {
          this.fetchleavetypes();

          let sidebar: any = JSON.parse(Common.getItem('sidebar')!);
          let s: any = sidebar.dynamicMenu.filter(
            (x: any) => x.id === 'correspondencemenu'
          );
          if (s.length > 0) {
            let sm: any = s[0].subMenuJsonList.filter(
              (x: any) => x.name === 'Leave'
            );
            s = s[0].dynamicNode.dynamicSelector.dynamicContextMenu;
            s = s.filter((x: any) => x.controlType === 2);
            if (s.length > 0) {
              s = s[0].subMenuJsonList.filter((x: any) => x.name === 'Leave');
              s = s[0].subMenuJsonList;

              if (s.length === 2) {
                sm = sm[0].subMenuJsonList;

                let reqidx: any = s[0].subMenuJsonList.findIndex(
                  (x: any) => x.leaveTypesId === (this.state.deldata as any).id
                );
                let authidx: any = s[1].subMenuJsonList.findIndex(
                  (x: any) => x.leaveTypesId === (this.state.deldata as any).id
                );
                if (reqidx >= 0) {
                  s[0].subMenuJsonList.splice(reqidx, 1);
                  sm[0].subMenuJsonList.splice(reqidx, 1);
                }
                if (authidx >= 0) {
                  s[1].subMenuJsonList.splice(authidx, 1);
                  sm[1].subMenuJsonList.splice(authidx, 1);
                }

                let t: any = sidebar.dynamicMenu.filter(
                  (x: any) => x.id === 'templatemenu'
                );
                if (t.length > 0) {
                  t = t[0].dynamicNode.dynamicSelector.dynamicContextMenu;
                  t = t.filter((x: any) => x.controlType === 2);
                  if (t.length > 0) {
                    t = t[0].subMenuJsonList.filter(
                      (x: any) => x.name === 'Leave'
                    );
                    t = t[0].subMenuJsonList;

                    if (t.length === 2) {
                      let reqidx: any = t[0].subMenuJsonList.findIndex(
                        (x: any) =>
                          x.leaveTypesId === (this.state.deldata as any).id
                      );
                      let authidx: any = t[1].subMenuJsonList.findIndex(
                        (x: any) =>
                          x.leaveTypesId === (this.state.deldata as any).id
                      );
                      if (reqidx >= 0) {
                        t[0].subMenuJsonList.splice(reqidx, 1);
                      }
                      if (authidx >= 0) {
                        t[1].subMenuJsonList.splice(authidx, 1);
                      }
                    }
                  }
                }
                Common.setItem('sidebar', sidebar);
                this.props.handleRefreshSidebar(true);
              }
            }
          }
        })
        .catch((error: any) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.messages &&
            error.response.data.messages.length > 0
          ) {
            // DialogConfirm.showDialog({
            //   content: `<p class="dialog-contain">${error.response.data.messages.join(
            //     '<br>'
            //   )}</p>`,
            // });
            this.toastObj = ToastUtility.show({
              cssClass: 'e-toast-error',
              icon: 'far fa-circle-xmark',
              showCloseButton: true,
              content: `${error.response.data.messages.join('<br>')}`,
              target: '#basic_card',
              position: { X: 'Center', Y: 'Top' },
              width: 'auto',
              timeOut: 3000,
            });
          }
        })
        .finally(() => {});
    }
  };

  private actionComplete = (args: DialogEditEventArgs): void => {
    const dialog: Dialog = args.dialog as Dialog;
    if (args.requestType === 'add') {
      dialog.header = 'Add Leave Type';
      this.SetDialogCSS(dialog);
    } else if (args.requestType === 'beginEdit') {
      dialog.header = 'Edit Leave Type';
      this.SetDialogCSS(dialog);
    }
  };

  private SetDialogCSS(dialog: Dialog) {
    for (let i = 0; i < dialog.buttons.length; i++) {
      let _primary = dialog.buttons[i].buttonModel?.isPrimary;

      let _model = {
        content: Common.toTitleCase(
          dialog.buttons[i].buttonModel?.content?.toLowerCase() == 'save'
            ? 'Accept'
            : `${dialog.buttons[i].buttonModel?.content}`
        ),
        cssClass: 'flat-button',
        isPrimary: _primary,
      };
      dialog.buttons[i].buttonModel = _model;
    }
    dialog.buttons.reverse();
  }
  private templateActive = (e: any) => {
    return (
      <>
        {e.length === 0 ? (
          ''
        ) : (
          <SwitchComponent
            id={`${e.id}-${e.column.field}`}
            data-item={e}
            checked={e.isActive}
            onChange={this.handleIsActiveChange.bind(this)}
          ></SwitchComponent>
        )}
      </>
    );
  };

  handleIsActiveChange = (args: any) => {
    let item: any = args.target['data-item'];
    let message: any = '';
    let payload: any = {
      id: item.id,
      code: item.code,
      name: item.name,
      color: item.color,
      isActive: args.target.checked,
      showOnlyActive: false,
    };
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_LEAVESERVICE}/leavetype/update`,
      payload,
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((resopnse: any) => {
        return resopnse.data;
      })
      .catch((error: any) => {
        console.error(error);
        args.target.checked = !args.target.checked;
      })
      .finally(() => {});
    if (args.target.checked) {
      this.toastObj = ToastUtility.show({
        cssClass: 'e-toast-success',
        icon: 'far fa-circle-check',
        showCloseButton: true,
        content: 'Leave activated Successfully..!',
        target: '#basic_card',
        position: { X: 'Center', Y: 'Top' },
        width: 'auto',
        timeOut: 3000,
      });
      // message =
      //   '<p class="dialog-contain">You are about to make this leave Active. Are you sure you want to Activate</p>';
    } else {
      this.toastObj = ToastUtility.show({
        cssClass: 'e-toast-success',
        icon: 'far fa-circle-check',
        showCloseButton: true,
        content: 'Leave deactivated Successfully..!',
        target: '#basic_card',
        position: { X: 'Center', Y: 'Top' },
        width: 'auto',
        timeOut: 3000,
      });
      // message =
      //   '<p class="dialog-contain">You are about to make this leave Inactive. Are you sure you want to Deactivate</p>';
    }

    // DialogConfirm.showDialog({
    // content: message,
    //   isConfirm: true,
    //   OkEvent: () => {
    //     let payload: any = {
    //       id: item.id,
    //       code: item.code,
    //       name: item.name,
    //       color: item.color,
    //       isActive: args.target.checked,
    //       showOnlyActive: false,
    //     };
    //     Common.ApiCallAsync(
    //       'POST',
    //       `${REACT_APP_ENDPOINT_LEAVESERVICE}/leavetype/update`,
    //       payload,
    //       Common.getToken() || '',
    //       null,
    //       this.token.tenant
    //     )
    //       .then((resopnse: any) => {
    //         return resopnse.data;
    //       })
    //       .catch((error: any) => {
    //         console.error(error);
    //         args.target.checked = !args.target.checked;
    //       })
    //       .finally(() => {});
    //   },
    //   CloseEvent: () => {
    //     args.target.checked = !args.target.checked;
    //   },
    // });
  };

  render() {
    const dialogTemplate = (props: any) => {
      return (
        <>
          <DialogFormTemplate {...props} {...this.state} />
        </>
      );
    };

    const editOptions: EditSettingsModel = {
      allowEditing: false,
      allowAdding: true,
      allowDeleting: true,
      mode: 'Dialog',
      allowEditOnDblClick: false,
      template: dialogTemplate,
    };

    const onRowSelected = (e: any) => {
      this.selectedrowindex = e.rowIndex;
      this.setState({ leavetypedetail: e.rowData });
    };

    const refreshLeaveType = (e: any) => {
      if (e !== null) {
        if (this.grid) {
          this.grid.refresh();
          this.setState({ leavetypedetail: undefined });
          this.grid.selectRow(this.selectedrowindex);
        }
      }
    };

    return (
      <>
        <div id='divleavetypes' className='py-2'>
          {/* <label
            className='pb-1'
            style={{ fontSize: '20px', fontWeight: '500' }}>
            Leave Types List
          </label> */}
          <div className='control-pane'>
            <div className='control-section'>
              <div className='control_wrapper' style={{ display: 'flex' }}>
                <div
                  className='col-md-3 divleavetypes-class1'
                  style={{ paddingRight: '20px' }}
                >
                  <div id='leftpane'>
                    <div className='control-pane'>
                      <div className='control-section'>
                        <GridComponent
                          id='grid-lvleavetypes'
                          ref={(g) => (this.grid = g)}
                          allowTextWrap={true}
                          allowGrouping={false}
                          showColumnChooser={false}
                          dataSource={this.state.leavetypes}
                          recordClick={onRowSelected}
                          // groupSettings={this.groupOptions}
                          height='calc(100vh - 485px)'
                          editSettings={editOptions}
                          toolbar={this.toolbarOptions}
                          actionBegin={this.cellSave.bind(this)}
                          actionComplete={this.actionComplete.bind(this)}
                        >
                          <ColumnsDirective>
                            <ColumnDirective
                              template={this.colorTemplate}
                              width='25'
                            />
                            <ColumnDirective
                              field='code'
                              headerText='Code'
                              width='30'
                              visible={false}
                            />
                            <ColumnDirective
                              field='name'
                              headerText='Name'
                              width='120'
                            />
                            <ColumnDirective
                              field='isActive'
                              headerText='Active'
                              width={'55px'}
                              type='boolean'
                              // displayAsCheckBox={true}
                              template={this.templateActive}
                              textAlign='Center'
                            />
                          </ColumnsDirective>
                          <Inject services={[Toolbar, Edit]} />
                        </GridComponent>
                      </div>
                      <SelectorSearch grid_id='grid-lvleavetypes' />
                    </div>
                  </div>
                </div>
                <div className='col-8 divleavetypes-class2'>
                  <div id='rightpane'>
                    <div className='control-pane'>
                      <div className='control-section'>
                        {this.state.leavetypedetail !== undefined ? (
                          <>
                            <LeaveTypeDetail
                              key={Math.random()}
                              leavedata={this.state.leavetypedetail}
                              leavetypes={this.state.leavetypes}
                              refreshLeavedata={refreshLeaveType}
                              handleRefreshSidebar={(e: any) =>
                                this.props.handleRefreshSidebar!(e)
                              }
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export class DialogFormTemplate extends React.Component<{}, {}> {
  private user: any;
  private _structure: any;
  private token: any = null;
  private child: LeaveType | any;

  constructor(props: any) {
    super(props);
    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');

    this.child = React.createRef();
  }

  componentDidMount(): void {}

  render() {
    let data: leavetypemodel = this.props;
    return (
      <>
        <LeaveType {...data} ref={this.child} />
      </>
    );
  }
}

export interface leavetypemodel {
  isAdd?: boolean;
  code?: string;
  name?: string;
  color?: string;
  deductFromLeaveId?: string;
  genderId?: string;
  isAccrued?: boolean;
  isEndDateOptional?: boolean;
  isExcuseLeave?: boolean;
  isPaid?: boolean;
  isShortLeave?: boolean;
  issueOnHoliday?: boolean;
  leavetypes?: any;
  minDays?: number;
  maxDays?: number;
  unitId?: number;
}
