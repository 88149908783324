import { fontColor } from '@syncfusion/ej2-react-spreadsheet';
import React, { useState, ChangeEvent, FormEvent } from 'react';
import Common from '../Util/Common';
import axios from 'axios';
import {
  ItemModel,
  ProgressButtonComponent,
  SplitButtonComponent,
} from '@syncfusion/ej2-react-splitbuttons';
import {
  Column,
  ColumnModel,
  DetailRow,
  ExcelExport,
  ExcelExportProperties,
  Filter,
  GridComponent,
  Inject,
  PdfExport,
  PdfExportProperties,
  Sort,
  Toolbar,
  Resize,
} from '@syncfusion/ej2-react-grids';
import {
  ButtonPropsModel,
  Dialog,
  DialogComponent,
} from '@syncfusion/ej2-react-popups';
import { log } from 'console';
import DialogConfirm from './Dialogbox/DialogConfirm';
import {
  ProgressAnnotation,
  ProgressBarAnnotationDirective,
  ProgressBarAnnotationsDirective,
  ProgressBarComponent,
} from '@syncfusion/ej2-react-progressbar';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
const {
  REACT_APP_ENDPOINT_CORESERVICE,
  REACT_APP_ENDPOINT_PAYROLLSERVICE,
  REACT_APP_ENDPOINT_API,
  REACT_APP_ENDPOINT_LEAVESERVICE,
  REACT_APP_ENDPOINT_ATTENDANCESERVICE,
} = process.env;

interface UploadType {
  uploadtypeid: number;
}

export default class FileUpload extends React.Component<UploadType> {
  private user: any;
  private _structure: any;
  private token: any = null;
  private file: any;
  private message: any;
  private colsImportResult: ColumnModel[];
  private grdImportResult: GridComponent | any = null;
  private fileInput: any;
  private tooltip: TooltipComponent | null = null;
  private apiurl: any;
  private templateapiurl: any;
  private apipayload: any;
  private filename: string = '';
  public toolbarOptions: any = undefined;
  private grid: GridComponent | null;
  toastObj: any;

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
    this.colsImportResult = [];
    this.grid = null;

    if (this.props.uploadtypeid == this._structure.UploadType.PayHeads) {
      this.apiurl = `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payrolldata/uploadpayhead`;
      this.templateapiurl = `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payrolldata/downloadtemplate`;
      this.apipayload = { TemplateId: 1 };
      this.filename = 'Payhead_Sample.csv';

      this.colsImportResult = [
        {
          field: 'employeecode',
          headerText: 'Employee Code',
        },
        {
          field: 'headcode',
          headerText: 'Head Code',
        },
        {
          field: 'amount',
          headerText: 'amount',
        },
        {
          field: 'status',
          headerText: 'Status',
        },
        {
          field: 'message',
          headerText: 'Message',
        },
      ];
    } else if (
      this.props.uploadtypeid == this._structure.UploadType.LeaveBalance
    ) {
      this.apiurl = `${REACT_APP_ENDPOINT_LEAVESERVICE}/leaveapplication/uploadbalances`;
      this.templateapiurl = `${REACT_APP_ENDPOINT_LEAVESERVICE}/leaveapplication/downloadtemplate`;
      this.apipayload = { TemplateId: 1 };
      this.filename = 'LeaveBalance_Sample.csv';

      this.colsImportResult = [
        {
          field: 'employeecode',
          headerText: 'Employee Code',
        },
        {
          field: 'leavename',
          headerText: 'Leave Name',
        },
        {
          field: 'entitlement',
          headerText: 'Entitlement',
        },
        {
          field: 'bfamount',
          headerText: 'BF Amount',
        },
        {
          field: 'status',
          headerText: 'Status',
        },
        {
          field: 'message',
          headerText: 'Message',
        },
      ];
    } else if (this.props.uploadtypeid == this._structure.UploadType.Employee) {
      this.apiurl = `${REACT_APP_ENDPOINT_CORESERVICE}/employee/uploademployee`;
      this.templateapiurl = `${REACT_APP_ENDPOINT_CORESERVICE}/employee/downloadtemplate`;
      this.apipayload = { TemplateId: 1 };
      this.filename = 'Employee_Sample.csv';

      this.colsImportResult = [
        {
          field: 'employeecode',
          headerText: 'Employee Code',
        },
        {
          field: 'firstname',
          headerText: 'FirstName',
        },
        {
          field: 'lastname',
          headerText: 'LastName',
        },
        {
          field: 'middlename',
          headerText: 'MiddleName',
        },
        {
          field: 'gender',
          headerText: 'Gender',
        },
        {
          field: 'birthdate',
          headerText: 'Birthdate',
        },
        {
          field: 'contracttype',
          headerText: 'Contracttype',
        },
        {
          field: 'appointmentdate',
          headerText: 'AppointmentDate',
        },
        {
          field: 'job',
          headerText: 'Job',
        },
        {
          field: 'branch',
          headerText: 'Branch',
        },
        {
          field: 'department',
          headerText: 'Department',
        },
        {
          field: 'salary',
          headerText: 'Salary',
        },
        {
          field: 'email',
          headerText: 'Email',
        },
        {
          field: 'status',
          headerText: 'Status',
        },
        {
          field: 'message',
          headerText: 'Message',
        },
      ];
    } else if (this.props.uploadtypeid == this._structure.UploadType.Identity) {
      this.apiurl = `${REACT_APP_ENDPOINT_CORESERVICE}/persons/uploadpersonidentity`;
      this.templateapiurl = `${REACT_APP_ENDPOINT_CORESERVICE}/persons/downloadtemplate_document`;
      this.apipayload = { TemplateId: 1 };
      this.filename = 'Document_Sample.csv';

      this.colsImportResult = [
        {
          field: 'employeecode',
          headerText: 'EmployeeCode',
        },
        {
          field: 'idname',
          headerText: 'IdName',
        },
        {
          field: 'idnumber',
          headerText: 'IdNumber',
        },
        {
          field: 'status',
          headerText: 'Status',
        },
        {
          field: 'message',
          headerText: 'Message',
        },
      ];
    } else if (this.props.uploadtypeid == this._structure.UploadType.Bank) {
      this.apiurl = `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/bank/employeebank/upload`;
      this.templateapiurl = `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/bank/employeebank/downloadtemplate`;
      this.apipayload = { TemplateId: 1 };
      this.filename = 'Bank_Sample.csv';

      this.colsImportResult = [
        {
          field: 'employeecode',
          headerText: 'Employee Code',
        },
        {
          field: 'bankgroupcode',
          headerText: 'Bankgroup Code',
        },
        {
          field: 'mode',
          headerText: 'Mode',
        },
        {
          field: 'paymenttype',
          headerText: 'Payment Type',
        },
        {
          field: 'bankbranchcode',
          headerText: 'BankBranch Code',
        },
        {
          field: 'accounttype',
          headerText: 'Account Type',
        },
        {
          field: 'startdate',
          headerText: 'Start Date',
        },
        {
          field: 'accountno',
          headerText: 'Account No',
        },
        {
          field: 'percentage',
          headerText: 'Percentage',
        },
        {
          field: 'amount',
          headerText: 'Amount',
        },
        {
          field: 'status',
          headerText: 'Status',
        },
        {
          field: 'message',
          headerText: 'Message',
        },
      ];
    } else if (
      this.props.uploadtypeid == this._structure.UploadType.Attendance
    ) {
      this.apiurl = `${REACT_APP_ENDPOINT_ATTENDANCESERVICE}/attendance/uploadattendance`;
      this.templateapiurl = `${REACT_APP_ENDPOINT_ATTENDANCESERVICE}/attendance/downloadtemplate`;
      this.apipayload = {
        TemplateId: 1,
        isManager: this.user.isManager,
        EmployeeCode: this.user.employee.employeecode,
      };
      this.filename = 'Attendance_Sample.csv';
      this.colsImportResult = [
        { field: 'date', headerText: 'Date' },
        { field: 'in', headerText: 'In' },
        { field: 'out', headerText: 'Out' },
        { field: 'status', headerText: 'Status' },
        { field: 'message', headerText: 'Message' },
      ];
      if (this.apipayload.isManager) {
        this.colsImportResult.unshift({
          field: 'employeecode',
          headerText: 'Employee Code',
        });
      }
    }
  }

  ExportsItems: ItemModel[] = [
    {
      text: 'Failed',
    },
    {
      text: 'Succeessful',
    },
    {
      text: 'All Warning',
    },
    {
      text: 'All',
    },
  ];
  state = {
    jsonEmployee: undefined,
    isLoading: false,
    ProgressHandle: false,
  };

  public handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    let csvText: any;
    if (e.target.files && e.target.files.length > 0) {
      this.file = e.target.files[0];
      if (this.file && this.file.name.split('.').pop() === 'csv') {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          csvText = e.target.result;
          const json = this.parseCSVtoJson(csvText);
          this.setState({
            jsonEmployee: json,
          });
          const data = btoa(csvText);
        };
        reader.readAsText(this.file);
      } else {
        this.toastObj = ToastUtility.show({
          cssClass: 'e-toast-warning',
          icon: 'e-warning toast-icons',
          showCloseButton: true,
          content: 'Please Upload CSV files only.',
          target: '#basic_card',
          position: { X: 'Center', Y: 'Top' },
          width: 'auto',
          timeOut: 3000,
        });
      }
    }
  };

  private parseCSVtoJson(csvText: any) {
    const lines = csvText.split('\n');
    const headers = lines[0].split(',');
    const parsedData = [];
    for (let i = 1; i < lines.length; i++) {
      const currentLine = lines[i].split(',');
      if (currentLine.length === headers.length) {
        const row: any = {};
        for (let j = 0; j < headers.length; j++) {
          row[headers[j].trim().toLowerCase()] = currentLine[j].trim();
        }
        parsedData.push(row);
      }
    }
    return parsedData;
  }
  public handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (this.file === undefined) {
      this.setState({ isLoading: false, ProgressHandle: false });

      // DialogConfirm.showDialog({
      //   content:
      //     '<p class="dialog-contain">No file selected: Please choose a file to upload.</p>',
      // });
      this.toastObj = ToastUtility.show({
        cssClass: 'e-toast-warning',
        icon: 'e-warning toast-icons',
        showCloseButton: true,
        content: 'No file selected: Please choose a file to upload.',
        target: '#basic_card',
        position: { X: 'Center', Y: 'Top' },
        timeOut: 3000,
        width: 'auto',
      });
      return;
    }
    this.setState({ isLoading: true, ProgressHandle: true });

    if (this.file.name.split('.').pop() !== 'csv') {
      // DialogConfirm.showDialog({
      //   content: '<p class="dialog-contain">Please Upload CSV files only.</p>',
      // });
      this.setState({ isLoading: false, ProgressHandle: false });

       this.toastObj = ToastUtility.show({
         cssClass: 'e-toast-warning',
         icon: 'e-warning toast-icons',
         showCloseButton: true,
         content: 'Please Upload CSV files only.',
         target: '#basic_card',
        position: { X: 'Center', Y: 'Top' },
        width: 'auto',
        timeOut: 3000,
      });
      return;
    }
    if (this.file) {
      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('ecode', this.user.employee.employeecode);
      formData.append('ismanager', this.user.isManager);
      this.setState({ isLoading: true, ProgressHandle: true });
      try {
        Common.ApiCallAsync(
          'POST',
          this.apiurl,
          formData,
          Common.getToken() || '',
          null,
          this.token.tenant
        )
          .then((response: any) => {
            let data = response.data.map(function (item: any) {
              for (var key in item) {
                item[key.toLowerCase()] = item[key];
                if (item.date) {
                  item.date = Common.formatDate(
                    new Date(item.date),
                    'dd-MMM-yyyy'
                  );
                }
                // delete item[key];
              }
              return item;
            });

            if (
              response.data[0].globalMassage.includes(
                'successfully uploaded'
              ) === false
            ) {
            this.toolbarOptions = ['ExcelExport'];
            }

            this.setState({
              jsonEmployee: data,
              isLoading: false,
              ProgressHandle: false,
            });
            this.fileInput.value = '';

            // DialogConfirm.showDialog({
            //   content: `<p class="dialog-contain">${response.data[0].globalMassage}</p>`,
            // });
             this.toastObj = ToastUtility.show({
               cssClass: 'e-toast-warning',
               icon: 'e-warning toast-icons',
               showCloseButton: true,
               content: `${response.data[0].globalMassage}`,
               target: '#basic_card',
              position: { X: 'Center', Y: 'Top' },
              width: 'auto',
              timeOut: 3000,
            });
          })
          .catch((error: any) => {
            console.error(error);
            this.setState({ isLoading: false, ProgressHandle: false });
          })
          .finally(() => {});
      } catch (error) {
        this.message = 'An error occurred while uploading the file.';
      }
    }
  };

  handleDownloadTemplate = () => {
    try {
      Common.ApiCallAsync(
        'POST',
        this.templateapiurl,
        this.apipayload,
        Common.getToken() || '',
        null,
        this.token.tenant
      ).then((response: any) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', this.filename);
        document.body.appendChild(link);
        link.click();
        link.remove();

        return response.data;
      });
    } catch (error) {}
  };

  public Filter: any = {
    type: 'CheckBox',
  };
  toolbarClick = (args: any) => {
    if (args.item.id === 'payrollemployeelist-grid_excelexport') {
      this.grid?.excelExport();
    }
  };

  beforeRender = (args: any) => {
    // let ictl: any;
    // ictl = document.getElementById('Tooltip');
    // const tooltip = ictl.ej2_instances[0];
    // tooltip.content = args.target.closest('td').innerText;

    if (this.tooltip) {
      if (args && args.target) {
        if (args.target.closest('td').innerText) {
          (this.tooltip as TooltipComponent).content =
            args.target.closest('td').innerText;
        } else {
          args.cancel = true;
        }
      } else {
        args.cancel = true;
      }
    } else {
      args.cancel = true;
    }
  };

  render() {
    const { ProgressHandle, isLoading } = this.state;

    return (
      <>
        {ProgressHandle ? (
          <div className='dialog'>
            <DialogComponent
              width='250px'
              isModal={true}
              header='<p align = "center">Processing...</p>'
            >
              <ProgressBarComponent
                id='circular'
                type='Circular'
                height='120'
                width='200'
                trackThickness={15}
                progressThickness={15}
                value={20}
                isIndeterminate={true}
                animation={{
                  enable: true,
                  duration: 2000,
                  delay: 0,
                }}
                cornerRadius={'Round'}
              ></ProgressBarComponent>
            </DialogComponent>
          </div>
        ) : (
          ''
        )}
        <div className='col-6'>
          <b>
            <label style={{ fontSize: '15px' }}>Choose a file</label>
          </b>
        </div>
        <div className='col-6' style={{ textAlign: 'right' }}>
          <button
            style={{
              color: '#FC4C02',
              border: 1,
              borderColor: 'black',
              backgroundColor: 'transparent',
            }}
            disabled={ProgressHandle}
            onClick={this.handleDownloadTemplate.bind(this)}
          >
            Download CSV Template
          </button>
        </div>
        <label
          style={{
            fontSize: '15px',
            paddingBottom: '7px',
            backgroundColor: 'transparent',
          }}
        >
          CSV files only. Please use Aruti template.
        </label>
        <div className='col-8'>
          <input
            type='file'
            onChange={this.handleFileChange.bind(this)}
            accept='.csv'
            style={{ fontSize: '15px' }}
            ref={(ref) => (this.fileInput = ref)}
            className='required'
          />
        </div>
        <div className='col-2'>
          <ProgressButtonComponent
            content='Upload'
            spinSettings={{ position: 'Right' }}
            onClick={this.handleSubmit.bind(this)}
            className='e-btn submitprogress'
            disabled={isLoading}
          ></ProgressButtonComponent>
        </div>

        <div className='col-12 py-2'>
          <TooltipComponent
            id='Tooltip'
            target='.e-rowcell'
            beforeRender={this.beforeRender}
            ref={(sc) => (this.tooltip = sc)}
          >
            <GridComponent
              id='payrollemployeelist-grid'
              height={'calc(100vh - 500px)'}
              width={'100%'}
              className='payrollemployeelist-grid-class'
              dataSource={this.state.jsonEmployee}
              columns={this.colsImportResult}
              ref={(obj: GridComponent | null) => (this.grid = obj)}
              allowExcelExport={true}
              allowPdfExport={true}
              allowFiltering={true}
              allowSorting={true}
              allowResizing={true}
              filterSettings={this.Filter}
              toolbar={this.toolbarOptions}
              toolbarClick={this.toolbarClick}
            >
              <Inject
                services={[
                  Toolbar,
                  PdfExport,
                  ExcelExport,
                  Filter,
                  Sort,
                  DetailRow,
                  Resize,
                ]}
              />
            </GridComponent>
          </TooltipComponent>
        </div>
      </>
    );
  }
}
