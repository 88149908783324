import * as React from 'react';
import './CustomReport.css';
import Common from '../../Util/Common';
import { InPlaceEditorComponent } from '@syncfusion/ej2-react-inplace-editor';
import { TextBoxModel } from '@syncfusion/ej2-react-inputs';
import {
  CheckBoxComponent,
  RadioButtonComponent,
} from '@syncfusion/ej2-react-buttons';
import { AccordionComponent } from '@syncfusion/ej2-react-navigations';
import { select } from '@syncfusion/ej2-react-schedule';
import {
  ListBoxComponent,
  FieldSettingsModel,
  DragEventArgs,
  DropDownListComponent,
} from '@syncfusion/ej2-react-dropdowns';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { showDialog } from '@syncfusion/ej2-react-spreadsheet';
import CustomReportPreview from './CustomReportPreview';
import DialogConfirm from '../Dialogbox/DialogConfirm';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';

const { REACT_APP_ENDPOINT_CORESERVICE, REACT_APP_ENDPOINT_PAYROLLSERVICE } =
  process.env;

interface Props {
  jsonDataViewer: any;
  reportId?: string;
  report?: any;
  showAddEditReport?: any;
}

export default class CustomReport extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;
  private textModel: TextBoxModel = {
    placeholder: 'Enter custom report name',
    autocomplete: 'off',
    cssClass: 'textfocus',
    floatLabelType: 'Auto',
  };
  private accrRepoCate: AccordionComponent | any = undefined;
  private repocategory: any = null;
  private allColumns: any = [];
  private lstColumn: ListBoxComponent | any = null;
  private lstFilterColumn: ListBoxComponent | any = null;
  private lstPivotRow: ListBoxComponent | any = null;
  private lstPivotVal: ListBoxComponent | any = null;
  private txtName: InPlaceEditorComponent | any = undefined;
  private ddlRepoGroup: DropDownListComponent | any = undefined;
  private jsonRepoGroup: [] = [];

  private mandatEmpFields: any = [
    'employee_info|Employeecode',
    'employee_info|PersonName',
    'employee_info|PersonCodeName',
    'employee_info|PersonNameCode',
  ];
  toastObj: any;

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
    this.jsonRepoGroup = this._structure.ReportsGroupList;
  }

  state = {
    columns: [],
    filtercolumns: [],
    pivotrows: [],
    pivotvals: [],
    showDialog: false,
    periods: undefined,
    reportType: this.props.report ? this.props.report.reportType : 'list',
  };

  componentDidMount(): void {
    this.GetPeriods();
    if (!this.props.reportId) {
      this.RefreshRepoCateGoryAccordion(null);
    } else {
      this.txtName.value = this.props.report.reportName;

      this.ddlRepoGroup.value = this.props.report.reportGroupId;
      this.props.report.reportCategory.split(',').forEach((x: any) => {
        let chk: any = document.querySelector(
          `input[type="checkbox"][id="${x}"]`
        );
        chk.checked = true;
        chk.ej2_instances[0].checked = true;

        this.RefreshRepoCateGoryAccordion(chk);
      });
    }
  }

  private GetPeriods() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/period/listperiods`,
      { addNewOption: false },
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        let date = new Date();
        let currPeriod: any = null;
        let empPeriod: any = response;
        empPeriod = empPeriod.filter(
          (x: any) =>
            Common.formatDate(new Date(x.startDate), 'dd-MM-yyyy') <=
              Common.formatDate(new Date(), 'dd-MM-yyyy') ||
            x.id == Common.blankguid
        );

        empPeriod.filter((x: any) => {
          if (date >= new Date(x.startDate) && date <= new Date(x.endDate)) {
            currPeriod = x;
          }
        });

        if (currPeriod === null) {
          currPeriod = empPeriod.slice(-1)[0];
        }

        let lastSelectedPeriod: any =
          localStorage.getItem('lastSelectedPeriod');

        if (
          lastSelectedPeriod &&
          lastSelectedPeriod !== 'undefined' &&
          lastSelectedPeriod !== 'null'
        ) {
          lastSelectedPeriod = JSON.parse(lastSelectedPeriod);

          if (
            empPeriod.length > 0 &&
            new Date(lastSelectedPeriod.startDate).getTime() <
              new Date(currPeriod.startDate).getTime() &&
            new Date(lastSelectedPeriod.startDate).getTime() >=
              new Date(empPeriod[0].startDate).getTime()
          ) {
            currPeriod = lastSelectedPeriod;
          }
        }

        this.setState({
          periods: currPeriod ?? undefined,
        });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  private RefreshRepoCateGoryAccordion(chkbox: any) {
    if (this.accrRepoCate) {
      if (!chkbox) {
        let chklist: any = document.querySelectorAll(
          '.divrepocategory input[type="checkbox"]'
        );

        chklist.forEach((chk: any) => {
          if (chk.checked) {
            switch (chk.id) {
              case this._structure.CustomReportCategory.EmployeeInfo.Id:
                this.accrRepoCate.addItem({
                  id: chk.id,
                  header: chk.ej2_instances[0].label,
                  content: this.contentEmployeeInfo.bind(this),
                });

                this.accrRepoCate.expandItem(
                  true,
                  this.accrRepoCate.items.length - 1
                );
                break;

              default:
                break;
            }
          }
        });
      } else {
        let chk: any;
        if (chkbox.type === 'checkbox') {
          chk = chkbox;
        } else {
          chk = chkbox.event.target.parentElement.querySelector(
            'input[type="checkbox"]'
          );
        }

        if (chk.checked) {
          switch (chk.id) {
            case this._structure.CustomReportCategory.EmployeeInfo.Id:
              this.accrRepoCate.addItem({
                id: chk.id,
                header: chk.ej2_instances[0].label,
                content: this.contentEmployeeInfo.bind(this),
              });
              this.accrRepoCate.expandItem(
                true,
                this.accrRepoCate.items.length - 1
              );
              break;

            case this._structure.CustomReportCategory.PayrollInfo.Id:
              this.accrRepoCate.addItem({
                id: chk.id,
                header: chk.ej2_instances[0].label,
                content: this.contentPayrollInfo.bind(this),
              });
              this.accrRepoCate.expandItem(
                true,
                this.accrRepoCate.items.length - 1
              );
              break;

            case this._structure.CustomReportCategory.LeaveInfo.Id:
              this.accrRepoCate.addItem({
                id: chk.id,
                header: chk.ej2_instances[0].label,
                content: this.contentLeaveInfo.bind(this),
              });
              this.accrRepoCate.expandItem(
                true,
                this.accrRepoCate.items.length - 1
              );
              break;

            default:
              break;
          }
        } else {
          this.accrRepoCate.removeItem(
            this.accrRepoCate.items.findIndex((x: any) => x.id === chk.id)
          );

          let sel: any = this.allColumns.filter(
            (x: any) => x.isSelected === true && x.category === chk.id
          );
          sel.forEach((x: any) => (x.isSelected = false));

          let pvt: any = this.allColumns.filter(
            (x: any) => x.isPivoted === true && x.category === chk.id
          );
          pvt.forEach((x: any) => (x.isPivoted = false));

          let pvtval: any = this.allColumns.filter(
            (x: any) => x.isPivotedVal === true && x.category === chk.id
          );
          pvtval.forEach((x: any) => (x.isPivotedVal = false));

          let data: any = this.state.columns;
          data = data.filter((x: any) => x.category !== chk.id);

          if (
            chk.id === this._structure.CustomReportCategory.PayrollInfo.Id ||
            chk.id === this._structure.CustomReportCategory.LeaveInfo.Id
          ) {
            pvt = this.allColumns.filter((x: any) => x.isPivoted === true);

            pvtval = this.allColumns.filter(
              (x: any) => x.isPivotedVal === true
            );
            this.setState({ columns: data, pivotrows: pvt, pivotvals: pvtval });
          } else {
            this.setState({ columns: data });
          }

          this.lstColumn.refresh();

          // switch (chk.id) {
          //   case this._structure.CustomReportCategory.EmployeeInfo.Id:

          //     break;

          //   case this._structure.CustomReportCategory.PayrollInfo.Id:
          //     this.accrRepoCate.items.splice(
          //       this.accrRepoCate.items.findIndex((x: any) => x.id === chk.id),
          //       1
          //     );
          //     //this.accrRepoCate.refresh();
          //     break;

          //   default:
          //     break;
          // }
        }
      }
    }
  }

  private contentEmployeeInfo() {
    let data: { [key: string]: Object }[];

    data = this._structure.CustomReportCategory.EmployeeInfo.Columns.sort(
      (a: any, b: any) => {
        return a.Name > b.Name ? 1 : -1;
      }
    ).map((x: any) => {
      let matched: any =
        this.props.report &&
        this.props.report.columns.filter(
          (c: any) =>
            c.columnId ===
            this._structure.CustomReportCategory.EmployeeInfo.Id + '|' + x.Id
        );
      if (matched && matched.length > 0) {
        matched = matched[0];
      } else {
        matched = null;
      }

      return {
        id: matched ? matched.id : null,
        customReportId: matched ? matched.customReportId : null,
        columnId: `${this._structure.CustomReportCategory.EmployeeInfo.Id}|${x.Id}`,
        text: x.Name,
        category: this._structure.CustomReportCategory.EmployeeInfo.Id,
        isNumber: x.IsNumber,
        isDate: x.IsDate,
        isForFilter: x.IsForFilter,
        isForGroup: x.IsForGroup,
        isSelected: matched ? matched.isSelected : false,
        isFiltered: false,
        isSorted: matched ? matched.isSorted : '',
        isSummed: matched ? matched.isSummed : false,
        isGrouped: matched ? matched.isGrouped : false,
        isPivoted: matched ? matched.isPivoted : false,
        isPivotedVal: matched ? matched.isPivotedVal : false,
        selectionOrder: matched ? matched.selectionOrder : 999,
        pivotRowOrder: matched ? matched.pivotRowOrder : 999,
        pivotValOrder: matched ? matched.pivotValOrder : 999,
      };
    });

    this.allColumns.push(...data);
    let columns: any = [];
    let pivotrows: any = [];
    let pivotvals: any = [];

    let cols: any = this.allColumns.filter((x: any) => x.isSelected === true);
    if (cols.length > 0) {
      columns = cols.sort((a: any, b: any) => {
        return a.selectionOrder > b.selectionOrder ? 1 : -1;
      });
      // this.setState({
      //   columns: cols.sort((a: any, b: any) => {
      //     return a.selectionOrder > b.selectionOrder ? 1 : -1;
      //   }),
      // });
      // this.lstColumn.refresh();
    }

    cols = this.allColumns.filter((x: any) => x.isPivoted === true);
    if (cols.length > 0) {
      pivotrows = cols.sort((a: any, b: any) => {
        return a.pivotRowOrder > b.pivotRowOrder ? 1 : -1;
      });
      //  this.setState({
      //    pivotrows: cols.sort((a: any, b: any) => {
      //      return a.pivotRowOrder > b.pivotRowOrder ? 1 : -1;
      //    }),
      //  });
    }

    cols = this.allColumns.filter((x: any) => x.isPivotedVal === true);
    if (cols.length > 0) {
      pivotvals = cols.sort((a: any, b: any) => {
        return a.pivotValOrder > b.pivotValOrder ? 1 : -1;
      });
      // this.setState({
      //   pivotvals: cols.sort((a: any, b: any) => {
      //     return a.pivotValOrder > b.pivotValOrder ? 1 : -1;
      //   }),
      // });
    }

    this.setState({
      columns: columns,
      pivotrows: pivotrows,
      pivotvals: pivotvals,
    });

    const listTemplate = (e: any) => {
      const onCheckedChange = (e: any) => {
        let data: any = [...this.state.columns];
        let itm: any = JSON.parse(
          e.target.element.closest('.customreportaccrlist').dataset.items
        );

        if (e.target.checked) {
          // if (data.length >= 10) {
          //   e.target.checked = false;
          // DialogConfirm.showDialog({
          //   content: '<p class="dialog-contain">Sorry, You can select maximum 10 columns.</p>',
          // });
          //   return;
          // }
          if (
            this.state.pivotrows.filter(
              (x: any) => x && x.columnId === itm.columnId
            ).length > 0 ||
            this.state.pivotvals.filter(
              (x: any) => x && x.columnId === itm.columnId
            ).length > 0
          ) {
            e.target.checked = false;

            // DialogConfirm.showDialog({
            //   content:
            //     '<p class="dialog-contain">Sorry, You cannot tick same column for both pivot columns, pivot rows or pivot value.</p>',
            // });
            this.toastObj = ToastUtility.show({
              cssClass: 'e-toast-warning',
              icon: 'e-warning toast-icons',
              showCloseButton: true,
              content:
                'Sorry, You cannot tick same column for both pivot columns, pivot rows or pivot value.',
              target: '#basic_card',
              position: { X: 'Center', Y: 'Top' },
              width: 'auto',
              timeOut: 3000,
            });
            return;
          }
          //data.push(itm);
          this.allColumns[
            this.allColumns.findIndex((x: any) => x.columnId === itm.columnId)
          ].isSelected = e.target.checked;
          let jsn: any = this.allColumns.filter(
            (x: any) => x.columnId === itm.columnId
          )[0];
          jsn['customReportId'] = this.props.reportId;
          data.push(jsn);
        } else {
          data.splice(
            data.findIndex((x: any) => x.columnId === itm.columnId),
            1
          );
          this.allColumns[
            this.allColumns.findIndex((x: any) => x.columnId === itm.columnId)
          ].isSelected = e.target.checked;
        }

        // this.allColumns[
        //   this.allColumns.findIndex((x: any) => x.columnId === itm.columnId)
        // ].isSelected = e.target.checked;

        this.setState({ columns: data });
        //this.lstColumn.refresh();
      };

      const onFilterChange = (e: any) => {
        let data: any = this.state.filtercolumns;
        let itm: any = JSON.parse(
          e.target.parentElement.closest('.customreportaccrlist').dataset.items
        );

        let isfltrd: boolean =
          !this.allColumns[
            this.allColumns.findIndex((x: any) => x.columnId === itm.columnId)
          ].isFiltered;

        itm.isFiltered = isfltrd;
        this.allColumns[
          this.allColumns.findIndex((x: any) => x.columnId === itm.columnId)
        ].isFiltered = isfltrd;

        if (isfltrd) {
          itm['customReportId'] = this.props.reportId;
          data.push(itm);
          e.target.classList.remove('lightgray');
          e.target.classList.add('black');
        } else {
          data.splice(
            data.findIndex((x: any) => x.columnId === itm.columnId),
            1
          );
          e.target.classList.remove('black');
          e.target.classList.add('lightgray');
        }

        this.setState({ filtercolumns: data });
        this.lstFilterColumn.refresh();
      };

      return (
        <>
          <div
            className=' customreportaccrlist position-relative'
            data-items={JSON.stringify(e)}
            data-color={'lightgray'}
          >
            <div className='position-absolute ps-1'>
              <CheckBoxComponent
                id={e.columnId}
                value={e.text}
                checked={e.isSelected}
                onChange={onCheckedChange}
              ></CheckBoxComponent>
            </div>
            <div className='etext'>{e.text}</div>

            {/* {e.isForFilter && (
              <div
                className='position-absolute accrfilter'
                title='Filter'
                onClick={onFilterChange}
              >
                <div className='fa-solid fa-filter lightgray'></div>
              </div>
            )} */}
            {this.state.reportType === 'pivottable' && (
              <div
                className='position-absolute accrpivot'
                title='Pivot Table Row'
                onClick={this.onPivotChange}
              >
                <div
                  className={`font-20 e-icons e-chart-switch-row-column ${
                    e.isPivoted ? 'black' : 'lightgray'
                  }`}
                ></div>
              </div>
            )}

            {this.state.reportType === 'pivottable' && (
              <div
                className='position-absolute accrpivotval'
                title='Pivot Table Value'
                onClick={this.onPivotValChange}
              >
                <div
                  className={`fa-solid fa-diagram-predecessor ${
                    e.isPivotedVal ? 'black' : 'lightgray'
                  }`}
                ></div>
              </div>
            )}
          </div>
        </>
      );
    };

    return (
      <>
        <ListBoxComponent
          id='accremployee'
          dataSource={data}
          itemTemplate={listTemplate}
          allowDragAndDrop={true}
          //scope='combined-list'
          fields={{ text: 'text' }}
        />
      </>
    );
  }

  private contentPayrollInfo() {
    let data: { [key: string]: Object }[];

    data = this._structure.CustomReportCategory.PayrollInfo.Columns.sort(
      (a: any, b: any) => {
        return a.Name > b.Name ? 1 : -1;
      }
    ).map((x: any) => {
      let matched: any =
        this.props.report &&
        this.props.report.columns.filter(
          (c: any) =>
            c.columnId ===
            this._structure.CustomReportCategory.PayrollInfo.Id + '|' + x.Id
        );
      if (matched && matched.length > 0) {
        matched = matched[0];
      } else {
        matched = null;
      }

      return {
        id: matched ? matched.id : null,
        customReportId: matched ? matched.customReportId : null,
        columnId: `${this._structure.CustomReportCategory.PayrollInfo.Id}|${x.Id}`,
        text: x.Name,
        category: this._structure.CustomReportCategory.PayrollInfo.Id,
        isNumber: x.IsNumber,
        isDate: x.IsDate,
        isForFilter: x.IsForFilter,
        isForGroup: x.IsForGroup,
        isSelected: matched ? matched.isSelected : false,
        isFiltered: false,
        isSorted: matched ? matched.isSorted : '',
        isSummed: matched ? matched.isSummed : false,
        isGrouped: matched ? matched.isGrouped : false,
        isPivoted: matched ? matched.isPivoted : false,
        isPivotedVal: matched ? matched.isPivotedVal : false,
        selectionOrder: matched ? matched.selectionOrder : 999,
        pivotRowOrder: matched ? matched.pivotRowOrder : 999,
        pivotValOrder: matched ? matched.pivotValOrder : 999,
      };
    });

    this.allColumns.push(...data);
    let columns: any = [];
    let pivotrows: any = [];
    let pivotvals: any = [];

    let cols: any = this.allColumns.filter((x: any) => x.isSelected === true);
    if (cols.length > 0) {
      columns = cols.sort((a: any, b: any) => {
        return a.selectionOrder > b.selectionOrder ? 1 : -1;
      });
      // this.setState({
      //   columns: columns,
      // });
      // this.lstColumn.refresh();
    }

    cols = this.allColumns.filter((x: any) => x.isPivoted === true);
    if (cols.length > 0) {
      pivotrows = cols.sort((a: any, b: any) => {
        return a.pivotRowOrder > b.pivotRowOrder ? 1 : -1;
      });
      // this.setState({
      //   pivotrows: cols.sort((a: any, b: any) => {
      //     return a.pivotRowOrder > b.pivotRowOrder ? 1 : -1;
      //   }),
      // });
    }

    cols = this.allColumns.filter((x: any) => x.isPivotedVal === true);
    if (cols.length > 0) {
      pivotvals = cols.sort((a: any, b: any) => {
        return a.pivotValOrder > b.pivotValOrder ? 1 : -1;
      });
      // this.setState({
      //   pivotvals: cols.sort((a: any, b: any) => {
      //     return a.pivotValOrder > b.pivotValOrder ? 1 : -1;
      //   }),
      // });
    }
    this.setState({
      columns: columns,
      pivotrows: pivotrows,
      pivotvals: pivotvals,
    });

    const listTemplate = (e: any) => {
      const onCheckedChange = (e: any) => {
        let data: any = [...this.state.columns];
        let itm: any = JSON.parse(
          e.target.element.closest('.customreportaccrlist').dataset.items
        );

        if (e.target.checked) {
          // if (data.length >= 10) {
          //   e.target.checked = false;
          // DialogConfirm.showDialog({
          //   content: '<p class="dialog-contain">Sorry, You can select maximum 10 columns.</p>',
          // });
          //   return;
          // }
          if (
            this.state.pivotrows.filter(
              (x: any) => x && x.columnId === itm.columnId
            ).length > 0 ||
            this.state.pivotvals.filter(
              (x: any) => x && x.columnId === itm.columnId
            ).length > 0
          ) {
            e.target.checked = false;

            // DialogConfirm.showDialog({
            //   content:
            //     '<p class="dialog-contain">Sorry, You cannot tick same column for both pivot columns, pivot rows or pivot value.</p>',
            // });
            this.toastObj = ToastUtility.show({
              cssClass: 'e-toast-warning',
              icon: 'e-warning toast-icons',
              showCloseButton: true,
              content:
                'Sorry, You cannot tick same column for both pivot columns, pivot rows or pivot value.',
              target: '#basic_card',
              position: { X: 'Center', Y: 'Top' },
              width: 'auto',
              timeOut: 3000,
            });
            return;
          }
          // data.push(itm);
          this.allColumns[
            this.allColumns.findIndex((x: any) => x.columnId === itm.columnId)
          ].isSelected = e.target.checked;
          let jsn: any = this.allColumns.filter(
            (x: any) => x.columnId === itm.columnId
          )[0];
          jsn['customReportId'] = this.props.reportId;
          data.push(jsn);
        } else {
          data.splice(
            data.findIndex((x: any) => x.columnId === itm.columnId),
            1
          );
          this.allColumns[
            this.allColumns.findIndex((x: any) => x.columnId === itm.columnId)
          ].isSelected = e.target.checked;
        }

        // this.allColumns[
        //   this.allColumns.findIndex((x: any) => x.columnId === itm.columnId)
        // ].isSelected = e.target.checked;

        this.setState({ columns: data });
        // setTimeout(() => {
        //   this.lstColumn.refresh();
        // }, 0);
      };

      const onFilterChange = (e: any) => {
        let data: any = this.state.filtercolumns;
        let itm: any = JSON.parse(
          e.target.parentElement.closest('.customreportaccrlist').dataset.items
        );

        let isfltrd: boolean =
          !this.allColumns[
            this.allColumns.findIndex((x: any) => x.columnId === itm.columnId)
          ].isFiltered;

        itm.isFiltered = isfltrd;
        this.allColumns[
          this.allColumns.findIndex((x: any) => x.columnId === itm.columnId)
        ].isFiltered = isfltrd;

        if (isfltrd) {
          itm['customReportId'] = this.props.reportId;
          data.push(itm);
          e.target.classList.remove('lightgray');
          e.target.classList.add('black');
        } else {
          data.splice(
            data.findIndex((x: any) => x.columnId === itm.columnId),
            1
          );
          e.target.classList.remove('black');
          e.target.classList.add('lightgray');
        }

        this.setState({ filtercolumns: data });
        this.lstFilterColumn.refresh();
      };

      return (
        <>
          <div
            className=' customreportaccrlist position-relative'
            data-items={JSON.stringify(e)}
            data-color={'lightgray'}
          >
            <div className='position-absolute ps-1'>
              <CheckBoxComponent
                id={e.columnId}
                value={e.text}
                checked={e.isSelected}
                onChange={onCheckedChange}
              ></CheckBoxComponent>
            </div>
            <div className='etext'>{e.text}</div>

            {/* {e.isForFilter && (
              <div
                className='position-absolute accrfilter'
                title='Filter'
                onClick={onFilterChange}
              >
                <div className='fa-solid fa-filter lightgray'></div>
              </div>
            )} */}
            {this.state.reportType === 'pivottable' && (
              <div
                className='position-absolute accrpivot'
                title='Pivot Table Row'
                onClick={this.onPivotChange}
              >
                <div
                  className={`font-20 e-icons e-chart-switch-row-column ${
                    e.isPivoted ? 'black' : 'lightgray'
                  }`}
                ></div>
              </div>
            )}

            {this.state.reportType === 'pivottable' && (
              <div
                className='position-absolute accrpivotval'
                title='Pivot Table Value'
                onClick={this.onPivotValChange}
              >
                <div
                  className={`fa-solid fa-diagram-predecessor ${
                    e.isPivotedVal ? 'black' : 'lightgray'
                  }`}
                ></div>
              </div>
            )}
          </div>
        </>
      );
    };

    return (
      <>
        <ListBoxComponent
          id='accrpayroll'
          dataSource={data}
          itemTemplate={listTemplate}
          allowDragAndDrop={true}
          //scope='combined-list'
          fields={{ text: 'text' }}
        />
      </>
    );
  }

  private contentLeaveInfo() {
    let data: { [key: string]: Object }[];

    data = this._structure.CustomReportCategory.LeaveInfo.Columns.sort(
      (a: any, b: any) => {
        return a.Name > b.Name ? 1 : -1;
      }
    ).map((x: any) => {
      let matched: any =
        this.props.report &&
        this.props.report.columns.filter(
          (c: any) =>
            c.columnId ===
            this._structure.CustomReportCategory.LeaveInfo.Id + '|' + x.Id
        );
      if (matched && matched.length > 0) {
        matched = matched[0];
      } else {
        matched = null;
      }

      return {
        id: matched ? matched.id : null,
        customReportId: matched ? matched.customReportId : null,
        columnId: `${this._structure.CustomReportCategory.LeaveInfo.Id}|${x.Id}`,
        text: x.Name,
        category: this._structure.CustomReportCategory.LeaveInfo.Id,
        isNumber: x.IsNumber,
        isDate: x.IsDate,
        isForFilter: x.IsForFilter,
        isForGroup: x.IsForGroup,
        isSelected: matched ? matched.isSelected : false,
        isFiltered: false,
        isSorted: matched ? matched.isSorted : '',
        isSummed: matched ? matched.isSummed : false,
        isGrouped: matched ? matched.isGrouped : false,
        isPivoted: matched ? matched.isPivoted : false,
        isPivotedVal: matched ? matched.isPivotedVal : false,
        selectionOrder: matched ? matched.selectionOrder : 999,
        pivotRowOrder: matched ? matched.pivotRowOrder : 999,
        pivotValOrder: matched ? matched.pivotValOrder : 999,
      };
    });

    this.allColumns.push(...data);
    let columns: any = [];
    let pivotrows: any = [];
    let pivotvals: any = [];

    let cols: any = this.allColumns.filter((x: any) => x.isSelected === true);
    if (cols.length > 0) {
      columns = cols.sort((a: any, b: any) => {
        return a.selectionOrder > b.selectionOrder ? 1 : -1;
      });
      // this.setState({
      //   columns: columns,
      // });
      // this.lstColumn.refresh();
    }

    cols = this.allColumns.filter((x: any) => x.isPivoted === true);
    if (cols.length > 0) {
      pivotrows = cols.sort((a: any, b: any) => {
        return a.pivotRowOrder > b.pivotRowOrder ? 1 : -1;
      });
      // this.setState({
      //   pivotrows: cols.sort((a: any, b: any) => {
      //     return a.pivotRowOrder > b.pivotRowOrder ? 1 : -1;
      //   }),
      // });
    }

    cols = this.allColumns.filter((x: any) => x.isPivotedVal === true);
    if (cols.length > 0) {
      pivotvals = cols.sort((a: any, b: any) => {
        return a.pivotValOrder > b.pivotValOrder ? 1 : -1;
      });
      // this.setState({
      //   pivotvals: cols.sort((a: any, b: any) => {
      //     return a.pivotValOrder > b.pivotValOrder ? 1 : -1;
      //   }),
      // });
    }
    this.setState({
      columns: columns,
      pivotrows: pivotrows,
      pivotvals: pivotvals,
    });

    const listTemplate = (e: any) => {
      const onCheckedChange = (e: any) => {
        let data: any = [...this.state.columns];
        let itm: any = JSON.parse(
          e.target.element.closest('.customreportaccrlist').dataset.items
        );

        if (e.target.checked) {
          // if (data.length >= 10) {
          //   e.target.checked = false;
          // DialogConfirm.showDialog({
          //   content: '<p class="dialog-contain">Sorry, You can select maximum 10 columns.</p>',
          // });
          //   return;
          // }
          if (
            this.state.pivotrows.filter(
              (x: any) => x && x.columnId === itm.columnId
            ).length > 0 ||
            this.state.pivotvals.filter(
              (x: any) => x && x.columnId === itm.columnId
            ).length > 0
          ) {
            e.target.checked = false;

            // DialogConfirm.showDialog({
            //   content:
            //     '<p class="dialog-contain">Sorry, You cannot tick same column for both pivot columns, pivot rows or pivot value.</p>',
            // });
            this.toastObj = ToastUtility.show({
              cssClass: 'e-toast-warning',
              icon: 'e-warning toast-icons',
              showCloseButton: true,
              content:
                'Sorry, You cannot tick same column for both pivot columns, pivot rows or pivot value.',
              target: '#basic_card',
              position: { X: 'Center', Y: 'Top' },
              width: 'auto',
              timeOut: 3000,
            });
            return;
          }
          // data.push(itm);
          this.allColumns[
            this.allColumns.findIndex((x: any) => x.columnId === itm.columnId)
          ].isSelected = e.target.checked;
          let jsn: any = this.allColumns.filter(
            (x: any) => x.columnId === itm.columnId
          )[0];
          jsn['customReportId'] = this.props.reportId;
          data.push(jsn);
        } else {
          data.splice(
            data.findIndex((x: any) => x.columnId === itm.columnId),
            1
          );
          this.allColumns[
            this.allColumns.findIndex((x: any) => x.columnId === itm.columnId)
          ].isSelected = e.target.checked;
        }

        // this.allColumns[
        //   this.allColumns.findIndex((x: any) => x.columnId === itm.columnId)
        // ].isSelected = e.target.checked;

        this.setState({ columns: data });
        // setTimeout(() => {
        //   this.lstColumn.refresh();
        // }, 0);
      };

      const onFilterChange = (e: any) => {
        let data: any = this.state.filtercolumns;
        let itm: any = JSON.parse(
          e.target.parentElement.closest('.customreportaccrlist').dataset.items
        );

        let isfltrd: boolean =
          !this.allColumns[
            this.allColumns.findIndex((x: any) => x.columnId === itm.columnId)
          ].isFiltered;

        itm.isFiltered = isfltrd;
        this.allColumns[
          this.allColumns.findIndex((x: any) => x.columnId === itm.columnId)
        ].isFiltered = isfltrd;

        if (isfltrd) {
          itm['customReportId'] = this.props.reportId;
          data.push(itm);
          e.target.classList.remove('lightgray');
          e.target.classList.add('black');
        } else {
          data.splice(
            data.findIndex((x: any) => x.columnId === itm.columnId),
            1
          );
          e.target.classList.remove('black');
          e.target.classList.add('lightgray');
        }

        this.setState({ filtercolumns: data });
        this.lstFilterColumn.refresh();
      };

      return (
        <>
          <div
            className=' customreportaccrlist position-relative'
            data-items={JSON.stringify(e)}
            data-color={'lightgray'}
          >
            <div className='position-absolute ps-1'>
              <CheckBoxComponent
                id={e.columnId}
                value={e.text}
                checked={e.isSelected}
                onChange={onCheckedChange}
              ></CheckBoxComponent>
            </div>
            <div className='etext'>{e.text}</div>

            {/* {e.isForFilter && (
              <div
                className='position-absolute accrfilter'
                title='Filter'
                onClick={onFilterChange}
              >
                <div className='fa-solid fa-filter lightgray'></div>
              </div>
            )} */}
            {this.state.reportType === 'pivottable' && (
              <div
                className='position-absolute accrpivot'
                title='Pivot Table Row'
                onClick={this.onPivotChange}
              >
                <div
                  className={`font-20 e-icons e-chart-switch-row-column ${
                    e.isPivoted ? 'black' : 'lightgray'
                  }`}
                ></div>
              </div>
            )}

            {this.state.reportType === 'pivottable' && (
              <div
                className='position-absolute accrpivotval'
                title='Pivot Table Value'
                onClick={this.onPivotValChange}
              >
                <div
                  className={`fa-solid fa-diagram-predecessor ${
                    e.isPivotedVal ? 'black' : 'lightgray'
                  }`}
                ></div>
              </div>
            )}
          </div>
        </>
      );
    };

    return (
      <>
        <ListBoxComponent
          id='accrleave'
          dataSource={data}
          itemTemplate={listTemplate}
          allowDragAndDrop={true}
          //scope='combined-list'
          fields={{ text: 'text' }}
        />
      </>
    );
  }

  onPivotChange = (e: any) => {
    let data: any = [...this.state.pivotrows];
    let itm: any = JSON.parse(
      e.target.parentElement.closest('.customreportaccrlist').dataset.items
    );

    let ispvtd: boolean =
      !this.allColumns[
        this.allColumns.findIndex((x: any) => x.columnId === itm.columnId)
      ].isPivoted;

    if (ispvtd) {
      if (
        this.state.columns.filter((x: any) => x && x.columnId === itm.columnId)
          .length > 0 ||
        this.state.pivotvals.filter(
          (x: any) => x && x.columnId === itm.columnId
        ).length > 0
      ) {
        // DialogConfirm.showDialog({
        //   content:
        //     '<p class="dialog-contain">Sorry, You cannot tick same column for both pivot columns, pivot rows or pivot value.</p>',
        // });
        this.toastObj = ToastUtility.show({
          cssClass: 'e-toast-warning',
          icon: 'e-warning toast-icons',
          showCloseButton: true,
          content:
            'Sorry, You cannot tick same column for both pivot columns, pivot rows or pivot value.',
          target: '#basic_card',
          position: { X: 'Center', Y: 'Top' },
          width: 'auto',
          timeOut: 3000,
        });
        return;
      }
      itm.isPivoted = ispvtd;
      this.allColumns[
        this.allColumns.findIndex((x: any) => x.columnId === itm.columnId)
      ].isPivoted = ispvtd;
      //data.push(itm);
      let jsn: any = this.allColumns.filter(
        (x: any) => x.columnId === itm.columnId
      )[0];
      jsn['customReportId'] = this.props.reportId;
      data.push(jsn);
      e.target.classList.remove('lightgray');
      e.target.classList.add('black');
    } else {
      data.splice(
        data.findIndex((x: any) => x.columnId === itm.columnId),
        1
      );
      itm.isPivoted = ispvtd;
      this.allColumns[
        this.allColumns.findIndex((x: any) => x.columnId === itm.columnId)
      ].isPivoted = ispvtd;
      e.target.classList.remove('black');
      e.target.classList.add('lightgray');
    }

    // itm.isPivoted = ispvtd;
    // this.allColumns[
    //   this.allColumns.findIndex((x: any) => x.columnId === itm.columnId)
    // ].isPivoted = ispvtd;

    this.setState({ pivotrows: data });
    //this.lstPivotRow.refresh();
  };

  onPivotValChange = (e: any) => {
    let data: any = [...this.state.pivotvals];
    let itm: any = JSON.parse(
      e.target.parentElement.closest('.customreportaccrlist').dataset.items
    );

    let ispvtd: boolean =
      !this.allColumns[
        this.allColumns.findIndex((x: any) => x.columnId === itm.columnId)
      ].isPivotedVal;

    if (ispvtd) {
      if (
        this.state.columns.filter((x: any) => x && x.columnId === itm.columnId)
          .length > 0 ||
        this.state.pivotrows.filter(
          (x: any) => x && x.columnId === itm.columnId
        ).length > 0
      ) {
        // DialogConfirm.showDialog({
        //   content:
        //     '<p class="dialog-contain">Sorry, You cannot tick same column for both pivot columns, pivot rows or pivot value.</p>',
        // });
        this.toastObj = ToastUtility.show({
          cssClass: 'e-toast-warning',
          icon: 'e-warning toast-icons',
          showCloseButton: true,
          content:
            'Sorry, You cannot tick same column for both pivot columns, pivot rows or pivot value.',
          target: '#basic_card',
          position: { X: 'Center', Y: 'Top' },
          width: 'auto',
          timeOut: 3000,
        });
        return;
      }
      // data.push(itm);
      itm.isPivotedVal = ispvtd;
      this.allColumns[
        this.allColumns.findIndex((x: any) => x.columnId === itm.columnId)
      ].isPivotedVal = ispvtd;
      let jsn: any = this.allColumns.filter(
        (x: any) => x.columnId === itm.columnId
      )[0];
      jsn['customReportId'] = this.props.reportId;
      data.push(jsn);
      e.target.classList.remove('lightgray');
      e.target.classList.add('black');
    } else {
      data.splice(
        data.findIndex((x: any) => x.columnId === itm.columnId),
        1
      );
      itm.isPivotedVal = ispvtd;
      this.allColumns[
        this.allColumns.findIndex((x: any) => x.columnId === itm.columnId)
      ].isPivotedVal = ispvtd;
      e.target.classList.remove('black');
      e.target.classList.add('lightgray');
    }

    // itm.isPivotedVal = ispvtd;
    // this.allColumns[
    //   this.allColumns.findIndex((x: any) => x.columnId === itm.columnId)
    // ].isPivotedVal = ispvtd;

    this.setState({ pivotvals: data });
    //this.lstPivotVal.refresh();
  };

  public DialogButtonModel: any = [
    {
      buttonModel: {
        content: Common.toTitleCase('Cancel'),
        cssClass: 'flat-button',
      },
      click: () => {
        if (this.state.showDialog === true) {
          this.setState({ showDialog: false });
          setTimeout(() => {
            let prd: any = document.querySelectorAll(
              '#customreport-periodctrls'
            );
            if (prd.length > 0) {
              prd.forEach((p: any) => {
                p.remove();
              });
            }
          }, 1000);
        } else {
        }
      },
    },
    // {
    //   buttonModel: {
    //     content: Common.toTitleCase('Accept'),
    //     cssClass: 'flat-button d-none',
    //     isPrimary: true,
    //   },
    //   click: () => {
    //     return;
    //   },
    // },
  ];

  render() {
    const onRepoCategoryCheckedChange = (e: any) => {
      let chk: any = e.event.target.parentElement.querySelector(
        'input[type="checkbox"]'
      );
      let chkList: any = e.event.target
        .closest('.divrepocategory')
        .querySelectorAll('input[type="checkbox"]');

      if (chkList.length > 0) {
        chkList.forEach((x: any) => {
          if (x.id !== 'employee_info' && x.id !== chk.id) {
            let c: any = x.ej2_instances[0];
            if (c.checked) {
              c.checked = false;
              this.RefreshRepoCateGoryAccordion(x);
            }
          }
        });
      }
      this.RefreshRepoCateGoryAccordion(e);
    };

    const onRepoTypeChange = (e: any) => {
      let ae: any = document.getElementById('accremployee');
      let ap: any = document.getElementById('accrpayroll');
      let al: any = document.getElementById('accrleave');

      if (e.value === this._structure.CustomReportType.List.Id) {
        let pvt: any = this.allColumns.filter((x: any) => x.isPivoted === true);
        pvt.forEach((x: any) => (x.isPivoted = false));

        let pvtval: any = this.allColumns.filter(
          (x: any) => x.isPivotedVal === true
        );
        pvtval.forEach((x: any) => (x.isPivotedVal = false));
      }
      this.setState({ reportType: e.value, pivotrows: [], pivotvals: [] });
      if (ae) {
        ae.ej2_instances[0].refresh();
      }

      if (ap) {
        ap.ej2_instances[0].refresh();
      }
      if (al) {
        al.ej2_instances[0].refresh();
      }
    };

    const onaccrRepoCateCreated = (e: any) => {};

    const onColumnCloseClick = (e: any) => {
      let id: string = e.target.dataset.id;
      if (!id) {
        id = e.target.parentElement.dataset.id;
      }

      let chklist: any = document.querySelector(
        `.customreportaccrlist input[type="checkbox"][id="${id}"]`
      );

      if (chklist) {
        chklist.ej2_instances[0].checked = false;
        this.allColumns[
          this.allColumns.findIndex((x: any) => x.columnId === id)
        ].isSelected = false;

        // let data: any = this.allColumns.filter(
        //   (x: any) => x.isSelected === true
        // );
        let data: any = this.state.columns;
        data.splice(
          data.findIndex((x: any) => x.columnId === id),
          1
        );

        this.setState({ columns: data });
        setTimeout(() => {
          this.lstColumn.refresh();
        }, 0);
      }
    };

    const columnlistTemplate = (e: any, i: any) => {
      let itm: any = this.allColumns.filter(
        (x: any) => x.columnId === e.columnId
      )[0];

      const onSumClick = (e: any) => {
        let id: string = e.target.dataset.id;
        if (!id) {
          id = e.target.parentElement.dataset.id;
        }

        let summed: boolean =
          !this.allColumns[
            this.allColumns.findIndex((x: any) => x.columnId === id)
          ].isSummed;

        if (summed) {
          e.target
            .closest('.customreportcolumnlist')
            .querySelector('.accrsum > div')
            .classList.remove('lightgray');

          e.target
            .closest('.customreportcolumnlist')
            .querySelector('.accrsum > div')
            .classList.add('black');
        } else {
          e.target
            .closest('.customreportcolumnlist')
            .querySelector('.accrsum > div')
            .classList.remove('black');

          e.target
            .closest('.customreportcolumnlist')
            .querySelector('.accrsum > div')
            .classList.add('lightgray');
        }

        this.allColumns[
          this.allColumns.findIndex((x: any) => x.columnId === id)
        ].isSummed = summed;
      };

      const onGroupClick = (e: any) => {
        let id: string = e.target.dataset.id;
        if (!id) {
          id = e.target.parentElement.dataset.id;
        }

        let grouped: boolean =
          !this.allColumns[
            this.allColumns.findIndex((x: any) => x.columnId === id)
          ].isGrouped;

        if (
          this.allColumns.filter((x: any) => x.isGrouped).length >= 2 &&
          grouped === true
        ) {
          // DialogConfirm.showDialog({
          //   content:
          //     '<p class="dialog-contain">Sorry, You can Group on maximum 2 columns only!</p>',
          // });
          this.toastObj = ToastUtility.show({
            cssClass: 'e-toast-warning',
            icon: 'e-warning toast-icons',
            showCloseButton: true,
            content: 'Sorry, You can Group on maximum 2 columns only!',
            target: '#basic_card',
            position: { X: 'Center', Y: 'Top' },
            width: 'auto',
            timeOut: 3000,
          });
          return;
        }

        if (grouped) {
          e.target
            .closest('.customreportcolumnlist')
            .querySelector('.accrgroup > div')
            .classList.remove('lightgray');

          e.target
            .closest('.customreportcolumnlist')
            .querySelector('.accrgroup > div')
            .classList.add('black');
        } else {
          e.target
            .closest('.customreportcolumnlist')
            .querySelector('.accrgroup > div')
            .classList.remove('black');

          e.target
            .closest('.customreportcolumnlist')
            .querySelector('.accrgroup > div')
            .classList.add('lightgray');
        }

        this.allColumns[
          this.allColumns.findIndex((x: any) => x.columnId === id)
        ].isGrouped = grouped;
      };

      return (
        <>
          <div className=' customreportcolumnlist position-relative'>
            <div className='etext'>{e.text}</div>
            <div
              className='position-absolute accclose'
              data-id={e.columnId}
              data-text={e.text}
              onClick={onColumnCloseClick}
            >
              <div className='e-icons e-close e-input-group-icon'></div>
            </div>
            {e.isForGroup && (
              <div
                className='position-absolute accrgroup'
                data-id={e.columnId}
                data-text={e.text}
                title='Group By'
                onClick={onGroupClick}
              >
                <div
                  className={`fa-solid fa-layer-group ${
                    itm.isGrouped ? 'black' : 'lightgray'
                  }`}
                ></div>
              </div>
            )}
            {e.isNumber && (
              <div
                className='position-absolute accrsum'
                data-id={e.columnId}
                data-text={e.text}
                onClick={onSumClick}
                title='Sum'
              >
                <div
                  className={`e-icons e-sum ${
                    itm.isSummed ? 'black' : 'lightgray'
                  }`}
                ></div>
              </div>
            )}
            <div
              className='position-absolute accrsort'
              data-id={e.columnId}
              data-text={e.text}
              onClick={onFilterColumnSortClick}
              title='Sort'
            >
              <div
                className={`fa-solid fa-arrow-up-a-z w-100 ${
                  itm.isSorted ? 'black' : 'lightgray'
                }`}
              ></div>
            </div>
          </div>
        </>
      );
    };

    const onFilterColumnCloseClick = (e: any) => {
      let chklist: any = Array.from(
        document.querySelectorAll(`.customreportaccrlist`) as any
      ).filter(
        (x: any) => JSON.parse(x.dataset.items).id === e.target.dataset.id
      );

      if (chklist) {
        this.allColumns[
          this.allColumns.findIndex(
            (x: any) => x.columnId === e.target.dataset.id
          )
        ].isFiltered = false;

        chklist[0].querySelector('.accrfilter > div').classList.remove('black');
        chklist[0]
          .querySelector('.accrfilter > div')
          .classList.add('lightgray');

        let data: any = this.state.filtercolumns;
        data.splice(
          data.findIndex((x: any) => x.columnId === e.target.dataset.id),
          1
        );
        this.setState({ filtercolumns: data });
        this.lstFilterColumn.refresh();
      }
    };

    const onFilterColumnSortClick = (e: any) => {
      let id: string = e.target.dataset.id;
      if (!id) {
        id = e.target.parentElement.dataset.id;
      }

      let sorted: string =
        this.allColumns[
          this.allColumns.findIndex((x: any) => x.columnId === id)
        ].isSorted;

      if (
        this.allColumns.filter((x: any) => x.isSorted).length >= 2 &&
        sorted === ''
      ) {
        // DialogConfirm.showDialog({
        //   content:
        //     '<p class="dialog-contain">Sorry, You can sort on maximum 2 columns only!</p>',
        // });
        this.toastObj = ToastUtility.show({
          cssClass: 'e-toast-warning',
          icon: 'e-warning toast-icons',
          showCloseButton: true,
          content: 'Sorry, You can sort on maximum 2 columns only!',
          target: '#basic_card',
          position: { X: 'Center', Y: 'Top' },
          width: 'auto',
          timeOut: 3000,
        });
        return;
      }

      if (sorted === '') {
        sorted = 'A';

        e.target
          .closest('.customreportcolumnlist')
          .querySelector('.accrsort > div')
          .classList.remove('lightgray');

        e.target
          .closest('.customreportcolumnlist')
          .querySelector('.accrsort > div')
          .classList.add('black');
      } else if (sorted === 'A') {
        sorted = 'Z';

        e.target
          .closest('.customreportcolumnlist')
          .querySelector('.accrsort > div')
          .classList.remove('fa-arrow-up-a-z');

        e.target
          .closest('.customreportcolumnlist')
          .querySelector('.accrsort > div')
          .classList.add('fa-arrow-up-z-a');
      } else {
        sorted = '';

        e.target
          .closest('.customreportcolumnlist')
          .querySelector('.accrsort > div')
          .classList.remove('black');

        e.target
          .closest('.customreportcolumnlist')
          .querySelector('.accrsort > div')
          .classList.remove('fa-arrow-up-z-a');

        e.target
          .closest('.customreportcolumnlist')
          .querySelector('.accrsort > div')
          .classList.add('lightgray');

        e.target
          .closest('.customreportcolumnlist')
          .querySelector('.accrsort > div')
          .classList.add('fa-arrow-up-a-z');
      }

      this.allColumns[
        this.allColumns.findIndex((x: any) => x.columnId === id)
      ].isSorted = sorted;
    };

    const columnFilterListTemplate = (e: any) => {
      return (
        <>
          <div className='customreportfiltercolumnlist position-relative my-2'>
            <div className='etext'>{e.text}</div>
            <div
              className='position-absolute e-icons e-close e-input-group-icon'
              data-id={e.columnId}
              data-text={e.text}
              onClick={onFilterColumnCloseClick}
            ></div>
          </div>
        </>
      );
    };

    const onPivotRowCloseClick = (e: any) => {
      let id: string = e.target.dataset.id;
      if (!id) {
        id = e.target.parentElement.dataset.id;
      }

      let chklist: any = Array.from(
        document.querySelectorAll(`.customreportaccrlist`) as any
      ).filter((x: any) => JSON.parse(x.dataset.items).columnId === id);

      if (chklist) {
        this.allColumns[
          this.allColumns.findIndex((x: any) => x.columnId === id)
        ].isPivoted = false;

        chklist[0].querySelector('.accrpivot > div').classList.remove('black');
        chklist[0].querySelector('.accrpivot > div').classList.add('lightgray');

        let data: any = this.state.pivotrows;
        data.splice(
          data.findIndex((x: any) => x.columnId === id),
          1
        );
        this.setState({ pivotrows: data });
        this.lstPivotRow.refresh();
      }
    };

    const pivotRowlistTemplate = (e: any, i: any) => {
      let itm: any = this.allColumns.filter(
        (x: any) => x.columnId === e.columnId
      )[0];

      return (
        <>
          <div className=' customreportpivotrowlist position-relative'>
            <div className='etext'>{e.text}</div>
            <div
              className='position-absolute accclose'
              data-id={e.columnId}
              data-text={e.text}
              onClick={onPivotRowCloseClick}
            >
              <div className='e-icons e-close e-input-group-icon'></div>
            </div>
          </div>
        </>
      );
    };

    const pivotVallistTemplate = (e: any, i: any) => {
      let itm: any = this.allColumns.filter(
        (x: any) => x.columnId === e.columnId
      )[0];

      return (
        <>
          <div className=' customreportpivotvallist position-relative'>
            <div className='etext'>{e.text}</div>
            <div
              className='position-absolute accclose'
              data-id={e.columnId}
              data-text={e.text}
              onClick={onPivotValCloseClick}
            >
              <div className='e-icons e-close e-input-group-icon'></div>
            </div>
          </div>
        </>
      );
    };

    const onPivotValCloseClick = (e: any) => {
      let id: string = e.target.dataset.id;
      if (!id) {
        id = e.target.parentElement.dataset.id;
      }

      let chklist: any = Array.from(
        document.querySelectorAll(`.customreportaccrlist`) as any
      ).filter((x: any) => JSON.parse(x.dataset.items).columnId === id);

      if (chklist) {
        this.allColumns[
          this.allColumns.findIndex((x: any) => x.columnId === id)
        ].isPivotedVal = false;

        chklist[0]
          .querySelector('.accrpivotval > div')
          .classList.remove('black');
        chklist[0]
          .querySelector('.accrpivotval > div')
          .classList.add('lightgray');

        let data: any = this.state.pivotvals;
        data.splice(
          data.findIndex((x: any) => x.columnId === id),
          1
        );
        this.setState({ pivotvals: data });
        this.lstPivotVal.refresh();
      }
    };

    const onDrop = (e: any) => {
      let prev: never = this.state.columns.splice(e.previousIndex, 1)[0];
      if (prev) {
        this.state.columns.splice(e.currentIndex, 0, prev);
      }
    };

    const Validate = () => {
      if (!this.txtName.value) {
        // DialogConfirm.showDialog({
        //   content:
        //     '<p class="dialog-contain">Please enter custom report name.</p>',
        // });
        this.toastObj = ToastUtility.show({
          cssClass: 'e-toast-warning',
          icon: 'e-warning toast-icons',
          showCloseButton: true,
          content: 'Please enter custom report name.',
          target: '#basic_card',
          position: { X: 'Center', Y: 'Top' },
          width: 'auto',
          timeOut: 3000,
        });
        return false;
      }

      if (!this.ddlRepoGroup.value) {
        // DialogConfirm.showDialog({
        //   content: '<p class="dialog-contain">Please select report group.</p>',
        // });
        this.toastObj = ToastUtility.show({
          cssClass: 'e-toast-warning',
          icon: 'e-warning toast-icons',
          showCloseButton: true,
          content: 'Please select report group.',
          target: '#basic_card',
          position: { X: 'Center', Y: 'Top' },
          width: 'auto',
          timeOut: 3000,
        });
        return false;
      }

      let isrepocategaryticked: boolean = false;
      document.getElementsByName('reportcategory').forEach((x: any) => {
        if (x.ej2_instances[0].checked) {
          isrepocategaryticked = true;
          return;
        }
      });

      if (!isrepocategaryticked) {
        // DialogConfirm.showDialog({
        //   content:
        //     '<p class="dialog-contain">Please tick atleast one report category.</p>',
        // });
        this.toastObj = ToastUtility.show({
          cssClass: 'e-toast-warning',
          icon: 'e-warning toast-icons',
          showCloseButton: true,
          content: 'Please tick atleast one report category.',
          target: '#basic_card',
          position: { X: 'Center', Y: 'Top' },
          width: 'auto',
          timeOut: 3000,
        });
        return false;
      }

      if (
        !this.state.columns ||
        (this.state.reportType === 'list' && this.state.columns.length <= 1)
      ) {
        // DialogConfirm.showDialog({
        //   content:
        //     '<p class="dialog-contain">Please tick atleast two columns from field name.</p>',
        // });
        this.toastObj = ToastUtility.show({
          cssClass: 'e-toast-warning',
          icon: 'e-warning toast-icons',
          showCloseButton: true,
          content: 'Please tick atleast two columns from field name.',
          target: '#basic_card',
          position: { X: 'Center', Y: 'Top' },
          width: 'auto',
          timeOut: 3000,
        });
        return false;
      }

      if (
        !this.state.columns ||
        (this.state.reportType === 'pivottable' &&
          this.state.columns.length <= 0)
      ) {
        // DialogConfirm.showDialog({
        //   content:
        //     '<p class="dialog-contain">Please tick atleast one column from field name.</p>',
        // });
        this.toastObj = ToastUtility.show({
          cssClass: 'e-toast-warning',
          icon: 'e-warning toast-icons',
          showCloseButton: true,
          content: 'Please tick atleast one column from field name.',
          target: '#basic_card',
          position: { X: 'Center', Y: 'Top' },
          width: 'auto',
          timeOut: 3000,
        });
        return false;
      }

      if (this.state.reportType === 'pivottable') {
        if (this.state.pivotrows.length <= 0) {
          // DialogConfirm.showDialog({
          //   content:
          //     '<p class="dialog-contain">Please tick atleast one pivot table row from field name.</p>',
          // });
          this.toastObj = ToastUtility.show({
            cssClass: 'e-toast-warning',
            icon: 'e-warning toast-icons',
            showCloseButton: true,
            content: 'Please tick atleast one pivot table row from field name.',
            target: '#basic_card',
            position: { X: 'Center', Y: 'Top' },
            width: 'auto',
            timeOut: 3000,
          });
          return false;
        }

        if (this.state.pivotvals.length <= 0) {
          this.toastObj = ToastUtility.show({
            cssClass: 'e-toast-warning',
            icon: 'e-warning toast-icons',
            showCloseButton: true,
            content:
              'Please tick atleast one pivot table value from field name.',
            target: '#basic_card',
            position: { X: 'Center', Y: 'Top' },
            width: 'auto',
            timeOut: 3000,
          });
          // DialogConfirm.showDialog({
          //   content:
          //     '<p class="dialog-contain">Please tick atleast one pivot table value from field name.</p>',
          // });

          return false;
        }

        let cnt: any = this.allColumns.filter(
          (x: any) =>
            (x.isSelected && x.isPivoted) ||
            (x.isSelected && x.isPivotedVal) ||
            (x.isPivoted && x.isPivotedVal)
        );

        if (cnt.length > 0) {
          // DialogConfirm.showDialog({
          //   content:
          //     '<p class="dialog-contain">Sorry!, You cannot tick same column for both pivot columns, pivot rows or pivot value.</p>',
          // });
          this.toastObj = ToastUtility.show({
            cssClass: 'e-toast-warning',
            icon: 'e-warning toast-icons',
            showCloseButton: true,
            content:
              'Sorry!, You cannot tick same column for both pivot columns, pivot rows or pivot value.',
            target: '#basic_card',
            position: { X: 'Center', Y: 'Top' },
            width: 'auto',
            timeOut: 3000,
          });
          return false;
        }
      }

      let repocat: any = Array.from(
        new Set(
          this.state.columns
            .concat(this.state.pivotrows)
            .concat(this.state.pivotvals)
            .map((x: any) => {
              return x.category;
            })
        )
      );

      let chk: any = document.querySelector(
        'input[type="checkbox"][id="employee_info"]'
      );
      if (chk.checked) {
        // let cnt: any = this.state.columns.filter((x: any) =>
        //   this.mandatEmpFields.includes(x.columnId)
        // ).length;
        // if (cnt <= 0) {
        //   cnt = this.state.pivotrows.filter((x: any) =>
        //     this.mandatEmpFields.includes(x.columnId)
        //   ).length;

        //   if (cnt <= 0) {
        //     cnt = this.state.pivotvals.filter((x: any) =>
        //       this.mandatEmpFields.includes(x.columnId)
        //     ).length;
        //   }
        // }
        let cnt: any = this.allColumns.filter(
          (x: any) =>
            this.mandatEmpFields.includes(x.columnId) &&
            (x.isSelected || x.isPivoted || x.isPivotedVal)
        );

        if (cnt <= 0 && this.state.reportType === 'list') {
          // DialogConfirm.showDialog({
          //   content:
          //     '<p class="dialog-contain">Please tick any one of the following columns to generate report. <br><br> Employee Code <br> Employee Name <br> Employee Name (Code + Name) <br> Employee Name (Name + Code)</p>',
          // });
          this.toastObj = ToastUtility.show({
            cssClass: 'e-toast-warning',
            icon: 'e-warning toast-icons',
            showCloseButton: true,
            content:
              'Please tick any one of the following columns to generate report. <br><br> Employee Code <br> Employee Name <br> Employee Name (Code + Name) <br> Employee Name (Name + Code)',
            target: '#basic_card',
            position: { X: 'Center', Y: 'Top' },
            width: 'auto',
            timeOut: 3000,
          });
          return false;
        }
      }

      return true;
    };

    const onPreviewClick = (e: any) => {
      if (Validate() === false) return;

      this.setState({ showDialog: true });
    };

    const onSaveClick = (e: any) => {
      if (Validate() === false) return;

      let cols: any = [];
      cols = this.state.columns.map((x: any, idx: any) => {
        let jsn: any = this.allColumns.filter(
          (f: any) => f.columnId === x.columnId
        )[0];
        jsn.selectionOrder = idx + 1;
        jsn.pivotRowOrder = 999;
        jsn.pivotValOrder = 999;
        return jsn;
      });

      cols.push(
        ...this.allColumns.filter(
          (f: any) =>
            f.isFiltered === true &&
            cols.findIndex((i: any) => i.columnId === f.columnId) < 0
        )
      );

      let fltr: any = this.state.pivotrows.filter(
        (f: any) =>
          f && cols.findIndex((i: any) => i.columnId === f.columnId) < 0
      );
      if (fltr.length > 0) {
        cols.push(
          ...fltr.map((x: any, idx: any) => {
            let jsn: any = x;
            jsn.selectionOrder = 999;
            jsn.pivotRowOrder = idx + 1;
            jsn.pivotValOrder = 999;
            return jsn;
          })
        );
      }

      fltr = this.state.pivotvals.filter(
        (f: any) =>
          f && cols.findIndex((i: any) => i.columnId === f.columnId) < 0
      );
      if (fltr.length > 0) {
        cols.push(
          ...fltr.map((x: any, idx: any) => {
            let jsn: any = x;
            jsn.selectionOrder = 999;
            jsn.pivotRowOrder = 999;
            jsn.pivotValOrder = idx + 1;
            return jsn;
          })
        );
      }

      let payload: any = {
        id: this.props.reportId,
        reportName: this.txtName.value,
        reportGroupId: this.ddlRepoGroup.value,
        reportType: this.state.reportType,
        reportCategory: Array.from(
          new Set(
            this.state.columns
              .concat(this.state.pivotrows)
              .concat(this.state.pivotvals)
              .map((x: any) => {
                return x.category;
              })
          )
        ).join(','),
        orientation: (
          document.getElementsByName('orientation')[0] as any
        ).ej2_instances[0].getSelectedValue(),
        columns: cols,
      };

      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_CORESERVICE}/customreport/add`,
        payload,
        Common.getToken() || '',
        null,
        this.token.tenant
      )
        .then((response: any) => {
          return response.data;
        })
        .then((response: any) => {
          // DialogConfirm.showDialog({
          //   content: '<p class="dialog-contain">Report saved successfully!</p>',
          // });
          this.toastObj = ToastUtility.show({
            cssClass: 'e-toast-success',
            icon: 'far fa-circle-check',
            showCloseButton: true,
            content: 'Report saved Successfully..!',
            target: '#basic_card',
            position: { X: 'Center', Y: 'Top' },
            width: 'auto',
            timeOut: 3000,
          });
        })
        .catch((error: any) => {
          console.error(error);
        })
        .finally(() => {});
    };

    const onCancelClick = (e: any) => {
      this.props.showAddEditReport(false);
    };

    const dialogContent = (e: any) => {
      let repoCategory: any = [];
      let orientation: string = (
        document.getElementsByName('orientation')[0] as any
      ).ej2_instances[0].getSelectedValue();
      // document.getElementsByName('reportcategory').forEach((x: any) => {
      //   if (x.ej2_instances[0].checked) {
      //     repoCategory.push({
      //       id: x.ej2_instances[0].id,
      //       name: x.ej2_instances[0].name,
      //     });
      //   }
      // });
      repoCategory = Array.from(
        new Set(
          this.state.columns
            .concat(this.state.pivotrows)
            .concat(this.state.pivotvals)
            .map((x: any) => {
              // let obj: any = {
              //   id: x.category,
              //   name: x.category,
              // };
              // return obj;
              return x.category;
            })
        )
      ).join(',');

      let cols: any = [];
      cols = this.state.columns.map((x: any, idx: any) => {
        let jsn: any = this.allColumns.filter(
          (f: any) => f.columnId === x.columnId
        )[0];
        jsn.selectionOrder = idx + 1;
        //return this.allColumns.filter((f: any) => f.columnId === x.columnId)[0];
        return jsn;
      });

      cols.push(
        ...this.allColumns.filter(
          (f: any) =>
            f.isFiltered === true &&
            cols.findIndex((i: any) => i.columnId === f.columnId) < 0
        )
      );

      let fltr: any = this.state.pivotrows.filter(
        (f: any) =>
          f && cols.findIndex((i: any) => i.columnId === f.columnId) < 0
      );
      if (fltr.length > 0) {
        cols.push(
          // ...this.state.pivotrows.filter(
          //   (f: any) =>
          //     f && cols.findIndex((i: any) => i.columnId === f.columnId) < 0
          // )
          ...fltr.map((x: any, idx: any) => {
            let jsn: any = x;
            jsn.pivotRowOrder = idx + 1;
            return jsn;
          })
        );
      }

      fltr = this.state.pivotvals.filter(
        (f: any) =>
          f && cols.findIndex((i: any) => i.columnId === f.columnId) < 0
      );
      if (fltr.length > 0) {
        cols.push(
          // ...this.state.pivotvals.filter(
          //   (f: any) =>
          //     f && cols.findIndex((i: any) => i.columnId === f.columnId) < 0
          // )
          ...fltr.map((x: any, idx: any) => {
            let jsn: any = x;
            jsn.pivotValOrder = idx + 1;
            return jsn;
          })
        );
      }

      return (
        <CustomReportPreview
          periodId={
            this.state.periods
              ? (this.state.periods as any).id
              : Common.blankguid
          }
          asOnStartDate={
            this.state.periods
              ? (this.state.periods as any).startDate
              : new Date()
          }
          asOnEndDate={
            this.state.periods
              ? (this.state.periods as any).endDate
              : new Date()
          }
          reportType={this.state.reportType}
          reportName={this.txtName.value}
          reportCategory={repoCategory}
          columns={cols}
          orientation={orientation}
        />
      );
    };
    // console.log(
    //   this.allColumns.filter((x: any) => x.isSelected).length,
    //   this.allColumns.filter((x: any) => x.isFiltered).length
    // );
    return (
      <>
        <div id='divcustomreport'>
          <div className='control-pane'>
            <div className='control-section inplace-control-section default_layout'>
              <div className='control_wrapper'>
                <div className='row'>
                  <div className='col-md-12 '>
                    <div className='e-card'>
                      <div className='e-card-header justify-content-start'>
                        <InPlaceEditorComponent
                          id='txtName'
                          data-underline='false'
                          mode='Inline'
                          emptyText='Enter report name'
                          className='txtName'
                          model={this.textModel}
                          ref={(obj: any) => (this.txtName = obj)}
                        />
                        <DropDownListComponent
                          id='reportGroupId'
                          name='reportGroupId'
                          className='ddlRepoGroup'
                          dataSource={this.jsonRepoGroup}
                          ref={(obj) => {
                            this.ddlRepoGroup = obj;
                          }}
                          filterBarPlaceholder='Search Report Category'
                          allowFiltering={true}
                          filterType='Contains'
                          fields={{ value: 'Id', text: 'Text' }}
                          placeholder='Select Report Category'
                          floatLabelType='Auto'
                          popupHeight='220px'
                          showClearButton={true}
                          //value={data.roleId ? data.roleId : undefined}
                          // change={(e: any) => {
                          //   this.setState({
                          //     roleId: e.itemData ? e.itemData.id : undefined,
                          //   });
                          // }}
                          // enabled={data.isManager === false ? false : true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12 mt-4'>
                    <div className='e-card'>
                      <div className='e-card-header justify-content-start'>
                        Report Type
                      </div>
                      <div className='e-card-content'>
                        <div className='radiobutton-control'>
                          <div className='row'>
                            {this._structure.CustomReportTypeList.map(
                              (x: any, idx: number) => {
                                return (
                                  <div key={Math.random()} className='col-md-3'>
                                    <RadioButtonComponent
                                      key={x.Id}
                                      //checked={idx === 0 ? true : false}
                                      checked={
                                        this.state.reportType === x.Id
                                          ? true
                                          : false
                                      }
                                      label={x.Text}
                                      name='reporttype'
                                      value={x.Id}
                                      className='reporttype'
                                      disabled={
                                        x.Id ===
                                        this._structure.CustomReportType.List.Id
                                          ? false
                                          : !this.state.periods
                                      }
                                      change={onRepoTypeChange}
                                    ></RadioButtonComponent>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12 mt-4'>
                    <div className='e-card'>
                      <div className='e-card-header justify-content-start'>
                        Data Group
                      </div>
                      <div className='e-card-content'>
                        <div className='radiobutton-control'>
                          <div className='row divrepocategory'>
                            {this._structure.CustomReportCategoryList.map(
                              (x: any, idx: number) => {
                                return (
                                  <div key={x.Id} className='col-md-3'>
                                    <CheckBoxComponent
                                      id={x.Id}
                                      checked={idx === 0 ? true : false}
                                      label={x.Text}
                                      name='reportcategory'
                                      value={x.Id}
                                      className='reportcategory'
                                      change={onRepoCategoryCheckedChange}
                                      disabled={
                                        x.Id ===
                                        this._structure.CustomReportCategory
                                          .EmployeeInfo.Id
                                          ? true
                                          : x.Id ===
                                            this._structure.CustomReportCategory
                                              .PayrollInfo.Id
                                          ? !this.state.periods
                                          : false
                                      }
                                    ></CheckBoxComponent>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mt-4'>
                  <div className='col-md-4 col-sm-12 payroll-edit-class'>
                    <div className='e-card fieldcolumn'>
                      <div className='e-card-header justify-content-start'>
                        Field Names
                      </div>
                      <div className='e-card-content'>
                        <div className='row'>
                          <div className='col-md-12'>
                            <AccordionComponent
                              ref={(obj: any) => (this.accrRepoCate = obj)}
                              created={onaccrRepoCateCreated}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-3 payroll-edit-class-child'>
                    <div className='e-card cardcolumn fieldcolumn'>
                      <div className='e-card-header justify-content-start'>
                        Columns
                      </div>
                      <div className='e-card-content'>
                        <div className='row'>
                          <div className='col-md-12 lbox-columns'>
                            <ListBoxComponent
                              dataSource={this.state.columns}
                              itemTemplate={columnlistTemplate}
                              allowDragAndDrop={true}
                              fields={{ text: 'text' }}
                              ref={(obj: any) => (this.lstColumn = obj)}
                              noRecordsTemplate=''
                              //onClick={(e: any) => e.preventDefault()}
                              drop={onDrop}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-3 payroll-edit-class-child'>
                    {/* <div className='e-card cardfilter fieldcolumn'>
                      <div className='e-card-header justify-content-start'>
                        Filter Columns
                      </div>
                      <div className='e-card-content'>
                        <div className='row'>
                          <div className='col-md-12'>
                            <ListBoxComponent
                              dataSource={this.state.filtercolumns}
                              itemTemplate={columnFilterListTemplate}
                              allowDragAndDrop={true}
                              ref={(obj: any) => (this.lstFilterColumn = obj)}
                              noRecordsTemplate=''
                            />
                          </div>
                        </div>
                      </div>
                    </div> */}
                    {this.state.reportType ===
                      this._structure.CustomReportType.PivotTable.Id && (
                      <div className='e-card cardpivot fieldcolumn'>
                        <div className='e-card-header justify-content-start'>
                          Pivot Table Rows
                        </div>
                        <div className='e-card-content'>
                          <div className='row'>
                            <div className='col-md-12 lbox-columns'>
                              <ListBoxComponent
                                dataSource={this.state.pivotrows}
                                itemTemplate={pivotRowlistTemplate}
                                allowDragAndDrop={true}
                                ref={(obj: any) => (this.lstPivotRow = obj)}
                                noRecordsTemplate=''
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-md-2 payroll-edit-class-child'>
                    {this.state.reportType ===
                      this._structure.CustomReportType.PivotTable.Id && (
                      <div className='e-card cardpivotval fieldcolumn'>
                        <div className='e-card-header justify-content-start'>
                          Pivot Table Values
                        </div>
                        <div className='e-card-content'>
                          <div className='row'>
                            <div className='col-md-12 lbox-columns'>
                              <ListBoxComponent
                                dataSource={this.state.pivotvals}
                                itemTemplate={pivotVallistTemplate}
                                allowDragAndDrop={true}
                                ref={(obj: any) => (this.lstPivotVal = obj)}
                                noRecordsTemplate=''
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row mt-4'>
                  <div className='col-md-4 col-sm-10'>
                    <div className='e-card repo-settings'>
                      <div className='e-card-header justify-content-start'>
                        Settings
                      </div>
                      <div className='e-card-content'>
                        <div className='row'>
                          <div className='col-md-12'>
                            <div className='e-card-header justify-content-start'>
                              Orientation
                            </div>
                            <div className='e-card-content d-flex flex-column '>
                              <RadioButtonComponent
                                checked={true}
                                label='Portrait'
                                name='orientation'
                                value='Portrait'
                                className='orientation'
                              ></RadioButtonComponent>
                              <RadioButtonComponent
                                checked={false}
                                label='Landscape'
                                name='orientation'
                                value='Landscape'
                                className='orientation'
                              ></RadioButtonComponent>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='footer'>
            <div className='save' onClick={onSaveClick}>
              <span className='w-100'>Save</span>
            </div>
            <div className='preview' onClick={onPreviewClick}>
              <span className='w-100'>Preview</span>
            </div>
            <div className='cancel' onClick={onCancelClick}>
              <span className='w-100'>Cancel</span>
            </div>
          </div>
        </div>
        {this.state.showDialog === true ? (
          <DialogComponent
            id='defaultDialog-custrepopreview'
            showCloseIcon={true}
            visible={this.state.showDialog}
            width={'90vw'}
            height={'90vh'}
            close={() => {
              this.setState({ showDialog: false });
              setTimeout(() => {
                let prd: any = document.querySelectorAll(
                  '#customreport-periodctrls'
                );
                if (prd.length > 0) {
                  prd.forEach((p: any) => {
                    p.remove();
                  });
                }
              }, 1000);
            }}
            content={dialogContent}
            isModal={true}
            header={this.txtName.value}
            buttons={this.DialogButtonModel}
            statelessTemplates={[]}
          ></DialogComponent>
        ) : null}
      </>
    );
  }
}
