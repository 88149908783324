import * as React from 'react';
import Common from '../../Util/Common';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import {
  DatePickerComponent,
  DateRangePickerComponent,
  RangeEventArgs,
} from '@syncfusion/ej2-react-calendars';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import DialogConfirm from '../Dialogbox/DialogConfirm';
import { createRef } from 'react';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';

interface Props {
  onSave: (response: any) => void;
  onCancel: () => void;
  leaveobj?: any;
  mainDive?: boolean;
  startdate?: any;
  enddate?: any;
  employeeID?: any;
  isFromHome?: any;
  disableddays?: any;
}

const { REACT_APP_ENDPOINT_LEAVESERVICE, REACT_APP_ENDPOINT_CORESERVICE } =
  process.env;

export default class LeaveApplication extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;
  private dateRangePickerRef = createRef<DateRangePickerComponent>();
  private disabledDates: any = [];
  private ltype: DropDownListComponent | any;
  private filteredleavetypes: any = [];
  toastObj: any;

  constructor(props: any) {
    super(props);
    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
    this.onSelect = this.onSelect.bind(this);
  }

  state = {
    leavetypes: [],
    employees: [],
    employeeid: undefined,
    lvtypobj: undefined,
    edata: undefined,
    isOpen: false,
    holidays: [],
    weekends: [],
  };

  componentDidMount(): void {
    if (this.state.employees.length <= 0) {
      this.fetchemployee();
    }

    if (this.state.leavetypes.length <= 0) {
      this.fetchleavetypes();
    }

    if (this.state.holidays.length <= 0) {
      this.fetchHoliday(new Date().getFullYear());
    }

    if (this.state.weekends.length <= 0) {
      this.fetchemployee();
    }

    setTimeout(() => {
      if (this.props.mainDive == true) {
        if (this.dateRangePickerRef.current) {
          this.dateRangePickerRef.current.show();
        }
      }
    }, 0);
  }

  private fetchHoliday(myear: number) {
    let startDate = Common.formatDate(new Date(myear, 0, 1), 'yyyy-MM-dd');
    let endDate = Common.formatDate(new Date(myear, 11, 31), 'yyyy-MM-dd');

    let payload: any = {
      fromDate: startDate,
      toDate: endDate,
    };
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/holiday/get`,
      payload,
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        this.setState({ holidays: response.data });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  private fetchleavetypes() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_LEAVESERVICE}/leavetype/getleavetypes`,
      {
        employeeId: this.user.isManager ? null : this.user.employee.id,
      },
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((resopnse: any) => {
        this.filteredleavetypes = resopnse.data;
        this.setState({ leavetypes: resopnse.data });

        if (this.state.employees.length > 0) {
          let empid = this.props.leaveobj
            ? this.props.leaveobj.employeeId
            : this.props.employeeID
            ? this.props.employeeID
            : this.state.employeeid;
          if (empid) {
            if (this.state.employees.filter((x: any) => x.id == empid)) {
              let genId = (
                this.state.employees.filter((x: any) => x.id == empid)[0] as any
              ).personGenderId;
              this.filteredleavetypes = this.state.leavetypes.filter(
                (x: any) =>
                  (x.genderId == null || x.genderId == genId) &&
                  x.isAccrued == true
              );
              this.ltype.dataSource = this.filteredleavetypes;
            }
          }
        }
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  private fetchemployee() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/employee/search${
        !this.user.isManager ? '/ess' : '/manager'
      }`,
      { id: this.user.isManager ? null : this.user.employee.id },
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        let empid: any;
        if (empid == undefined && this.props.employeeID !== undefined) {
          empid = this.props.employeeID;
        }
        if (empid == undefined && this.props.leaveobj !== undefined) {
          empid = this.props.leaveobj.employeeId;
        }
        if (empid == undefined && this.user.employee) {
          empid = this.user.employee.id;
        }

        let dayids: any;
        if (empid !== undefined) {
          dayids = response.data
            .filter((x: any) => x.id == empid)[0]
            .employeeShift[0].shift.shiftTran.filter(
              (s: any) => s.isWeekend === true
            );
        }

        if (this.user.employee) {
          this.setState({
            employees: response.data,
            employeeid: this.user.employee.id,
            weekends: dayids,
          });
        } else {
          this.setState({ employees: response.data, weekends: dayids });
        }
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  private itemTemplate(data: any): JSX.Element {
    return (
      <div style={{ display: 'flex' }}>
        <div
          id='color'
          style={{
            backgroundColor: data.color,
            width: '18px',
            height: '18px',
            borderRadius: '50%',
            marginLeft: '5px',
            marginTop: '9px',
          }}
        ></div>
        <div className='ename'>{data.name}</div>
      </div>
    );
  }

  private valueTemplate(data: any): JSX.Element {
    return (
      <div style={{ display: 'flex' }}>
        <div
          id='color'
          style={{
            backgroundColor: data.color,
            width: '18px',
            height: '18px',
            borderRadius: '50%',
            marginRight: '9px',
          }}
        ></div>
        <div className='ename'>{data.name}</div>
      </div>
    );
  }

  filterInvalidDates(iRange: any) {
    let lvtyp: any = undefined;

    lvtyp =
      this.state.lvtypobj !== undefined
        ? this.state.lvtypobj
        : this.props.leaveobj !== undefined
        ? this.props.leaveobj.leaveType
        : undefined;

    if (lvtyp && iRange) {
      if ((lvtyp as any).issueOnHoliday == false) {
        if (this.state.holidays.length > 0) {
          iRange.forEach((i: any) => {
            if (
              this.state.holidays.filter(
                (h: any) =>
                  new Date(h.date).getDate() == new Date(i).getDate() &&
                  new Date(h.date).getMonth() == new Date(i).getMonth()
              ).length > 0
            ) {
              this.disabledDates.push(i);
            }
          });
        }
      }

      if ((lvtyp as any).issueOnWeekend == false) {
        if (this.state.weekends.length > 0) {
          iRange.forEach((i: any) => {
            if (
              this.state.weekends.filter(
                (w: any) => w.dayid == new Date(i).getDay()
              ).length > 0
            ) {
              this.disabledDates.push(i);
            }
          });
        }
      }
    }
  }

  handleCancel = () => {
    this.props.onCancel();
  };
  ValidateForm = () => {
    const inputs = [
      document.querySelector<HTMLInputElement>('#leaveid'),
      document.querySelector<HTMLInputElement>('#employee'),
      document.querySelector<HTMLInputElement>('#dRange'),
    ];

    inputs.forEach((input) => {
      if (input) {
        input.classList.remove('textbox-border-error');

        if (input.value.trim() === '') {
          input.classList.add('textbox-border-error');
        } else {
          input.classList.add('textbox-class');
        }
      }
    });
  };

  onFocusFunction = (event: any) => {
    event.target.classList.remove('textbox-border-error');
  };
  SelectedEvent = (e: RangeEventArgs) => {
    if (e.endDate) {
      const selectedRange = e.text || '';
      if (this.dateRangePickerRef.current) {
        const inputElement = this.dateRangePickerRef.current
          .element as HTMLInputElement;
        inputElement.value = selectedRange;
      }
      const element = document.querySelector(
        '.e-daterangepicker.e-popup.e-control.e-lib.e-popup-open.e-keyboard'
      );
      if (element) {
        const htmlElement = element as HTMLElement;
        htmlElement.style.visibility = 'hidden';
      }
    }
  };
  handleSave = async () => {
    this.ValidateForm();

    var startDate = this.dateRangePickerRef.current?.startDate;
    var endDate = this.dateRangePickerRef.current?.endDate;
    try {
      if (
        document.getElementById('leaveid') &&
        (document.getElementById('leaveid') as any).ej2_instances[0].value ==
          null
      ) {
        // DialogConfirm.showDialog({
        //   content:
        //     '<p class="dialog-contain">Sorry, Leave Type is mandatory information.Please select leave type to continue.</p>',
        // });
        const TargetInput =
          document.querySelector<HTMLInputElement>('#leaveid');
        if (TargetInput) {
          TargetInput.style.borderBottom = '2px solid red';
        }
        return;
      }

      if (
        document.getElementById('employee') &&
        (document.getElementById('employee') as any).ej2_instances[0].value ==
          null
      ) {
        const TargetInput =
          document.querySelector<HTMLInputElement>('#employee');
        if (TargetInput) {
          TargetInput.style.borderBottom = '2px solid red';
        }
        // DialogConfirm.showDialog({
        //   content:
        //     '<p class="dialog-contain">Sorry, Employee is mandatory information. Please select employee to continue.</p>',
        // });
        return;
      }
      if (
        document.getElementById('dRange') &&
        (document.getElementById('dRange') as any).ej2_instances[0].value ==
          null
      ) {
        // DialogConfirm.showDialog({
        //   content:
        //     '<p class="dialog-contain">Sorry, From Date is mandatory information. Please set from date to continue.</p>',
        // });
        const TargetInput = document.querySelector<HTMLInputElement>('#dRange');
        if (TargetInput) {
          TargetInput.style.borderBottom = '2px solid red';
        }
        return;
      }
      // if (document.getElementById('startDate') && (document.getElementById('startDate') as any).ej2_instances[0].value == null) {
      //   DialogConfirm.showDialog({
      //     content:
      //       '<p class="dialog-contain">Sorry, From Date is mandatory information. Please set from date to continue.</p>',
      //   });
      //   return;
      // }

      // if (document.getElementById('endDate') && (document.getElementById('endDate') as any).ej2_instances[0].value == null) {
      //   DialogConfirm.showDialog({
      //     content:
      //       '<p class="dialog-contain">Sorry, To Date is mandatory information. Please set to date to continue.</p>',
      //   });
      //   return;
      // }

      // if ((document.getElementById('endDate') as any).ej2_instances[0].value < (document.getElementById('startDate') as any).ej2_instances[0].value) {
      //   DialogConfirm.showDialog({
      //     content:
      //       '<p class="dialog-contain">Sorry, To Date cannot be less than the From Date. Please set correct dates to continue.</p>',
      //   });
      //   return;
      // }

      let dateRange: any = [];
      let sRange = Common.getDatesInRange(startDate, endDate);
      // let sRange = Common.getDatesInRange(
      //   this.dRange.value[0],
      //   this.dRange.value[1]
      // );
      if (startDate === null && endDate == null) {
        sRange = [];
      }

      this.filterInvalidDates(sRange);

      let uniqueElements: any = [];
      if (this.disabledDates !== undefined) {
        for (let i = 0; i < sRange.length; i++) {
          let sDate = sRange[i];
          if (
            this.disabledDates.filter(
              (d: any) =>
                new Date(d).getDate() == new Date(sDate).getDate() &&
                new Date(d).getMonth() == new Date(sDate).getMonth()
            ).length <= 0
          ) {
            uniqueElements.push(sDate);
          }
        }
        sRange = uniqueElements;
      }

      if (sRange.length <= 0) {
        if (this.dateRangePickerRef) {
          (this.dateRangePickerRef.current as any).startDate = null;
          (this.dateRangePickerRef.current as any).endDate = null;
        }
        // DialogConfirm.showDialog({
        //   content:
        //     '<p class="dialog-contain">Sorry, Leave duration is mandatory information. Please select leave duration to continue.</p>',
        // });
        this.toastObj = ToastUtility.show({
          cssClass: 'e-toast-warning',
          icon: 'e-warning toast-icons',
          showCloseButton: true,
          content:
            'Sorry, Leave duration is mandatory information. Please select leave duration to continue.',
          target: '#basic_card',
          position: { X: 'Center', Y: 'Top' },
          width: 'auto',
          timeOut: 3000,
        });
        return;
      }

      for (let i = 0; i < sRange.length; i++) {
        dateRange.push({
          appliedDate: Common.formatDate(
            Common.RemoveTimezoneOffsetFromDate(new Date(sRange[i])),
            'yyyy-MM-dd'
          ),
        });
      }

      if (
        (document.getElementById('leaveid') as any).ej2_instances[0].itemData
          .isAccrued == true
      ) {
        if (
          parseFloat((document.getElementById('lvbalance') as any).value) <= 0
        ) {
          // DialogConfirm.showDialog({
          //   content:
          //     '<p class="dialog-contain">Sorry, Selected leave type does not have any balance set for the selected employee.</p>',
          // });
          this.toastObj = ToastUtility.show({
            cssClass: 'e-toast-warning',
            icon: 'e-warning toast-icons',
            showCloseButton: true,
            content:
              'Sorry, Selected leave type does not have any balance set for the selected employee.',
            target: '#basic_card',
            position: { X: 'Center', Y: 'Top' },
            width: 'auto',
            timeOut: 3000,
          });
          return;
        }

        if (
          parseFloat((document.getElementById('lvbalance') as any).value) <
          dateRange.length
        ) {
          let avail: string = (document.getElementById('lvbalance') as any)
            .value;
          let reqst: string = dateRange.length;
          // DialogConfirm.showDialog({
          //   content:
          //     '<div clas="new" style="display: flex;flex-direction: column;"><p>Leave request exceeds available balance.</p><p>Available:' +
          //     `${avail}` +
          //     ' day(s). </p><p>Requested: ' +
          //     `${reqst}` +
          //     ' day(s).</p></div >',
          // });
          this.toastObj = ToastUtility.show({
            cssClass: 'e-toast-success',
            icon: 'far fa-circle-check',
            showCloseButton: true,
            content:
              '<div clas="new" style="display: flex;flex-direction: column;"><p>Leave request exceeds available balance.</p><p>Available:' +
              `${avail}` +
              ' day(s). </p><p>Requested: ' +
              `${reqst}` +
              ' day(s).</p></div >',
            target: '#basic_card',
            position: { X: 'Center', Y: 'Top' },
            width: 'auto',
            timeOut: 3000,
          });
          return;
        }
      }

      const formattedStartDate = startDate
        ? Common.formatDate(
            Common.RemoveTimezoneOffsetFromDate(startDate),
            'yyyy-MM-dd'
          )
        : 'No start date';

      const formattedEndDate = endDate
        ? Common.formatDate(
            Common.RemoveTimezoneOffsetFromDate(endDate),
            'yyyy-MM-dd'
          )
        : 'No end date';

      let payload: any = {
        id: this.props.leaveobj ? this.props.leaveobj.id : null,
        employeeId: (document.getElementById('employee') as any)
          .ej2_instances[0].value,
        leaveTypesId: (document.getElementById('leaveid') as any)
          .ej2_instances[0].value,
        applyDate: Common.formatDate(
          Common.RemoveTimezoneOffsetFromDate(new Date()),
          'yyyy-MM-dd'
        ),
        startDate: dateRange[0].appliedDate,
        endDate: dateRange[dateRange.length - 1].appliedDate,
        statusId: this._structure.LeaveApplicationStatus.Pending.Id,
        leaveAppliedDays: dateRange,
        appliedDays: dateRange.length,
        remark:
          (document.getElementById('lvremark') as any).ej2_instances[0].value ==
          null
            ? ''
            : (document.getElementById('lvremark') as any).ej2_instances[0]
                .value,
      };

      const response = await this.saveData(payload);
      this.props.onSave(response);
    } catch (error) {
      this.setState({ error: 'Error saving data. Please try again.' });
    }
  };

  handleChange(e: any) {
    let lvtypeid: any = null;
    let empid: any = null;

    if (e.target.id == 'employee') {
      if (e.target.itemData) {
        this.filteredleavetypes = this.state.leavetypes.filter(
          (x: any) =>
            (x.genderId == null ||
              x.genderId == e.target.itemData.personGenderId) &&
            x.isAccrued == true
        );
        this.ltype.dataSource = this.filteredleavetypes;
      }
    }

    if (e.target.id == 'leaveid') {
      if (e.target.itemData) {
        this.setState({ lvtypobj: e.target.itemData });
      }
    }

    if (
      document.getElementById('leaveid') &&
      (document.getElementById('leaveid') as any).ej2_instances[0].value !==
        null
    ) {
      lvtypeid = (document.getElementById('leaveid') as any).ej2_instances[0]
        .value;
    }
    if (
      document.getElementById('employee') &&
      (document.getElementById('employee') as any).ej2_instances[0].value !==
        null
    ) {
      empid = (document.getElementById('employee') as any).ej2_instances[0]
        .value;
    }
    if (lvtypeid !== null && empid !== null) {
      let ldata: any = this.state.leavetypes.filter(
        (x: any) => x.id == lvtypeid
      )[0];
      if (ldata && ldata.isAccrued == true) {
        let balances = ldata.leaveBalances.filter(
          (x: any) => x.leaveTypeId == lvtypeid && x.employeeId == empid
        );
        if (balances !== null && balances.length > 0) {
          document.getElementById('divlvbalance')?.classList.remove('d-none');
          (document.getElementById('lvbalance') as any).value = Number(
            balances[0].availableToDate
          ).toFixed(2);
        } else {
          (document.getElementById('lvbalance') as any).value = '0';
        }
      } else {
        document.getElementById('divlvbalance')?.classList.add('d-none');
        (document.getElementById('lvbalance') as any).value = '0';
      }
    }

    this.onDateChange();
  }

  saveData = async (data: any) => {
    const response = await Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_LEAVESERVICE}/leaveapplication/add${
        !this.user.isManager ? '/ess' : ''
      }`,
      data,
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        console.error(error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.messages &&
          error.response.data.messages.length > 0
        ) {
          // DialogConfirm.showDialog({
          //   content: `<p class="dialog-contain">${error.response.data.messages.join()}</p>`,
          // });
        }
      })
      .finally(() => {});

    return response;

    // // Replace with your API endpoint
    // const response = await fetch('/api/saveLeaveData', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify(data)
    // });
    // if (!response.ok) {
    //   throw new Error('Network response was not ok');
    // }
    // return response.json();
  };

  onDateChange = () => {
    var startDate = this.dateRangePickerRef.current?.startDate;
    var endDate = this.dateRangePickerRef.current?.endDate;
    if (
      this.dateRangePickerRef &&
      this.dateRangePickerRef.current !== null &&
      startDate !== null &&
      endDate !== null
    ) {
      let sRange = Common.getDatesInRange(startDate, endDate);
      (document.getElementById('lvRequested') as any).value = sRange.length;
    }
    if (
      this.dateRangePickerRef &&
      this.dateRangePickerRef.current?.value !== null
    ) {
      let sRange: any = Common.getDatesInRange(startDate, endDate);
      // this.disabledDates = this.dateRangePickerRef.current!['disabledDays'];

      this.filterInvalidDates(sRange);

      let uniqueElements: any = [];
      if (this.disabledDates !== undefined) {
        for (let i = 0; i < sRange.length; i++) {
          let sDate = sRange[i];
          if (
            this.disabledDates.filter(
              (d: any) =>
                new Date(d).getDate() == new Date(sDate).getDate() &&
                new Date(d).getMonth() == new Date(sDate).getMonth()
            ).length <= 0
          ) {
            uniqueElements.push(sDate);
          }
        }

        sRange = uniqueElements;
      }

      if (sRange.length <= 0) {
        if (this.dateRangePickerRef) {
          (this.dateRangePickerRef.current as any).startDate = null;
          (this.dateRangePickerRef.current as any).endDate = null;
        }
      }

      (document.getElementById('lvRequested') as any).value = sRange.length;
    }

    // if ((document.getElementById('startDate') && (document.getElementById('startDate') as any).ej2_instances[0].value !== null) &&
    //   (document.getElementById('endDate') && (document.getElementById('endDate') as any).ej2_instances[0].value !== null)) {

    //   let sRange = Common.getDatesInRange((document.getElementById('startDate') as any).ej2_instances[0].value, (document.getElementById('endDate') as any).ej2_instances[0].value);
    //   (document.getElementById('lvRequested') as any).value = sRange.length;
    // }
  };

  // Handle the Select Event of DateRangePicker Component
  onSelect(args: any) {
    if (args.endDate) {
      args.element.value = args.text;
      // this.startDate = args.startDate.toLocaleDateString();
      // this.endDate = args.endDate.toLocaleDateString();
      const selectedRange = `${args.startDate.toLocaleDateString()} - ${args.endDate.toLocaleDateString()}`;
      console.log('Selected Range is : ', selectedRange);
    }
  }

  // Handle Open and Close by Time DateRangePicker Component
  handleOpen = () => {
    // console.log('open', this.state.isOpen);
    // this.setState({ isOpen: true });
    // setTimeout(() => {
    //   this.setState({ isOpen: true }, () => {
    //     const formElements = document.getElementsByClassName(
    //       'e-date-range-container'
    //     );
    //     Array.from(formElements).forEach((element: Element) => {
    //       (element as HTMLElement).style.display = 'none';
    //     });
    //   });
    // }, 3000);
  };

  disabledDate = (e: any) => {
    if (e) {
      if (this.state.holidays.length > 0) {
        if (
          this.state.holidays.filter(
            (h: any) => new Date(h.date).getTime() == e.date.getTime()
          ).length > 0
        ) {
          e.isDisabled = true;
        }
      }
      if (this.state.weekends.length > 0) {
        if (
          this.state.weekends.filter((w: any) => w.dayid == e.date.getDay())
            .length > 0
        ) {
          e.isDisabled = true;
        }
      }
    }
  };

  render() {
    let rDays = 0;
    if (
      this.props.startdate !== undefined &&
      this.props.enddate !== undefined
    ) {
      let sRange = Common.getDatesInRange(
        this.props.startdate,
        this.props.enddate
      );

      if (this.props.disableddays) {
        let uniqueElements: any = [];
        if (this.disabledDates !== undefined) {
          for (let i = 0; i < sRange.length; i++) {
            let sDate = sRange[i];
            if (
              this.props.disableddays.filter(
                (d: any) =>
                  new Date(d).getDate() == new Date(sDate).getDate() &&
                  new Date(d).getMonth() == new Date(sDate).getMonth()
              ).length <= 0
            ) {
              uniqueElements.push(sDate);
            }
          }
        }
        sRange = uniqueElements;
      }

      rDays = sRange.length;
    }

    let balance = 0;
    if (this.props.leaveobj !== undefined) {
      rDays = this.props.leaveobj.appliedDays;
      if (
        this.props.leaveobj.leaveType.isAccrued == true &&
        this.state.leavetypes.length > 0
      ) {
        let ldata: any = this.state.leavetypes.filter(
          (x: any) => x.id == this.props.leaveobj.leaveTypesId
        );
        if (ldata !== null && ldata.length > 0) {
          if (
            ldata[0].leaveBalances != null &&
            ldata[0].leaveBalances.length > 0
          ) {
            let balances = ldata[0].leaveBalances.filter(
              (x: any) => x.employeeId == this.props.leaveobj.employeeId
            );
            if (balances !== null && balances.length > 0) {
              balance = balances[0].availableToDate;
            } else {
              balance = 0;
            }
          }
        }
      }
    }

    return (
      <>
        {1 > 0 ? (
          <>
            <div className='row align-items-center'>
              {/* <div className='col-4 py-2'>
                <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                  Application Number
                </label>
                <input
                  className='input-text-border'
                  id='lvformnumber'
                  name='lvformnumber'
                  type='text'
                  autoComplete='off'
                  style={{ fontSize: '15px' }}
                  value={this.props.leaveobj ? this.props.leaveobj.applicationNumber : ''}
                  disabled
                />
              </div> */}
              <div className='col-5 py-2'>
                {/* <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                  Employee
                </label>
                <span className='mandatoryinfo'>*</span> */}
                <DropDownListComponent
                  id='employee'
                  className='textbox-class'
                  // onFocus={this.onFocusFunction}
                  dataSource={this.state.employees}
                  filterBarPlaceholder='Search a Employee'
                  allowFiltering={true}
                  filterType='Contains'
                  fields={{ value: 'id', text: 'personName' }}
                  placeholder='Select Employee *'
                  floatLabelType='Auto'
                  popupHeight='220px'
                  value={
                    this.props.leaveobj
                      ? this.props.leaveobj.employeeId
                      : this.props.employeeID
                      ? this.props.employeeID
                      : this.state.employeeid
                  }
                  enabled={
                    this.user.isManager && this.props.leaveobj == undefined
                      ? this.props.isFromHome
                        ? false
                        : true
                      : false
                  }
                  onChange={this.handleChange.bind(this)}
                  // onChange={this.handleChange.bind(this)}
                />
              </div>
              <div className='col-5 py-2'>
                {/* <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                  Leave Type
                </label>
                <span className='mandatoryinfo'>*</span> */}
                <DropDownListComponent
                  id='leaveid'
                  className='textbox-class'
                  // onFocus={this.onFocusFunction}
                  dataSource={this.filteredleavetypes}
                  ref={(s) => (this.ltype = s)}
                  filterBarPlaceholder='Search a Leave'
                  allowFiltering={true}
                  filterType='Contains'
                  fields={{ value: 'id', text: 'name' }}
                  placeholder='Select Leave *'
                  floatLabelType='Auto'
                  popupHeight='220px'
                  value={
                    this.props.leaveobj == undefined
                      ? undefined
                      : this.props.leaveobj.leaveTypesId
                  }
                  // itemTemplate={this.itemTemplate}
                  valueTemplate={this.valueTemplate}
                  // text={
                  //   this.props.leaveobj == undefined
                  //     ? undefined
                  //     : this.props.leaveobj.leaveType.name
                  // }

                  onChange={this.handleChange.bind(this)}
                />
              </div>
              <div
                className={
                  this.props.leaveobj == undefined
                    ? 'col-2 py-2 d-none'
                    : this.props.leaveobj.leaveType.isAccrued == true
                    ? 'col-2 py-2'
                    : 'col-2 py-2 d-none'
                }
                id='divlvbalance'
              >
                <label
                  style={{
                    fontSize: '14px',
                    paddingBottom: '7px',
                    fontWeight: '500',
                  }}
                >
                  Available
                </label>
                <div style={{ display: 'flex' }}>
                  <input
                    id='lvbalance'
                    name='lvbalance'
                    type='text'
                    autoComplete='off'
                    style={{
                      fontSize: '14px',
                      textAlign: 'left',
                      border: '0px',
                      width: '50%',
                    }}
                    readOnly
                    value={balance}
                  />
                  {/* <label style={{ fontSize: '14px', fontWeight: '500' }}>
                    Days
                  </label> */}
                </div>
              </div>
            </div>
            <div className='row align-items-center'>
              {/* <div className='col-2 py-2 d-none' id='divlvbalance'>
                <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                  Balance
                </label>
                <input
                  className='input-text-border'
                  id='lvbalance'
                  name='lvbalance'
                  type='text'
                  autoComplete='off'
                  style={{ fontSize: '15px', textAlign: 'right' }}
                  readOnly
                  value={''}
                /> 
            </div>*/}
              {/* <div className='col-3 py-2'>
                <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                  Apply Date
                </label>
                <DatePickerComponent
                  id='applydate'
                  format={this.user.settings.formats.DateShort}
                  value={this.props.leaveobj == undefined ? new Date() : new Date(this.props.leaveobj.applyDate)}
                  showClearButton={false}
                  cssClass='e-hidedate-icon'
                  openOnFocus={true}
                  showTodayButton={false}
                />
              </div> */}
              <div className='col-10 py-2'>
                {/* <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                  Leave Duration
                </label>
                <span className='mandatoryinfo'>*</span> */}
                <DateRangePickerComponent
                  id='dRange'
                  className='textbox-class'
                  onFocus={this.onFocusFunction}
                  // cssClass='e-hidedate-icon'
                  format={this.user.settings.formats.DateShort}
                  placeholder='Select Leave Duration *'
                  floatLabelType='Auto'
                  showTodayButton={false}
                  showClearButton={true}
                  allowEdit={false}
                  openOnFocus={true}
                  // renderDayCell={this.disabledDate}
                  startDate={
                    this.props.leaveobj == undefined
                      ? this.props.startdate == undefined
                        ? undefined
                        : new Date(this.props.startdate)
                      : new Date(this.props.leaveobj.startDate)
                  }
                  endDate={
                    this.props.leaveobj == undefined
                      ? this.props.enddate == undefined
                        ? undefined
                        : new Date(this.props.enddate)
                      : new Date(this.props.leaveobj.endDate)
                  }
                  onChange={this.onDateChange}
                  onBlur={this.onDateChange}
                  ref={this.dateRangePickerRef}
                  select={this.SelectedEvent}
                />
              </div>
              <div className='col-2 py-2' id='divRequested'>
                <label
                  style={{
                    fontSize: '14px',
                    paddingBottom: '7px',
                    fontWeight: '500',
                  }}
                >
                  Requested
                </label>
                <div style={{ display: 'flex' }}>
                  <input
                    id='lvRequested'
                    name='lvRequested'
                    type='text'
                    autoComplete='off'
                    style={{
                      fontSize: '14px',
                      textAlign: 'left',
                      border: '0px',
                      width: '50%',
                    }}
                    readOnly
                    value={rDays}
                  />
                  {/* <label style={{ fontSize: '14px', fontWeight: '500' }}> Days</label> */}
                </div>
              </div>
            </div>
            <div className='row align-items-center'>
              <div className='col-12 py-2'>
                {/* <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                  Remark (optional)
                </label> */}
                <TextBoxComponent
                  id='lvremark'
                  placeholder='Enter Remark [ optional ]'
                  floatLabelType='Auto'
                  multiline={true}
                  value={
                    this.props.leaveobj
                      ? (this.props.leaveobj as any)!.remark
                      : ''
                  }
                ></TextBoxComponent>
              </div>
            </div>
            <div
              className='row'
              style={{
                textAlign: 'right',
                display: 'flex',
                float: 'right',
                paddingTop: '10px',
              }}
            >
              <div>
                <button
                  id='btnIAccept'
                  className='flat-button'
                  onClick={this.handleSave.bind(this)}
                >
                  Submit
                </button>
                <button
                  id='btnICancel'
                  className='flat-button'
                  onClick={this.handleCancel.bind(this)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </>
        ) : null}
      </>
    );
  }
}
