import React from 'react';
import './TeamMember.css';
import Common from '../../../Util/Common';
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  InfiniteScroll,
  Inject,
  Page,
} from '@syncfusion/ej2-react-grids';

const { REACT_APP_ENDPOINT_CORESERVICE } = process.env;

interface Props {
  UpdateCount?: any;
}

export default class TeamMember extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
  }

  state = { jsonData: undefined, showViewAll: false };

  componentDidMount() {
    if (!this.state.jsonData) {
      this.GetList();
    }
  }

  private GetList() {
    let payload: any = {
      showOnlyActive: true,
      showLatestContractOnly: true,
      showDossier: false,
      showPersonPhoto: true,
      departmentIds: [
        this.user.employee ? this.user.employee.contract[0].departmentId : null,
      ],
    };

    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/employee/search/myteam`,
      payload,
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        let fltr: any = response.data.filter(
          (x: any) => x.id !== this.user.employee.id
        );
        let viewall: boolean = fltr.length > 4;

        this.setState({
          jsonData: fltr,
          showViewAll: viewall,
        });
        //if (viewall) {

        document
          .querySelector('.divteammemberlist .teammemberlist-grid')
          ?.classList.add('overflowhidden');
        //}
        this.props.UpdateCount(fltr.length);
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  render() {
    const updateEmployeeDetailsClass = (element: any) => {
      const jobLabel = element.querySelector('.employee-job');
      const detailsContainer = element.querySelector('.employee-details');

      if (jobLabel && jobLabel.textContent.trim() === '') {
        detailsContainer.classList.add('no-job');
      } else {
        detailsContainer.classList.remove('no-job');
      }
    };

    // Example usage
    const employeeElements = document.querySelectorAll('.empinfo');
    employeeElements.forEach(updateEmployeeDetailsClass);
    const columnTemplate = (e: any) => {
      return (
        <>
          <div
            className='d-flex employeeid'
            style={{ flexDirection: 'column' }}
            data-id={e.id}
          >
            {/* <div className='d-flex empinfo'>
              <div className='employee-photo e-avatar e-avatar-circle e-avatar-large'>
                {e.personPhoto ? (
                  <img
                    src={
                      e.personPhoto
                        ? `data:image/png;base64,${e.personPhoto}`
                        : ''
                    }
                    alt='profile_pic'
                  />
                ) : (
                  e.firstName[0].toUpperCase() + e.lastName[0].toUpperCase()
                )}
              </div>
              <label className='employee-name' title='Name'>
                {e.personName}
              </label>
              <label
                className='employee-job'
                style={{ fontSize: '13px', color: '#00000085' }}
                title='Job'
              >
                {e.currentJobName}
              </label>
            </div> */}
            <div className='d-flex empinfo'>
              <div className='employee-photo e-avatar e-avatar-circle e-avatar-large'>
                {e.personPhoto ? (
                  <img
                    src={`data:image/png;base64,${e.personPhoto}`}
                    alt='profile_pic'
                  />
                ) : (
                  e.firstName[0].toUpperCase() + e.lastName[0].toUpperCase()
                )}
              </div>
              <div className='employee-details'>
                <label className='employee-name' title='Name'>
                  {e.personName}
                </label>
                {e.currentJobName && (
                  <label
                    className='employee-job'
                    style={{ fontSize: '13px', color: '#00000085' }}
                    title='Job'
                  >
                    {e.currentJobName}
                  </label>
                )}
              </div>
            </div>
          </div>
        </>
      );
    };

    const onViewAllClick = (e: any) => {
      //this.setState({ showViewAll: false });
      if (this.state.showViewAll) {
        document
          .querySelector('.divteammemberlist .teammemberlist-grid')
          ?.classList.remove('overflowhidden');
      } else {
        document.querySelector(
          '.divteammemberlist .teammemberlist-grid .e-content'
        )!.scrollTop = 0;
        document
          .querySelector('.divteammemberlist .teammemberlist-grid')
          ?.classList.add('overflowhidden');
      }
      this.setState({ showViewAll: !this.state.showViewAll });
    };
    return (
      <div className='control-pane divteammemberlist expanded'>
        <div className='control-section'>
          <div className='row1'>
            <div className='col-md-122 ps-22 position-relative'>
              {this.state.jsonData && (
                <GridComponent
                  id='teammemberlist-grid'
                  className='teammemberlist-grid'
                  dataSource={this.state.jsonData}
                  //height={this.state.showViewAll ? '272px' : '310px'}
                  height={'308px'}
                  enableInfiniteScrolling={true}
                  pageSettings={{ pageSize: 5 }}
                  gridLines={'None'}
                >
                  <ColumnsDirective>
                    <ColumnDirective
                      headerText='Member'
                      field='personName'
                      template={columnTemplate}
                    />
                  </ColumnsDirective>
                  <Inject services={[InfiniteScroll, Page]} />
                </GridComponent>
              )}

              {this.state.jsonData &&
                (this.state.jsonData as any).length > 4 && (
                  <div className='teammember-viewall' onClick={onViewAllClick}>
                    See {this.state.showViewAll ? 'more' : 'less'}
                    <i
                      style={{ margin: '2px 10px', display: 'inline-block' }}
                      className={`ps-1 fa-solid fa-chevron-${
                        this.state.showViewAll ? 'down' : 'up'
                      }`}
                    ></i>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
