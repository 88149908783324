import React from 'react';
import './RightPanel.css';
import Common from '../../../Util/Common';
import {
  DashboardLayoutComponent,
  PanelDirective,
  PanelModel,
  PanelsDirective,
} from '@syncfusion/ej2-react-layouts';
import Reminder from './Reminder';
import TeamMember from './TeamMember';
import UpcomingEvent from './UpcomingEvent';
import { cssClass } from '@syncfusion/ej2-react-lists';

interface Props {
  rootmenu: any;
  selectedmenu: any;
}

export default class RightPanel extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
  }

  state = {};

  componentDidMount() {}
  toggleCard(cardId: any, iconClass: any) {
    const card = document.getElementById(cardId);
    const icon = document.querySelector(`.${iconClass}`);

    if (card) {
      if (card.classList.contains('expanded')) {
        card.style.height = `${card.scrollHeight}px`;
        setTimeout(() => {
          card.style.height = '0px';
        }, 0);
        icon?.classList.replace('fa-angle-up', 'fa-angle-down');
      } else {
        card.style.height = '0px';
        setTimeout(() => {
          card.style.height = `${card.scrollHeight}px`;
        }, 0);
        icon?.classList.replace('fa-angle-down', 'fa-angle-up');
      }
      card.classList.toggle('expanded');
    }
  }

  render() {
    const UpdateCount = (count: any) => {
      (document.getElementById('memberCount') as any).innerText = count;
    };

    let panels: object[] = [
      {
        id: 'reminders',
        sizeX: 1,
        sizeY: 1,
        row: 0,
        col: 0,
        header:
          '<div class = "fadeUp"><i class="pe-2 fa-regular fa-bells" style="color: rgba(0, 0, 0, 0.54);"></i><span>Reminders</span></div>',
        content: () => <Reminder />,
      },
      {
        id: 'upcomingevents',
        sizeX: 1,
        sizeY: 1,
        row: 1,
        col: 0,
        cssClass: 'stickydivprev e-custom-card',
        header: `<div class="fadeUp upcoming-event-header"><div><i class= "px-2 fa-regular fa-users" style="font-size:16px; color: rgba(0, 0, 0, 0.54);" ></i><span>UpComing Events</span></div></div>`,
        content: () => <UpcomingEvent />,
      },
      {
        id: 'teammembers',
        sizeX: 1,
        sizeY: 1,
        row: 2,
        col: 0,
        cssClass: 'stickydiv',
        header: `<div class="fadeUp" id= "team-member">Team Members <span class="e-badge e-badge-primary spancount" style ="color: #fefefe; background-color: #FC4C02"> <label id = "memberCount"></label> </span> <i class="ps-2 fa-regular fa-users" style="color: rgba(0, 0, 0, 0.54);"></i></div>`,
        content: () => <TeamMember UpdateCount={UpdateCount} />,
      },
    ];

    return (
      <>
        <div id='dashboardrightpanel' className='control-pane'>
          {/* <DashboardLayoutComponent
            id='rightpanel_dashboard'
            columns={1}
            cellAspectRatio={1.1}
            panels={panels}
            cellSpacing={[20, 20]}
            allowDragging={false}
          ></DashboardLayoutComponent> */}
          <div className='rightpanel-card fadeUp'>
            <div className='rightpanel-card-header'>
              <div>
                <i
                  className='pe-2 fa-regular fa-bells'
                  style={{ color: 'rgba(0, 0, 0, 0.54)' }}
                ></i>
                <span>Reminders</span>
                </div>
              <i
                className='fa-regular fa-angle-up show-down-arrow3'
                onClick={() =>
                  this.toggleCard('accordion-reminder', 'show-down-arrow3')
                }
                style={{ cursor: 'pointer' }}
              ></i>
            </div>
            <div id='accordion-reminder' className='expanded'>
              <div className='rightpanel-card-content'>
                <Reminder />
              </div>
            </div>
          </div>

          <div className='rightpanel-card fadeUp'>
            <div className='rightpanel-card-header'>
              <div>
                <i
                  className='pe-2 fa-regular  fa-user-plus'
                  style={{ color: 'rgba(0, 0, 0, 0.54)' }}
                ></i>
                <span>UpComing Events</span>
              </div>
              <i
                className='fa-regular fa-angle-up show-down-arrow4'
                onClick={() =>
                  this.toggleCard('divupcomingeventlist', 'show-down-arrow4')
                }
                style={{ cursor: 'pointer' }}
              ></i>
            </div>
            <div id='divupcomingeventlist' className='expanded'>
              <div className='rightpanel-card-content'>
                <UpcomingEvent />
              </div>
            </div>
          </div>

          <div className='rightpanel-card fadeUp'>
            <div className='rightpanel-card-header'>
              <div>
                <i
                  className='pe-2 fa-regular fa-users-medical'
                  style={{ color: 'rgba(0, 0, 0, 0.54)' }}
                ></i>
                <span>Team Members</span>
                <label id='memberCount'></label>
              </div>
              <i
                className='fa-regular fa-angle-up show-down-arrow5'
                onClick={() =>
                  this.toggleCard('divteammemberlist', 'show-down-arrow5')
                }
                style={{ cursor: 'pointer' }}
              ></i>
              </div>
            <div id='divteammemberlist' className='expanded overflowhidden'>
              <div className='rightpanel-card-content'>
                <TeamMember UpdateCount={UpdateCount} />
                </div>
              </div>
            </div>
        </div>
      </>
    );
  }
}
