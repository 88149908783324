import * as React from 'react';
import Common from '../../Util/Common';
import {
  ColorPickerComponent,
  NumericTextBoxComponent,
  TextBoxComponent,
} from '@syncfusion/ej2-react-inputs';
import {
  CheckBoxComponent,
  RadioButtonComponent,
} from '@syncfusion/ej2-react-buttons';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';

const { REACT_APP_ENDPOINT_LEAVESERVICE } = process.env;

const parser = require('xml2js').Parser({
  explicitRoot: false,
  explicitArray: false,
});

interface leavetypemodel {
  isAdd?: boolean;
  code?: string;
  name?: string;
  color?: string;
  deductFromLeaveId?: string;
  genderId?: string;
  isAccrued?: boolean;
  isEndDateOptional?: boolean;
  isExcuseLeave?: boolean;
  isPaid?: boolean;
  isShortLeave?: boolean;
  issueOnHoliday?: boolean;
  leavetypes?: any;
  minDays?: number;
  maxDays?: number;
}

export default class LeaveType extends React.Component<{}, {}> {
  private user: any;
  private _structure: any;
  private token: any = null;

  constructor(props: any) {
    super(props);
    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
  }

  state = {
    leavetypes: [],
  };

  onChange(args: any): void {
    let key: string = args.target.name;
    let value: string = args.target.value;
    this.setState({ [key]: value });
  }

  onCheckboxChange(args: any): void {
    let ctrl: HTMLElement = args.event.target.parentElement;
    let ctrlid: any = ctrl.getElementsByTagName('input');
    if (!ctrlid) return;

    let chk: any = (document.getElementById(ctrlid[0].id) as any)
      .ej2_instances[0];

    let key: string = chk.id;
    let value: string = chk.checked;
    (
      document.getElementById(
        'ck_' + ctrlid[0].id.toLowerCase()
      ) as HTMLInputElement
    ).value = value;
  }

  onradiochanged(e: any) {
    if (e.target.id.toLowerCase() === 'radfulldays') {
      (document.getElementById('unitid') as HTMLInputElement).value =
        this._structure.Unit.FullDay.id;
    } else if (e.target.id.toLowerCase() === 'radflexhours') {
      (document.getElementById('unitid') as HTMLInputElement).value =
        this._structure.Unit.FlexHours.id;
    }
  }

  componentDidMount(): void {
    let data: leavetypemodel = this.props;
    // if (data.leavetypes !== undefined && data.leavetypes.length > 0) {
    //   this.setState({ leavetypes: data.leavetypes });
    // }
    // else {
    //   if (this.state.leavetypes.length <= 0) {
    //     this.fetchleavetypes()
    //   }
    // }
  }

  private fetchleavetypes() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_LEAVESERVICE}/leavetype/getleavetypes`,
      {},
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((resopnse: any) => {
        this.setState({ leavetypes: resopnse.data });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }
  onFocusFunction = (event: any) => {
    event.target.classList.remove('textbox-border-error');
  };
  render() {
    let data: leavetypemodel = this.props;

    return (
      <>
        <div
          className='row align-items-center'
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        >
          <div className='col-4 py-2'>
            {/* <label style={{ paddingBottom: '7px' }}>Code</label>
            <span className='mandatoryinfo'>*</span> */}
            <TextBoxComponent
              className='textbox-class'
              onFocus={this.onFocusFunction}
              id='txtCode'
              name='txtCode'
              type='text'
              placeholder='Enter Code *'
              floatLabelType='Auto'
              autocomplete='off'
              // required={true}
              value={data.code!}
              onChange={this.onChange.bind(this)}
            />
          </div>
          <div className='col-7 py-2' style={{ marginLeft: 'auto' }}>
            {/* <label style={{ paddingBottom: '7px' }}>Name</label>
            <span className='mandatoryinfo'>*</span> */}
            <TextBoxComponent
              className='textbox-class'
              onFocus={this.onFocusFunction}
              id='txtName'
              name='txtName'
              type='text'
              placeholder='Enter Name *'
              floatLabelType='Auto'
              autocomplete='off'
              // required={true}
              value={data.name!}
              onChange={this.onChange.bind(this)}
            />
          </div>
        </div>
        {/* <div className='row align-items-center'>
          <div className='col-3 py-2'>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              Pick Color{' '}
            </label>
            <ColorPickerComponent id='colorpicker' name='colorpicker' className='ck_color' value={data.color!} onChange={this.onCheckboxChange.bind(this)} />
            <input
              id='ck_color'
              name='ck_color'
              className='ck_color'
              type='hidden'
            />
          </div>
          <div className='col-6 py-2'>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              Deduct From Leave
            </label>
            <DropDownListComponent
              id='leaveid'
              dataSource={this.state.leavetypes}
              filterBarPlaceholder='Search a Leave'
              allowFiltering={true}
              filterType='Contains'
              fields={{ value: 'id', text: 'name' }}
              placeholder='Select Leave'
              popupHeight='220px'
              style={{ fontSize: '15px' }}
              onChange={this.onChange.bind(this)}
              value={data.deductFromLeaveId!}
            ></DropDownListComponent>
          </div>
          <div className='col-3 py-2'>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              Gender
            </label>
            <DropDownListComponent
              id='genderid'
              dataSource={this.user.commondata.filter(
                (g: any) => g.itemType == this._structure.CommonDataItems.Gender
              )}
              filterBarPlaceholder='Search Gender'
              allowFiltering={true}
              filterType='Contains'
              fields={{ value: 'id', text: 'itemValue' }}
              placeholder='Select Gender'
              popupHeight='220px'
              style={{ fontSize: '15px' }}
              onChange={this.onChange.bind(this)}
              value={data.genderId!}
            ></DropDownListComponent>
          </div>
        </div> 
        <div className='row align-items-center'>
          <label
            style={{
              fontSize: '15px',
              paddingBottom: '7px',
              fontWeight: 'bold',
            }}
          >
            Settings
          </label>
        </div>*/}
        <div className='row align-items-center'>
          <div className='col-3 py-2'>
            <label style={{ paddingBottom: '7px' }}>Type</label>
          </div>
          <div className='col-9 py-2'>
            <CheckBoxComponent
              id='chkPaidLeave'
              name='chkPaidLeave'
              className='ck_chkpaidleave'
              label={'Paid'}
              checked={data.isPaid!}
              cssClass='larger'
              change={this.onCheckboxChange.bind(this)}
            />
            <input
              id='ck_chkpaidleave'
              name='ck_chkpaidleave'
              className='ck_chkpaidleave'
              type='hidden'
            />
          </div>
          {/* <div className='col-4 py-2'>
            <CheckBoxComponent
              id='chkAccruedLeave'
              name='chkAccruedLeave'
              className='ck_chkaccruedleave'
              label={'Accrued Leave'}
              cssClass='larger'
              checked={data.isAccrued!}
              change={this.onCheckboxChange.bind(this)}
            />
            <input
              id='ck_chkaccruedleave'
              name='ck_chkaccruedleave'
              className='ck_chkaccruedleave'
              type='hidden'
            />
          </div>
          <div className='col-4 py-2'>
            <CheckBoxComponent
              id='chkShortLeave'
              name='chkShortLeave'
              className='ck_chkshortleave'
              label={'Short Leave'}
              cssClass='larger'
              checked={data.isShortLeave!}
              change={this.onCheckboxChange.bind(this)}
            />
            <input
              id='ck_chkshortleave'
              name='ck_chkshortleave'
              className='ck_chkshortleave'
              type='hidden'
            />
          </div> */}
        </div>
        <div className='row align-items-center'>
          <div className='col-3 py-2'>
            <label style={{ paddingBottom: '7px' }}>Unit</label>
          </div>
          <div className='col-4 py-2'>
            <RadioButtonComponent
              id='radFullDays'
              name='radUnits'
              value={this._structure.Unit.FullDay.id}
              label='Full Days'
              onChange={this.onradiochanged.bind(this)}
            />
          </div>
          <div className='col-4 py-2'>
            <RadioButtonComponent
              id='radFlexHours'
              name='radUnits'
              value={this._structure.Unit.FlexHours.id}
              label='Flex Hours'
              onChange={this.onradiochanged.bind(this)}
            />
          </div>
          <input id='unitid' name='unitid' type='hidden' />
        </div>
        {/* <div className='row align-items-center'>
          <div className='col-4 py-2'>
            <CheckBoxComponent
              id='chkEndDateOptional'
              name='chkEndDateOptional'
              className='ck_chkenddateoptional'
              label={'Optional End Date'}
              cssClass='larger'
              checked={data.isEndDateOptional!}
              change={this.onCheckboxChange.bind(this)}
            />
            <input
              id='ck_chkenddateoptional'
              name='ck_chkenddateoptional'
              className='ck_chkenddateoptional'
              type='hidden'
            />
          </div>
          <div className='col-4 py-2'>
            <CheckBoxComponent
              id='chkIssueOnHoliday'
              name='chkIssueOnHoliday'
              className='ck_chkissueonholiday'
              label={'Issue On Holiday'}
              cssClass='larger'
              checked={data.issueOnHoliday!}
              change={this.onCheckboxChange.bind(this)}
            />
            <input
              id='ck_chkissueonholiday'
              name='ck_chkissueonholiday'
              className='ck_chkissueonholiday'
              type='hidden'
            />
          </div>
          <div className='col-4 py-2'>
            <CheckBoxComponent
              id='chkIssueOnWeekend'
              name='chkIssueOnWeekend'
              className='ck_chkissueonweekend'
              label={'Issue On Weekend'}
              cssClass='larger'
              change={this.onCheckboxChange.bind(this)}
            />
            <input
              id='ck_chkissueonweekend'
              name='ck_chkissueonweekend'
              className='ck_chkissueonweekend'
              type='hidden'
            />
          </div>
        </div> */}
        {/* <div className='row align-items-center'>
          <div className='col-4 py-2'>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              Min Days
            </label>
            <NumericTextBoxComponent
              id='mindays'
              name='mindays'
              decimals={0}
              max={100}
              min={0}
              value={data.minDays!}
              onChange={this.onChange.bind(this)}
            />
          </div>
          <div className='col-4 py-2'>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              Max Days
            </label>
            <NumericTextBoxComponent
              id='maxdays'
              name='maxdays'
              decimals={0}
              max={100}
              min={0}
              value={data.maxDays!}
              onChange={this.onChange.bind(this)}
            />
          </div>
        </div> */}
      </>
    );
  }
}
