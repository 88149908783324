import * as React from 'react';
import Common from '../../Util/Common';
import { TabComponent } from '@syncfusion/ej2-react-navigations';
import EmployeeFormat from './EmployeeFormat';
import GeneralFormat from './GeneralFormat';

interface Props {
  jsonDataViewer: any;
}

export default class Formats extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;
  private tabInstance: TabComponent | any = undefined;

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
  }

  componentDidMount() {
    if (this.tabInstance) {
      this.loadTabs();
    }
  }

  private loadTabs() {
    const itemsData: any = [
      {
        id: 'general',
        header: 'General',
        content: '',
      },
      {
        id: 'employee',
        header: 'Employee',
        content: '',
      },
    ];

    itemsData.forEach((t: any) => {
      switch (t.id) {
        case 'general':
          t.header = { text: t.header };
          t.content = () => {
            return <GeneralFormat />;
          };
          break;

        case 'employee':
          t.header = { text: t.header };
          t.content = () => {
            return <EmployeeFormat />;
          };
          break;

        default:
          break;
      }
    });

    this.tabInstance.items = itemsData;
    this.tabInstance.overflowMode = 'Popup';
    this.tabInstance.refresh();
  }

  render() {
    return (
      <div className='control-pane'>
        <div className='control-section tab-control-section row'>
          <div className='control-section'>
            <div className='tab-control-resize-container'>
              <TabComponent
                // className='tabline'
                id='formats-tabcomponent'
                heightAdjustMode='Auto'
                className='custom-tab-design'
                enablePersistence={true}
                ref={(tab) => (this.tabInstance = tab)}
              ></TabComponent>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
