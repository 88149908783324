import {
  ContextMenuComponent,
  MenuItemModel,
} from '@syncfusion/ej2-react-navigations';
import React from 'react';
import Common from '../../Util/Common';
import DataGrid from '../DataGrid';
import {
  ColumnDirective,
  ColumnsDirective,
  DialogEditEventArgs,
  Edit,
  EditSettingsModel,
  GridComponent,
  Inject,
  Toolbar,
} from '@syncfusion/ej2-react-grids';
import SelectorSearch from '../Layout/SelectorSearch';
import CreatePeriod from './CreatePeriod';
import {
  ButtonPropsModel,
  Dialog,
  DialogComponent,
} from '@syncfusion/ej2-react-popups';
import './PeriodSelector.css';
import * as signalR from '@microsoft/signalr';
import {
  IProgressValueEventArgs,
  ITextRenderEventArgs,
  ProgressBarComponent,
} from '@syncfusion/ej2-react-progressbar';
import DialogConfirm from '../Dialogbox/DialogConfirm';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';

const { REACT_APP_ENDPOINT_API, REACT_APP_ENDPOINT_PAYROLLSERVICE } =
  process.env;

// * ------------------------------------------------------------------------------------------------------------------3.Style
const parser = require('xml2js').Parser({
  explicitRoot: false,
  explicitArray: false,
});

// -------------------------------------------------------------------------------------------------------------------------------------------------DOTENV VAR DECLARATION

// * ----------------------------------------------------------------------------------------------------------------2.Initialize Component
export default class PeriodList extends React.Component<{}, {}> {
  private user: any;
  private token: any = null;
  private cMenu: ContextMenuComponent | null = null;
  private menuItem: MenuItemModel[] = [];
  private GridInstance: GridComponent | any;
  // public toolbarOptions: any = ['Add', 'Edit', 'Delete', {
  //   text: 'Close Period', tooltipText: 'Close Period', prefixIcon: 'e-cancel', id: 'Close', align: 'Right', cssClass: "close-toolbar-ico close-toolbar-btn"
  // }];
  public toolbarOptions: any = ['Add', 'Edit', 'Delete'];
  private _structure: any;
  public connection: any;
  private buttons: ButtonPropsModel[];
  toastObj: any;
  constructor(props: any) {
    super(props);
    this.user = JSON.parse(Common.getUser() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.connection = null;
    this.buttons = [{}];
  }

  state = {
    periodlist: [],
    showCloseDialog: false,
    _menuitems: undefined,
    deldata: undefined,
    progress: 0,
    totalcount: 0,
    totalpct: 0,
    closepid: undefined,
    openpid: undefined,
  };
  // * ------------------------------------------------------------------------------------------------------------------Event Handler
  componentDidMount() {
    if (this.state.periodlist.length <= 0) {
      this.fetchPeriods();
    }
    // let port = `${REACT_APP_ENDPOINT_PAYROLLSERVICE}`.split('/')[0];
    // let url = `${REACT_APP_ENDPOINT_API}${port}/progressHub`;
    // console.log(url);

    // this.connection = new signalR.HubConnectionBuilder()
    //   .withUrl(url) // Replace with your SignalR Hub URL
    //   .build();

    // this.connection.start();

    // this.connection.on('PayrollCount', (cnt: any, totalcnt: any) => {
    //   console.log('DATA: ', cnt, totalcnt);
    //   const pct = (cnt / totalcnt) * 100;
    //   this.setState({ progress: cnt, totalcount: totalcnt, totalpct: pct });
    // });
  }

  componentWillUnmount() {}

  // * ------------------------------------------------------------------------------------------------------------------6.Adapter
  private fetchPeriods() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/period/listperiods`,
      { addNewOption: false },
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        this.setState({
          periodlist: response,
        });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }
  ValidateForm = () => {
    const inputs = [
      document.querySelector<HTMLInputElement>('#pr_code'),
      document.querySelector<HTMLInputElement>('#pr_name'),
      document.querySelector<HTMLInputElement>('#prstartdate'),
      document.querySelector<HTMLInputElement>('#prenddate'),
    ];

    inputs.forEach((input) => {
      if (input) {
        input.classList.remove('textbox-border-error');

        if (input.value.trim() === '') {
          input.classList.add('textbox-border-error');
        } else {
          input.classList.add('textbox-class');
        }
      }
    });
  };
  private cellSave(e: any) {
    if (e.requestType === 'save') {
      let isedit: boolean = false;
      if (e.data !== undefined) {
        this.ValidateForm();
        if (e.data.id !== undefined && e.data.id !== null) {
          isedit = true;
        }

        if (e.data.pr_code == null) {
          e.requestType = 'add';
          e.cancel = true;
          // DialogConfirm.showDialog({
          //   content: '<p class="dialog-contain">Please enter code to continue.</p>',
          // });
          const TargetInput =
            document.querySelector<HTMLInputElement>('#pr_code');
          if (TargetInput) {
            TargetInput.style.borderBottom = '2px solid red';
          }
          return;
        }
        if (e.data.pr_name == null) {
          e.requestType = 'add';
          e.cancel = true;
          // DialogConfirm.showDialog({
          //   content: '<p class="dialog-contain">Please enter name to continue.</p>',
          // });
          const TargetInput =
            document.querySelector<HTMLInputElement>('#pr_name');
          if (TargetInput) {
            TargetInput.style.borderBottom = '2px solid red';
          }
          return;
        }
        if (e.data.prstartdate == null) {
          e.requestType = 'add';
          e.cancel = true;
          // DialogConfirm.showDialog({
          //   content: '<p class="dialog-contain">Please set start date to continue.</p>',
          // });
          const TargetInput =
            document.querySelector<HTMLInputElement>('#prstartdate');
          if (TargetInput) {
            TargetInput.style.borderBottom = '2px solid red';
          }
          return;
        }
        if (e.data.prenddate == null) {
          e.requestType = 'add';
          e.cancel = true;
          // DialogConfirm.showDialog({
          //   content: '<p class="dialog-contain">Please set end date to continue.</p>',
          // });
          const TargetInput =
            document.querySelector<HTMLInputElement>('#prenddate');
          if (TargetInput) {
            TargetInput.style.borderBottom = '2px solid red';
          }
          return;
        }
        if (new Date(e.data.prenddate) <= new Date(e.data.prstartdate)) {
          e.requestType = 'add';
          e.cancel = true;
          // DialogConfirm.showDialog({
          //   content: '<p class="dialog-contain">Please set end date to continue.</p>',
          // });
          const TargetInput =
            document.querySelector<HTMLInputElement>('#prenddate');
          if (TargetInput) {
            TargetInput.style.borderBottom = '2px solid red';
          }
          return;
        }

        let payload: any = {
          id: isedit == true ? e.data.id : null,
          code: e.data.pr_code,
          name: e.data.pr_name,
          startDate: Common.formatDate(
            new Date(e.data.prstartdate),
            'yyyy-MM-dd'
          ),
          endDate: Common.formatDate(new Date(e.data.prenddate), 'yyyy-MM-dd'),
          statusId: this._structure.PeriodStatusId.Open,
        };
        Common.ApiCallAsync(
          'POST',
          `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/period/add`,
          payload,
          Common.getToken() || '',
          this.user,
          this.token.tenant
        )
          .then((response: any) => {
            return response.data;
          })
          .then((response: any) => {
            let data = this.state.periodlist;
            data = (data as any).filter(
              (x: any) => x.id !== response.id && x.id !== undefined
            );
            if (data) {
              (data as any).push(response);
            }
            this.setState({ periodlist: data });
          })
          .catch((error: any) => {
            console.error(error);
            // if (
            //   error.response &&
            //   error.response.data &&
            //   error.response.data.messages &&
            //   error.response.data.messages.length > 0
            // ) {
            // DialogConfirm.showDialog({
            //    content: `<p class="dialog-contain">${error.response.data.messages.join('<br>')}</p>`,
            // });
            // }
          })
          .finally(() => {});
      }

      //pr_code
      //pr_name
      //prstartdate
      //prenddate
      //id
    } else if (e.requestType === 'delete') {
      if (e.data[0].statusId == this._structure.PeriodStatusId.Close) {
        // DialogConfirm.showDialog({
        //   content:
        //     '<p class="dialog-contain">Sorry, you cannot delete the selected period as period is already closed.</p>',
        // });
        this.toastObj = ToastUtility.show({
          cssClass: 'e-toast-warning',
          icon: 'e-warning toast-icons',
          showCloseButton: true,
          content:
            'Sorry, you cannot delete the selected period as period is already closed.',
          target: '#basic_card',
          position: { X: 'Center', Y: 'Top' },
          width: 'auto',
          timeOut: 3000,
        });
        e.cancel = true;
      } else {
        this.setState({ deldata: e.data[0] });
        e.data = [];

        DialogConfirm.showDialog({
          isConfirm: true,
          content:
            '<div class="row" style="display: flex;flex-direction: column;align-items: center;"><p class="dialog-contain">Are you sure you want to remove selected period?</p></div>',
          okCaption: 'Yes',
          closeCaption: 'No',
          OkEvent: this.confirmAction.bind(this),
        });
      }
    }
  }

  confimClosePeriod = () => {
    this.setState({ showCloseDialog: true });
    this.ClosePeriod(this.state.closepid, this.state.openpid);
  };

  confirmAction = () => {
    if (this.state.deldata !== undefined) {
      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/period/deleteperiod`,
        { id: (this.state.deldata as any).id },
        Common.getToken() || '',
        this.user,
        this.token.tenant
      )
        .then((response: any) => {
          return response.data;
        })
        .then((response: any) => {
          if (response === true) {
            let data = this.state.periodlist;
            data = (data as any).filter(
              (x: any) => x.id !== (this.state.deldata as any).id
            );
            this.setState({ periodlist: data });
          }
        })
        .catch((error: any) => {
          console.error(error);
          // if (
          //   error.response &&
          //   error.response.data &&
          //   error.response.data.messages &&
          //   error.response.data.messages.length > 0
          // ) {
          // DialogConfirm.showDialog({
          //    content: `<p class="dialog-contain">${error.response.data.messages.join('<br>')}</p>`,

          // });
          // }
        })
        .finally(() => {});
    }
  };

  private showProgress() {
    return (
      <>
        <div>
          <ProgressBarComponent
            id='linear'
            type='Linear'
            showProgressValue={true}
            labelStyle={{ color: '#FFFFFF', fontWeight: 'bold' }}
            trackThickness={30}
            progressThickness={30}
            trackColor='transparent'
            progressCompleted={(args: IProgressValueEventArgs) => {
              args.value = 0;
              this.setState({
                totalcount: 0,
                showCloseDialog: false,
                totalpct: 0,
              });
            }}
            maximum={100}
            value={this.state.totalpct}
          ></ProgressBarComponent>
        </div>
      </>
    );
  }

  private ClosePeriod(closepid: any, openpid: any) {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payrolldata/closeperiod`,
      {
        closePeriodId: closepid,
        openPeriodId: openpid,
      },
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        let data: any = this.state.periodlist.filter(
          (x: any) => x.id !== response.id
        );
        data.push(response);
        data = data.sort((a: any, b: any) => {
          return (
            new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
          );
        });
        this.setState({ periodlist: data });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});

    // let url = `${REACT_APP_ENDPOINT_API}${REACT_APP_ENDPOINT_PAYROLLSERVICE}/period/closeperiod`;
    // this.socket = new WebSocket(url.replace("https:", "ws:").replace("http:", "ws:"));
    // this.socket.onopen = () => {
    //   console.log('WebSocket connected');
    // };

    // this.socket.onmessage = (event: any) => {
    //   const data = JSON.parse(event.data);
    //   this.setState({ progress: data.progress });
    // };
  }

  private SetDialogCSS(dialog: Dialog) {
    for (let i = 0; i < dialog.buttons.length; i++) {
      let _primary = dialog.buttons[i].buttonModel?.isPrimary;

      let _model = {
        content: Common.toTitleCase(
          dialog.buttons[i].buttonModel?.content?.toLowerCase() == 'save'
            ? 'Accept'
            : `${dialog.buttons[i].buttonModel?.content}`
        ),
        cssClass: 'flat-button',
        isPrimary: _primary,
      };
      dialog.buttons[i].buttonModel = _model;
    }
    dialog.buttons.reverse();
  }

  private actionComplete = (args: DialogEditEventArgs): void => {
    if (args.requestType === 'beginEdit' || args.requestType === 'add') {
      const dialog: Dialog = args.dialog as Dialog;
      if (args.requestType === 'add') {
        dialog.header = 'New Period';
      }
      if (args.requestType === 'beginEdit') {
        dialog.header = 'Edit Period';
      }
      this.SetDialogCSS(dialog);

      // if ((args.dialog as any).btnObj[0]) {
      //   (args.dialog as any).btnObj[0].element.style.display = 'none';
      // }
      // if ((args.dialog as any).btnObj[1]) {
      //   (args.dialog as any).btnObj[1].element.style.display = 'none';
      // }
    } else if (args.requestType === 'delete') {
    }
  };

  clickHandler = (e: any) => {
    if (this.GridInstance && e.item.id.toLowerCase() === 'close') {
      if (this.GridInstance.selectedRowIndex < 0) {
        // DialogConfirm.showDialog({
        //   content:
        //     '<p class="dialog-contain">Please select period to close.</p>',
        // });
        this.toastObj = ToastUtility.show({
          cssClass: 'e-toast-warning',
          icon: 'e-warning toast-icons',
          showCloseButton: true,
          content: 'Please select period to close.',
          target: '#basic_card',
          position: { X: 'Center', Y: 'Top' },
          width: 'auto',
          timeOut: 3000,
        });
        return;
      }
      let pItem =
        this.GridInstance.dataSource[this.GridInstance.selectedRowIndex];

      if (pItem) {
        let time = new Date(pItem.startDate).getTime();
        if (
          this.GridInstance.dataSource.filter(
            (x: any) =>
              new Date(x.startDate).getTime() < time && x.statusId === 1
          ).length > 0
        ) {
          // DialogConfirm.showDialog({
          //   content:
          //     '<p class="dialog-contain">Sorry, you cannot close the selected period as previous period is already open.</p>',
          // });
          this.toastObj = ToastUtility.show({
            cssClass: 'e-toast-warning',
            icon: 'e-warning toast-icons',
            showCloseButton: true,
            content:
              'Sorry, you cannot close the selected period as previous period is already open.',
            target: '#basic_card',
            position: { X: 'Center', Y: 'Top' },
            width: 'auto',
            timeOut: 3000,
          });
          return;
        }
      }
      let oItem: any;
      let time = new Date(pItem.startDate).getTime();
      if (
        this.GridInstance.dataSource.filter(
          (x: any) => new Date(x.startDate).getTime() > time && x.statusId === 1
        ).length > 0
      ) {
        oItem =
          this.GridInstance.dataSource[this.GridInstance.selectedRowIndex + 1];
      }
      if (pItem && oItem) {
        this.setState({ closepid: pItem.id, openpid: oItem.id });
        DialogConfirm.showDialog({
          isConfirm: true,
          content:
            '<div class="row" style="display: flex;flex-direction: column;align-items: center;"><p class="dialog-contain">Are you sure you want to close selected period?</p></div>',
          okCaption: 'Yes',
          closeCaption: 'No',
          OkEvent: this.confimClosePeriod.bind(this),
        });
      }
    }
  };

  // * --------------------------------------------------------------------------------------------------------------------HTML
  render() {
    const progress = this.state.progress;
    console.log(progress);

    const dialogTemplate = (props: any) => {
      return (
        <>
          <DialogFormTemplate {...props} />
        </>
      );
    };

    const editSettings: EditSettingsModel = {
      allowEditing: true,
      allowAdding: true,
      allowDeleting: true,
      mode: 'Dialog',
      allowEditOnDblClick: false,
      template: dialogTemplate,
    };

    return (
      <>
        <div style={{ height: '10px' }}>&nbsp;</div>
        <div className='control-pane'>
          <div className='control-section'>
            <GridComponent
              id='grid-selector'
              dataSource={this.state.periodlist}
              allowTextWrap={true}
              allowGrouping={false}
              showColumnChooser={false}
              ref={(g) => (this.GridInstance = g)}
              editSettings={editSettings}
              toolbar={this.toolbarOptions}
              height='calc(100vh - 475px)'
              actionBegin={this.cellSave.bind(this)}
              actionComplete={this.actionComplete.bind(this)}
              toolbarClick={this.clickHandler}
              immediateRender={true}
            >
              <ColumnsDirective>
                <ColumnDirective field='code' headerText='Code' />
                <ColumnDirective field='name' headerText='Name' />
                <ColumnDirective
                  field='startDate'
                  headerText='Start Date'
                  format={this.user.settings.formats.DateShort}
                  type='date'
                />
                <ColumnDirective
                  field='endDate'
                  headerText='End Date'
                  format={this.user.settings.formats.DateShort}
                  type='date'
                />
                <ColumnDirective field='totalDays' headerText='Total Days' />
                <ColumnDirective field='periodStatus' headerText='Status' />
                <ColumnDirective field='id' headerText='id' visible={false} />
                <ColumnDirective
                  field='statusId'
                  headerText='statusId'
                  visible={false}
                />
              </ColumnsDirective>
              <Inject services={[Toolbar, Edit]} />
            </GridComponent>
            <SelectorSearch grid_id='grid-selector' />
          </div>
          {this.state.showCloseDialog == true ? (
            <>
              <DialogComponent
                id='defaultDialog1'
                showCloseIcon={false}
                visible={this.state.showCloseDialog}
                width={'45vw'}
                height={'15vh'}
                content={this.showProgress.bind(this)}
                isModal={true}
                header={'Closing Period...'}
                statelessTemplates={[]}
                buttons={this.buttons}
              ></DialogComponent>
            </>
          ) : null}
        </div>
      </>
    );
  }
}

export class DialogFormTemplate extends React.Component<{}, {}> {
  private user: any;
  private _structure: any;
  private token: any = null;
  private child: CreatePeriod | any;
  constructor(props: any) {
    super(props);
    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
    this.state = Object.assign({}, props);
    this.child = React.createRef();
  }

  render(): any {
    let data: perioddatamodel = this.state;
    return (
      <>
        <CreatePeriod
          ref={this.child}
          id={Math.random().toString()}
          title=''
          code={data.code}
          name={data.name}
          startdate={data.startDate}
          enddate={data.endDate}
          guid={data.id}
        ></CreatePeriod>
      </>
    );
  }
}

export interface perioddatamodel {
  isAdd?: boolean;
  code?: string;
  name?: string;
  startDate?: Date;
  endDate?: Date;
  id?: string;
}
