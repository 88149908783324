// * -----------------------------------------------------------------------------------------------------------------1.Aknowlege
// * SANDEEP - 2023-03-16 - 75 -- Meeting
// * SANDEEP - 2023-03-16 - 400 -- Made All Code
// * SANDEEP - 2023-03-17 - 180 -- RnD on google for issues
// * SANDEEP - 2023-03-20 - 90 -- Meeting
// * SANDEEP - 2023-03-21 - 100 -- RnD on google for issues
// * SANDEEP - 2023-03-22 - 40 -- Meeting
// * SANDEEP - 2023-03-22 - 90 -- RnD on google for issues
// * SOURAV - 2023-05-03 - 70 -- Meeting
// * SOURAV - 2023-05-08 - 80 -- Meeting
// * SOURAV - 2023-05-10 - 90 -- Meeting.
// * SOURAV - 2023-05-11 - 30 -- Meeting.
// * SOURAV - 2023-05-12 - 60 -- Meeting.
// * SOURAV - 2023-05-15 - 80 -- Meeting.
// * SOURAV - 2023-05-17 - 70 -- Meeting.
// * SOURAV - 2023-05-18 - 40 -- Meeting.
// * SOURAV - 2023-05-22 - 20 -- Meeting.
// * SOURAV - 2023-05-24 - 50 -- Meeting.
// * SOURAV - 2023-05-25 - 50 -- Meeting.
// * SOURAV - 2023-05-26 - 35 -- Meeting.
// * SOURAV - 2023-05-26 - 50 -- Meeting.
// * SOURAV - 2023-10-06 - 30 -- Meeting.
// * SOURAV - 2023-10-09 - 60 -- Meeting.
// * SOURAV - 2023-10-16 - 30 -- Meeting.
// * SOURAV - 2023-11-24 - 30 -- Meeting.

// * -----------------------------------------------------------------------------------------------------------------2.Initialize
// * SANDEEP - 2023-05-04 - 10  -- Added Item "_selectedtabname" under _CardProps
// * SANDEEP - 2023-06-03 - 120 -- Implemented the Font awesom Icons on cards and profile picture

import { TextBox } from '@syncfusion/ej2-react-inputs';
import { debug } from 'console';
import React, { ReactNode, useEffect } from 'react';
import { text } from 'stream/consumers';
import { PagerComponent } from '@syncfusion/ej2-react-grids';
import { HandLeft } from 'react-ionicons';
import Common from '../Util/Common';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import './ImageEditor.css';
//import Accordion from './Accordion';
import ImageEditor from './ImageEditor';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { setElementStype } from '@syncfusion/ej2-react-pdfviewer';
import { TypePredicateKind } from 'typescript';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';

interface _CardProps {
  _element: any;
  _data: any;
  _selectedtabname?: string;
  _user: any;
  _maincardid?: any;
  _persomempid?: any;
  _addressid?: any;
  _id?: any;
}

let currentPage = 1;
const recordsPerPage = 1;
var currentRecords: any;
var nPages: any;
var indexOfLastRecord: any;
var indexOfFirstRecord: any;
var _CommonStyle: any = { attributes: {} };
// * ------------------------------------------------------------------------------------------------------------------3.Style

/*
UNCOMMENT IF XML2JS ARE REQUIRED
*/
const parser = require('xml2js').Parser({
  explicitRoot: false,
  explicitArray: false,
});

const { REACT_APP_ENDPOINT_FOXSYSTEMSERVICE, REACT_APP_ENDPOINT_CORESERVICE } =
  process.env;
class XCard extends React.Component<_CardProps> {
  pagerInstance: PagerComponent | null | undefined;
  private _structure: any;
  private user: any;
  private _fulljson: any;
  // private cfields: object = { value: 'object', text: 'value' };
  private cfields: object = { value: 'id', text: 'itemValue' };
  private _sl_ctrlid: string = '';
  private _itemidx: Number = -1;
  private _itemval: Number = -1;
  private _itemname: string = '';
  private token: any = null;
  toastObj: any;

  // * ----------------------------------------------------------------------------------------------------------------2.Initialize Component
  constructor(props: any) {
    super(props);
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.user = JSON.parse(Common.getUser() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
    //this._fulljson = JSON.parse(Common.getFullJson() ?? '');
  }

  // * ------------------------------------------------------------------------------------------------------------------5.State
  state = {
    showimage: false,
    // fImage: null,
    profileImage: null,
    _gender: undefined,
    _religion: undefined,
    _maritalstatus: undefined,
    _relation: undefined,
    editImage: null,
    _nationality: undefined,
  };

  // * ------------------------------------------------------------------------------------------------------------------Event Handler
  // * SANDEEP - 2023-04-28 - 30  -- Made Events (clicked)
  // * SANDEEP - 2023-05-04 - 30  -- Made Events (ImageDblclicked)

  componentDidMount() {
    if (
      this.state._relation == undefined &&
      this.props._selectedtabname?.toLowerCase() === 'contact'
    ) {
      let relation: any;
      relation = this.user.commondata.filter(
        (x: any) => x.itemType == this._structure.CommonDataItems.Relation
      );
      this.setState({
        _relation: relation,
      });
    }
    if (
      this.state._gender == undefined &&
      this.state._religion == undefined &&
      this.state._maritalstatus == undefined &&
      this.props._selectedtabname?.toLowerCase() === 'bio'
    ) {
      let gender: any;
      let religion: any;
      let maritalstatus: any;

      gender = this.user.commondata.filter(
        (x: any) => x.itemType == this._structure.CommonDataItems.Gender
      );
      religion = this.user.commondata.filter(
        (x: any) => x.itemType == this._structure.CommonDataItems.Religion
      );
      maritalstatus = this.user.commondata.filter(
        (x: any) => x.itemType == this._structure.CommonDataItems.MaritalStatus
      );

      this.GetCountryList();

      this.setState({
        _gender: gender,
        _religion: religion,
        _maritalstatus: maritalstatus,
      });

      if (
        (this.state.profileImage === null ||
          this.state.profileImage === undefined) &&
        this.props._selectedtabname?.toLowerCase() === 'bio'
      ) {
        this.setState({ profileImage: this.props._data.image });
      }

      // let payload: any = {};
      // Common.ApiCallAsync(
      //   'POST',
      //   `${REACT_APP_ENDPOINT_CORESERVICE}` + '/commondata/search',
      //   payload,
      //   Common.getToken() || '',
      //   this.user,
      //   this.token.tenant
      // )
      //   .then((response: any) => {
      //     return response.data;
      //     //.itemType <= this._structure.CommonDataItems.MaritalStatus
      //   })
      //   .then((response: any) => {
      //     let _items: any = response.data.filter(
      //       (x: any) =>
      //         x.itemType <= this._structure.CommonDataItems.MaritalStatus
      //     );
      //     if (_items !== null && _items !== undefined) {
      //       gender = _items.filter(
      //         (g: any) => g.itemType == this._structure.CommonDataItems.Gender
      //       );
      //       religion = _items.filter(
      //         (g: any) => g.itemType == this._structure.CommonDataItems.Religion
      //       );
      //       maritalstatus = _items.filter(
      //         (g: any) =>
      //           g.itemType == this._structure.CommonDataItems.MaritalStatus
      //       );
      //     }

      // })
      //     .catch ((error: any) => {
      //   console.error(error);
      // })
      //     .finally(() => { });

      // let _objectId = Common.FindJsonByObjectValue(
      //   this._fulljson,
      //   '_bio_',
      //   this.user
      // );
      //   if (_objectId !== null) {
      //     let items = Common.FindJsonByObjectId(
      //       this._fulljson,
      //       _objectId.object,
      //       this.user
      //     );

      //     if (items !== undefined) {
      //       let gender: any;
      //       let religion: any;
      //       let maritalstatus: any;

      //       // if (
      //       //   items.children.filter(
      //       //     (x: any) => x.value.toLowerCase() == '_gender_'
      //       //   ).length > 0
      //       // ) {
      //       //   gender = Common.FindJsonByObjectId(
      //       //     this._fulljson,
      //       //     items.children.filter(
      //       //       (x: any) => x.value.toLowerCase() == '_gender_'
      //       //     )[0].object,
      //       //     this.user
      //       //   );
      //       // }

      //       // if (
      //       //   items.children.filter(
      //       //     (x: any) => x.value.toLowerCase() == '_religion_'
      //       //   ).length > 0
      //       // ) {
      //       //   religion = Common.FindJsonByObjectId(
      //       //     this._fulljson,
      //       //     items.children.filter(
      //       //       (x: any) => x.value.toLowerCase() == '_religion_'
      //       //     )[0].object,
      //       //     this.user
      //       //   );
      //       // }

      //       // if (
      //       //   items.children.filter(
      //       //     (x: any) => x.value.toLowerCase() == '_marital_'
      //       //   ).length > 0
      //       // ) {
      //       //   maritalstatus = Common.FindJsonByObjectId(
      //       //     this._fulljson,
      //       //     items.children.filter(
      //       //       (x: any) => x.value.toLowerCase() == '_marital_'
      //       //     )[0].object,
      //       //     this.user
      //       //   );
      //       // }

      //       this.setState({
      //         _gender: gender.children,
      //         _religion: religion.children,
      //         _maritalstatus: maritalstatus.children,
      //       });
      //     }
      //   }
    }

    // if (
    //   this.state.profileImage === null &&
    //   this.props._selectedtabname?.toLowerCase() === 'bio'
    // ) {
    //   Common.ApiCallAsync(
    //     'POST',
    //     `${REACT_APP_ENDPOINT_FOXSYSTEMSERVICE}/Image/Get`,
    //     {
    //       object: this.props._data.id,
    //       field: this._structure.Field.FileType.Jpg,
    //     },
    //     Common.getToken() || '',
    //     this.user
    //   )
    //     .then((resopnse: any) => {
    //       return resopnse.data;
    //     })
    //     .then((resopnse: any) => {
    //       this.setState({ profileImage: resopnse });
    //     })
    //     .catch((error: any) => {
    //       //console.error(error);
    //       if ((error.code = 'ERR_BAD_REQUEST')) {
    //         setTimeout(() => {
    //           Common.ApiCallAsync(
    //             'POST',
    //             `${REACT_APP_ENDPOINT_FOXSYSTEMSERVICE}/Image/Get`,
    //             {
    //               object: this.props._data.id,
    //               field: this._structure.Field.FileType.Jpg,
    //             },
    //             Common.getToken() || '',
    //             this.user
    //           )
    //             .then((response: any) => {
    //               return response.data;
    //             })
    //             .then((response: any) => {
    //               this.setState({ profileImage: response });
    //             })
    //             .catch((error: any) => {
    //               console.error(error);
    //             })
    //             .finally(() => {});
    //         }, 1000);
    //       }
    //     })
    //     .finally(() => {});
    // }
  }

  public GetCountryList() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/country/get`,
      {},
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        // let objNationality: any = [];
        // response.data.forEach((x: any) => {
        //   objNationality.push({
        //     id: x.id,
        //     name: x.name,
        //   });
        // });
        // return objNationality;
        return response.data;
      })
      .then((response: any) => {
        let objNationality: any = [];
        response.forEach((x: any) => {
          objNationality.push({
            id: x.id,
            itemValue: x.name,
          });
        });
        this.setState({ _nationality: objNationality });
        //return objNationality;
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  clicked = (args: any) => {
    currentPage = args.currentPage;
    indexOfLastRecord = currentPage * recordsPerPage;
    indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    currentRecords = this.props._data.cards.slice(
      indexOfFirstRecord,
      indexOfLastRecord
    )[0];
    this.forceUpdate();
  };

  inptcarddblclick = (e: any) => {
    if (e) {
      if (e.target.attributes.disabled) {
        e.target.attributes.removeNamedItem('disabled');
      }
      e.target.style.border = '1px solid';
    }
  };

  inptcardblur = (e: any) => {
    if (e) {
      if (e.target.value.trim().length > 0) {
        this._itemidx =
          e.target.dataset.inptid !== undefined ? e.target.dataset.inptid : 0;
        this._itemval = e.target.value;
        this._itemname =
          e.target.dataset.item !== undefined ? e.target.dataset.item : '';

        if (
          this._itemidx == 0 &&
          e.target.dataset != undefined &&
          e.target.dataset.subcrd == e.target.id.split('_')[1]
        ) {
          let payload: any = {
            id: this.props._data.id,
            birthplace: e.target.value,
            nationalityid: this.props._data.nationalityid,
          };
          this.UpdatePersonInfo(payload);
        } else {
          if (this._itemname === 'Email') {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailPattern.test(this._itemval.toString())) {
              e.target.style.borderBottom = '2px solid red';
              this.toastObj = ToastUtility.show({
                cssClass: 'e-toast-warning',
                icon: 'e-warning toast-icons',
                showCloseButton: true,
                content: 'Please Enter Corract format of Email ..!',
                target: '#basic_card',
                position: { X: 'Center', Y: 'Top' },
                width: 'auto',
                timeOut: 3000,
              });
              return;
            }
          }
          this.AddAddressItem(
            e.target.parentElement.parentElement.parentElement.id,
            e.target.dataset.item !== undefined ? e.target.dataset.item : '',
            e.target.value,
            this.props._maincardid,
            e.target.dataset.subcrd,
            e.target.dataset.inptid !== undefined ? e.target.dataset.inptid : 0,
            e.target.dataset.par,
            this.props._data.commonDataId
          );
        }
        // if (this._itemname === 'Email') {
        //   const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        //   if (!emailPattern.test(this._itemval.toString())) {
        //     e.target.style.borderBottom = '2px solid red';
        //     this.toastObj = ToastUtility.show({
        //       cssClass: 'e-toast-warning',
        //       icon: 'e-warning toast-icons',
        //       showCloseButton: true,
        //       content: 'Please Enter Corract format of Email ..!',
        //       target: '#basic_card',
        //       position: { X: 'Center', Y: 'Top' },
        //       width: 'auto',
        //       timeOut: 3000,
        //     });
        //     return;
        //   }
        // }
        // this.AddAddressItem(
        //   e.target.parentElement.parentElement.parentElement.id,
        //   e.target.dataset.item !== undefined ? e.target.dataset.item : '',
        //   e.target.value,
        //   this.props._maincardid,
        //   e.target.dataset.subcrd,
        //   e.target.dataset.inptid !== undefined ? e.target.dataset.inptid : 0,
        //   e.target.dataset.par,
        //   this.props._data.commonDataId
        // );

        if (e.target.dataset.par !== undefined) {
          if (e.target !== null && e.target !== undefined) {
            let orgle: any;
            orgle = document.getElementById(e.target.id + '_orgnl');
            if (orgle !== null) {
              orgle.parentElement.style.display = 'block';
              orgle.textContent = e.target.value;
              e.target.parentElement.style.display = 'none';
            }
          }
        } else {
          e.target.setAttribute('disabled', '');
          e.target.style.borderStyle = 'none';
          e.target.style.backgroundColor = 'transparent';
        }
      } else {
        if (this.props._data.cards != undefined) {
          let _crdelems = this.props._data.cards
            .filter((x: any) => x.id == e.target.dataset.subcrd)[0]
            .items.filter((i: any) => i.itemValue !== '');

          this.props._data.cards.filter(
            (x: any) => x.id == e.target.dataset.subcrd
          )[0].items = _crdelems;
          //this.props._data.cards.filter((x: any) => x.id == e.target.dataset.subcrd)[0].items.slice(0, -1);
        } else {
          if (
            this._itemidx == -1 &&
            e.target.dataset != undefined &&
            e.target.dataset.subcrd == e.target.id.split('_')[1]
          ) {
            let payload: any = {
              id: this.props._data.id,
              birthplace: e.target.value,
              nationalityid: this.props._data.nationalityid,
            };
            this.UpdatePersonInfo(payload);
          }
        }

        this.setState({
          _addNew: true,
        });
      }
    }
  };

  ImageDblclicked = (args: any) => {
    if (
      this.props._selectedtabname !== undefined &&
      this.props._selectedtabname.toLowerCase() == 'bio'
    ) {
      this.setState({ showimage: true });
    }
  };

  getFinalImage = (data: any) => {
    this.setState({
      profileImage: data.image,
      isCropping: data.isCropping,
      showimage: data.showimage,
    });
  };

  lblDoubleClick(e: any) {
    let _hidel: any = null;
    if (e.target.attributes['itemtype'].value == 'date') {
      _hidel = document.getElementById(
        e.target.id.toString().replace('_orgnl', '')
      );
      if (_hidel !== null) {
        (_hidel as HTMLElement).style.display = 'block';
        e.target.parentElement.style.display = 'none';
        let dtctrl = document.getElementById('dt_date');
        if (dtctrl !== null) {
          dtctrl.autofocus = true;
          dtctrl.focus();
        }
      }
    } else if (e.target.attributes['itemtype'].value == 'select') {
      _hidel = document.getElementById(
        e.target.id.toString().replace('_orgnl', '')
      );
      if (_hidel !== null) {
        (_hidel as HTMLElement).style.display = 'block';
        e.target.parentElement.style.display = 'none';
        this._sl_ctrlid = _hidel.id;
      }
    } else if (e.target.attributes['itemtype'].value == 'textbox') {
      _hidel = document.getElementById(
        e.target.id.toString().replace('_orgnl', '')
      );
      if (_hidel !== null) {
        (_hidel as HTMLElement).parentElement!.style.display = 'block';
        _hidel.style.border = '1px solid';
        _hidel.autofocus = true;
        _hidel.focus();
        e.target.parentElement.style.display = 'none';
      }
    }
  }

  dtOnBlur(e: any) {
    if (e.target !== null && e.target !== undefined) {
      let orgle: any;
      orgle = document.getElementById(
        e.target.parentElement.parentElement.id + '_orgnl'
      );
      if (orgle !== null) {
        orgle.parentElement.style.display = 'block';
        e.target.parentElement.parentElement.style.display = 'none';
      }
    }
  }

  dtOnChange(e: any) {
    if (
      e.target !== null &&
      e.target !== undefined &&
      e.target.value !== null
    ) {
      // let payload: any = [
      //   {
      //     object: Number(this.props._data.id),
      //     field: this._structure.Field.Chrono.Date,
      //     value: Common.formatDate(e.target.value, 'yyyy-MM-dd'),
      //     unique: true,
      //   },
      // ];

      let payload: any = {
        Id: this.props._data.id,
        birthdate: Common.formatDate(e.target.value, 'yyyy-MM-dd'),
      };
      let diff = this.DateDifferenceYMD(
        new Date(Common.formatDate(e.target.value, 'yyyy-MM-dd')),
        new Date(Common.formatDate(new Date(), 'yyyy-MM-dd'))
      );
      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_CORESERVICE}/persons/update${
          !this.user.isManager ? '/ess' : ''
        }`,
        payload,
        Common.getToken() || '',
        this.user,
        this.token.tenant
      )
        .then((resopnse: any) => {
          if (this.props._data.birthdate !== undefined) {
            this.props._data.birthdate = resopnse.data.birthdate;
            this.props._data.age = resopnse.data.age;
          }

          let orgle: any;
          orgle = document.getElementById(
            e.target.inputElement.parentElement.parentElement.id + '_orgnl'
          );
          if (orgle !== null) {
            orgle.parentElement.style.display = 'block';
            orgle.textContent = Common.formatDate(
              e.target.value,
              this.props._user.settings.formats.DateShort
            );
            e.target.inputElement.parentElement.parentElement.style.display =
              'none';
          }

          this.setState({ _addNew: true });
        })
        .catch((error: any) => {
          console.error(error);
        })
        .finally(() => {});
    }
  }

  slOnBlur() {
    if (this._sl_ctrlid !== '') {
      let orgle: any;
      let curle: any;
      orgle = document.getElementById(this._sl_ctrlid + '_orgnl');
      curle = document.getElementById(this._sl_ctrlid);
      if (orgle !== null && curle !== null) {
        orgle.parentElement.style.display = 'block';
        curle.style.display = 'none';
      }
    }
    this._sl_ctrlid = '';
  }

  slOnChange(e: any) {
    if (e.event !== null && e.event !== undefined) {
      let resp: any;
      if (
        e.element.parentElement.parentElement.id
          .toLowerCase()
          .startsWith('_rt') == true
      ) {
        this.AddAddressItem(
          0,
          '',
          e.event.target.dataset.value,
          this.props._maincardid,
          0,
          0,
          'kchg',
          ''
        );

        let orgle: any;
        orgle = document.getElementById(
          e.element.parentElement.parentElement.id + '_orgnl'
        );
        if (orgle !== null) {
          orgle.parentElement.style.display = 'block';
          orgle.textContent = e.itemData.value;
          e.element.parentElement.parentElement.style.display = 'none';
        }
      }
      if (
        e.element.parentElement.parentElement.id
          .toLowerCase()
          .startsWith('_gn') == true ||
        e.element.parentElement.parentElement.id
          .toLowerCase()
          .startsWith('_rl') == true ||
        e.element.parentElement.parentElement.id
          .toLowerCase()
          .startsWith('_ms') == true
      ) {
        let payload: any = {
          Id: e.element.parentElement.parentElement.dataset.cid,
          PersonsId: this.props._data.id,
          CommonDataId: e.itemData.id,
        };
        Common.ApiCallAsync(
          'POST',
          `${REACT_APP_ENDPOINT_CORESERVICE}/personbioiitems/update${
            !this.user.isManager ? '/ess' : ''
          }`,
          payload,
          Common.getToken() || '',
          this.user,
          this.token.tenant
        )
          .then((resopnse: any) => {
            resp = resopnse.data;
            if (this.props._data.bio !== undefined) {
              let _item = this.props._data.bio.filter(
                (x: any) =>
                  x.id == e.element.parentElement.parentElement.dataset.cid
              );
              if (_item.length > 0) {
                _item[0].commonDataId = e.itemData.id;
                _item[0].commonData.id = e.itemData.id;
                _item[0].commonData.itemType = resp.commonData.itemType;
                _item[0].commonData.itemValue = resp.commonData.itemValue;
              }
            }
            let orgle: any;
            orgle = document.getElementById(
              e.element.parentElement.parentElement.id + '_orgnl'
            );
            if (orgle !== null) {
              orgle.parentElement.style.display = 'block';
              orgle.textContent = e.itemData.itemValue;
              e.element.parentElement.parentElement.style.display = 'none';
            }

            this.setState({ _addNew: true });
          })
          .catch((error: any) => {
            console.error(error);
          })
          .finally(() => {});
      }
      if (
        e.element.parentElement.parentElement.id
          .toLowerCase()
          .startsWith('_nt') == true
      ) {
        let payload: any = {
          id: this.props._data.id,
          birthplace: this.props._data.birthplace,
          nationalityid: e.event.target.dataset.value,
        };
        this.UpdatePersonInfo(payload);
      }
    }
  }
  // slOnChange(e: any) {
  //   if (e.event !== null && e.event !== undefined) {
  //     let resp: any;

  //     let x = Common.FindParentJsonByIdKeyChildren(
  //       this._fulljson,
  //       parseInt(e.itemData.object),
  //       'object',
  //       'children',
  //       null
  //     );
  //     if (x) {
  //       let payload: any;
  //       if (
  //         e.element.parentElement.parentElement.id
  //           .toLowerCase()
  //           .startsWith('_rt') == true
  //       ) {
  //         let kinship: any = '';
  //         kinship = Common.FindJsonByObjectId(
  //           this._fulljson,
  //           e.itemData.object,
  //           this.user,
  //           this._structure.Field.Identif.SysName
  //         );
  //         // this.SaveAddressItem(
  //         //   '',
  //         //   kinship.value,
  //         //   this.props._maincardid,
  //         //   Number(e.element.parentElement.parentElement.dataset.cid),
  //         //   0,
  //         //   'kchg'
  //         // );
  //         let orgle: any;
  //         orgle = document.getElementById(
  //           e.element.parentElement.parentElement.id + '_orgnl'
  //         );
  //         if (orgle !== null) {
  //           orgle.parentElement.style.display = 'block';
  //           orgle.textContent = e.itemData.value;
  //           e.element.parentElement.parentElement.style.display = 'none';
  //         }
  //       }

  //       if (
  //         e.element.parentElement.parentElement.id
  //           .toLowerCase()
  //           .startsWith('_gn') == true ||
  //         e.element.parentElement.parentElement.id
  //           .toLowerCase()
  //           .startsWith('_rl') == true ||
  //         e.element.parentElement.parentElement.id
  //           .toLowerCase()
  //           .startsWith('_ms') == true
  //       ) {
  //         payload = [
  //           {
  //             object: Number(e.element.parentElement.parentElement.dataset.cid),
  //             field: this._structure.Field.Identif.Account,
  //             value: e.itemData.object.toString(),
  //             unique: true,
  //           },
  //         ];
  //       }
  //       Common.ApiCallAsync(
  //         'POST',
  //         `${REACT_APP_ENDPOINT_FOXSYSTEMSERVICE}/Save`,
  //         payload,
  //         Common.getToken() || '',
  //         this.user
  //       )
  //         .then((resopnse: any) => {
  //           if (this.props._data.bio !== undefined) {
  //             let _item = this.props._data.bio.filter(
  //               (x: any) =>
  //                 x.id ==
  //                 Number(e.element.parentElement.parentElement.dataset.cid)
  //             );
  //             if (_item.length > 0) {
  //               _item[0].account.id = e.itemData.object;
  //               _item[0].account.name = e.itemData.value;
  //             }
  //           }

  //           let orgle: any;
  //           orgle = document.getElementById(
  //             e.element.parentElement.parentElement.id + '_orgnl'
  //           );
  //           if (orgle !== null) {
  //             orgle.parentElement.style.display = 'block';
  //             orgle.textContent = e.itemData.value;
  //             e.element.parentElement.parentElement.style.display = 'none';
  //           }

  //           this.setState({ _addNew: true });
  //         })
  //         .catch((error: any) => {
  //           console.error(error);
  //         })
  //         .finally(() => {
  //           this._sl_ctrlid = '';
  //         });
  //     }
  //   }
  // }
  // * ------------------------------------------------------------------------------------------------------------------6.Adapter
  // * SANDEEP - 2023-03-17 - 300 -- working on methods for read elements and processing styles
  // * SANDEEP - 2023-03-18 - 400 -- working on methods for read elements and processing styles
  // * SANDEEP - 2023-03-20 - 400 -- working on methods for read elements and processing styles and generating card
  // * SANDEEP - 2023-03-21 - 300 -- working on methods for read elements based on attributes and processing styles and generating card
  // * SANDEEP - 2023-03-22 - 300 -- working on methods for read elements based on attributes and processing styles and generating card
  // * SANDEEP - 2023-03-24 - 120 -- Solving issues and creating xml file for address
  // * SANDEEP - 2023-03-24 - 300 -- working on paging implementation on cards for final copy
  // * SANDEEP - 2023-03-25 - 400 -- working on paging implementation on cards for final copy
  // * SANDEEP - 2023-03-26 - 40  -- Solving Bugs for no contact and header display
  // * SANDEEP - 2023-05-09 - 360  -- Trying to display data with minimum details in xml specially for array.
  // * SANDEEP - 2023-05-10 - 420  -- Trying to display data with minimum details in xml specially for array.
  // * SANDEEP - 2023-05-23 - 300  -- Implementing Icons and Styles on each tab and cards
  // * SANDEEP - 2023-05-24 - 300  -- Implementing Icons and Styles on each tab and cards

  private rgbToHex(value: string): string {
    var color: string;
    color = '';
    if (value.split(',').length > 1) {
      var r = value.split(',')[0] as unknown as number;
      var g = value.split(',')[1] as unknown as number;
      var b = value.split(',')[2] as unknown as number;
      color =
        '#' + ((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1);
    } else {
      color = value;
    }
    return color;
  }

  private Process_Card(xeml: any) {
    var _final: any[] = [];
    var _rows: any[] = [];
    for (let index = 0; index < xeml.children.length; index++) {
      if (xeml.children[index].name.toLowerCase() === 'grid') {
        var _child = xeml.children[index];
        _rows = this.getAttributeValues(_child, 'row');
        if (_rows !== undefined && _rows.length > 0) {
          for (let i = 0; i < _rows.length; i++) {
            var _extra: any[] = [];
            var _elements = this.getElementByAttributeValue(
              _child,
              'row',
              _rows[i]
            );
            var _text: any;
            for (let idx = 0; idx < _elements.length; idx++) {
              if (_elements[idx].name.toLowerCase() === 'bioinfo') {
                let _src = this.props._data[_elements[idx].attributes.source];
                if (Array.isArray(_src) === true) {
                  for (let e = 0; e < _src.length; e++) {
                    for (let c = 0; c < _elements[idx].children.length; c++) {
                      let _edata = _src[e];
                      _extra.push(
                        this.Process_Element(
                          _elements[idx].children[c],
                          i.toString() + idx.toString(),
                          _edata
                        )
                      );
                    }
                  }
                }
              }
              if (_elements[idx].name.toLowerCase() === 'text') {
                _text = this.Process_Element(
                  _elements[idx],
                  i.toString() + idx.toString(),
                  this.props._data
                );
                if (_text == undefined) {
                  if (_extra.length > 0) {
                    _extra = _extra.slice(0, -1);
                  }
                } else {
                  _extra.push(
                    this.Process_Element(
                      _elements[idx],
                      i.toString() + idx.toString(),
                      this.props._data
                    )
                  );
                }
              } else {
                _extra.push(
                  this.Process_Element(
                    _elements[idx],
                    i.toString() + idx.toString(),
                    this.props._data
                  )
                );
              }
            }
            _final.push(
              <div
                id={i.toString()}
                key={index.toString() + i.toString()}
                className='row linerow'
              >
                {_extra}
              </div>
            );
          }
        } else {
          for (let i = 0; i < _child.children.length; i++) {
            var _extra: any[] = [];
            if (this.props._data.cards !== undefined) {
              _extra.push(
                this.Process_Element(
                  _child.children[i],
                  i.toString() + i.toString(),
                  currentRecords
                )
              );
            } else {
              _extra.push(
                this.Process_Element(
                  _child.children[i],
                  i.toString() + i.toString(),
                  this.props._data
                )
              );
            }
            _final.push(
              <div
                id={i.toString()}
                key={index.toString() + i.toString()}
                className='row'
              >
                {_extra}
              </div>
            );
          }
        }
      }
    }
    return _final;
  }

  private DateDifferenceYMD(startDate: Date, endDate: Date) {
    const oneDayMs = 1000 * 60 * 60 * 24;
    const diffMs = endDate.getTime() - startDate.getTime();
    const diffDays = Math.floor(diffMs / oneDayMs);
    const years = Math.floor(diffDays / 365);
    const months = Math.floor(diffDays / 30.44) % 12;
    const days = diffDays - years * 365 - Math.floor(months * 30.44);
    return { years, months, days };
  }

  private AddAddressItem(
    arrdid: any,
    aitem: any,
    aitemvalue: any,
    mcardid: Number,
    scardid: Number,
    itmcrid: Number,
    valtchg: any,
    adrtypeid: any
  ) {
    let payload: any;
    let _type: any;
    let _namechg: any = null;
    let _titlechg: any = null;
    let _relationchg: any = null;
    let _commonData: any = null;
    switch (this.props._selectedtabname?.toLowerCase()) {
      case 'address':
        _type = 'Personal';
        break;
      case 'contact':
        _type = 'Emergency';
        break;
      case 'reference':
        _type = 'Reference';
        break;
    }
    if (valtchg !== undefined) {
      switch (valtchg.toLowerCase()) {
        case 'nchg':
          _namechg = aitemvalue;
          if (this.props._selectedtabname?.toLowerCase() === 'address') {
            _commonData = {
              Id: adrtypeid,
              ItemType: 7,
              ItemValue: aitemvalue,
            };
          }
          break;
        case 'tchg':
          _titlechg = aitemvalue;
          break;
        case 'kchg':
          _relationchg = aitemvalue;
          break;
      }
    }

    payload = {
      Id: !Number.isNaN(Number(arrdid)) ? null : arrdid,
      personsId: this.props._persomempid,
      type: _type,
      contacts: [
        {
          Id: mcardid,
          value: _namechg,
          title: _titlechg,
          commonDataId: _relationchg,
          CommonData: _commonData,
          cards: [
            {
              Id: scardid,
              items: [
                {
                  Id: itmcrid ? itmcrid : 0,
                  itemType: aitem,
                  itemValue: aitemvalue,
                },
              ],
            },
          ],
        },
      ],
    };
    if (payload != undefined) {
      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_CORESERVICE}/address/update`,
        payload,
        Common.getToken() || '',
        this.user,
        this.token.tenant
      )
        .then((resopnse: any) => {
          const index = this.props._data.cards.indexOf(
            this.props._data.cards.filter((x: any) => x.id == scardid)[0]
          );
          if (index > -1) {
            this.props._data.cards.splice(index, 1);
          }
          let ctx = resopnse.data.contacts.filter(
            (x: any) => x.id == this.props._maincardid
          );
          if (ctx != undefined) {
            let cards = ctx[0].cards.filter((x: any) => x.id == scardid)[0];
            if (cards) {
              this.props._data.cards.push(cards);
            }
            //.filter((x: any) => x.id == this._itemidx)[0].value = this._itemval;
          }
          if (_namechg && ctx) {
            this.props._data.value = ctx[0].value;
          } else if (_titlechg && ctx) {
            this.props._data.title = ctx[0].title;
          } else if (_relationchg && ctx) {
            this.props._data.commonDataId = ctx[0].commonDataId;
            this.props._data.commonData.id = ctx[0].commonData.id;
            this.props._data.commonData.itemType = ctx[0].commonData.itemType;
            this.props._data.commonData.itemValue = ctx[0].commonData.itemValue;
          }

          this._itemidx = -1;
          this._itemval = -1;
          this._itemname = '';

          this.setState({
            _addNew: true,
          });
        })
        .catch((error: any) => {
          console.error(error);
        })
        .finally(() => {});
    }
  }

  private SaveAddressItem(
    addrItem: any,
    fldvalue: any,
    mcardid: Number,
    scardid: Number,
    itmcrid: Number,
    valtchg?: any
  ) {
    let payload: any = {
      MainTableId: this._structure.Cluster.Identity.Person,
      FieldId: this._structure.Field.Identif.Address,
      AddressSection: this.props._data.account.name,
      AddressItem: addrItem,
      AddressEmRefNameCol: 'pname',
      AddressKinshipCol: 'kinship',
      AddressTitleCol: 'title',
      AsOnDateColumn: 'asondate',
      DocumentType: 'XML',
      MainTableOldIdColumn: 'personid',
      TypeColumn: '',
      FieldColumn: 'fldcolval',
      Data: [
        {
          personid: this.props._persomempid,
          pname: this.props._data.name,
          kinship:
            valtchg !== undefined && valtchg == 'kchg'
              ? fldvalue
              : this.props._data.kinship,
          title: this.props._data.title,
          asondate: Common.formatDate(
            new Date(
              this.props._data.cards.filter((x: any) => x.id == scardid)[0].date
            ),
            'yyyy-MM-dd'
          ),
          fldcolval: fldvalue,
          clone: false,
          subcrdid: scardid,
          itemcardid: itmcrid == undefined ? 0 : itmcrid,
          valuechange: valtchg == undefined ? '' : valtchg,
          maincardid: mcardid,
        },
      ],
    };
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_FOXSYSTEMSERVICE}/Person/Address/Set`,
      payload,
      Common.getToken() || '',
      this.user
    )
      .then((resopnse: any) => {
        if (resopnse.data.length <= 0) {
          if (Number(this._itemidx) > 0) {
            this.props._data.cards
              .filter((x: any) => x.id == scardid)[0]
              .items.filter((x: any) => x.id == this._itemidx)[0].value =
              this._itemval;
          }
        }

        this._itemidx = -1;
        this._itemval = -1;
        this._itemname = '';
        if (valtchg == undefined) {
          this.setState({
            _addNew: true,
          });
        } else if (valtchg !== undefined && valtchg !== 'kchg') {
          this.setState({
            _addNew: true,
          });
        }
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  private UpdatePersonInfo(payload: any) {
    if (payload != undefined) {
      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_CORESERVICE}/persons/update${
          !this.user.isManager ? '/ess' : ''
        }`,
        payload,
        Common.getToken() || '',
        this.user,
        this.token.tenant
      )
        .then((resopnse: any) => {
          let ctx = resopnse.data;
          if (ctx) {
            this.props._data.nationalityid = ctx.nationalityid;
            this.props._data.nationality = ctx.nationality;
            this.props._data.birthplace = ctx.birthplace;
          }
          this._itemidx = -1;
          this._itemval = -1;
          this._itemname = '';
          this.setState({ _addNew: true });
        })
        .catch((error: any) => {
          console.error(error);
        })
        .finally(() => {});
    }
  }

  private process_Margin(_value: string) {
    var _margin = '';
    if (_value.split(',').length > 1) {
      var _mval = _value.split(',');
      for (let i = 0; i < _mval.length; i++) {
        _margin = _margin + _mval[i] + 'px ';
      }
    } else {
      _margin = _value + 'px ';
    }
    return _margin;
  }

  private process_Padding(_value: string) {
    var _padding = '';
    if (_value.split(',').length > 1) {
      var _mval = _value.split(',');
      for (let i = 0; i < _mval.length; i++) {
        _padding = _padding + _mval[i] + 'px ';
      }
    } else {
      _padding = _value + 'px ';
    }
    return _padding;
  }

  private process_ValueInfo(
    _valu_attbrs: any,
    _value: any,
    _itemData: any,
    _isText: boolean,
    _itmid?: number
  ) {
    if (currentRecords !== undefined) {
      _itemData = currentRecords;
    }
    var _valueinfo: any;
    if (_valu_attbrs.field !== undefined) {
      if (_valu_attbrs.source !== undefined) {
        if (_valu_attbrs.source.includes('.') === true) {
          var _fld: any = _valu_attbrs.source;
          if (_itemData[_valu_attbrs.field] !== undefined) {
            if (
              _valu_attbrs.match !== undefined &&
              _valu_attbrs.match == 'itemType'
            ) {
              if (_valu_attbrs.fieldid == undefined) {
                if (
                  _itemData[_valu_attbrs.field].filter(
                    (x: any) => x.item === _fld.split('.')[0]
                  ).length > 0
                ) {
                  _valueinfo = _itemData[_valu_attbrs.field].filter(
                    (x: any) => x.item === _fld.split('.')[0]
                  )[0][_fld.split('.')[1]];
                } else {
                  _valueinfo = '';
                }
              } else {
                if (
                  _itemData[_valu_attbrs.field].filter(
                    (x: any) =>
                      x.itemType === _fld.split('.')[0] && x.id == _itmid
                  ).length > 0
                ) {
                  _valueinfo = _itemData[_valu_attbrs.field].filter(
                    (x: any) =>
                      x.itemType === _fld.split('.')[0] && x.id == _itmid
                  )[0][_fld.split('.')[1]];
                } else {
                  _valueinfo = '';
                }
              }
            } else {
              if (
                _itemData[_valu_attbrs.field].filter(
                  (x: any) => x.type === _fld.split('.')[0]
                ).length > 0
              ) {
                _valueinfo = _itemData[_valu_attbrs.field].filter(
                  (x: any) => x.type === _fld.split('.')[0]
                )[0][_fld.split('.')[1]];
              } else {
                _valueinfo = '';
              }
            }
          }
        } else {
          if (
            _itemData[_valu_attbrs.field] !== undefined &&
            _itemData[_valu_attbrs.field] !== null
          ) {
            _valueinfo =
              _itemData[_valu_attbrs.field][_valu_attbrs.source.toLowerCase()];
          }
        }
      } else {
        if (
          _itemData[_valu_attbrs.field] !== undefined &&
          _itemData[_valu_attbrs.field] !== null
        ) {
          _valueinfo = _itemData[_valu_attbrs.field][_value.toLowerCase()];
        }
      }
    } else {
      if (_valu_attbrs.source !== undefined) {
        if (_valu_attbrs.source.includes('.') === true) {
          var _fld = _valu_attbrs.source;

          if (_itemData[_fld.split('.')[0]] !== undefined) {
            _valueinfo = _itemData[_fld.split('.')[0]][_fld.split('.')[1]];
          } else if (this.props._data[_fld.split('.')[0]]) {
            _valueinfo =
              this.props._data[_fld.split('.')[0]][_fld.split('.')[1]];
          }
        } else {
          if (_itemData[_valu_attbrs.source] !== undefined) {
            _valueinfo = _itemData[_valu_attbrs.source];
          } else {
            _valueinfo = this.props._data[_valu_attbrs.source];
            if (
              _valueinfo !== undefined &&
              _valueinfo !== null &&
              _valueinfo.toString().startsWith('_') &&
              _valueinfo.toString().endsWith('_')
            ) {
              let obj = Common.FindJsonByObjectValue(
                this._fulljson,
                _valueinfo,
                this.user,
                0,
                0,
                true
              );
              if (obj) {
                _valueinfo = Common.FindJsonByObjectId(
                  this._fulljson,
                  obj.object,
                  this.user
                ).value;
              }
            }
          }
        }
      } else {
        if (_isText == true) {
          _valueinfo = _itemData[_value.toLowerCase()];
        } else {
          _valueinfo = _value;
        }
      }
    }

    if (_valueinfo == undefined) {
      _valueinfo = '';
    }
    if (_valu_attbrs.type !== undefined) {
      if (_valu_attbrs.type.toLowerCase() == 'date') {
        if (isNaN(_valueinfo) === true) {
          let x = new Date(_valueinfo.toString());
          if (x instanceof Date && !isNaN(x.valueOf())) {
            //_valueinfo = new Date(_valueinfo).toDateString().substring(4);
            _valueinfo = Common.formatDate(
              new Date(_valueinfo),
              this.props._user.settings.formats.DateShort
            );
          }
        }
      } else if (_valu_attbrs.type.toLowerCase() == 'fetch') {
        if (parseInt(_valueinfo) > 0) {
          _valueinfo = Object.keys(this._structure.CommonDataItems).find(
            (key) =>
              this._structure.CommonDataItems[key] === parseInt(_valueinfo)
          );
          if (
            this._structure.CommonDataTempLanguage[_valueinfo] !== undefined
          ) {
            _valueinfo = this._structure.CommonDataTempLanguage[_valueinfo];
          }
        }
        // let x = Common.FindParentJsonByIdKeyChildren(
        //   this._fulljson,
        //   parseInt(_valueinfo),
        //   'object',
        //   'children',
        //   null
        // );
        // if (x) {
        //   _valueinfo = x.value;
        // }
      }
    }

    return _valueinfo;
  }

  private Process_Element(element: any, _idx: any, jsonData: any) {
    var _src_attbrs;
    var _prop_attbrs;
    var _fnt_attbrs;
    var _bord_attbrs;
    var _valu_attbrs;

    var _value: any;
    var _mstyle: any = { attributes: {} };
    if (this.ReadSource(element).length > 0) {
      _src_attbrs = this.Child_Element_Attributes(element, 'Source');
      _value = this.Child_Element_Value(element, 'Source');
    }

    if (this.ReadProperties(element).length > 0) {
      _prop_attbrs = this.Child_Element_Attributes(element, 'Properties');
    }

    if (this.ReadFont(element).length > 0) {
      _fnt_attbrs = this.Child_Element_Attributes(element, 'Font');
    }

    if (this.ReadBorder(element).length > 0) {
      _bord_attbrs = this.Child_Element_Attributes(element, 'Border');
    }

    if (this.ReadValue(element).length > 0) {
      _value = this.Child_Element_Value(element, 'Value');
      _valu_attbrs = this.Child_Element_Attributes(element, 'Value');
    }

    if (_prop_attbrs !== undefined) {
      if (_prop_attbrs.padding !== undefined) {
        _mstyle.attributes['padding'] =
          _prop_attbrs.padding !== undefined
            ? this.process_Padding(_prop_attbrs.padding)
            : 'inherit';
      }
      if (_prop_attbrs.width !== undefined) {
        _mstyle.attributes['width'] =
          _prop_attbrs.width !== undefined ? _prop_attbrs.width + '%' : '100%';
      }
      if (_prop_attbrs.margin !== undefined) {
        _mstyle.attributes['margin'] =
          _prop_attbrs.margin !== undefined
            ? this.process_Margin(_prop_attbrs.margin)
            : 'inherit';
      }
      if (_prop_attbrs.lineheight !== undefined) {
        _mstyle.attributes['lineHeight'] =
          _prop_attbrs.lineheight !== undefined
            ? _prop_attbrs.lineheight + 'px'
            : 'inherit';
      }
      if (_prop_attbrs.spacing !== undefined) {
        _mstyle.attributes['whiteSpace'] =
          _prop_attbrs.spacing !== undefined
            ? _prop_attbrs.spacing + 'px'
            : 'pre-wrap';
      }
      if (_prop_attbrs.alignment !== undefined) {
        _mstyle.attributes['textAlign'] =
          _prop_attbrs.alignment !== undefined ? _prop_attbrs.alignment : '';
      }
    }

    if (_bord_attbrs !== undefined) {
      if (_bord_attbrs.width !== undefined) {
        _mstyle.attributes['borderWidth'] =
          _bord_attbrs.width !== undefined
            ? _prop_attbrs.margin + 'px'
            : 'fit-content';
      }
      if (_bord_attbrs.color !== undefined) {
        _mstyle.attributes['color'] =
          _bord_attbrs.color !== undefined
            ? this.rgbToHex(_bord_attbrs.color)
            : 'transparent';
      }
      if (_bord_attbrs.style !== undefined) {
        _mstyle.attributes['borderStyle'] =
          _bord_attbrs.style !== undefined ? _bord_attbrs.style : 'solid';
      }
    }

    if (_fnt_attbrs !== undefined) {
      if (_fnt_attbrs.family !== undefined) {
        _mstyle.attributes['fontFamily'] =
          _fnt_attbrs.family !== undefined ? _fnt_attbrs.family : 'inherit';
      }
      if (_fnt_attbrs.size !== undefined) {
        _mstyle.attributes['fontSize'] =
          _fnt_attbrs.size !== undefined ? _fnt_attbrs.size + 'px' : '1px';
      }
      if (_fnt_attbrs.color !== undefined) {
        _mstyle.attributes['color'] =
          _fnt_attbrs.color !== undefined ? _fnt_attbrs.color : 'transparent';
      }
      if (_fnt_attbrs.weight !== undefined) {
        _mstyle.attributes['fontWeight'] =
          _fnt_attbrs.weight !== undefined ? _fnt_attbrs.weight : 'inherit';
      }
      if (_fnt_attbrs.decoration !== undefined) {
        _mstyle.attributes['textDecoration'] =
          _fnt_attbrs.decoration !== undefined
            ? _fnt_attbrs.decoration
            : 'none';
      }
      if (_fnt_attbrs.show !== undefined) {
        if (_fnt_attbrs.show.toLowerCase() == 'false') {
          _mstyle.attributes['display'] = 'none';
        }
      }
    }

    let _dataid = null;

    if (_valu_attbrs !== undefined) {
      if (
        _valu_attbrs.psource !== undefined &&
        _valu_attbrs.psource == 'nationality'
      ) {
        _dataid = jsonData['nationalityid'];
      } else {
        _dataid = jsonData && jsonData.id ? jsonData.id : '';
      }
    }
    let _datapid: any = '';
    if (_valu_attbrs !== undefined) {
      if (_valu_attbrs.psource !== undefined) {
        if (_valu_attbrs.psource == 'nationality') {
          _datapid = jsonData.id;
        } else {
          _datapid =
            jsonData[_valu_attbrs.psource.split('.')[0]][
              _valu_attbrs.psource.split('.')[1]
            ];
        }
        // let x = Common.FindParentJsonByIdKeyChildren(
        //   this._fulljson,
        //   parseInt(
        //     jsonData[_valu_attbrs.psource.split('.')[0]][
        //       _valu_attbrs.psource.split('.')[1]
        //     ]
        //   ),
        //   'object',
        //   'children',
        //   null
        // );
        // if (x) {
        //   _datapid = x.object;
        // }
      }
    }

    if (element.name.toLowerCase() === 'image') {
      var _class = '';
      if (element.attributes.col !== undefined) {
        _class = 'offset-' + element.attributes.col.toString();
      }
      if (element.attributes.cols !== undefined) {
        _class = _class + ' col-' + element.attributes.cols.toString();
      }

      if (
        this.props._selectedtabname !== undefined &&
        this.props._selectedtabname.toLowerCase() == 'bio'
      ) {
        _mstyle.attributes['cursor'] = 'pointer';
      }
      if (_value === '') {
        _mstyle.attributes['fontSize'] = '256px';
        _mstyle.attributes['color'] = 'gray';
        return (
          <>
            <div key={_idx.toString()} className={_class}>
              <i
                className='fa fa-user-tie'
                style={_mstyle.attributes}
                onDoubleClick={this.ImageDblclicked.bind(this)}
              ></i>
            </div>
          </>
        );
      } else {
        return (
          <>
            <div
              key={_idx.toString()}
              className={_class}
              style={{ marginBottom: '10px' }}
            >
              {' '}
              <img
                src={
                  this.state.profileImage
                    ? `data:image/png;base64,${this.state.profileImage}`
                    : // : this.state.fImage
                      // ? this.state.fImage
                      _value
                }
                alt={_src_attbrs.alt !== undefined ? _src_attbrs.alt : ''}
                style={_mstyle.attributes}
                className='light-border-class'
                onDoubleClick={this.ImageDblclicked.bind(this)}
              ></img>
            </div>
          </>
        );
      }

      // return <>
      //   <div key={_idx.toString()} className={_class}>
      //     <img src={_value} alt={_src_attbrs.alt !== undefined ? _src_attbrs.alt : ''} style={_mstyle.attributes} onDoubleClick={this.ImageDblclicked.bind(this)}></img>
      //   </div>
      // </>
    } else if (element.name.toLowerCase() === 'label') {
      var _class = '';
      if (element.attributes.col !== undefined) {
        _class = 'offset-' + element.attributes.col.toString();
      }
      if (element.attributes.cols !== undefined) {
        _class = _class + ' col-' + element.attributes.cols.toString();
      }

      var _valueinfo = '';
      if (_value !== undefined) {
        if (jsonData !== null && jsonData !== undefined) {
          _valueinfo = this.process_ValueInfo(
            _valu_attbrs,
            _value,
            jsonData,
            false
          );
        }
      }

      let _lblid: any;
      if (jsonData && jsonData.id !== undefined) {
        _lblid = 'lbl_' + jsonData.id.toString();
      } else {
        _lblid = Math.random().toString();
      }
      var _jsxItem: any;
      if (_fnt_attbrs.icon !== undefined) {
        _class = _class + ' e-icon-opacity';
        var iconclassname = 'fa ' + _fnt_attbrs.icon;
        if (_mstyle.attributes.textAlign !== undefined) {
          _jsxItem = (
            <div
              key={_idx.toString()}
              className={_class}
              style={{ textAlign: _mstyle.attributes.textAlign }}
            >
              <i className={iconclassname} style={_mstyle.attributes}></i>
            </div>
          );
        } else {
          _jsxItem = (
            <div key={_idx.toString()} className={_class}>
              <i className={iconclassname} style={_mstyle.attributes}></i>
            </div>
          );
        }
      } else {
        if (_mstyle.attributes.textAlign !== undefined) {
          _jsxItem = (
            <div
              key={_idx.toString()}
              className={_class}
              style={{ textAlign: _mstyle.attributes.textAlign }}
            >
              <label
                id={_lblid}
                style={_mstyle.attributes}
                data-subcrd={jsonData.id ? jsonData.id : '-1'}
              >
                {_valueinfo}
              </label>
            </div>
          );
        } else {
          _jsxItem = (
            <div key={_idx.toString()} className={_class}>
              <label
                id={_lblid}
                style={_mstyle.attributes}
                data-subcrd={jsonData.id ? jsonData.id : '-1'}
              >
                {_valueinfo}
              </label>
            </div>
          );
        }
      }
      return _jsxItem;
    } else if (element.name.toLowerCase() === 'text') {
      var _class = '';
      if (element.attributes.col !== undefined) {
        _class = 'offset-' + element.attributes.col.toString();
      }
      if (element.attributes.cols !== undefined) {
        _class = _class + ' col-' + element.attributes.cols.toString();
      }
      var _valueinfo = '';
      var _jsxs: any[] = [];
      var _ctrl_id = '';

      if (jsonData !== null && jsonData !== undefined) {
        if (_valu_attbrs.field !== undefined) {
          if (
            Array.isArray(jsonData[_valu_attbrs.field]) == true &&
            _valu_attbrs.source.includes('.') == false
          ) {
            var _originalSrc = _valu_attbrs.source;
            for (let i = 0; i < jsonData[_valu_attbrs.field].length; i++) {
              var _srcItem = jsonData[_valu_attbrs.field][i];
              var _imgdata: any;
              if (_valu_attbrs.source.includes('.') == false) {
                _valu_attbrs.source = _srcItem.itemType + '.' + _originalSrc;
                _ctrl_id = _srcItem.itemType;
                if (
                  Number(this._structure.AddressItems[_srcItem.itemType]) ==
                  this._structure.AddressItems.Mobile
                ) {
                  _imgdata = (
                    <i
                      className='fa fa-mobile'
                      style={{ fontSize: '20px', color: 'gray' }}
                      data-item={_ctrl_id}
                    ></i>
                  );
                } else if (
                  Number(this._structure.AddressItems[_srcItem.itemType]) ==
                  this._structure.AddressItems.Telephone
                ) {
                  _imgdata = (
                    <i
                      className='fa fa-phone'
                      style={{ fontSize: '20px', color: 'gray' }}
                      data-item={_ctrl_id}
                    ></i>
                  );
                } else if (
                  Number(this._structure.AddressItems[_srcItem.itemType]) ==
                  this._structure.AddressItems.Email
                ) {
                  _imgdata = (
                    <i
                      className='fa fa-at'
                      style={{ fontSize: '20px', color: 'gray' }}
                      data-item={_ctrl_id}
                    ></i>
                  );
                } else if (
                  Number(this._structure.AddressItems[_srcItem.itemType]) ==
                  this._structure.AddressItems.Position
                ) {
                  _imgdata = (
                    <i
                      className='fa fa-landmark-dome'
                      style={{ fontSize: '20px', color: 'gray' }}
                      data-item={_ctrl_id}
                    ></i>
                  );
                } else if (
                  Number(this._structure.AddressItems[_srcItem.itemType]) ==
                  this._structure.AddressItems.Domicile
                ) {
                  _imgdata = (
                    <i
                      className='fa fa-house'
                      style={{ fontSize: '20px', color: 'gray' }}
                      data-item={_ctrl_id}
                    ></i>
                  );
                } else if (
                  Number(this._structure.AddressItems[_srcItem.itemType]) ==
                  this._structure.AddressItems.Location
                ) {
                  _imgdata = (
                    <i
                      className='fa fa-location-dot'
                      style={{ fontSize: '20px', color: 'gray' }}
                      data-item={_ctrl_id}
                    ></i>
                  );
                } else if (
                  Number(this._structure.AddressItems[_srcItem.itemType]) ==
                  this._structure.AddressItems.PoBox
                ) {
                  _imgdata = (
                    <i
                      className='fa fa-envelope'
                      style={{ fontSize: '20px', color: 'gray' }}
                      data-item={_ctrl_id}
                    ></i>
                  );
                } else if (
                  Number(this._structure.AddressItems[_srcItem.itemType]) ==
                  this._structure.AddressItems.City
                ) {
                  _imgdata = (
                    <i
                      className='fa fa-city'
                      style={{ fontSize: '20px', color: 'gray' }}
                      data-item={_ctrl_id}
                    ></i>
                  );
                } else if (
                  Number(this._structure.AddressItems[_srcItem.itemType]) ==
                  this._structure.AddressItems.Fax
                ) {
                  _imgdata = (
                    <i
                      className='fa fa-fax'
                      style={{ fontSize: '20px', color: 'gray' }}
                      data-item={_ctrl_id}
                    ></i>
                  );
                } else if (
                  Number(this._structure.AddressItems[_srcItem.itemType]) ==
                  this._structure.AddressItems.Website
                ) {
                  _imgdata = (
                    <i
                      className='fab fa-internet-explorer'
                      data-item={_ctrl_id}
                      style={{ fontSize: '20px', color: 'gray' }}
                    ></i>
                  );
                }
                _jsxs.push(<div className='col-1'>{_imgdata}</div>);
              }
              _valueinfo = this.process_ValueInfo(
                _valu_attbrs,
                _value,
                jsonData,
                true,
                _valu_attbrs.fieldid == undefined ? 0 : _srcItem.id
              );
              _valu_attbrs.source = _originalSrc;

              if (_valu_attbrs.control !== undefined) {
                switch (_valu_attbrs.control.toLowerCase()) {
                  case 'textbox':
                    let txtcss: any = { attributes: {} };

                    txtcss = JSON.parse(JSON.stringify(_mstyle));
                    txtcss.attributes['width'] = '90%';
                    if (
                      _valueinfo !== undefined &&
                      _valueinfo.toString().length > 0
                    ) {
                      txtcss.attributes['border'] = 'none';
                      txtcss.attributes['cursor'] = 'pointer';
                    } else {
                      txtcss.attributes['border'] = '1px solid black';
                      txtcss.attributes['cursor'] = 'default';
                    }
                    txtcss.attributes['height'] = '32px';
                    txtcss.attributes['backgroundColor'] = 'transparent';
                    txtcss.attributes['color'] = 'black';

                    let _id =
                      'txt_' +
                      (jsonData.id ? jsonData.id.toString() : '0') +
                      _srcItem.id.toString() +
                      _ctrl_id;
                    _jsxs.push(
                      <div
                        key={Math.random()}
                        id={_id.replace('txt_', 'div_')}
                        style={{ width: '91%' }}
                        onDoubleClick={this.inptcarddblclick.bind(this)}
                      >
                        <input
                          key={Math.random()}
                          id={_id}
                          type='text'
                          style={txtcss.attributes}
                          data-item={_ctrl_id}
                          // disabled={_valueinfo ? true : false}
                          // autoFocus={_valueinfo ? false : true}
                          onBlur={this.inptcardblur.bind(this)}
                          data-inptid={_srcItem.id ? _srcItem.id : '-1'}
                          data-subcrd={jsonData.id ? jsonData.id : '-1'}
                          defaultValue={_valueinfo ? _valueinfo : ''}
                        ></input>
                      </div>
                    );
                    break;
                  default:
                    _jsxs.push(
                      <div key={_idx.toString()} className={_class}>
                        <label
                          typeof='text'
                          style={_mstyle.attributes}
                          data-item={_ctrl_id}
                          data-subcrd={jsonData.id ? jsonData.id : '-1'}
                        >
                          {_valueinfo ? _valueinfo : ''}
                        </label>
                      </div>
                    );
                    break;
                }
              } else {
                _jsxs.push(
                  <div key={_idx.toString()} className={_class}>
                    <label
                      typeof='text'
                      style={_mstyle.attributes}
                      data-item={_ctrl_id}
                      data-subcrd={jsonData.id ? jsonData.id : '-1'}
                    >
                      {_valueinfo ? _valueinfo : ''}
                    </label>
                  </div>
                );
              }
            }
          } else {
            _valueinfo = this.process_ValueInfo(
              _valu_attbrs,
              _value,
              jsonData,
              true
            );
            if (_valueinfo !== undefined && _valueinfo.toString().length > 0) {
              if (_mstyle.attributes.textAlign !== undefined) {
                _jsxs.push(
                  <div
                    key={_idx.toString()}
                    className={_class}
                    style={{ textAlign: _mstyle.attributes.textAlign }}
                  >
                    <label
                      typeof='text'
                      style={_mstyle.attributes}
                      data-item={_ctrl_id}
                      data-subcrd={jsonData.id ? jsonData.id : '-1'}
                    >
                      {_valueinfo}
                    </label>
                  </div>
                );
              } else {
                _jsxs.push(
                  <div key={_idx.toString()} className={_class}>
                    <label
                      typeof='text'
                      style={_mstyle.attributes}
                      data-item={_ctrl_id}
                      data-subcrd={jsonData.id ? jsonData.id : '-1'}
                    >
                      {_valueinfo}
                    </label>
                  </div>
                );
              }
            } else {
              return undefined;
            }
          }
        } else {
          if (_valu_attbrs.icon !== undefined) {
            if (_valu_attbrs.icon.toLowerCase() == 'person') {
              //_imgdata = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAfCAYAAADwbH0HAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAKFSURBVEhL7ZZNS6pBFMfvF2vhV3DhWiWllF4UIRIXpSi6CqEkNVDISig37aJFC1EpBElBfKlQWviCupF8wex/OXMnbt1HfeZ6o7vxB89iznOY/8w5Z87MD/wnFsIzyWQy2Nvbg8lkwsbGBtxuNxKJBN7e3riHOMLCgUAAKysruLi4QLVaRaPRwPX1Nba2ttgijo+PMR6Pubc8QsKXl5dsly8vL9zymXw+D5vNBr/fL7x7WeHBYIDNzU0UCgVu+c3z8zMsFgtOT0/R7/dhNptRr9f539nICj88PGB3d1cSxpubGywvL+Ps7Ax2ux3hcBjb29sol8vcYzaywiRAE7ZaLVQqFTbe2dmB0WjE/f098xkOh1hfX4dCoUCz2WQ2OWSFqZKXlpagUqmwtrbGRCnnFNqP0IKUSiVLjQhCxRWLxeD1evloMrlcDk6nU7iyhYQpz3RkRqMRt0gJBoM4PDzkI3mEhGkX1Cx8Ph+3fIZ2S4VWq9W4RR4hYaLT6bDjQuEsFot4fX1Fu93G+fk5NBoNkskk9xRDWJjo9XqIRCJsATqdjlW2x+PB09MT9xDnr4TfodB3u122kHkREqawplIp7O/vs578EfoXCoVwcHCAeDwuOWbTmClMjSEajbLQWq1WVrUOh4O10JOTExZ2qnaXy8UuEerXNKaFTOvr70wVvru7w+rqKhPKZrPc+ovb21scHR2xj/w+QoVHJ4CqnK7MaUwUvrq6glarZeGdl3Q6DbVazeaahESYjg1V6+PjI7fMD81hMBjYnH8iES6VSiyHXwXlfNKNJRGmHFExfRX0gFgIExJhej/p9Xo++neotU56NkmEqfHT6/GroLmEqvq7WAh/E8BPuPLT/igo3P4AAAAASUVORK5CYII="
              _ctrl_id = '_person';

              _imgdata = (
                <i
                  className='fa fa-user'
                  data-item={_ctrl_id}
                  data-subcrd={jsonData.id ? jsonData.id : '-1'}
                  style={{ fontSize: '20px', color: 'gray' }}
                ></i>
              );
            } else if (_valu_attbrs.icon.toLowerCase() == 'relation') {
              //_imgdata = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGySURBVEhL5ZY7i8JAFIX3p2ppIb4gIoI2vmoVFRG0EhSxMaW9P8JSQUXxiYIKnuXcncC6O7BOdFPsfjCQ8cp8yWRmTt7gMf9cOJ/PcblcVO81aIWLxQLpdBrRaBSWZWE8HqvK82iF+XwevV5PrkejEWKxmEin0ykmk8lTT64VhkKhuwF9Ph8ikYjcSC6XQyAQwHA4VFUztMJSqYRKpYL9fo9+v49UKoXNZoPj8Sit2Wyi3W6rf5uhFXLQYrGIcDiMbDaL5XKpKh9wurvdruqZoRX+BGWeCgeDgTQ3GAsPh4NMNRvfsSlGwu12i2QyiXq9Li2RSMhiMsFIyPfG1etQrVbR6XRU7zGMhBz8s7BWq/2ukNPHaWw0GtJ4vV6vVfUxjBcNF4pni8bBtm3vtgXxfONT5qSJw2q1QqFQkEO+XC7L8ajDlZAHd6vVwul0ksb9yfzkTex2O1nJPIt1uBIymhhRjCpGFoPa7/erKnA+nxEMBlXvHlfC6/UqIcwwZigznBnSDGvCSMtkMnL9FVdCHZTG43ERc3pns5mq3PMyIeFXAp/8drupX77zUuEj/HUh8A5mI8dGFPiu6wAAAABJRU5ErkJggg=="
              _ctrl_id = '_relation';
              _imgdata = (
                <i
                  className='fa fa-chain'
                  data-item={_ctrl_id}
                  style={{ fontSize: '20px', color: 'gray' }}
                ></i>
              );
            } else if (_valu_attbrs.icon.toLowerCase() == 'education') {
              //_imgdata = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAH5SURBVEhL7ZS5imJREIb7/TTwAQwMfQU3XAIVl0RcAhcUwcAFRcxUUCMFdzRwA8FAFAXBRFH8h6q5l5nbfce2Zeiguz8oqHtq+Q+nzrkv+CR+hJ7mmwstl0ukUik28j/Cu0Lb7RaFQgF2ux1WqxXpdJqNfFrL5/Oc8x6yQsfjEZVKBR6PB0ajEdFoFKPRSIj+YTwec4xy3G43yuUy18ohEer1eggEAlzo9/vRbDZxuVyE6L+5Xq9otVpcYzKZuEe/3xeiv2GhdrsNlUoFjUaDWq2G2+3GwWepVqtQq9Xck3oTLHQ4HJDNZqHVaqHX65HL5bBarTjhI1ANzY+O0ev1ck/qTUiOLhwOo1QqIR6PQ6lUckEmk7krSjHKoXkqFAoEg0HZfIlQKBTCZDLBer2G0+nkgkQiAbPZDIfDwZvY7/ds5FMOxWhjlEu3cLFYCN2kvBGi2zWfz3mHIjSz4XCISCTCjcnIp7W/5+lyuXhNDomQz+fjxNlsxmcsx/l8xul0Er6k0FG/vm0iEqFOp4Pdbsev3mAwCKuPo9PpMJ1OhS8pEiERehexWIxfP4nfe0sU63a7sNlsXEO1csgKiTQaDR64xWLhmSWTSb76ZOTTGm2Gcur1ulAlz10hkc1mg8FgwL+lYrHIRj7Ng2KP8JDQ/+BH6Gm+mhDwC4jSADYdtqgcAAAAAElFTkSuQmCC"
              _ctrl_id = '_education';
              _imgdata = (
                <i
                  className='fa fa-graduation-cap'
                  style={{ fontSize: '20px', color: 'gray' }}
                  data-item={_ctrl_id}
                ></i>
              );
            }

            // _jsxs.push(<div className="col-1"><img src={_imgdata}></img></div>);
            _jsxs.push(<div className='col-1'>{_imgdata}</div>);
          }
          _valueinfo = this.process_ValueInfo(
            _valu_attbrs,
            _value,
            jsonData,
            true
          );

          if (_valueinfo !== undefined && _valueinfo.toString().length > 0) {
            let dymc_ctrl: any;
            let iName: string = '';
            let fName: string = _ctrl_id;
            if (_valu_attbrs.control !== undefined) {
              switch (_valu_attbrs.control.toLowerCase()) {
                case 'textbox':
                  let txtcss: any = { attributes: {} };
                  let _par: any = '';
                  if (_valu_attbrs.source == 'value') {
                    fName =
                      'txt_' +
                      this.props._data.value +
                      this.props._data.id.toString();

                    _par = 'nchg';
                  } else if (_valu_attbrs.source == 'title') {
                    fName = 'txt_title' + this.props._data.id.toString();
                    _par = 'tchg';
                  } else if (_valu_attbrs.source == 'kinship') {
                    fName = 'txt_kinship' + this.props._data.id.toString();
                    _par = 'kchg';
                  } else if (_valu_attbrs.source == 'birthplace') {
                    fName = 'txt_' + this.props._data.id.toString();
                  }

                  txtcss = JSON.parse(JSON.stringify(_mstyle));
                  txtcss.attributes['width'] = '90%';
                  if (
                    _valueinfo !== undefined &&
                    _valueinfo.toString().length > 0
                  ) {
                    txtcss.attributes['border'] = 'none';
                    txtcss.attributes['cursor'] = 'pointer';
                  } else {
                    txtcss.attributes['border'] = '1px solid black';
                    txtcss.attributes['cursor'] = 'default';
                  }

                  txtcss.attributes['backgroundColor'] = 'transparent';
                  txtcss.attributes['color'] = 'black';
                  dymc_ctrl = (
                    <div
                      key={Math.random()}
                      id={fName.replace('txt_', 'div_')}
                      style={{
                        width: '91%',
                        display: 'none',
                        marginLeft: '-2px',
                      }}
                      onClick={this.inptcarddblclick.bind(this)}
                    >
                      <input
                        key={Math.random()}
                        id={fName}
                        type='text'
                        style={txtcss.attributes}
                        autoFocus={_valueinfo ? false : true}
                        data-subcrd={jsonData.id ? jsonData.id : '-1'}
                        data-par={_par}
                        onBlur={this.inptcardblur.bind(this)}
                        defaultValue={_valueinfo ? _valueinfo : ''}
                      ></input>
                    </div>
                  );
                  break;
                case 'date':
                  // fName =
                  //   iName + 'dt_' + new Date(_valueinfo).getTime().toString();
                  fName = iName + 'dt_dob';
                  dymc_ctrl = (
                    <div id={fName} style={{ display: 'none' }}>
                      <DatePickerComponent
                        id='dt_date'
                        format={this.props._user.settings.formats.DateShort}
                        value={
                          _valueinfo == undefined
                            ? new Date()
                            : new Date(_valueinfo)
                        }
                        showClearButton={false}
                        cssClass='e-hidedate-icon'
                        // onFocus={this.onFocus.bind(this)}
                        onBlur={this.dtOnBlur.bind(this)}
                        openOnFocus={true}
                        onChange={this.dtOnChange.bind(this)}
                        showTodayButton={false}
                        style={{ fontSize: '14px' }}
                      />
                    </div>
                  );
                  break;
                case 'select':
                  let iSorce: any;
                  let _text_: string = '';
                  let _id_: string = '';
                  if (this.props._selectedtabname?.toLowerCase() === 'bio') {
                    if (_valu_attbrs.source == 'nationality') {
                      _text_ = jsonData['nationality'];
                      _id_ = jsonData['nationalityid'];
                    } else {
                      _text_ = jsonData.commonData
                        ? jsonData.commonData.itemValue
                        : '';
                      _id_ = jsonData.commonData ? jsonData.commonData.id : '';
                    }
                  } else if (
                    this.props._selectedtabname?.toLowerCase() === 'contact'
                  ) {
                    _datapid = this.props._data.id;
                    _text_ = this.props._data.commonData.itemValue;
                    _id_ = this.props._data.commonData.id;
                    iSorce = this.state._relation;
                    iName = '_RT';
                    // let robj = Common.FindJsonByObjectValue(
                    //   this._fulljson,
                    //   this.props._data.kinship,
                    //   this.user,
                    //   0,
                    //   0,
                    //   true
                    // );
                    // if (robj) {
                    //   let _relt = Common.FindJsonByObjectId(
                    //     this._fulljson,
                    //     robj.object,
                    //     this.user
                    //   );
                    //   _text_ = _relt.value;
                    //   _id_ = _relt.object;
                    //   iSorce = this.state._relation;
                    //   iName = '_RT';
                    // }
                  }

                  if (_valu_attbrs.psource !== undefined) {
                    let xobj: any;

                    if (_valu_attbrs.psource == 'nationality') {
                      xobj = _valu_attbrs.psource;
                    } else {
                      xobj =
                        jsonData[_valu_attbrs.psource.split('.')[0]][
                          _valu_attbrs.psource.split('.')[1]
                        ];
                    }
                    // let xobj = Common.FindParentJsonByIdKeyChildren(
                    //   this._fulljson,
                    //   parseInt(
                    //     jsonData[_valu_attbrs.psource.split('.')[0]][
                    //       _valu_attbrs.psource.split('.')[1]
                    //     ]
                    //   ),
                    //   'object',
                    //   'children',
                    //   null
                    // );
                    let _ctrlid: any = null;
                    if (xobj) {
                      // _ctrlid = Common.FindJsonByObjectId(
                      //   this._fulljson,
                      //   xobj.object,
                      //   this.user,
                      //   this._structure.Field.Identif.SysName
                      // );
                      _ctrlid = xobj;
                    }
                    if (_ctrlid) {
                      switch (_ctrlid) {
                        case this.user.structure.CommonDataItems.Religion:
                          iSorce = this.state._religion;
                          iName = '_RL';
                          break;
                        case this.user.structure.CommonDataItems.Gender:
                          iSorce = this.state._gender;
                          iName = '_GN';
                          break;
                        case this.user.structure.CommonDataItems.MaritalStatus:
                          iSorce = this.state._maritalstatus;
                          iName = '_MS';
                          break;

                        case _valu_attbrs.psource:
                          iSorce = this.state._nationality;
                          iName = '_NT';
                          break;
                      }
                    }
                  }
                  // fName =
                  //   iName + 'sl_' + _valueinfo.toString().replace('', '_');
                  fName = iName + 'sl_' + _id_.toString().replace('', '_');
                  dymc_ctrl = (
                    <div
                      id={fName}
                      style={{ display: 'none' }}
                      data-cid={_dataid}
                      data-pid={_datapid}
                    >
                      <DropDownListComponent
                        //key={Math.random()}
                        popupWidth={'300px'}
                        dataSource={iSorce}
                        // ref={(scope) => {
                        //   this.listdocumenttype = scope;
                        // }}
                        filterBarPlaceholder=''
                        allowFiltering={true}
                        filterType='Contains'
                        fields={this.cfields}
                        change={this.slOnChange.bind(this)}
                        blur={this.slOnBlur.bind(this)}
                        // showClearButton={
                        //   fName != null &&
                        //   fName != '' &&
                        //   fName != undefined &&
                        //   fName.startsWith('_NT')
                        //     ? true
                        //     : false
                        // }
                        placeholder='Select Info'
                        popupHeight='220px'
                        value={_id_}
                        text={_text_}
                        style={{
                          fontSize: '14px',
                        }}
                      ></DropDownListComponent>
                    </div>
                  );
                  break;
                default:
                  break;
              }
            }
            _mstyle.attributes['cursor'] =
              _valu_attbrs.control !== undefined ? 'pointer' : 'default';
            _mstyle.attributes['userSelect'] =
              _valu_attbrs.control !== undefined ? 'none' : 'element';

            if (_mstyle.attributes.textAlign !== undefined) {
              _jsxs.push(
                <div
                  key={_idx.toString()}
                  className={_class}
                  style={{ textAlign: _mstyle.attributes.textAlign }}
                >
                  <div>
                    <label
                      id={fName + '_orgnl'}
                      typeof='text'
                      itemType={
                        _valu_attbrs.control !== undefined
                          ? _valu_attbrs.control
                          : ''
                      }
                      style={_mstyle.attributes}
                      onClick={this.lblDoubleClick.bind(this)}
                      data-subcrd={jsonData.id ? jsonData.id : '-1'}
                    >
                      {_valueinfo}
                    </label>
                  </div>
                  {dymc_ctrl}
                </div>
              );
            } else {
              _jsxs.push(
                <div key={_idx.toString()} className={_class}>
                  <div>
                    <label
                      id={fName + '_orgnl'}
                      typeof='text'
                      style={_mstyle.attributes}
                      itemType={
                        _valu_attbrs.control !== undefined
                          ? _valu_attbrs.control
                          : ''
                      }
                      onDoubleClick={this.lblDoubleClick.bind(this)}
                      data-subcrd={jsonData.id ? jsonData.id : '-1'}
                    >
                      {_valueinfo}
                    </label>
                  </div>
                  {dymc_ctrl}
                </div>
              );
            }
          } else {
            return undefined;
          }
        }
      }
      return _jsxs;
    }
  }

  private Process_Element_Old(element: any, _idx: any) {
    var _src_attbrs;
    var _prop_attbrs;
    var _fnt_attbrs;
    var _bord_attbrs;
    var _valu_attbrs;
    var _value: any;
    var _mstyle: any = { attributes: {} };
    if (this.ReadSource(element).length > 0) {
      _src_attbrs = this.Child_Element_Attributes(element, 'Source');
      _value = this.Child_Element_Value(element, 'Source');
    }

    if (this.ReadProperties(element).length > 0) {
      _prop_attbrs = this.Child_Element_Attributes(element, 'Properties');
    }

    if (this.ReadFont(element).length > 0) {
      _fnt_attbrs = this.Child_Element_Attributes(element, 'Font');
    }

    if (this.ReadBorder(element).length > 0) {
      _bord_attbrs = this.Child_Element_Attributes(element, 'Border');
    }

    if (this.ReadValue(element).length > 0) {
      _value = this.Child_Element_Value(element, 'Value');
      _valu_attbrs = this.Child_Element_Attributes(element, 'Value');
    }

    if (_prop_attbrs !== undefined) {
      if (_prop_attbrs.padding !== undefined) {
        _mstyle.attributes['padding'] =
          _prop_attbrs.padding !== undefined
            ? this.process_Padding(_prop_attbrs.padding)
            : 'inherit';
      }
      if (_prop_attbrs.width !== undefined) {
        _mstyle.attributes['width'] =
          _prop_attbrs.width !== undefined ? _prop_attbrs.width + '%' : '100%';
      }
      if (_prop_attbrs.margin !== undefined) {
        _mstyle.attributes['margin'] =
          _prop_attbrs.margin !== undefined
            ? this.process_Margin(_prop_attbrs.margin)
            : 'inherit';
      }
      if (_prop_attbrs.lineheight !== undefined) {
        _mstyle.attributes['lineHeight'] =
          _prop_attbrs.lineheight !== undefined
            ? _prop_attbrs.lineheight + 'px'
            : 'inherit';
      }
      if (_prop_attbrs.spacing !== undefined) {
        _mstyle.attributes['whiteSpace'] =
          _prop_attbrs.spacing !== undefined
            ? _prop_attbrs.spacing + 'px'
            : 'pre-wrap';
      }
      if (_prop_attbrs.alignment !== undefined) {
        _mstyle.attributes['textAlign'] =
          _prop_attbrs.alignment !== undefined ? _prop_attbrs.alignment : '';
      }
    }

    if (_bord_attbrs !== undefined) {
      if (_bord_attbrs.width !== undefined) {
        _mstyle.attributes['borderWidth'] =
          _bord_attbrs.width !== undefined
            ? _prop_attbrs.margin + 'px'
            : 'fit-content';
      }
      if (_bord_attbrs.color !== undefined) {
        _mstyle.attributes['color'] =
          _bord_attbrs.color !== undefined
            ? this.rgbToHex(_bord_attbrs.color)
            : 'transparent';
      }
      if (_bord_attbrs.style !== undefined) {
        _mstyle.attributes['borderStyle'] =
          _bord_attbrs.style !== undefined ? _bord_attbrs.style : 'solid';
      }
    }

    if (_fnt_attbrs !== undefined) {
      if (_fnt_attbrs.family !== undefined) {
        _mstyle.attributes['fontFamily'] =
          _fnt_attbrs.family !== undefined ? _fnt_attbrs.family : 'inherit';
      }
      if (_fnt_attbrs.size !== undefined) {
        _mstyle.attributes['fontSize'] =
          _fnt_attbrs.size !== undefined ? _fnt_attbrs.size + 'px' : '1px';
      }
      if (_fnt_attbrs.color !== undefined) {
        _mstyle.attributes['color'] =
          _fnt_attbrs.color !== undefined ? _fnt_attbrs.color : 'transparent';
      }
      if (_fnt_attbrs.weight !== undefined) {
        _mstyle.attributes['fontWeight'] =
          _fnt_attbrs.weight !== undefined ? _fnt_attbrs.weight : 'inherit';
      }
      if (_fnt_attbrs.decoration !== undefined) {
        _mstyle.attributes['textDecoration'] =
          _fnt_attbrs.decoration !== undefined
            ? _fnt_attbrs.decoration
            : 'none';
      }
    }

    if (element.name.toLowerCase() === 'image') {
      var _class = '';
      if (element.attributes.col !== undefined) {
        _class = 'offset-' + element.attributes.col.toString();
      }
      if (element.attributes.cols !== undefined) {
        _class = _class + ' col-' + element.attributes.cols.toString();
      }

      if (
        this.props._selectedtabname !== undefined &&
        this.props._selectedtabname.toLowerCase() == 'bio'
      ) {
        _mstyle.attributes['cursor'] = 'pointer';
      }
      return (
        <>
          <div key={_idx.toString()} className={_class}>
            <img
              src={_value}
              alt={_src_attbrs.alt !== undefined ? _src_attbrs.alt : ''}
              style={_mstyle.attributes}
              onDoubleClick={this.ImageDblclicked.bind(this)}
            ></img>
          </div>
        </>
      );
    } else if (element.name.toLowerCase() === 'label') {
      var _class = '';
      if (element.attributes.col !== undefined) {
        _class = 'offset-' + element.attributes.col.toString();
      }
      if (element.attributes.cols !== undefined) {
        _class = _class + ' col-' + element.attributes.cols.toString();
      }

      var _valueinfo = '';
      if (this.props._data !== null && this.props._data !== undefined) {
        _valueinfo = this.process_ValueInfo(
          _valu_attbrs,
          _value,
          this.props._data,
          false
        );
      }

      if (_mstyle.attributes.textAlign !== undefined) {
        return (
          <div
            key={_idx.toString()}
            className={_class}
            style={{ textAlign: _mstyle.attributes.textAlign }}
          >
            <label style={_mstyle.attributes}>{_valueinfo}</label>
          </div>
        );
      } else {
        return (
          <div key={_idx.toString()} className={_class}>
            <label style={_mstyle.attributes}>{_valueinfo}</label>
          </div>
        );
      }
    } else if (element.name.toLowerCase() === 'text') {
      var _class = '';
      if (element.attributes.col !== undefined) {
        _class = 'offset-' + element.attributes.col.toString();
      }
      if (element.attributes.cols !== undefined) {
        _class = _class + ' col-' + element.attributes.cols.toString();
      }

      // var _valueinfo: any;
      // if (_valu_attbrs.field !== undefined) {
      //   if (_valu_attbrs.source !== undefined) {
      //     if (_valu_attbrs.source.includes('.') === true) {
      //       var _fld: any = _valu_attbrs.source;
      //       if (this.props._data[_valu_attbrs.field] !== undefined) {
      //         if (_valu_attbrs.match !== undefined && _valu_attbrs.match.toLowerCase() == "item") {
      //           if (this.props._data[_valu_attbrs.field].filter((x: any) => x.item === _fld.split('.')[0]).length > 0) {
      //             _valueinfo = this.props._data[_valu_attbrs.field].filter((x: any) => x.item === _fld.split('.')[0])[0][_fld.split('.')[1]];
      //           } else {
      //             _valueinfo = "";
      //           }
      //         }
      //         else {
      //           if (this.props._data[_valu_attbrs.field].filter((x: any) => x.type === _fld.split('.')[0]).length > 0) {
      //             _valueinfo = this.props._data[_valu_attbrs.field].filter((x: any) => x.type === _fld.split('.')[0])[0][_fld.split('.')[1]];
      //           } else {
      //             _valueinfo = "";
      //           }
      //         }
      //       }
      //     }
      //     else {
      //       if (this.props._data[_valu_attbrs.field] !== undefined) {
      //         _valueinfo = this.props._data[_valu_attbrs.field][_valu_attbrs.source.toLowerCase()];
      //       }
      //     }
      //   }
      //   else {
      //     if (this.props._data[_valu_attbrs.field] !== undefined) {
      //       _valueinfo = this.props._data[_valu_attbrs.field][_value.toLowerCase()];
      //     }
      //   }
      // }
      // else {
      //   if (_valu_attbrs.source !== undefined) {
      //     if (_valu_attbrs.source.includes('.') === true) {
      //       var _fld = _valu_attbrs.source;
      //       if (this.props._data[_fld.split('.')[0]] !== undefined) {
      //         _valueinfo = this.props._data[_fld.split('.')[0]][_fld.split('.')[1]]
      //       }
      //     }
      //     else {
      //       _valueinfo = this.props._data[_valu_attbrs.source.toLowerCase()];
      //     }
      //   }
      //   else {
      //     _valueinfo = this.props._data[_value.toLowerCase()];
      //   }
      // }

      // if (_value == "date" && new Date(_valueinfo).toString() !== "Invalid Date") {
      //   _valueinfo = new Date(_valueinfo).toDateString().substring(4);
      // }

      var _valueinfo = '';
      var _jsxs: any[] = [];
      // if (this.props._data !== null && this.props._data !== undefined) {
      //   if (_valu_attbrs.field !== undefined) {
      //     if (this.props._data.cards !== undefined) {
      //       if (Array.isArray(this.props._data.cards) == true) {
      //         _jsxs = [];
      //         for (let x = 0; x < this.props._data.cards.length; x++) {
      //           var _data = this.props._data.cards[x];
      //           if (Array.isArray(_data[_valu_attbrs.field]) == true) {
      //             var _originalSrc = _valu_attbrs.source;
      //             for (let i = 0; i < _data[_valu_attbrs.field].length; i++) {
      //               var _srcItem = _data[_valu_attbrs.field][i];
      //               _valu_attbrs.source = _originalSrc
      //               if ((_valu_attbrs.source).includes('.') == false) {
      //                 _valu_attbrs.source = _srcItem.item + '.' + _originalSrc;
      //               };
      //               _valueinfo = this.process_ValueInfo(_valu_attbrs, _value, _data, true);
      //               if (_valueinfo !== undefined && _valueinfo.toString().length > 0) {
      //                 _class = _class + " row "
      //                 if (_mstyle.attributes.textAlign !== undefined) {
      //                   _jsxs.push(<div key={_idx.toString()} className={_class} style={{ textAlign: _mstyle.attributes.textAlign }} ><label typeof="text" style={_mstyle.attributes}>{_valueinfo}</label></div>);
      //                 }
      //                 else {
      //                   _jsxs.push(<div key={_idx.toString()} className={_class}><label typeof="text" style={_mstyle.attributes}>{_valueinfo}</label></div>);
      //                 }
      //               }
      //               else {
      //                 return undefined;
      //               }
      //             }
      //           }
      //           else {
      //             _valueinfo = this.process_ValueInfo(_valu_attbrs, _value, this.props._data, true);
      //             if (_valueinfo !== undefined && _valueinfo.toString().length > 0) {
      //               if (_mstyle.attributes.textAlign !== undefined) {
      //                 _jsxs.push(<div key={_idx.toString()} className={_class} style={{ textAlign: _mstyle.attributes.textAlign }} ><label typeof="text" style={_mstyle.attributes}>{_valueinfo}</label></div>);
      //               }
      //               else {
      //                 _jsxs.push(<div key={_idx.toString()} className={_class}><label typeof="text" style={_mstyle.attributes}>{_valueinfo}</label></div>);
      //               }
      //             }
      //             else {
      //               return undefined;
      //             }
      //           }
      //         }
      //       }
      //     }
      //     else {
      //       if (Array.isArray(this.props._data[_valu_attbrs.field]) == true) {
      //         var _originalSrc = _valu_attbrs.source;
      //         for (let i = 0; i < this.props._data[_valu_attbrs.field].length; i++) {
      //           var _srcItem = this.props._data[_valu_attbrs.field][i];
      //           _valu_attbrs.source = _originalSrc
      //           if ((_valu_attbrs.source).includes('.') == false) {
      //             _valu_attbrs.source = _srcItem.item + '.' + _originalSrc;
      //           };
      //           _valueinfo = this.process_ValueInfo(_valu_attbrs, _value, this.props._data, true);
      //           if (_valueinfo !== undefined && _valueinfo.toString().length > 0) {
      //             _class = _class + " row "
      //             if (_mstyle.attributes.textAlign !== undefined) {
      //               _jsxs.push(<div key={_idx.toString()} className={_class} style={{ textAlign: _mstyle.attributes.textAlign }} ><label typeof="text" style={_mstyle.attributes}>{_valueinfo}</label></div>);
      //             }
      //             else {
      //               _jsxs.push(<div key={_idx.toString()} className={_class}><label typeof="text" style={_mstyle.attributes}>{_valueinfo}</label></div>);
      //             }
      //           }
      //           else {
      //             return undefined;
      //           }
      //         }
      //       }
      //       else {
      //         _valueinfo = this.process_ValueInfo(_valu_attbrs, _value, this.props._data, true);
      //         if (_valueinfo !== undefined && _valueinfo.toString().length > 0) {
      //           if (_mstyle.attributes.textAlign !== undefined) {
      //             _jsxs.push(<div key={_idx.toString()} className={_class} style={{ textAlign: _mstyle.attributes.textAlign }} ><label typeof="text" style={_mstyle.attributes}>{_valueinfo}</label></div>);
      //           }
      //           else {
      //             _jsxs.push(<div key={_idx.toString()} className={_class}><label typeof="text" style={_mstyle.attributes}>{_valueinfo}</label></div>);
      //           }
      //         }
      //         else {
      //           return undefined;
      //         }
      //       }
      //     }
      //   }
      //   else {
      //     _valueinfo = this.process_ValueInfo(_valu_attbrs, _value, this.props._data, true);
      //     if (_valueinfo !== undefined && _valueinfo.toString().length > 0) {
      //       if (_mstyle.attributes.textAlign !== undefined) {
      //         _jsxs.push(<div key={_idx.toString()} className={_class} style={{ textAlign: _mstyle.attributes.textAlign }} ><label typeof="text" style={_mstyle.attributes}>{_valueinfo}</label></div>);
      //       }
      //       else {
      //         _jsxs.push(<div key={_idx.toString()} className={_class}><label typeof="text" style={_mstyle.attributes}>{_valueinfo}</label></div>);
      //       }
      //     }
      //     else {
      //       return undefined;
      //     }
      //   }
      // }

      if (this.props._data !== null && this.props._data !== undefined) {
        if (_valu_attbrs.field !== undefined) {
          if (Array.isArray(this.props._data[_valu_attbrs.field]) == true) {
            var _originalSrc = _valu_attbrs.source;
            for (
              let i = 0;
              i < this.props._data[_valu_attbrs.field].length;
              i++
            ) {
              var _srcItem = this.props._data[_valu_attbrs.field][i];
              _valu_attbrs.source = _originalSrc;
              if (_valu_attbrs.source.includes('.') == false) {
                _valu_attbrs.source = _srcItem.item + '.' + _originalSrc;
              }
              _valueinfo = this.process_ValueInfo(
                _valu_attbrs,
                _value,
                this.props._data,
                true
              );
              if (
                _valueinfo !== undefined &&
                _valueinfo.toString().length > 0
              ) {
                _class = _class + ' row ';
                if (_mstyle.attributes.textAlign !== undefined) {
                  _jsxs.push(
                    <div
                      key={_idx.toString()}
                      className={_class}
                      style={{ textAlign: _mstyle.attributes.textAlign }}
                    >
                      <label typeof='text' style={_mstyle.attributes}>
                        {_valueinfo}
                      </label>
                    </div>
                  );
                } else {
                  _jsxs.push(
                    <div key={_idx.toString()} className={_class}>
                      <label typeof='text' style={_mstyle.attributes}>
                        {_valueinfo}
                      </label>
                    </div>
                  );
                }
              } else {
                return undefined;
              }
            }
          } else {
            _valueinfo = this.process_ValueInfo(
              _valu_attbrs,
              _value,
              this.props._data,
              true
            );
            if (_valueinfo !== undefined && _valueinfo.toString().length > 0) {
              if (_mstyle.attributes.textAlign !== undefined) {
                _jsxs.push(
                  <div
                    key={_idx.toString()}
                    className={_class}
                    style={{ textAlign: _mstyle.attributes.textAlign }}
                  >
                    <label typeof='text' style={_mstyle.attributes}>
                      {_valueinfo}
                    </label>
                  </div>
                );
              } else {
                _jsxs.push(
                  <div key={_idx.toString()} className={_class}>
                    <label typeof='text' style={_mstyle.attributes}>
                      {_valueinfo}
                    </label>
                  </div>
                );
              }
            } else {
              return undefined;
            }
          }
        } else {
          _valueinfo = this.process_ValueInfo(
            _valu_attbrs,
            _value,
            this.props._data,
            true
          );
          if (_valueinfo !== undefined && _valueinfo.toString().length > 0) {
            if (_mstyle.attributes.textAlign !== undefined) {
              _jsxs.push(
                <div
                  key={_idx.toString()}
                  className={_class}
                  style={{ textAlign: _mstyle.attributes.textAlign }}
                >
                  <label typeof='text' style={_mstyle.attributes}>
                    {_valueinfo}
                  </label>
                </div>
              );
            } else {
              _jsxs.push(
                <div key={_idx.toString()} className={_class}>
                  <label typeof='text' style={_mstyle.attributes}>
                    {_valueinfo}
                  </label>
                </div>
              );
            }
          } else {
            return undefined;
          }
        }
      }
      return _jsxs;
    }
  }

  private getAttributeValues(element: any, name: string) {
    var matches: any[] = [];
    for (var attribute in element.attributes) {
      if (attribute.toLowerCase() === name.toLowerCase()) {
        matches.push(element.attributes[attribute]);
      }
    }
    for (let index = 0; index < element.children.length; index++) {
      matches = matches.concat(
        this.getAttributeValues(element.children[index], name)
      );
    }
    return Array.from(new Set(matches.map((item: any) => item)));
  }

  private getElementByAttributeValue(
    element: any,
    name: string,
    value: string
  ) {
    var matches: any[] = [];
    for (var attribute in element.attributes) {
      if (attribute.toLowerCase() === name.toLowerCase()) {
        if (
          value !== '' &&
          element.attributes[attribute].toLowerCase() === value.toLowerCase()
        ) {
          matches.push(element);
        } else {
          //matches.push(element);
        }
      }
    }
    for (let index = 0; index < element.children.length; index++) {
      matches = matches.concat(
        this.getElementByAttributeValue(element.children[index], name, value)
      );
    }
    return matches;
  }

  private ReadElement(Parent: any, tagname: string) {
    var matches: any[] = [];
    if (Parent.name.toLowerCase() === tagname.toLowerCase()) {
      matches.push(Parent);
    }
    Parent.children.map((child: any) => {
      matches = matches.concat(this.ReadElement(child, tagname));
    });
    return matches;
  }

  private ReadSource(Parent: any) {
    return this.ReadElement(Parent, 'Source');
  }

  private ReadProperties(Parent: any) {
    return this.ReadElement(Parent, 'Properties');
  }

  private ReadFont(Parent: any) {
    return this.ReadElement(Parent, 'Font');
  }

  private ReadBorder(Parent: any) {
    return this.ReadElement(Parent, 'Border');
  }

  private ReadValue(Parent: any) {
    return this.ReadElement(Parent, 'Value');
  }

  private ReadIcon(Parent: any) {
    return this.ReadElement(Parent, 'Icon');
  }

  private IsAttributeExists(element: any, _attribute: string) {
    for (var attribute in element.attributes) {
      if (_attribute.toLowerCase() === attribute.toLowerCase()) {
        return true;
      }
    }
  }

  private Child_Element_Attributes(ParentElement: any, ChildTagName: string) {
    var attbr;
    for (let index = 0; index < ParentElement.children.length; index++) {
      if (
        ParentElement.children[index].name.toLowerCase() ===
        ChildTagName.toLowerCase()
      ) {
        attbr = ParentElement.children[index].attributes;
      }
    }
    return attbr;
  }

  private Child_Element_Value(ParentElement: any, ChildTagName: string) {
    var _value;
    for (let index = 0; index < ParentElement.children.length; index++) {
      if (
        ParentElement.children[index].name.toLowerCase() ===
        ChildTagName.toLowerCase()
      ) {
        _value =
          ParentElement.children[index].value.toString() === ''
            ? ''
            : ParentElement.children[index].value; //ParentElement.children[index].attributes;
      }
    }
    return _value;
  }

  // * ------------------------------------------------------------------------------------------------------------------6.Adapter
  // * SOURAV - 2023-05-05 - 360 -- Coding and Implementing the Functionality(crop,Reset,Zoom)
  // * SOURAV - 2023-05-05 - 100 -- Learnind and doing R & D.
  // * SOURAV - 2023-05-08 - 370 -- Fixing Issues and Doing R&D.
  // * SOURAV - 2023-05-09 - 300 -- Solving Issues and Doing R&D.
  // * SOURAV - 2023-05-09 - 150 -- Understanding React Image Editor from Syncfusion.
  // * SOURAV - 2023-05-10 - 360 -- Doing Research for Image Resizer.
  // * SOURAV - 2023-05-11 - 300 -- Writing code for Image Resizer.
  // * SOURAV - 2023-05-11 - 130 -- Doing Research for Image Resizer.
  // * SOURAV - 2023-05-12 - 300 -- Writing code for Image Resizer.
  // * SOURAV - 2023-05-12 - 100 -- Doing research for image cropper in react js.
  // * SOURAV - 2023-05-15 - 290 --  Doing research on resizing algorithm and also doing check by implementing it.
  // * SOURAV - 2023-05-15 - 90 -- Learning Typescript.
  // * SOURAV - 2023-05-16 - 460 -- Doing research and trying to implement in my previously written code (trying different approch)
  // * SOURAV - 2023-05-17 - 60 -- Doins some research related to image resizing.
  // * SOURAV - 2023-05-17 - 360 -- Writing code to resize the image.
  // * SOURAV - 2023-05-22 - 420 -- Writing code and doing research for image dragging features.
  // * SOURAV - 2023-05-23 - 160 -- Writing code and doing research for image cropping.
  // * SOURAV - 2023-05-23 - 300 -- Writing code for image dragging features.
  // * SOURAV - 2023-05-24 - 180 -- Writing code for image cropping.
  // * SOURAV - 2023-05-24 - 230 -- Doing research to avoid an error for image dragging features.
  // * SOURAV - 2023-05-25 - 410 -- writing code and doing research on image editor component like image cropping,image reseting,cancel button,image rotating.
  // * SOURAV - 2023-05-26 - 420 -- writing final code for image editor component like image cropping,image reseting.
  // * SOURAV - 2023-05-29 - 240 -- Implementing image saving features.
  // * SOURAV - 2023-05-25 - 170 -- started working on document editor.
  // * SOURAV - 2023-06-09 - 100 -- working on to fix the issues image editor rotating features.
  // * SOURAV - 2023-06-12 - 250 -- working on to fix the issues image editor dragging features.
  // * SOURAV - 2023-06-13 - 240 -- doing research for fixing width related issues.
  // * SOURAV - 2023-06-14 - 420 -- doing research and writing code for fixing issues related to image editor component.
  // * SOURAV - 2023-06-15 - 460 -- writing code for image and document saving and also working on insomania.
  // * SOURAV - 2023-06-16 - 470 -- implementing changes in image editor (like image should be resized from it's initial position,changes in sallvate)
  // * SOURAV - 2023-06-19 - 400 --  working on image and document saving features to the backend with suhail sir.
  // * SOURAV - 2023-06-20 - 470 --  writing code to implement changes in image and document editor component(like trying to implement smooth movement of cropbox).
  // * SOURAV - 2023-06-21 - 120 --  working on image and document editor.
  // * SOURAV - 2023-06-26 - 370 --  working on image and document editor(to implement some changes as said by fabio sir).
  // * SOURAV - 2023-06-27 - 460 --  working on image and document editor(image and document fitting issue inside a div).
  // * SOURAV - 2023-06-28 - 300 --  working on image and document editor(working on cropping and rotating issue).
  // * SOURAV - 2023-06-28 - 100 --  reading mdn docs for canva in html5(as required in my current task)
  // * SOURAV - 2023-06-29 - 170 -- implemented changes in image editor(fixes issues like silhoutte shadowing issue,direct cropping issue)
  // * SOURAV - 2023-10-06 - 440 -- working on to fix document saving issue and also started working on image editor component layout.
  // * SOURAV - 2023-10-09 - 400 -- working on to fix document saving issue and also working on image editor component layout.
  // * SOURAV - 2023-10-10 - 460 -- working on to fix document saving issue , image cropping on accept btn and also working on image editor component layout.
  // * SOURAV - 2023-10-11 - 430 -- working on to make image editor component dynamic(reusable) .
  // * SOURAV - 2023-10-16 - 430 -- working on to make common component for image editor and document editor component.
  // * SOURAV - 2023-10-17 - 340 -- working on to make common component for image editor and document editor component.
  // * SOURAV - 2023-11-16 - 300 --  working on image editor to get image if exist in editor div.
  // * SOURAV - 2023-11-24 - 440 --  working on accept and cancel button of image editor, also worked on silhauette for image(if exist).

  // * --------------------------------------------------------------------------------------------------------------------HTML
  render() {
    var _card;
    if (
      this.props._data !== undefined &&
      this.props._data.cards !== undefined
    ) {
      indexOfLastRecord = currentPage * recordsPerPage;
      indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
      if (this.props._data.cards) {
        currentRecords = this.props._data.cards.slice(
          indexOfFirstRecord,
          indexOfLastRecord
        )[0];
      }

      if (currentRecords == undefined && this.props._data.cards) {
        currentRecords = this.props._data.cards.slice(0, 1)[0];
      }
      if (this.props._data.cards) {
        nPages = Math.ceil(this.props._data.cards.length / recordsPerPage);
      }
    } else {
      currentRecords = undefined;
    }

    _card = this.Process_Card(this.props._element);

    return (
      <>
        <div
          style={{ margin: '14px' }}
          id={this.props._id ? this.props._id : ''}
        >
          {this.props._data !== undefined ? _card : ''}
        </div>
        {this.props._data !== undefined &&
        this.props._data.cards !== undefined &&
        nPages > 1 ? (
          <PagerComponent
            ref={(XCard) => (this.pagerInstance = XCard)}
            totalRecordsCount={nPages}
            pageSize={1}
            currentPage={currentPage}
            enablePagerMessage={false}
            click={this.clicked.bind(this)}
            style={{
              backgroundColor: 'transparent',
              width: '98%',
              margin: 'auto',
              borderRadius: '5px',
            }}
          />
        ) : (
          ''
        )}
        {this.state.showimage === true ? (
          <ImageEditor
            key={Math.random()}
            showImage={this.state.showimage}
            _data={this.props._data}
            getFinalImage={this.getFinalImage}
            isImageEditor={true}
            doubleClickedId={null}
            editImage={this.state.profileImage}
          />
        ) : null}
      </>
    );
  }
}

export default XCard;
