import * as React from 'react';
import './CompanyGroup.css';
import Common from '../../../Util/Common';
import DialogConfirm from '../../Dialogbox/DialogConfirm';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
const { REACT_APP_ENDPOINT_EMPLOYEESERVICE, REACT_APP_ENDPOINT_CORESERVICE } =
  process.env;

interface Props {
  code?: string;
  name?: string;
  handleCancelClick?: (e: any) => void;
  handleSaveClick?: (e: any) => void;
}

export default class CompanyGroup extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;
  toastObj: any;

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
  }

  state = { code: '', name: '' };

  componentDidMount(): void {}
  onFocusFunction = (event: any) => {
    event.target.classList.remove('textbox-border-error');
  };
  render() {
    const onCancelClick = (e: any) => {
      if (this.props.handleCancelClick) this.props.handleCancelClick(e);
    };

    const onSaveClick = (e: any) => {
      ValidateForm();
      if (IsValid() === false) return false;

      let payload: any = {
        code: this.state.code,
        name: this.state.name,
        totalCompany: 1,
        totalEmployee: 1,
        email: '',
        website: '',
        issuer: '',
        isActive: true,
      };

      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_EMPLOYEESERVICE}/api/tenants/tenantgroup/set`,
        payload,
        Common.getToken() || '',
        this.user,
        this.token.tenant
      )
        .then((response: any) => {
          return response.data;
        })
        .then((data: any) => {
          // DialogConfirm.showDialog({
          //   content: '<p class="dialog-contain">Record saved successfully!</p>',
          // });
          this.toastObj = ToastUtility.show({
            cssClass: 'e-toast-success',
            icon: 'far fa-circle-check',
            showCloseButton: true,
            content: 'Record saved SuccessFully..!',
            target: '#basic_card',
            position: { X: 'Center', Y: 'Top' },
            width: 'auto',
            timeOut: 3000,
          });
          this.setState({
            code: '',
            name: '',
          });

          if (this.props.handleSaveClick) this.props.handleSaveClick(data);
        })
        .catch((error: any) => {
          console.error(error);
        })
        .finally(() => {});
    };

    const ValidateForm = () => {
      const inputs = [
        document.querySelector<HTMLInputElement>('#code'),
        document.querySelector<HTMLInputElement>('#groupname'),
      ];

      inputs.forEach((input) => {
        if (input) {
          input.classList.remove('textbox-border-error');

          if (input.value.trim() === '') {
            input.classList.add('textbox-border-error');
          } else {
            input.classList.add('textbox-class');
          }
        }
      });
    };
    const IsValid = (): boolean => {
      if (!this.state.code.trim()) {
        // DialogConfirm.showDialog({
        //   content: '<p class="dialog-contain">Please enter Code.</p>',
        // });
        const TargetInput = document.querySelector<HTMLInputElement>('#code');
        if (TargetInput) {
          TargetInput.style.borderBottom = '2px solid red';
        }
        return false;
      } else if (!this.state.name.trim()) {
        // DialogConfirm.showDialog({
        //   content: '<p class="dialog-contain">Please enter Name.</p>',
        // });
        const TargetInput =
          document.querySelector<HTMLInputElement>('#groupname');
        if (TargetInput) {
          TargetInput.style.borderBottom = '2px solid red';
        }
        return false;
      }

      return true;
    };
    return (
      <>
        <div id='divCompanyGroup' className='py-2'>
          <div className='row'>
            <div className='col-12'>
              {/* <label style={{ paddingBottom: '7px' }}>Code</label>
              <span className='mandatoryinfo'>*</span> */}
              <TextBoxComponent
                className='input-text-border'
                id='code'
                name='code'
                type='text'
                placeholder={'Code *'}
                floatLabelType='Auto'
                onFocus={this.onFocusFunction}
                autocomplete='off'
                // autoFocus={true}
                //ref={(obj: any) => (this.txtName = obj)}
                value={this.state.code}
                onChange={(e: any) => {
                  this.setState({ code: e.target.value });
                }}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              {/* <label style={{ paddingBottom: '7px' }}>Name</label>
              <span className='mandatoryinfo'>*</span> */}
              <TextBoxComponent
                className='input-text-border'
                id='groupname'
                name='name'
                type='text'
                placeholder={'Name *'}
                floatLabelType='Auto'
                onFocus={this.onFocusFunction}
                autocomplete='off'
                //autoFocus={true}
                //ref={(obj: any) => (this.txtName = obj)}
                value={this.state.name}
                onChange={(e: any) => {
                  this.setState({ name: e.target.value });
                }}
              />
            </div>
          </div>
          <div className='e-footer-content'>
            <div>
              <button
                id='btnCancel'
                className='e-control e-btn e-lib flat-button e-flat'
                onClick={onCancelClick}
              >
                Cancel
              </button>
              <button
                id='btnAccept'
                className='e-control e-btn e-lib flat-button e-primary e-flat'
                onClick={onSaveClick}
              >
                Accept
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}
