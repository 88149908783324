// * ---------------------------------------------------------------------------------------------------------------1.Aknowlege
// * SUHAIL - 2023-11-28 - 30 - meeting with fabio and saurav on google login
// * SUHAIL - 2023-11-28 - 210 - Show validation message in username and password text as place holder
// * SUHAIL - 2023-11-28 - 240 - Add new component Dashboard and show dashboard layout on dashboard sidebar menu click

// * ---------------------------------------------------------------------------------------------------------------2.Initialize
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import {
  DashboardLayoutComponent,
  PanelDirective,
  PanelModel,
  PanelsDirective,
} from '@syncfusion/ej2-react-layouts';
import React from 'react';
import './Dashboard.css';
import Profile from './Profile';
import CenterPanel from './CenterPanel';
import RightPanel from './RightPanel';
import { FabComponent } from '@syncfusion/ej2-react-buttons';
import Common from '../../../Util/Common';

const { REACT_APP_ENDPOINT_ATTENDANCESERVICE } = process.env;
// * ---------------------------------------------------------------------------------------------------------------2.Initialize
interface Props {
  rootmenu: any;
  selectedmenu: any;
  updateJsonDataViewer?: any;
}

// * ------------------------------------------------------------------------------------------------------------------3.Style

// * -----------------------------------------------------------------------------------------------------------------DOTENV VAR DECLARATION

// * -------------------------------------------------------------------------------------------------------------------CLASS DECLARATION
class Dashboard extends React.Component<Props> {
  private dashboardObj: DashboardLayoutComponent | any;
  private cellSpacing: number[] = [20, 20];
  private panels: PanelModel[];
  private mediaQuery: string = 'max-width: 700px';
  private count: number = 8;
  private user: any;
  private token: any = null;
  private EmpId: string = '';
  private ShiftId: string = '';
  private workingHour: number = 0;

  // * ------------------------------------------------------------------------------------------------------------------2.Initialize Component
  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');

    this.panels = [
      {
        sizeX: 1,
        sizeY: 2,
        row: 0,
        col: 0,
        content: '<div class="content">0</div>',
      },
      {
        sizeX: 3,
        sizeY: 4,
        row: 1,
        col: 2,
        content: '<div class="content">1</div>',
      },
      {
        sizeX: 1,
        sizeY: 2,
        row: 1,
        col: 5,
        content: '<div class="content">2</div>',
      },
    ];
  }

  componentDidMount() {
    // this.rendereComplete();
  }
  // * ------------------------------------------------------------------------------------------------------------------5.State
  state = { showdashboard: true };

  onCloseIconHandler(event: any) {
    let proxy: any = this;
    let panel: any = event.target;
    if (panel.offsetParent) {
      proxy.dashboardObj.removePanel(panel.offsetParent.id);
    }
  }
  btnClick() {
    let proxy: any = this;
    let panel: PanelModel[] = [
      {
        id: this.count.toString() + '_layout',
        sizeX: 1,
        sizeY: 1,
        row: 0,
        col: 0,
        content:
          '<span id="close" class="e-close-icon e-clear-icon"></span><div class="text-align">' +
          this.count.toString() +
          '</div>',
      },
    ];
    proxy.dashboardObj.addPanel(panel[0]);
    let closeIcon = document
      .getElementById(this.count.toString() + '_layout')!
      .querySelector('.e-clear-icon');
    closeIcon!.addEventListener('click', this.onCloseIconHandler.bind(this));
    this.count = this.count + 1;
  }
  rendereComplete() {
    let closeElement = document.querySelectorAll('.e-clear-icon');
    for (let i = 0; i < closeElement.length; i++) {
      closeElement[i].addEventListener(
        'click',
        this.onCloseIconHandler.bind(this)
      );
    }
    setTimeout(() => {
      this.setState({ showdashboard: true });
    }, 500);
  }

  // * ------------------------------------------------------------------------------------------------------------------6.Adapter
  render() {
    const updateJsonDataViewer = (
      rootmenu: any,
      selectedmenu: any,
      data: any
    ) => {
      this.props.updateJsonDataViewer(rootmenu, selectedmenu, data, true);
    };

    const AddAttendance = (e: any) => {
      var payload: any = {
        employeeId: this.user.employee.id,
        shiftId: this.user.employee.employeeShift[0].shiftId,
        logindatetime: Common.RemoveTimezoneOffsetFromDate(new Date()),
        sourcetype: 1,
        baseWorkHours:
          this.user.employee.employeeShift[0].shift.shiftTran[0].workingHour,
      };

      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_ATTENDANCESERVICE}/attendance/add${
          !this.user.isManager ? '/ess' : ''
        }`,
        payload,
        Common.getToken() || '',
        null,
        this.token.tenant
      )
        .then((response: any) => {
          return response;
        })
        .then((resopnse: any) => {
          if (resopnse.status == 200) {
            if (e.target.textContent == 'Clock In') {
              e.target.textContent = 'Clock Out';
            } else if (e.target.textContent == 'Clock Out') {
              e.target.textContent = 'Clock In';
            }
          }
        })
        .catch((error: any) => {
          console.error(error);
        })
        .finally(() => {});
    };

    const onDivScroll = (e: any) => {
      let stk: any = document.querySelectorAll(
        '#default_dashboard .maindiv .stickydivprev ~ .stickydiv'
      );

      stk.forEach((x: any) => {
        //let prev: any = x.parentElement.querySelector(' > .stickydivprev');
        let prev: any = x.previousElementSibling;

        // if (prev) {
        //   if (x.id === 'teammembers') {
        //     if (
        //       x.getBoundingClientRect().top < 84 ||
        //       prev.getBoundingClientRect().bottom < 75
        //     ) {
        //       x.style.position = 'fixed';
        //       x.style.top = '84px';
        //       x.style.left = 'unset';
        //     } else {
        //       x.style.position = 'absolute';
        //       x.style.top = '732px';
        //       // x.style.top = '870px';
        //     }
        //   } else {
        //     if (
        //       x.getBoundingClientRect().top < 84 ||
        //       prev.getBoundingClientRect().bottom < 76
        //     ) {
        //       x.style.position = 'fixed';
        //       x.style.top = '84px';
        //       x.style.width = prev.getBoundingClientRect().width + 'px';
        //     } else {
        //       x.style.position = 'relative';
        //       x.style.top = 'initial';
        //     }
        //   }
        // }
      });
    };
    // * --------------------------------------------------------------------------------------------------------------------HTML
    return (
      <div id='default_dashboard' style={{ height: 'calc(100vh - 120px)' }}>
        <div id='container'>
          <div className='default_dashboard_defaultLayout1'>
            <div className='row maindiv' onScroll={onDivScroll}>
              <div className='column-1'>
                <Profile
                  rootmenu={this.props.rootmenu}
                  selectedmenu={this.props.selectedmenu}
                  updateJsonDataViewer={updateJsonDataViewer}
                />
              </div>
              <div className='column-2'>
                <CenterPanel
                  rootmenu={this.props.rootmenu}
                  selectedmenu={this.props.selectedmenu}
                />
              </div>
              <div className='column-3'>
                <RightPanel
                  rootmenu={this.props.rootmenu}
                  selectedmenu={this.props.selectedmenu}
                />
              </div>
            </div>
          </div>

          {/* <DashboardLayoutComponent
            id='defaultLayout'
            columns={5}
            cellSpacing={this.cellSpacing}
            cellAspectRatio={100 / 70}
            //panels={this.panels}
            mediaQuery={this.mediaQuery}
            //allowResizing={true}
            allowDragging={false}
          >
            <PanelsDirective>
              <PanelDirective
                sizeX={1}
                sizeY={2}
                row={0}
                col={0}
                content={() => (
                  <Profile
                    rootmenu={this.props.rootmenu}
                    selectedmenu={this.props.selectedmenu}
                    updateJsonDataViewer={updateJsonDataViewer}
                  />
                )}
              />
              <PanelDirective
                sizeX={3}
                sizeY={4}
                row={0}
                col={1}
                content={() => (
                  <CenterPanel
                    rootmenu={this.props.rootmenu}
                    selectedmenu={this.props.selectedmenu}
                  />
                )}
              />
              <PanelDirective
                sizeX={1}
                sizeY={2}
                row={0}
                col={4}
                content={''}
              />
            </PanelsDirective>
          </DashboardLayoutComponent> */}
        </div>
      </div>
    );
  }
}

export default Dashboard;
