import * as React from 'react';
import Common from '../../Util/Common';
import './GeneralFormat.css';
import { RadioButtonComponent } from '@syncfusion/ej2-react-buttons';

const { REACT_APP_ENDPOINT_CORESERVICE } = process.env;

interface Props {}

export default class GeneralFormat extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;
  private changesDetected: boolean = false;
  private JsonLoaded: boolean = false;

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
  }

  state = {
    jsonTenantSetting: undefined,
    selectedFormat: undefined,
  };

  componentDidMount() {
    if (this.state.jsonTenantSetting == undefined) {
      this.GetTenantSetting();
    }
  }

  componentWillUnmount() {
    if (
      this.state.jsonTenantSetting &&
      this.state.selectedFormat !==
        (this.state.jsonTenantSetting as any).formatValue
    ) {
      let payload: any = this.state.jsonTenantSetting;
      payload.formatValue = this.state.selectedFormat;

      this.SaveData(payload);
    }
  }

  private GetTenantSetting() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/MultiTenant/TenantSetting/Get`,
      { groupId: this._structure.TenantSetting.General.Id },
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        let fltr: any = response.filter(
          (x: any) => x.formatName === 'DateFormat'
        );

        if (fltr.length > 0) {
          this.setState({
            jsonTenantSetting: fltr[0],
            selectedFormat:
              fltr[0].formatValue === 'dd MMM yyyy'
                ? 'dd-MMM-yyyy'
                : fltr[0].formatValue,
          });
        }
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {
        this.JsonLoaded = true;
      });
  }

  public SaveData(payload: any) {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/MultiTenant/TenantSetting/Set`,
      payload,
      Common.getToken() || '',
      this.user
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        let usr: any = this.user;
        usr.settings.formats.DateShort = payload.formatValue;
        Common.setItem('user', usr);

        return response;
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  render() {
    const onRadioChange = (e: any) => {
      this.setState({ selectedFormat: e.value });
    };

    return (
      <div id='divdateformat' className='divdateformat'>
        <div className='row pt-1'>
          <div className='col-md-12'>
            <label className='genFormat-header'>Date format</label>
          </div>
        </div>
        <div className='row' style={{ marginTop: '10px', padding: '20px' }}>
          <div className='col-md-3'>
            <RadioButtonComponent
              checked={
                this.state.jsonTenantSetting &&
                ((this.state.jsonTenantSetting as any).formatValue ===
                  'dd-MMM-yyyy' ||
                  (this.state.jsonTenantSetting as any).formatValue ===
                    'dd MMM yyyy')
              }
              cssClass='w-100'
              label={`dd-mmm-yyyy (${Common.formatDate(
                new Date(),
                'dd-MMM-yyyy'
              )})`}
              name='dateFormat'
              value='dd-MMM-yyyy'
              change={onRadioChange}
            ></RadioButtonComponent>
          </div>
          <div className='col-md-3'>
            <RadioButtonComponent
              checked={
                this.state.jsonTenantSetting &&
                (this.state.jsonTenantSetting as any).formatValue ===
                  'dd/MM/yyyy'
              }
              cssClass='w-100'
              label={`dd/mm/yyyy (${Common.formatDate(
                new Date(),
                'dd/MM/yyyy'
              )})`}
              name='dateFormat'
              value='dd/MM/yyyy'
              change={onRadioChange}
            ></RadioButtonComponent>
          </div>
          <div className='col-md-3'>
            <RadioButtonComponent
              checked={
                this.state.jsonTenantSetting &&
                (this.state.jsonTenantSetting as any).formatValue ===
                  'MM/dd/yyyy'
              }
              cssClass='w-100'
              label={`mm/dd/yyyy (${Common.formatDate(
                new Date(),
                'MM/dd/yyyy'
              )})`}
              name='dateFormat'
              value='MM/dd/yyyy'
              change={onRadioChange}
            ></RadioButtonComponent>
          </div>
        </div>
      </div>
    );
  }
}
