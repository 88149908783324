import * as React from 'react';
import Common from '../../Util/Common';
import {
  Column,
  ColumnModel,
  DetailRow,
  ExcelExport,
  ExcelExportProperties,
  Filter,
  GridComponent,
  Inject,
  PdfExport,
  PdfExportProperties,
  Sort,
  Toolbar,
  Resize,
} from '@syncfusion/ej2-react-grids';
import PeriodSelector from '../PeriodSelector/PeriodSelector';

interface UploadType {
  reportid: string;
}

const {
  REACT_APP_ENDPOINT_CORESERVICE,
  REACT_APP_ENDPOINT_PAYROLLSERVICE,
  REACT_APP_ENDPOINT_API,
} = process.env;

export default class StatutoryReport extends React.Component<UploadType> {
  private user: any;
  private _structure: any;
  private token: any = null;
  private colsImportResult: ColumnModel[];
  private grid: GridComponent | null;
  private apiurl: any;

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
    this.colsImportResult = [];
    this.grid = null;

    if (this.props.reportid == 'NSSF_Report') {
      this.apiurl = `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payrolldata/shownssfreportdata`;

      this.colsImportResult = [
        {
          field: 'MEMBER_NO',
          headerText: 'MEMBER NO',
        },
        {
          field: 'FIRST_NAME',
          headerText: 'FIRST NAME',
        },
        {
          field: 'MIDDLE_NAME',
          headerText: 'MIDDLE NAME',
        },
        {
          field: 'SURNAME',
          headerText: 'SURNAME',
        },
        {
          field: 'WAGE',
          headerText: 'WAGE',
        },
      ];
    } else if (this.props.reportid == 'NHIF_Report') {
      this.apiurl = `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payrolldata/shownhifreportdata`;
      this.colsImportResult = [
        {
          field: 'MEMBER_NO',
          headerText: 'Membership No',
        },
        {
          field: 'Mobile_Number',
          headerText: 'Mobile Number',
        },
        {
          field: 'National_ID',
          headerText: 'National ID',
        },
        {
          field: 'Basic_Salary',
          headerText: 'Basic Salary',
        },
      ];
    } else if (this.props.reportid == 'Tax_Report') {
      this.apiurl = `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payrolldata/showtaxreportdata`;
      this.colsImportResult = [
        {
          field: 'S_N',
          headerText: 'S_N',
        },
        {
          field: 'EMPLOYEE_TIN',
          headerText: 'EMPLOYEE_TIN',
        },
        {
          field: 'RESIDENTAL_STATUS',
          headerText: 'RESIDENTAL_STATUS',
        },
        {
          field: 'TYPE_OF_EMPLOYMENT',
          headerText: 'TYPE_OF_EMPLOYMENT',
        },
        {
          field: 'SOCIAL_SECURITY_NO',
          headerText: 'SOCIAL_SECURITY_NO',
        },
        {
          field: 'BASIC_SALARY',
          headerText: 'BASIC_SALARY',
        },
        {
          field: 'ALLOWANCE',
          headerText: 'ALLOWANCE',
        },
        {
          field: 'DEDUCTION',
          headerText: 'DEDUCTION',
        },
        {
          field: 'LOCATION',
          headerText: 'LOCATION',
        },
      ];
    } else if (this.props.reportid == 'WCF_Report') {
      this.apiurl = `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payrolldata/showwcfreportdata`;
      this.colsImportResult = [
        {
          field: 'MEMBER_NO',
          headerText: 'WCF Number',
        },
        {
          field: 'FIRST_NAME',
          headerText: 'First Name',
        },
        {
          field: 'MIDDLE_NAME',
          headerText: 'Middle Name',
        },
        {
          field: 'SURNAME',
          headerText: 'Last Name',
        },
        {
          field: 'GENDER',
          headerText: 'Gender',
        },
        {
          field: 'DATE_OF_BIRTH',
          headerText: 'DOB',
        },
        {
          field: 'BASIC_SALARY',
          headerText: 'Basic Salary',
        },
        {
          field: 'JOB',
          headerText: 'Job Title',
        },
        {
          field: 'GENDER',
          headerText: 'Gender',
        },
        {
          field: 'EMPLOYMENT_CATEGORY',
          headerText: 'Employment Category',
        },
        {
          field: 'NIDA',
          headerText: 'National Id',
        },
      ];
    } else if (this.props.reportid == 'Housing_Levy_Report') {
      this.apiurl = `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payrolldata/showhousinglevyreportdata`;
      this.colsImportResult = [
        {
          field: 'ID',
          headerText: 'ID',
        },
        {
          field: 'Employee_Name',
          headerText: 'Employee Name',
        },
        {
          field: 'PIN',
          headerText: 'PIN',
        },
        {
          field: 'Gross_Salary',
          headerText: 'Gross Salary',
        },
      ];
    } else if (this.props.reportid == 'NITA_Report') {
      this.apiurl = `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payrolldata/shownitareportdata`;
      this.colsImportResult = [
        {
          field: 'ID',
          headerText: 'ID',
        },
        {
          field: 'Employee_Name',
          headerText: 'Employee Name',
        },
        {
          field: 'PIN',
          headerText: 'PIN',
        },
        {
          field: 'NITA',
          headerText: 'NITA',
        },
      ];
    } else if (this.props.reportid == 'NHIF_Report_Kenya') {
      this.apiurl = `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payrolldata/shownhifreportkenyadata`;
      this.colsImportResult = [
        {
          field: 'EMPLOYEE_CODE',
          headerText: 'PAYROLL NUMBER',
        },
        {
          field: 'SURNAME',
          headerText: 'LAST NAME',
        },
        {
          field: 'FIRST_NAME',
          headerText: 'FIRST NAME',
        },
        {
          field: 'ID',
          headerText: 'ID',
        },
        {
          field: 'NHIF_NO',
          headerText: 'NHIF NO',
        },
        {
          field: 'NHIF_AMOUNT',
          headerText: 'AMOUNT',
        },
      ];
    } else if (this.props.reportid == 'NSSF_Report_Kenya') {
      this.apiurl = `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payrolldata/shownssfreportkenyadata`;
      this.colsImportResult = [
        {
          field: 'EMPLOYEE_CODE',
          headerText: 'PAYROLL NUMBER',
        },
        {
          field: 'SURNAME',
          headerText: 'SURNAME',
        },
        {
          field: 'OTHER_NAMES',
          headerText: 'OTHER NAMES',
        },
        {
          field: 'ID',
          headerText: 'ID',
        },
        {
          field: 'PIN',
          headerText: 'KRA PIN',
        },
        {
          field: 'NSSF_NO',
          headerText: 'NSSF NO',
        },
        {
          field: 'GROSS_SALARY',
          headerText: 'GROSS PAY',
        },
        {
          field: 'VOLUNTARY',
          headerText: 'VOLUNTARY',
        },
      ];
    }
  }

  state = {
    jsonEmployee: undefined,
    periodselectorkey: '',
    periodid: '',
    pstartDate: null,
    pendDate: null,
  };

  private handleChange = (e: any) => {
    this.setState({
      periodid: e.id,
      pstartDate: e.startDate,
      pendDate: e.endDate,
    });
    this.fetchData(e.id);
  };

  public Filter: any = {
    type: 'CheckBox',
  };

  private fetchData(pid: any) {
    Common.ShowSpinner();
    Common.ApiCallAsync(
      'POST',
      this.apiurl,
      { periodId: pid },
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((data: any) => {
        let jdataFlat: any = undefined;
        jdataFlat = Common.flattenJSON(data, {}, '', null, [], [], '_');
        if (this.grid) {
          this.grid.dataSource = jdataFlat;
          this.grid.refresh();
        } else {
          this.setState({ dataSource: jdataFlat });
        }
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {
        Common.HideSpinner();
      });
  }

  private handleExcelExportClick(args: any) {
    if (this.props.reportid == 'NSSF_Report') {
      // NSSF Report Tanzania
      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payrolldata/nssfreport`,
        { periodId: this.state.periodid },
        Common.getToken() || '',
        null,
        this.token.tenant,
        'blob'
      ).then((response: any) => {
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        const hh = today.getHours();
        const mm = today.getMinutes();
        const ss = today.getSeconds();

        // Create a new Blob object using the response data
        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        // Create a link element
        const link = document.createElement('a');

        // Set the download attribute with a filename
        link.href = URL.createObjectURL(blob);
        // link.download = 'Sample.xlsx';
        link.setAttribute(
          'download',
          'NSSF_' + date + month + year + hh + mm + ss + '.xlsx'
        );

        // Append the link to the body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up and remove the link
        // link.parentNode.removeChild(link);
        link.remove();

        return response.data;
      });
    } else if (this.props.reportid == 'NHIF_Report') {
      // NHIF Report Tanzania
      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payrolldata/nhifreport`,
        { periodId: this.state.periodid },
        Common.getToken() || '',
        null,
        this.token.tenant,
        'blob'
      ).then((response: any) => {
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        const hh = today.getHours();
        const mm = today.getMinutes();
        const ss = today.getSeconds();

        // Create a new Blob object using the response data
        const blob = new Blob([response.data], {});

        // Create a link element
        const link = document.createElement('a');

        // Set the download attribute with a filename
        link.href = URL.createObjectURL(blob);
        // link.download = 'Sample.xlsx';
        link.setAttribute(
          'download',
          'NHIF_' + date + month + year + hh + mm + ss + '.csv'
        );

        // Append the link to the body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up and remove the link
        // link.parentNode.removeChild(link);
        link.remove();

        return response.data;
      });
    } else if (this.props.reportid == 'Tax_Report') {
      // Tax Report
      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payrolldata/taxreport`,
        { periodId: this.state.periodid },
        Common.getToken() || '',
        null,
        this.token.tenant,
        'blob'
      ).then((response: any) => {
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        const hh = today.getHours();
        const mm = today.getMinutes();
        const ss = today.getSeconds();

        // Create a new Blob object using the response data
        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        // Create a link element
        const link = document.createElement('a');

        // Set the download attribute with a filename
        link.href = URL.createObjectURL(blob);
        // link.download = 'Sample.xlsx';
        link.setAttribute(
          'download',
          'TAX_' + date + month + year + hh + mm + ss + '.xlsm'
        );

        // Append the link to the body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up and remove the link
        // link.parentNode.removeChild(link);
        link.remove();

        return response.data;
      });
    } else if (this.props.reportid == 'WCF_Report') {
      // WCF Report Tanzania
      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payrolldata/wcfreport`,
        { periodId: this.state.periodid },
        Common.getToken() || '',
        null,
        this.token.tenant,
        'blob'
      ).then((response: any) => {
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        const hh = today.getHours();
        const mm = today.getMinutes();
        const ss = today.getSeconds();

        // Create a new Blob object using the response data
        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        // Create a link element
        const link = document.createElement('a');

        // Set the download attribute with a filename
        link.href = URL.createObjectURL(blob);
        // link.download = 'Sample.xlsx';
        link.setAttribute(
          'download',
          'WCF_' + date + month + year + hh + mm + ss + '.xlsx'
        );

        // Append the link to the body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up and remove the link
        // link.parentNode.removeChild(link);
        link.remove();

        return response.data;
      });
    } else if (this.props.reportid == 'Housing_Levy_Report') {
      // Housing Levy Report Kenya
      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payrolldata/housinglevyreport`,
        { periodId: this.state.periodid },
        Common.getToken() || '',
        null,
        this.token.tenant,
        'blob'
      ).then((response: any) => {
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        const hh = today.getHours();
        const mm = today.getMinutes();
        const ss = today.getSeconds();

        // Create a new Blob object using the response data
        const blob = new Blob([response.data], {});

        // Create a link element
        const link = document.createElement('a');

        // Set the download attribute with a filename
        link.href = URL.createObjectURL(blob);
        // link.download = 'Sample.xlsx';
        link.setAttribute(
          'download',
          'HOUSING_LEVY_' + date + month + year + hh + mm + ss + '.csv'
        );

        // Append the link to the body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up and remove the link
        // link.parentNode.removeChild(link);
        link.remove();

        return response.data;
      });
    } else if (this.props.reportid == 'NITA_Report') {
      // NITA Report Kenya
      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payrolldata/nitareport`,
        { periodId: this.state.periodid },
        Common.getToken() || '',
        null,
        this.token.tenant,
        'blob'
      ).then((response: any) => {
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        const hh = today.getHours();
        const mm = today.getMinutes();
        const ss = today.getSeconds();

        // Create a new Blob object using the response data
        const blob = new Blob([response.data], {});

        // Create a link element
        const link = document.createElement('a');

        // Set the download attribute with a filename
        link.href = URL.createObjectURL(blob);
        // link.download = 'Sample.xlsx';
        link.setAttribute(
          'download',
          'NITA_' + date + month + year + hh + mm + ss + '.csv'
        );

        // Append the link to the body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up and remove the link
        // link.parentNode.removeChild(link);
        link.remove();

        return response.data;
      });
    } else if (this.props.reportid == 'NHIF_Report_Kenya') {
      // NHIF Report Kenya
      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payrolldata/nhifreportkenya`,
        { periodId: this.state.periodid },
        Common.getToken() || '',
        null,
        this.token.tenant,
        'blob'
      ).then((response: any) => {
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        const hh = today.getHours();
        const mm = today.getMinutes();
        const ss = today.getSeconds();

        // Create a new Blob object using the response data
        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        // Create a link element
        const link = document.createElement('a');

        // Set the download attribute with a filename
        link.href = URL.createObjectURL(blob);
        // link.download = 'Sample.xlsx';
        link.setAttribute(
          'download',
          'NHIF_' + date + month + year + hh + mm + ss + '.xlsx'
        );

        // Append the link to the body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up and remove the link
        // link.parentNode.removeChild(link);
        link.remove();

        return response.data;
      });
    } else if (this.props.reportid == 'NSSF_Report_Kenya') {
      // NSSF Report Kenya
      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payrolldata/nssfreportkenya`,
        { periodId: this.state.periodid },
        Common.getToken() || '',
        null,
        this.token.tenant,
        'blob'
      ).then((response: any) => {
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        const hh = today.getHours();
        const mm = today.getMinutes();
        const ss = today.getSeconds();

        // Create a new Blob object using the response data
        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        // Create a link element
        const link = document.createElement('a');

        // Set the download attribute with a filename
        link.href = URL.createObjectURL(blob);
        // link.download = 'Sample.xlsx';
        link.setAttribute(
          'download',
          'NSSF_' + date + month + year + hh + mm + ss + '.xlsx'
        );

        // Append the link to the body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up and remove the link
        // link.parentNode.removeChild(link);
        link.remove();

        return response.data;
      });
    }
  }

  render() {
    return (
      <>
        <div className='row'>
          <div
            className='col-12'
            style={{ display: 'flex', position: 'relative' }}
          >
            <div
              className='col-12'
              style={{
                width: '99%',
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
              }}
            >
              <div
                id='statutoryreport-periodctrls'
                style={{
                  left: '10px',
                  border: '1px solid rgba(0, 0, 0, 0.12)',
                }}
              >
                <PeriodSelector
                  key={this.state.periodselectorkey}
                  handleInputChange={this.handleChange}
                  id='payroll-selector'
                  _jObject={[]}
                  showEmployeePeriodsOnly={false}
                  showLastPeriod={true}
                  alttext={'No Period Defined.'}
                />
              </div>
            </div>
            <div style={{ position: 'relative', right: '10px', top: '8px' }}>
              <span
                id='Eexport'
                onClick={this.handleExcelExportClick.bind(this)}
                style={{
                  fontSize: '15px',
                  cursor: 'pointer',
                  padding: '10px',
                  borderRadius: '4px',
                  fontWeight: 400,
                  boxShadow: 'none',
                  border: '1px solid rgba(0, 0, 0, 0.12)',
                  position: 'relative',
                  bottom: '4px',
                }}
                className='e-tbar-btn e-btn fa-regular fa-download'
              ></span>
            </div>
          </div>
        </div>
        <div className='col-12 py-2'>
          <GridComponent
            id='payrollemployeelist-grid'
            height={'calc(117vh - 500px)'}
            width={'100%'}
            className='payrollemployeelist-grid-class'
            dataSource={this.state.jsonEmployee}
            columns={this.colsImportResult}
            ref={(obj: GridComponent | null) => (this.grid = obj)}
            allowFiltering={true}
            allowSorting={true}
            allowResizing={true}
            filterSettings={this.Filter}
          ></GridComponent>
        </div>
      </>
    );
  }
}
