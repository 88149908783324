import * as React from 'react';
import Common from '../Util/Common';
import './Attendance.css';
import {
  DialogComponent,
  TooltipComponent,
} from '@syncfusion/ej2-react-popups';
import {
  DatePickerComponent,
  TimePickerComponent,
  Inject as TimeInject,
  MaskedDateTime,
} from '@syncfusion/ej2-react-calendars';
import {
  Edit,
  GridComponent,
  ColumnDirective,
  ColumnsDirective,
  Inject,
  Toolbar,
  AggregatesDirective,
  AggregateDirective,
  AggregateColumnsDirective,
  AggregateColumnDirective,
  Aggregate,
  ExcelExport,
} from '@syncfusion/ej2-react-grids';
import {
  ContextMenuComponent,
  MenuItemModel,
} from '@syncfusion/ej2-react-navigations';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import FileUpload from './FileUpload';
const {
  REACT_APP_ENDPOINT_ATTENDANCESERVICE,
  REACT_APP_ENDPOINT_CORESERVICE,
  REACT_APP_ENDPOINT_LEAVESERVICE,
} = process.env;

interface Props {
  selectorData: any;
}

class Attendance extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;

  private TimeRangeref: GridComponent | null = null;
  private TimeGrid: GridComponent | null = null;
  private startTimeRef: TimePickerComponent | null = null;
  private endTimeRef: TimePickerComponent | null = null;
  private cMenu: ContextMenuComponent | any = null;
  private cntxitems: MenuItemModel[] | any = null;
  private remarkTooltip: TooltipComponent | null = null;
  private newSummaryData: any = [];
  private emp_Data: any = [];
  private modifiedArray: any = [];
  private currentRId: number = 0;
  private GridId: any = 'Timesheet';
  private menuItems: MenuItemModel[];
  private menuObj: ContextMenuComponent | any = null;
  private clickedRow: any = undefined;
  private clickedRowId: any = undefined;
  toastObj: any;

  constructor(props: any) {
    super(props);
    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');

    this.menuItems = [
      { id: '101', text: 'New', iconCss: 'e-icons e-plus' },
      { id: '102', text: 'Edit', iconCss: 'e-icons e-edit' },
      { id: '103', text: 'Delete', iconCss: 'e-icons e-trash' },
    ];
    this.cntxitems = [
      {
        text: 'Display Work',
        items: [
          { text: 'Hours', id: 'dataHours' },
          { id: 'dataMinutes', text: 'Minutes' },
        ],
      },
      {
        text: 'Display Date',
        items: [
          { id: '_fullDate', text: 'Full' },
          { id: '_day', text: 'Day' },
        ],
      },
    ];
  }

  state = {
    Attendance: [],
    DeleteEmpData: [],
    _startDate: undefined,
    _endDate: undefined,
    selectedDate: undefined,
    showDialog: false,
    _clickedrow: undefined,
    _singleClickedrow: undefined,
    _dlgWidth: '50vw',
    _dlgHeight: '40vh',
    _isEditing: false,
    _dlgInvoker: '',
    _dataInMinutes: false,
    _fulldate: true,
    _holidays: [],
    _leaves: [],
    _year: undefined,
    _month: undefined,
    _datafilled: undefined,
    _partialDelete: false,
    showTimeUploadDialog: false,
  };

  componentDidMount() {
    let dateStart: any = Common.formatDate(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      'yyyy-MM-dd'
    );
    let dateEnd: any = Common.formatDate(
      new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
      'yyyy-MM-dd'
    );
    this.setState({
      selectedDate: new Date(),
      _startDate: dateStart,
      _endDate: dateEnd,
    });

    let payload: any = {
      startDate: this.state._startDate,
      endDate: this.state._endDate,
    };
    if (this.state.Attendance.length <= 0) {
      this.getAttendance(payload);
    }

    if (this.state._holidays.length <= 0) {
      this.fetchHoliday(new Date().getFullYear());
    }

    if (this.state._leaves.length <= 0) {
      this.GetIssuedLeaves();
    }
  }

  private GetIssuedLeaves() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_LEAVESERVICE}/leaveapplication/getissued${
        !this.user.isManager ? '/ess' : ''
      }`,
      {
        employeeId: this.props.selectorData.id,
        excludeCancelledDays: true,
      },
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((resopnse: any) => {
        this.setState({
          _month: new Date().getMonth(),
          _leaves: resopnse,
        });
      })
      .catch((error: any) => {
        console.error(error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.messages &&
          error.response.data.messages.length > 0
        ) {
          this.toastObj = ToastUtility.show({
            cssClass: 'e-toast-error',
            icon: 'e-error toast-icons',
            showCloseButton: true,
            content: `<p>${error.response.data.messages.join('<br>')}</p>`,
            target: '#basic_card',
            position: { X: 'right', Y: 'Top' },
            timeOut: 5000,
          });
        }
      })
      .finally(() => {});
  }

  private fetchHoliday(myear: number) {
    let startDate = Common.formatDate(new Date(myear, 0, 1), 'yyyy-MM-dd');
    let endDate = Common.formatDate(new Date(myear, 11, 31), 'yyyy-MM-dd');

    let payload: any = {
      fromDate: startDate,
      toDate: endDate,
    };
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/holiday/get`,
      payload,
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        this.setState({
          _year: new Date().getFullYear(),
          _holidays: response.data,
        });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  private getAttendance(payload: any) {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_ATTENDANCESERVICE}/attendance/getall`,
      payload,
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((data: any) => {
        this.setState({
          Attendance: data,
          _datafilled: true,
        });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  private updateAttendance(payload: any) {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_ATTENDANCESERVICE}/attendance/update`,
      payload,
      Common.getToken() || '',
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((data: any) => {
        this.newSummaryData = [];
        let getpayload: any = {
          startDate: this.state._startDate,
          endDate: this.state._endDate,
        };
        this.getAttendance(getpayload);
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  private addAttendance(payload: any) {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_ATTENDANCESERVICE}/attendance/addall`,
      payload,
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((data: any) => {
        this.newSummaryData = [];
        let getpayload: any = {
          startDate: this.state._startDate,
          endDate: this.state._endDate,
        };
        this.getAttendance(getpayload);
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  handleChange = (args: any) => {
    let dateStart: any = Common.formatDate(
      new Date(
        new Date(args.target.value).getFullYear(),
        new Date(args.target.value).getMonth(),
        1
      ),
      'yyyy-MM-dd'
    );
    let dateEnd: any = Common.formatDate(
      new Date(
        new Date(args.target.value).getFullYear(),
        new Date(args.target.value).getMonth() + 1,
        0
      ),
      'yyyy-MM-dd'
    );

    this.setState({
      selectedDate: args.target.value,
      _startDate: dateStart,
      _endDate: dateEnd,
    });
    let payload: any = {
      startDate: this.state._startDate,
      endDate: this.state._endDate,
    };
    this.getAttendance(payload);
    if (this.state._year !== new Date(dateStart).getFullYear()) {
      this.fetchHoliday(new Date(dateStart).getFullYear());
    }
    if (this.state._month !== new Date(dateStart).getMonth()) {
      this.GetIssuedLeaves();
    }
  };

  private doubleclick(_data: any) {
    const closestRow = _data.target.closest('tr');
    this.setState({
      _isEditing: true,
      _clickedrow: closestRow,
      showDialog: true,
      _dlgInvoker: 'rowclick',
      _dlgWidth: '35vw',
      _dlgHeight: '40vh',
    });
  }

  private handleStartTimeChange = (args: any) => {
    if (args.value) {
      let nxtctrl = args.target.id.replace('I', 'O');
      if (nxtctrl) {
        if (document.getElementById(nxtctrl)) {
          let minvalue = new Date(args.value).setMinutes(
            new Date(args.value).getMinutes() + 1
          );
          (document.getElementById(nxtctrl) as any).ej2_instances[0].min =
            new Date(minvalue);
        }
      }

      let _row: any = this.state._clickedrow;
      let currDate = new Date(parseFloat(_row.id)).toDateString();
      let currTime = args.value.toTimeString();
      args.value = currDate + ' ' + currTime;
      let startTimeId = args.target.id.split(['-'])[2];
      var newTimeData: any = document.getElementsByClassName(
        startTimeId
      ) as any;
      let Out: any;
      for (let index = 0; index < newTimeData.length; index++) {
        const element = newTimeData[index];
        if (element.id !== args.target.id) {
          Out = element;
        }
      }
      var prevRow = this.modifiedArray.filter(
        (item: any) =>
          item.RId < args.target.element.parentElement.parentElement.id &&
          item.Id == args.target.id.split(['-'])[1]
      );
      if (prevRow.length > 0) {
        prevRow = prevRow[prevRow.length - 1];
        if (new Date(prevRow.Out).getTime() >= new Date(args.value).getTime()) {
          // this.toastObj = ToastUtility.show({
          //   cssClass: 'e-toast-warning',
          //   icon: 'e-warning toast-icons',
          //   showCloseButton: true,
          //   content: 'Please enter correct start time.',
          //   target: '#basic_card',
          //   position: { X: 'right', Y: 'Top' },
          //   timeOut: 5000,
          // });
          args.target.element.parentElement.style.setProperty(
            'border-bottom',
            '2px solid red',
            'important'
          );
          args.target.element.ej2_instances[0].value = undefined;
        } else {
          let itemIndex = this.modifiedArray.findIndex((item: any) => {
            return (
              item.Id === args.target.id.split('-')[1] &&
              item.RId == args.target.element.parentElement.parentElement.id
            );
          });
          if (itemIndex !== -1) {
            args.target.element.parentElement.style.borderBottom = '1px solid';
            this.modifiedArray[itemIndex].In =
              Common.RemoveTimezoneOffsetFromDate(new Date(args.value))
                .toISOString()
                .replace('Z', '');
          }
        }
      } else {
        var current = this.modifiedArray.filter(
          (item: any) =>
            item.RId == args.target.element.parentElement.parentElement.id &&
            item.Id === args.target.id.split(['-'])[1]
        );
        if (current.length > 0) {
          if (
            new Date(args.value).getTime() >= new Date(current[0].Out).getTime()
          ) {
            // this.toastObj = ToastUtility.show({

            //   cssClass: 'e-toast-warning',
            //   icon: 'e-warning toast-icons',
            //   showCloseButton: true,
            //   content: 'Please enter correct start time.',
            //   target: '#basic_card',
            //   position: { X: 'right', Y: 'Top' },
            //   timeOut: 5000,
            // });
            args.target.element.parentElement.style.setProperty(
              'border-bottom',
              '2px solid red',
              'important'
            );
            args.target.element.ej2_instances[0].value = undefined;
          } else {
            let itemIndex = this.modifiedArray.findIndex((item: any) => {
              return (
                item.Id === args.target.id.split('-')[1] &&
                item.RId == args.target.element.parentElement.parentElement.id
              );
            });
            if (itemIndex !== -1) {
              args.target.element.parentElement.style.borderBottom =
                '1px solid';
              this.modifiedArray[itemIndex].In =
                Common.RemoveTimezoneOffsetFromDate(new Date(args.value))
                  .toISOString()
                  .replace('Z', '');
            }
          }
        } else {
          let itemIndex = this.modifiedArray.findIndex((item: any) => {
            return item.Id === args.target.id.split('-')[1];
          });
          if (itemIndex !== -1) {
            args.target.element.parentElement.style.borderBottom = '1px solid';
            this.modifiedArray[itemIndex].In =
              Common.RemoveTimezoneOffsetFromDate(new Date(args.value))
                .toISOString()
                .replace('Z', '');
          }
        }
      }
    }
  };

  private handleEndTimeChange = (args: any) => {
    if (args.value) {
      let _row: any = this.state._clickedrow;
      let currDate = new Date(parseFloat(_row.id)).toDateString();
      let currTime = args.value.toTimeString();
      args.value = currDate + ' ' + currTime;
      let EndTimeId = args.target.id.split(['-'])[2];
      let newEndTimeData: any = document.getElementsByClassName(
        EndTimeId
      ) as any;
      let In: any;
      for (let index = 0; index < newEndTimeData.length; index++) {
        const element = newEndTimeData[index];
        if (element.id !== args.target.id) {
          In = element;
        }
      }
      var prevRow = this.modifiedArray.filter(
        (item: any) =>
          item.RId < args.target.element.parentElement.parentElement.id &&
          item.Id == args.target.id.split(['-'])[1]
      );
      var nextRow = this.modifiedArray.filter(
        (item: any) =>
          item.RId > args.target.element.parentElement.parentElement.id &&
          item.Id == args.target.id.split(['-'])[1]
      );
      if (nextRow.length > 0) {
        if (
          new Date(nextRow[0].In).getTime() <= new Date(args.value).getTime()
        ) {
          // this.toastObj = ToastUtility.show({

          //   cssClass: 'e-toast-warning',
          //   icon: 'e-warning toast-icons',
          //   showCloseButton: true,
          //   content: 'Please enter correct end time.',
          //   target: '#basic_card',
          //   position: { X: 'right', Y: 'Top' },
          //   timeOut: 5000,
          // });
          args.target.element.parentElement.style.setProperty(
            'border-bottom',
            '2px solid red',
            'important'
          );
          args.target.element.ej2_instances[0].value = undefined;
        } else {
          let itemIndex = this.modifiedArray.findIndex((item: any) => {
            return (
              item.Id === args.target.id.split('-')[1] &&
              item.RId == args.target.element.parentElement.parentElement.id
            );
          });
          if (itemIndex !== -1) {
            args.target.element.parentElement.style.borderBottom = '1px solid';
            this.modifiedArray[itemIndex].Out =
              Common.RemoveTimezoneOffsetFromDate(new Date(args.value))
                .toISOString()
                .replace('Z', '');
          }
        }
      }
      if (prevRow.length > 0) {
        prevRow = prevRow[prevRow.length - 1];
        if (new Date(prevRow.In).getTime() >= new Date(args.value).getTime()) {
          // this.toastObj = ToastUtility.show({

          //   cssClass: 'e-toast-warning',
          //   icon: 'e-warning toast-icons',
          //   showCloseButton: true,
          //   content: 'Please enter correct end time.',
          //   target: '#basic_card',
          //   position: { X: 'right', Y: 'Top' },
          //   timeOut: 5000,
          // });
          args.target.element.parentElement.style.setProperty(
            'border-bottom',
            '2px solid red',
            'important'
          );
          args.target.element.ej2_instances[0].value = undefined;
        } else {
          let itemIndex = this.modifiedArray.findIndex((item: any) => {
            return (
              item.Id === args.target.id.split('-')[1] &&
              item.RId == args.target.element.parentElement.parentElement.id
            );
          });
          if (itemIndex !== -1) {
            args.target.element.parentElement.style.borderBottom = '1px solid';
            this.modifiedArray[itemIndex].Out =
              Common.RemoveTimezoneOffsetFromDate(new Date(args.value))
                .toISOString()
                .replace('Z', '');
          }
        }
      } else {
        var current = this.modifiedArray.filter(
          (item: any) =>
            item.RId == args.target.element.parentElement.parentElement.id &&
            item.Id === args.target.id.split(['-'])[1]
        );
        if (current.length > 0) {
          if (
            new Date(args.value).getTime() <= new Date(current[0].In).getTime()
          ) {
            // this.toastObj = ToastUtility.show({

            //   cssClass: 'e-toast-warning',
            //   icon: 'e-warning toast-icons',
            //   showCloseButton: true,
            //   content: 'Please enter correct end time.',
            //   target: '#basic_card',
            //   position: { X: 'right', Y: 'Top' },
            //   timeOut: 5000,
            // });
            args.target.element.parentElement.style.setProperty(
              'border-bottom',
              '2px solid red',
              'important'
            );
            args.target.element.ej2_instances[0].value = undefined;
          } else {
            let itemIndex = this.modifiedArray.findIndex(
              (item: any) => item.Id === args.target.id.split('-')[1]
            );
            if (itemIndex !== -1) {
              args.target.element.parentElement.style.borderBottom =
                '1px solid';
              this.modifiedArray[itemIndex].Out =
                Common.RemoveTimezoneOffsetFromDate(new Date(args.value))
                  .toISOString()
                  .replace('Z', '');
            }
          }
        } else {
          let itemIndex = this.modifiedArray.findIndex((item: any) => {
            return item.Id === args.target.id.split('-')[1];
          });
          if (itemIndex !== -1) {
            args.target.element.parentElement.style.borderBottom = '1px solid';
            this.modifiedArray[itemIndex].Out =
              Common.RemoveTimezoneOffsetFromDate(new Date(args.value))
                .toISOString()
                .replace('Z', '');
          }
        }
      }
    }
  };

  private handleRemarkChange = (args: any) => {
    let itemIndex = this.modifiedArray.findIndex((item: any) => {
      return (
        item.Id === args.target.id.split('|')[0] &&
        item.RId == args.target.id.split('|')[1]
      );
    });
    if (itemIndex !== -1) {
      this.modifiedArray[itemIndex].RowRemark = args.value;
    }
  };

  private TimePickerTemplate = (props: any) => {
    const startTimeId =
      `I-${props.Id}-${
        props.In
          ? props.In.split('T')[1].replaceAll(':', '').split('.')[0]
          : '00'
      }` +
      '|' +
      props.RId;
    const endTimeId =
      `O-${props.Id}-${
        props.Out
          ? props.Out.split('T')[1].replaceAll(':', '').split('.')[0]
          : '00'
      }` +
      '|' +
      props.RId;

    const maxRId = Math.max(
      ...this.newSummaryData
        .filter((item: any) => item.Id === props.Id)
        .map((item: any) => item.RId)
    );

    let preval: any;
    let currval: any;
    let startminval: any;
    let endminval: any;
    if (props.RId > 0) {
      preval = this.newSummaryData.filter(
        (x: any) => x.Id == props.Id && x.RId < props.RId
      );
      currval = this.newSummaryData.filter(
        (x: any) => x.Id == props.Id && x.RId == props.RId
      );
      if (preval) {
        preval = preval[preval.length - 1]?.Out;
      }
      startminval = new Date(
        new Date(preval).setMinutes(new Date(preval).getMinutes() + 1)
      );
      currval = currval[currval.length - 1];
      if (currval) {
      if (currval.In !== undefined && currval.Out !== undefined) {
        endminval = new Date(
            new Date(currval.In).setMinutes(
              new Date(currval.In).getMinutes() + 1
            )
        );
      }
      }
    } else if (props.RId == 0) {
      currval = this.newSummaryData.filter(
        (x: any) => x.Id == props.Id && x.RId == props.RId
      );
      currval = currval[currval.length - 1];
      if (currval) {
      if (currval.In !== undefined && currval.Out !== undefined) {
        endminval = new Date(
            new Date(currval.In).setMinutes(
              new Date(currval.In).getMinutes() + 1
            )
        );
      }
    }
    }

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div
          id={props.RId}
          style={{ display: 'flex', gap: '10px', width: '50%' }}
        >
        <TimePickerComponent
          enableMask={true}
          format='h:mm a'
          id={startTimeId}
          placeholder='Start Time'
          value={props.In ? new Date(props.In) : undefined}
          onChange={this.handleStartTimeChange}
          ref={(sc) => (this.startTimeRef = sc)}
          className={`${
            props.In
            ? props.In.split('T')[1].replaceAll(':', '').split('.')[0]
            : '00'
            }`}
            min={startminval}
          step={5}
        >
          <TimeInject services={[MaskedDateTime]} />
        </TimePickerComponent>
        <TimePickerComponent
          id={endTimeId}
          enableMask={true}
          format='h:mm a'
          placeholder='End Time'
          value={props.Out ? new Date(props.Out) : undefined}
          onChange={this.handleEndTimeChange}
          ref={(sc) => (this.endTimeRef = sc)}
          className={`${
            props.In
            ? props.In.split('T')[1].replaceAll(':', '').split('.')[0]
            : '00'
            }`}
            min={endminval}
          step={5}
        >
          <TimeInject services={[MaskedDateTime]} />
        </TimePickerComponent>
      </div>
        <TextBoxComponent
          id={props.Id + '|' + props.RId}
          width={'45%'}
          placeholder='Notes (optional)'
          value={props ? props.RowRemark : ''}
          onChange={this.handleRemarkChange.bind(this)}
        ></TextBoxComponent>
        {props.RId === maxRId && this.state._partialDelete && (
          <div style={{ position: 'relative', top: '3px' }}>
            <span
              id={props.RId}
              style={{
                fontSize: '15px',
                cursor: 'pointer',
                borderRadius: '4px',
                fontWeight: 400,
                boxShadow: 'none',
              }}
            >
              <i
                id='btnDelete'
                onClick={() => this.handleDeleteSelectedTime(props)}
                className='e-icons e-trash'
              ></i>
            </span>
          </div>
        )}
      </div>
    );
  };

  private formatDuration(durationMs: any) {
    if (this.state._dataInMinutes) {
      return durationMs;
    } else {
      const hours = Math.floor(durationMs / 60);
      const minutes = durationMs % 60;
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
        2,
        '0'
      )}`;
    }
  }

  private toolbarClick(args: any) {
    let _row: any = this.state._clickedrow;
    let existingData = this.modifiedArray.filter(
      (item: any) => item.Id == _row.id
    );
    if (args.item.id === 'Timesheet_add') {
      this.currentRId++;
      let newEntry: any = {
        Id: _row.id,
        In: undefined,
        Out: undefined,
        RId: existingData ? existingData.length : this.currentRId,
        Inid: Common.blankguid,
        Outid: Common.blankguid,
        RowRemark: '',
        _newAdded: true,
      };
      if (this.TimeRangeref) {
        this.newSummaryData.push(newEntry);
        this.modifiedArray = this.newSummaryData;
        this.TimeRangeref?.addRecord(newEntry, this.newSummaryData.length - 1);
      }
    }
  }

  private DialogButtonModel: any = [
    {
      buttonModel: {
        content: Common.toTitleCase('Cancel'),
        cssClass: 'flat-button',
      },
      click: () => {
        this.newSummaryData = this.modifiedArray.filter(
          (item: any) => item._newAdded == false
        );

        if (this.state.showDialog === true) {
          this.setState({
            showDialog: false,
            _isEditing: false,
            _partialDelete: false,
          });
        }
      },
    },
    {
      buttonModel: {
        content: Common.toTitleCase('Accept'),
        cssClass: 'flat-button',
        isPrimary: true,
      },
      click: () => {
        let _rows: any = this.state._clickedrow;
        let addPayload: any = [];
        let updatePayload: any = [];
        let currRowData = this.modifiedArray.filter(
          (item: any) => item.Id == _rows.id
        );
        if (currRowData.length > 0) {
          if (
            currRowData.filter(
              (x: any) => x.In == undefined || x.Out == undefined
            ).length > 0
          ) {
            // this.toastObj = ToastUtility.show({

            //   cssClass: 'e-toast-warning',
            //   icon: 'e-warning toast-icons',
            //   showCloseButton: true,
            //   content: 'Please enter start and end time.',
            //   target: '#basic_card',
            //   position: { X: 'right', Y: 'Top' },
            //   timeOut: 5000,
            // });
            let TimepickerCompo: any = document.querySelectorAll(
              '#Timesheet_content_table .e-rowcell .e-input-group.e-control-wrapper.e-time-wrapper'
            );
            if (TimepickerCompo) {
              TimepickerCompo.forEach((element: HTMLElement) => {
                let TimeInput: any = element.getElementsByTagName('input');
                if (TimeInput && TimeInput[0].value == '') {
                  element.style.setProperty(
                    'border-bottom',
                    '2px solid red',
                    'important'
                  );
                }
            });
            }
            return;
          }

          currRowData.map((item: any) => {
            addPayload.push(
              {
                employeeId: this.props.selectorData.id,
                shiftId: this.props.selectorData.employeeShift[0].shiftId,
                logindatetime: item.In,
                sourcetype: 1,
                baseWorkHours:
                  this.props.selectorData.employeeShift[0].shift.shiftTran[0]
                    .workingHour,
                remark: item.RowRemark,
              },
              {
                employeeId: this.props.selectorData.id,
                shiftId: this.props.selectorData.employeeShift[0].shiftId,
                logindatetime: item.Out,
                sourcetype: 1,
                baseWorkHours:
                  this.props.selectorData.employeeShift[0].shift.shiftTran[0]
                    .workingHour,
                remark: item.RowRemark,
              }
            );
            updatePayload.push(
              {
                id: item.Inid,
                employeeId: this.props.selectorData.id,
                shiftId: this.props.selectorData.employeeShift[0].shiftId,
                logindatetime: item.In,
                sourcetype: 1,
                baseWorkHours:
                  this.props.selectorData.employeeShift[0].shift.shiftTran[0]
                    .workingHour,
                remark: item.RowRemark,
              },
              {
                id: item.Outid,
                employeeId: this.props.selectorData.id,
                shiftId: this.props.selectorData.employeeShift[0].shiftId,
                logindatetime: item.Out,
                sourcetype: 1,
                baseWorkHours:
                  this.props.selectorData.employeeShift[0].shift.shiftTran[0]
                    .workingHour,
                remark: item.RowRemark,
              }
            );
          });
        }
        if (_rows.cells[2].innerHTML || _rows.cells[3].innerHTML) {
          this.updateAttendance(updatePayload);
        } else {
          this.addAttendance(addPayload);
        }
        let inCell = _rows.querySelector('td:nth-child(3)');
        let outCell = _rows.querySelector('td:nth-child(4)');
        if (currRowData.length > 0) {
        if (currRowData[0].In) {
          inCell.innerHTML = Common.formatDate(
            new Date(currRowData[0].In),
            'HH:mm'
          );
        } else {
          inCell.innerHTML = '';
        }
        if (currRowData[currRowData.length - 1].Out) {
          outCell.innerHTML = Common.formatDate(
            new Date(currRowData[currRowData.length - 1].Out),
            'HH:mm'
          );
        } else {
          outCell.innerHTML = '';
        }
        }

        this.setState({
          showDialog: false,
          _isEditing: false,
          _partialDelete: false,
        });
      },
    },
  ];

  private dialogclose() {
    this.newSummaryData = this.modifiedArray.filter(
      (item: any) => item._newAdded == false
    );

    this.setState({
      showDialog: false,
      _isEditing: false,
      _partialDelete: false,
    });
  }

  private dialogContent() {
    var _content: any;
    if (this.state._dlgInvoker == 'rowclick') {
      if (this.state._isEditing == true) {
        let _rows: any = this.state._clickedrow;
        let TimeEData = this.newSummaryData.filter(
          (x: any) => x.Id == _rows.id
        );
        let newBlankEntry = {
          Id: _rows.id,
          In: undefined,
          Out: undefined,
          RId: 0,
          Inid: Common.blankguid,
          Outid: Common.blankguid,
          RowRemark: '',
        };
        if (TimeEData.length === 0) {
          TimeEData.push(newBlankEntry);
          if (
            !this.newSummaryData.some(
              (item: any) =>
                item.id === newBlankEntry.Id && item.RId === newBlankEntry.RId
            )
          ) {
            this.newSummaryData.push(newBlankEntry);
            this.modifiedArray = this.newSummaryData;
          }
        }

        _content = (
          <>
            <div
              style={{
                fontSize: '25px',
                borderBottom: '1px solid steelblue',
                paddingBottom: '12px',
              }}
            >
              {Common.formatDate(
                new Date(parseInt(_rows.id)),
                'MMM dd, yyyy (EEE)'
              )}
            </div>
            <div>
              <div style={{ width: '100%', height: '10px' }}></div>
              <div id='attendance'>
                <GridComponent
                  id={this.GridId}
                  dataSource={TimeEData}
                  toolbar={this.state._partialDelete ? undefined : ['Add']}
                  height={'25vh'}
                  toolbarClick={this.toolbarClick.bind(this)}
                  editSettings={{ allowAdding: true, allowDeleting: true }}
                  ref={(sc) => (this.TimeRangeref = sc)}
                >
                  <ColumnsDirective>
                    <ColumnDirective
                      template={this.TimePickerTemplate.bind(this)}
                    />
                  </ColumnsDirective>
                  <Inject services={[Toolbar, Edit]} />
                </GridComponent>
              </div>
            </div>
          </>
        );
      }
    }

    return <>{_content}</>;
  }

  private customAggregate(args: any) {
    let totalBreakTime: any = 0;
    let totalWorkedTime: any = 0;
    let totalOverTime: any = 0;
    let totalShortTime: any = 0;
    if (args.result) {
    args.result.forEach((item: any) => {
      if (item.BreakTime) {
        if (Number(item.BreakTime)) {
          totalBreakTime += item.BreakTime;
        } else if (item.BreakTime.includes(':')) {
          const [hours, minutes] = item.BreakTime.split(':').map(Number);
          totalBreakTime += hours * 60 + minutes;
        }
      }
      if (item.WorkedTime) {
        if (Number(item.WorkedTime)) {
          totalWorkedTime += item.WorkedTime;
        } else if (item.WorkedTime.includes(':')) {
          const [hours, minutes] = item.WorkedTime.split(':').map(Number);
          totalWorkedTime += hours * 60 + minutes;
        }
      }
      if (item.OverTime) {
        if (Number(item.OverTime)) {
          totalOverTime += item.OverTime;
        } else if (item.OverTime.includes(':')) {
          const [hours, minutes] = item.OverTime.split(':').map(Number);
          totalOverTime += hours * 60 + minutes;
        }
      }
      if (item.ShortTime) {
        if (Number(item.ShortTime)) {
          totalShortTime += item.ShortTime;
        } else if (item.ShortTime.includes(':')) {
          const [hours, minutes] = item.ShortTime.split(':').map(Number);
          totalShortTime += hours * 60 + minutes;
        }
      }
    });
    } else {
      args.forEach((item: any) => {
        if (item.BreakTime) {
          if (Number(item.BreakTime)) {
            totalBreakTime += item.BreakTime;
          } else if (item.BreakTime.includes(':')) {
            const [hours, minutes] = item.BreakTime.split(':').map(Number);
            totalBreakTime += hours * 60 + minutes;
          }
        }
        if (item.WorkedTime) {
          if (Number(item.WorkedTime)) {
            totalWorkedTime += item.WorkedTime;
          } else if (item.WorkedTime.includes(':')) {
            const [hours, minutes] = item.WorkedTime.split(':').map(Number);
            totalWorkedTime += hours * 60 + minutes;
          }
        }
        if (item.OverTime) {
          if (Number(item.OverTime)) {
            totalOverTime += item.OverTime;
          } else if (item.OverTime.includes(':')) {
            const [hours, minutes] = item.OverTime.split(':').map(Number);
            totalOverTime += hours * 60 + minutes;
          }
        }
        if (item.ShortTime) {
          if (Number(item.ShortTime)) {
            totalShortTime += item.ShortTime;
          } else if (item.ShortTime.includes(':')) {
            const [hours, minutes] = item.ShortTime.split(':').map(Number);
            totalShortTime += hours * 60 + minutes;
          }
        }
      });
    }
    return { totalBreakTime, totalWorkedTime, totalOverTime, totalShortTime };
  }

  private TotalBreakTemplate(_props: any) {
    const totalBreakTime = _props.Custom?.totalBreakTime || 0;
    if (this.state._dataInMinutes) {
      return totalBreakTime;
    } else {
      const hours = Math.floor(totalBreakTime / 60);
      const minutes = totalBreakTime % 60;
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
        2,
        '0'
      )}`;
    }
  }

  private TotalWorkedTemplate(_props: any) {
    const totalWorkedTime = _props.Custom?.totalWorkedTime || 0;
    if (this.state._dataInMinutes) {
      return totalWorkedTime;
    } else {
      const hours = Math.floor(totalWorkedTime / 60);
      const minutes = totalWorkedTime % 60;
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
        2,
        '0'
      )}`;
    }
  }

  private TotalOverTimeTemplate(_props: any) {
    const totalOverTime = _props.Custom?.totalOverTime || 0;
    if (this.state._dataInMinutes) {
      return totalOverTime;
    } else {
      const hours = Math.floor(totalOverTime / 60);
      const minutes = totalOverTime % 60;
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
        2,
        '0'
      )}`;
    }
  }

  private TotalShortTemplate(_props: any) {
    const totalShortTime = _props.Custom?.totalShortTime || 0;
    if (this.state._dataInMinutes) {
      return totalShortTime;
    } else {
      const hours = Math.floor(totalShortTime / 60);
      const minutes = totalShortTime % 60;
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
        2,
        '0'
      )}`;
    }
  }

  private captionTemplate() {
    return <span>Total {this.state._dataInMinutes ? 'Minutes' : 'Hours'}</span>;
  }

  private onDataBound() {
    if (this.TimeGrid) {
      let GridDataSource: any = this.TimeGrid.dataSource;
      let table = this.TimeGrid.element.querySelector(
        '.e-gridcontent .e-content tbody'
      );
      let rows = this.TimeGrid.element.querySelectorAll(
        '.e-gridcontent .e-content tbody tr'
      );
      rows.forEach((row: any, index: any) => {
        let rowId = GridDataSource[index]?.id;
        if (GridDataSource[index]?.isHoliday == true) {
          const cells = row.querySelectorAll('.e-rowcell');
          cells.forEach((cell: any) => {
            cell.style.color = 'red';
            if (cell.cellIndex === 2) {
              cell.textContent = `${GridDataSource[index]?.ToolTip}`;
              cell.setAttribute('colSpan', 2);
              cell.style.textAlign = 'center';
              cell.setAttribute('title', `${GridDataSource[index]?.ToolTip}`);
            } else if (cell.cellIndex === 3) {
              cell.style.display = 'none';
            }
          });
            }
        if (GridDataSource[index]?.isLeave == true) {
          const cells = row.querySelectorAll('.e-rowcell');
          cells.forEach((cell: any) => {
            cell.style.color = 'red';
            if (cell.cellIndex === 2) {
              cell.textContent = `${GridDataSource[index]?.ToolTip}`;
              cell.setAttribute('colSpan', 2);
              cell.style.textAlign = 'center';
              cell.setAttribute('title', `${GridDataSource[index]?.ToolTip}`);
            } else if (cell.cellIndex === 3) {
              cell.style.display = 'none';
            }
          });
        }
        // <span className="e-badge e-badge-info e-badge-overlap e-badge-dot"></span>
        if (GridDataSource[index]?.isMultiple == true) {
          const cells = row.querySelectorAll('.e-rowcell');
          if (cells && cells.length > 0) {
            cells[1].textContent = cells[1].textContent + ' *';
            cells[1].setAttribute(
              'title',
              'Has Multiple Entries (' +
                `${GridDataSource[index]?.totaldata}` +
                ')'
            );
          }
        }
        const cells = row.querySelectorAll('.e-rowcell');
        cells.forEach((cell: any) => {
          if (cell.cellIndex === 2 || cell.cellIndex === 3) {
            cell.style.cursor = 'pointer';
          }
        });
        if (GridDataSource[index]?.DayId == 0) {
          row.style.backgroundColor = 'Linen';
        }
        if (GridDataSource[index]?.DayId == 6) {
          row.style.backgroundColor = 'whitesmoke';
        }
        row.setAttribute('id', rowId);
      });

      table?.addEventListener('click', (event: any) => {
        const target = event.target;
        if (event.target.style.color !== 'red') {
          if (target && target.closest('td.e-rowcell')) {
            const rowIndex = target.closest('td.e-rowcell').cellIndex;
            if (rowIndex === 2 || rowIndex === 3) {
              this.doubleclick(event);
            }
          }
        }
      });
    }
  }

  public updateDimensions = () => {
    const divElement: HTMLElement = document.getElementById(
      'attendance'
    ) as HTMLElement;
    if (!divElement) return;
    const gridHeader: HTMLElement = divElement?.querySelector(
      'e-gridheader.e-stackedheader.e-lib.e-droppable'
    ) as HTMLElement;
    if (gridHeader) {
      gridHeader.style.paddingRight = '0px';
    }

    const gridFooter: HTMLElement = divElement?.querySelector(
      'e-grid.e-footerpadding'
    ) as HTMLElement;
    if (gridFooter) {
      gridFooter.style.paddingRight = '0px';
    }
  };

  private onMenuItemSelect(e: any) {
    if (this.clickedRow !== undefined) {
      if (e.item.id === '101') {
        this.doubleclick(this.clickedRow);
      } else if (e.item.id === '102') {
        this.doubleclick(this.clickedRow);
      }
      if (e.item.id === '103') {
        let SelectedRowData: any = this.newSummaryData.filter(
          (item: any) => item.Id === this.clickedRowId
        );
        if (SelectedRowData && SelectedRowData.length > 1) {
          this.setState({
            _partialDelete: true,
          });
          this.doubleclick(this.clickedRow);
        } else {
        let payload: any = {
          employeeId: this.props.selectorData.id,
          loginDatetime: Common.formatDate(
            new Date(Number(this.clickedRowId)),
            'yyyy-MM-dd'
          ),
        };
        Common.ApiCallAsync(
          'POST',
          `${REACT_APP_ENDPOINT_ATTENDANCESERVICE}/attendance/delete`,
          payload,
          Common.getToken() || '',
          null,
          this.token.tenant
        )
          .then((response: any) => {
            return response.data;
          })
          .then((data: any) => {
            this.newSummaryData = [];
            this.clickedRow = undefined;
            this.clickedRowId = undefined;
            let getpayload: any = {
              startDate: this.state._startDate,
              endDate: this.state._endDate,
            };
            this.getAttendance(getpayload);
          })
          .catch((error: any) => {
            console.error(error);
          })
          .finally(() => {});
      }
    }
  }
  }

  handleDeleteSelectedTime = (props: any) => {
    let _clickedRow: any = this.state._clickedrow;
    if (props) {
      let payload: any = {
        transactionIds: [props.Inid, props.Outid],
        employeeId: this.props.selectorData.id,
        shiftId: this.props.selectorData.employeeShift[0].shiftId,
        loginDatetime: Common.formatDate(
          new Date(Number(_clickedRow.id)),
          'yyyy-MM-dd'
        ),
        baseWorkHours:
          this.props.selectorData.employeeShift[0].shift.shiftTran[0]
            .workingHour,
      };
      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_ATTENDANCESERVICE}/attendance/singledelete`,
        payload,
        Common.getToken() || '',
        null,
        this.token.tenant
      )
        .then((response: any) => {
          return response.data;
        })
        .then((data: any) => {
          this.newSummaryData = this.newSummaryData.filter(
            (item: any) => !(item.Id === props.Id && item.RId === props.RId)
          );
          this.modifiedArray = this.newSummaryData;
          let getpayload: any = {
            startDate: this.state._startDate,
            endDate: this.state._endDate,
          };
          this.getAttendance(getpayload);
          if (this.TimeRangeref) {
            let newDataSource: any = this.TimeRangeref.dataSource;
            newDataSource = newDataSource.filter(
              (item: any) => !(item.Id === props.Id && item.RId === props.RId)
            );
            this.TimeRangeref.dataSource = newDataSource;
            if (newDataSource.length === 0) {
              this.setState({
                showDialog: false,
                _isEditing: false,
                _partialDelete: false,
              });
            }
          }
        })
        .catch((error: any) => {
          console.error(error);
        })
        .finally(() => {});
    }
  };

  private onContextMenuBeforeOpen(e: any) {
    if (e) {
      if (
        Number(e.event.target.dataset['colindex']) == 2 ||
        Number(e.event.target.dataset['colindex']) == 3
      ) {
        const data = e.event.target.closest('tr');
        if (data.querySelector('td:nth-child(3)').textContent == '') {
          this.menuObj.enableItems(['New'], true);
          this.menuObj.enableItems(['Edit'], false);
          this.menuObj.enableItems(['Delete'], false);
        }
        if (data.querySelector('td:nth-child(3)').textContent !== '') {
          this.menuObj.enableItems(['New'], false);
          this.menuObj.enableItems(['Edit'], true);
          this.menuObj.enableItems(['Delete'], true);
        }
        this.clickedRow = e.event;
        this.clickedRowId = data.id;
      } else {
        e.cancel = true;
      }
      }
      }

  private toolbarMenuClick(args: any) {
    this.cMenu?.open(args.clientY, args.clientX);
  }

  private handleCntxSelect(args: any) {
    switch (args.item.id) {
      case 'dataHours':
        this.setState({ _dataInMinutes: false });
        break;
      case 'dataMinutes':
        this.setState({ _dataInMinutes: true });
        break;
      case '_fullDate':
        this.setState({ _fulldate: true });
        break;
      case '_day':
        this.setState({ _fulldate: false });
        break;
    }
  }
  public uploadButtons: any = [
    {
      buttonModel: {
        content: Common.toTitleCase('Cancel'),
        cssClass: 'flat-button',
      },
      click: () => {
        this.setState({ showTimeUploadDialog: false });
        let payload: any = {
          startDate: this.state._startDate,
          endDate: this.state._endDate,
        };
        this.getAttendance(payload);
      },
    },
  ];

  private closeUploadDialog() {
    this.setState({ showTimeUploadDialog: false });
    let payload: any = {
      startDate: this.state._startDate,
      endDate: this.state._endDate,
    };
    this.getAttendance(payload);
  }

  private generateUploadContent() {
    var _content: any;
    _content = (
      <>
        <div className='row align-items-center'>
          <FileUpload uploadtypeid={this._structure.UploadType.Attendance} />
        </div>
      </>
    );
    return _content;
  }

  private toobarTemplate() {
    return (
      <div className='e-toolbar-items'>
        <div className='PeriodSelector'>
          <DatePickerComponent
            placeholder='Choose a date'
            value={this.state.selectedDate}
            start='Year'
            depth='Year'
            format={'MMMM y'}
            onChange={this.handleChange.bind(this)}
            showClearButton={false}
          />
        </div>
        <div className='toolbar-item'>
          <span
            id='_ImportTime'
            className='fa-regular fa-upload'
            style={{
              fontSize: '15px',
              cursor: 'pointer',
              fontWeight: 400,
            }}
            onClick={() => this.setState({ showTimeUploadDialog: true })}
          ></span>
          <span
            id='_Excelexport'
            className='fa-regular fa-download'
            style={{
              fontSize: '15px',
              cursor: 'pointer',
              textTransform: 'capitalize',
              fontWeight: 400,
            }}
            onClick={(args: any) => {
              if (args.target.id === '_Excelexport') {
                this.TimeGrid?.excelExport();
              }
            }}
          ></span>
          <i
            id='target'
            onClick={this.toolbarMenuClick.bind(this)}
            className='e-icons e-more-vertical-1'
            style={{ cursor: 'pointer' }}
          ></i>
          {this.cntxitems.length > 0 ? (
            <ContextMenuComponent
              id={Math.random().toString() + 'cntxItem'}
              ref={(sc) => (this.cMenu = sc)}
              items={this.cntxitems}
              target='#target'
              select={this.handleCntxSelect.bind(this)}
            />
          ) : null}
        </div>
      </div>
    );
  }
  private beforeRender = (args: any) => {
    const fieldName: any = args.target.getAttribute('aria-label') || '';
    const tooltipField: any = ['In', 'Out'];
    const handleFieldMatch = tooltipField.some((item: any) =>
      fieldName.includes(item)
    );
    if (this.emp_Data.length > 0) {
      let remarkData = this.emp_Data.filter((item: any) => {
        return item.id === args.target.parentElement.id;
      });
      if (handleFieldMatch) {
        if (args.target.innerText) {
          if (remarkData[0].remark) {
            (this.remarkTooltip as TooltipComponent).content =
              remarkData[0].remark;
          } else {
            args.cancel = true;
          }
        } else {
          args.cancel = true;
        }
      } else {
        args.cancel = true;
      }
    }
  };

  render() {
    this.updateDimensions();

    let DateColumns: any = [
      {
        field: 'Date',
        headerText: 'Date',
        width: 50,
        textAlign: 'Left',
        minWidth: 10,
        customAttributes: { class: 'customHeaderCss' },
      },
      {
        field: 'Day',
        headerText: 'Week Day',
        width: 60,
        textAlign: 'Left',
        minWidth: 10,
        customAttributes: { class: 'customHeaderCss' },
      },
    ];
    let TimeColumns: any = [
      {
        field: 'BreakTime',
        headerText: 'Break',
        width: 60,
        textAlign: 'Right',
        minWidth: 10,
        customAttributes: { class: 'customHeaderCss' },
      },
      {
        field: 'WorkedTime',
        headerText: 'Regular',
        width: 60,
        textAlign: 'Right',
        minWidth: 10,
        customAttributes: { class: 'customHeaderCss' },
      },
      {
        field: 'OverTime',
        headerText: 'OverTime',
        width: 60,
        textAlign: 'Right',
        minWidth: 10,
        customAttributes: { class: 'customHeaderCss' },
      },
      {
        field: 'ShortTime',
        headerText: 'Short',
        width: 60,
        textAlign: 'Right',
        minWidth: 10,
        customAttributes: { class: 'customHeaderCss' },
      },
    ];

    if (this.state._datafilled !== undefined) {
      if (
        this.state._startDate !== undefined &&
        this.state._endDate !== undefined
      ) {
        const date = new Date(this.state._startDate as any);
        this.emp_Data = [];
        let rcount = 0;
        while (date <= new Date(this.state._endDate as any)) {
          var data = (this.state.Attendance as any).filter((item: any) => {
            if (item.employeeId === this.props.selectorData.id) {
              return (
                new Date(item.logindatetime).getDate() ===
                new Date(date).getDate() &&
                new Date(item.logindatetime).getMonth() ===
                new Date(date).getMonth()
              );
            }
          });

          let isHoliday: boolean = false;
          let isLeave: boolean = false;
          let hName: string = '';

          if (this.state._holidays.length > 0) {
            if (
              this.state._holidays.filter(
                (x: any) =>
                  new Date(x.date).getDate() === new Date(date).getDate() &&
                  new Date(x.date).getMonth() === new Date(date).getMonth()
              ).length > 0
            ) {
              isHoliday = true;
              hName = this.state._holidays
                .filter(
                  (x: any) =>
                    new Date(x.date).getDate() === new Date(date).getDate() &&
                    new Date(x.date).getMonth() === new Date(date).getMonth()
                )
                .map((i: any) => i.name)
                .join(', ');
            }
          }

          if (this.state._leaves.length > 0) {
            var issuedLeaves: any = this.state._leaves;
            issuedLeaves[0].leavesIssued.forEach((item: any) => {
              let data: any = item.appliedDays.filter(
                (x: any) =>
                  new Date(x.appliedDate).getDate() ===
                    new Date(date).getDate() &&
                  new Date(x.appliedDate).getMonth() ===
                    new Date(date).getMonth()
              );
              if (data.length > 0) {
                isLeave = true;
                hName = item.name;
              }
            });
          }

          if (
            this.emp_Data.filter(
              (item: any) => item.Id == new Date(date).getTime().toString()
            ).length <= 0
          ) {
            var uniqueRemarks = Array.from(
              new Set(data.map((item: any) => item.remark))
            );
            uniqueRemarks = uniqueRemarks.filter((item: any) => item !== '');

            this.emp_Data.push({
              id: new Date(date).getTime().toString(),
              RowId: Common.formatDate(new Date(date), 'yyyyMMdd'),
              EmplyoeeId: data[0]?.employeeId,
              Employee: data[0]?.employee,
              ShiftId: data[0]?.shiftId,
              Date: this.state._fulldate
                ? Common.formatDate(date, 'dd-MM-yyyy')
                : Common.formatDate(date, 'dd'),
              Day: Common.formatDate(date, 'EEEE'),
              In: data[0]?.logindatetime
                ? Common.formatDate(new Date(data[0]?.logindatetime), 'HH:mm')
                : '',
              Out: data[data.length - 1]?.logindatetime
                ? Common.formatDate(
                  new Date(data[data.length - 1]?.logindatetime),
                  'HH:mm'
                )
                : '',
              BreakTime: this.state._dataInMinutes
                ? typeof data[0]?.totalBreak === 'number'
                  ? data[0]?.totalBreak
                  : ''
                : typeof data[0]?.totalBreak === 'number'
                  ? this.formatDuration(data[0]?.totalBreak)
                  : '',
              WorkedTime: this.state._dataInMinutes
                ? typeof data[0]?.totalWorked === 'number'
                  ? data[0]?.totalWorked
                  : ''
                : typeof data[0]?.totalWorked === 'number'
                  ? this.formatDuration(data[0]?.totalWorked)
                  : '',
              OverTime: this.state._dataInMinutes
                ? typeof data[0]?.totalOvertime === 'number'
                  ? data[0]?.totalOvertime
                  : ''
                : typeof data[0]?.totalOvertime === 'number'
                  ? this.formatDuration(data[0]?.totalOvertime)
                  : '',
              ShortTime: this.state._dataInMinutes
                ? typeof data[0]?.totalShort === 'number'
                  ? data[0]?.totalShort
                  : ''
                : typeof data[0]?.totalShort === 'number'
                  ? this.formatDuration(data[0]?.totalShort)
                  : '',
              isMultiple: data.length > 2 ? true : false,
              isHoliday: isHoliday,
              isLeave: isLeave,
              ToolTip: hName,
              DayId: date.getDay(),
              rowidx: rcount,
              totaldata: data.length / 2,
              remark: uniqueRemarks.join(', '),
            });
          }
          if (
            this.newSummaryData.filter(
              (f: any) => f.Id == new Date(date).getTime().toString()
            ).length <= 0
          ) {
            let In: any = undefined;
            let Out: any = undefined;
            let Inid: any = undefined;
            let Outid: any = undefined;
            let RowRemark: any = '';
            var _InTime: { [key: number]: any } = {};
            var _OutTime: { [key: number]: any } = {};
            var _InTimeId: { [key: number]: any } = {};
            var _OutTimeId: { [key: number]: any } = {};
            var _RowRemark: { [key: number]: any } = {};
            var _newAdded: any = false;
            var index: any = 0;
            var TotalLen: any = 0;
            if (data.length > 0) {
              for (let d = 0; d < data.length; d++) {
                const item = data[d];
                if (item.typeid == '1') {
                  if (_InTime[index]) {
                    _OutTime[index] = undefined;
                    index++;
                  }
                  _InTime[index] = item.logindatetime;
                  _InTimeId[index] = item.id;
                  _RowRemark[index] = item.remark;
                } else if (item.typeid == '2') {
                  _OutTime[index] = item.logindatetime;
                  _OutTimeId[index] = item.id;
                  _RowRemark[index] = item.remark;
                  if (_InTime[index] == undefined) {
                    _InTime[index] = undefined;
                    index++;
                  }
                }
                if (_InTime[index] && _OutTime[index]) {
                  index++;
                }
              }

              if (Object.keys(_InTime).length > Object.keys(_OutTime).length) {
                TotalLen = Object.keys(_InTime).length;
              } else {
                TotalLen = Object.keys(_OutTime).length;
              }
              for (let index = 0; index < TotalLen; index++) {
                In = _InTime[index];
                Out = _OutTime[index];
                Inid = _InTimeId[index];
                Outid = _OutTimeId[index];
                RowRemark = _RowRemark[index];
                this.newSummaryData.push({
                  Id: new Date(date).getTime().toString(),
                  In,
                  Out,
                  RId: index,
                  Inid,
                  Outid,
                  _newAdded,
                  RowRemark,
                });
              }
              this.modifiedArray = this.newSummaryData;
            }
          }
          date.setDate(date.getDate() + 1);
          rcount += 1;
        }
      }
    }

    return (
      <>
        <div
          id='attendance'
          style={{ display: 'grid' }}
          className='table-container'
        >
          <TooltipComponent
            id='Tooltip'
            target='.e-rowcell'
            cssClass='custom-tooltip'
            beforeRender={this.beforeRender}
            ref={(sc) => (this.remarkTooltip = sc)}
          >
          <GridComponent
            id='GrdTimesheet'
            dataSource={this.emp_Data.length > 0 ? this.emp_Data : []}
              height={'calc(100vh -600px)'}
            gridLines='Both'
            ref={(sc) => (this.TimeGrid = sc)}
            dataBound={this.onDataBound.bind(this)}
            toolbarTemplate={this.toobarTemplate.bind(this)}
            allowExcelExport={true}
          >
            <ColumnsDirective>
              <ColumnDirective
                columns={DateColumns}
                width='100'
                customAttributes={{ class: 'customHeaderCss' }}
              />
              <ColumnDirective
                headerText='In'
                field='In'
                width='50'
                textAlign='Right'
                customAttributes={{ class: 'customHeaderCss' }}
              />
              <ColumnDirective
                headerText='Out'
                field='Out'
                width='50'
                textAlign='Right'
                customAttributes={{ class: 'customHeaderCss' }}
              />
              <ColumnDirective
                headerText='Total Work'
                textAlign='Center'
                columns={TimeColumns}
                width='100'
                customAttributes={{ class: 'customHeaderCss' }}
              />
            </ColumnsDirective>
            <AggregatesDirective>
              <AggregateDirective>
                <AggregateColumnsDirective>
                  <AggregateColumnDirective
                    field='Out'
                    type='Custom'
                    footerTemplate={this.captionTemplate.bind(this)}
                  ></AggregateColumnDirective>
                  <AggregateColumnDirective
                    field='BreakTime'
                    type='Custom'
                    customAggregate={this.customAggregate.bind(this)}
                    footerTemplate={this.TotalBreakTemplate.bind(this)}
                  ></AggregateColumnDirective>
                  <AggregateColumnDirective
                    field='WorkedTime'
                    type='Custom'
                    customAggregate={this.customAggregate.bind(this)}
                    footerTemplate={this.TotalWorkedTemplate.bind(this)}
                  ></AggregateColumnDirective>
                  <AggregateColumnDirective
                    field='OverTime'
                    type='Custom'
                    customAggregate={this.customAggregate.bind(this)}
                    footerTemplate={this.TotalOverTimeTemplate.bind(this)}
                  ></AggregateColumnDirective>
                  <AggregateColumnDirective
                    field='ShortTime'
                    type='Custom'
                    customAggregate={this.customAggregate.bind(this)}
                    footerTemplate={this.TotalShortTemplate.bind(this)}
                  ></AggregateColumnDirective>
                </AggregateColumnsDirective>
              </AggregateDirective>
            </AggregatesDirective>
            <Inject services={[Aggregate, ExcelExport]} />
          </GridComponent>
          </TooltipComponent>
        </div>
        <div>
          {this.state.showDialog === true ? (
            <DialogComponent
              id='defaultDialog1'
              showCloseIcon={true}
              visible={this.state.showDialog}
              width={this.state._dlgWidth}
              close={this.dialogclose.bind(this)}
              content={this.dialogContent.bind(this)}
              isModal={true}
              buttons={
                this.state._partialDelete ? [{}] : this.DialogButtonModel
              }
            ></DialogComponent>
          ) : null}
          {this.state.showTimeUploadDialog === true ? (
            <DialogComponent
              id='uploadDialog'
              showCloseIcon={true}
              visible={this.state.showTimeUploadDialog}
              width={'50vw'}
              close={this.closeUploadDialog.bind(this)}
              content={this.generateUploadContent.bind(this)}
              isModal={true}
              buttons={this.uploadButtons}
              statelessTemplates={[]}
            ></DialogComponent>
          ) : null}
        </div>
        <ContextMenuComponent
          ref={(menu) => (this.menuObj = menu)}
          target='#GrdTimesheet'
          items={this.menuItems}
          beforeOpen={this.onContextMenuBeforeOpen.bind(this)}
          select={this.onMenuItemSelect.bind(this)}
        />
      </>
    );
  }
}

export default Attendance;
