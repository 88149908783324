import * as React from 'react';
import Common from '../Util/Common';
import PeriodSelector from './PeriodSelector/PeriodSelector';

import {
  TreeGridComponent,
  ColumnsDirective,
  ColumnDirective,
  ExcelExport,
  PdfExport,
  Inject,
} from '@syncfusion/ej2-react-treegrid';
import './PaySlip.css';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import Bank from './Bank/Bank';
import { payslip_contextmenu } from '../dummydata/xml_data_employee';
import {
  ContextMenuComponent,
  MenuItemModel,
} from '@syncfusion/ej2-react-navigations';

interface Props {
  selectorData: any;
}

const parser = require('xml2js').Parser({
  explicitRoot: false,
  explicitArray: false,
});

const { REACT_APP_ENDPOINT_CORESERVICE, REACT_APP_ENDPOINT_PAYROLLSERVICE } =
  process.env;

class NewPayslip extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;
  private cntxitems: MenuItemModel[] = [];
  private cMenu: ContextMenuComponent | null = null;
  private earninggrid: TreeGridComponent | any = null;
  private deductiongrid: TreeGridComponent | any = null;
  private netpaygrid: TreeGridComponent | any = null;
  private bankdetailgrid: TreeGridComponent | any = null;
  private selectedperiodid: any = null;

  constructor(props: any) {
    super(props);
    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');

    var _result: any;
    parser.parseString(payslip_contextmenu, function (err: any, result: any) {
      _result = JSON.parse(JSON.stringify(result));
    });

    this.cntxitems = Common.GetContextMenuFromXML(
      _result.ContextMenu.Item
    ) as MenuItemModel[];
  }

  state = {
    periodselectorkey: '',
    groupeddata: [],
    earning: [],
    deduction: [],
    netpay: [],
    paydetails: [],
  };

  componentDidMount(): void {
    document
      .querySelector('#moreoptions')
      ?.closest('div')
      ?.addEventListener('click', (e: any) => this.cMenu?.open(e.y, e.x));
  }

  private handleChange(e: any) {
    this.selectedperiodid = e.id;
    if (e.id !== Common.blankguid) {
      this.FetchPaySlip(e.id);
    }
  }

  private GroupPayrollData(data: any[]): any[] {
    const groupedData: any[] = data.reduce((acc: any[], item: any) => {
      const groupKey = { headTypeName: item.employeePayHeadTran.headTypeName };
      const existingGroup = acc.find(
        (group: any) => group.headTypeName === groupKey.headTypeName
      );
      const amount = item.amount;
      if (existingGroup) {
        existingGroup.totalAmount += amount;
        existingGroup.data.push({
          payHeadName: item.employeePayHeadTran.payHead.name,
          payHeadCode: item.employeePayHeadTran.payHead.code,
          headTypeName: `${item.employeePayHeadTran.payHead.code} - ${item.employeePayHeadTran.payHead.name}`,
          calcTypeId: item.employeePayHeadTran.calcTypeId,
          calcTypeName: item.employeePayHeadTran.calcTypeName,
          typeOfId: item.employeePayHeadTran.typeOfId,
          typeOfName: item.employeePayHeadTran.typeOfName,
          amount: Number(amount),
          totalAmount: Number(item.amountPaidCurrency),
        });
      } else {
        acc.push({
          headTypeName: groupKey.headTypeName,
          totalAmount: Number(amount),
          data: [
            {
              payHeadName: item.employeePayHeadTran.payHead.name,
              payHeadCode: item.employeePayHeadTran.payHead.code,
              headTypeName: `${item.employeePayHeadTran.payHead.code} - ${item.employeePayHeadTran.payHead.name}`,
              calcTypeId: item.employeePayHeadTran.calcTypeId,
              calcTypeName: item.employeePayHeadTran.calcTypeName,
              typeOfId: item.employeePayHeadTran.typeOfId,
              typeOfName: item.employeePayHeadTran.typeOfName,
              amount: Number(amount),
              totalAmount: Number(item.amountPaidCurrency),
            },
          ],
        });
      }
      return acc;
    }, []);

    return groupedData;
  }

  private DetailedPayData(data: any[]): any[] {
    let earningTotal = 0;
    let deductionTotal = 0;
    let netPayTotal = 0;
    const earningGroup: any[] = [];
    const deductionGroup: any[] = [];
    const netPayGroup: any[] = [];
    const employeeBanks: any[] = [];

    const groups: {
      Name: string;
      Data: any[];
      total: number;
      gtypeid: number;
    }[] = [];

    if (employeeBanks.length <= 0) {
      if (data[0]['employeeBank'].length > 0) {
        data[0]['employeeBank'][0].distribution.forEach((b: any) => {
          const ebank = {
            Name: b.bank.name,
            groupName: b.bank.groupName,
            accountType: b.accountType.name,
            accountNo: b.accountNo,
            percentage: b.percentage,
            amount: b.amount,
            paymentTypeName: data[0]['employeeBank'][0].paymentTypeName,
          };
          employeeBanks.push(ebank);
        });
      }
    }

    data.forEach((item: any) => {
      if (!item.employeePayHeadTran) return;
      const { payHead } = item.employeePayHeadTran;
      if (
        payHead.headTypeId !==
        this._structure.HeadType.EmployersStatutoryContributions.id
      ) {
        // const Name = `${payHead.code} - ${payHead.name}`;
        const Name = `${payHead.name}`;
        const newItem = {
          payHeadName: payHead.name,
          payHeadCode: payHead.code,
          Name,
          amount: Number(item.amount),
          total: Number(item.amountPaidCurrency),
        };

        if (
          item.employeePayHeadTran.headTypeId ===
          this._structure.HeadType.EarningforEmployee.id
        ) {
          if (item.amountPaidCurrency > 0) {
            earningGroup.push(newItem);
          }
          earningTotal += Number(item.amountPaidCurrency);
        } else if (
          item.employeePayHeadTran.headTypeId !==
          this._structure.HeadType.Informational.id
        ) {
          if (item.amountPaidCurrency > 0) {
            deductionGroup.push(newItem);
          }
          deductionTotal += Number(item.amountPaidCurrency);
        } else if (
          item.employeePayHeadTran.typeOfId ===
            this._structure.TypeOf.Informational.id &&
          item.employeePayHeadTran.calcTypeId ===
            this._structure.CalcType.NetPay.id
        ) {
          netPayGroup.push(newItem);
          netPayTotal += Number(item.amountPaidCurrency);
        }
      }
    });

    if (earningGroup.length > 0) {
      groups.push({
        Name: 'Earnings',
        Data: earningGroup,
        total: earningTotal,
        gtypeid: 0,
      });
    }
    if (deductionGroup.length > 0) {
      groups.push({
        Name: 'Deductions',
        Data: deductionGroup,
        total: deductionTotal,
        gtypeid: 1,
      });
    }
    if (netPayGroup.length > 0) {
      groups.push({
        Name: 'Net Pay',
        Data: netPayGroup,
        total: netPayTotal,
        gtypeid: 2,
      });
    }
    if (employeeBanks.length > 0) {
      groups.push({
        Name: 'Payment Type',
        Data: employeeBanks,
        total: 0,
        gtypeid: 3,
      });
    }

    return groups;
  }

  private onBindingInfo(args: any) {
    if (args.data && args.data.Name.toLowerCase() === 'earnings') {
      args.cell.style.fontWeight = '500';
      args.cell.style.fontSize = '16px';
      args.cell.style.background = 'rgba(72, 70, 69, 0.086)';
    }
    if (args.data && args.data.Name.toLowerCase() === 'deductions') {
      args.cell.style.fontWeight = '500';
      args.cell.style.fontSize = '16px';
      args.cell.style.background = 'rgba(72, 70, 69, 0.086)';
    }
    if (args.data && args.data.Name.toLowerCase() === 'net pay') {
      args.cell.style.fontWeight = '500';
      args.cell.style.fontSize = '16px';
      args.cell.style.background = 'rgba(72, 70, 69, 0.086)';
    }
    if (args.data && args.data.Name.toLowerCase() === 'payment type') {
      args.cell.style.fontWeight = '500';
      args.cell.style.fontSize = '16px';
      args.cell.style.background = 'rgba(72, 70, 69, 0.086)';
    }
  }

  private FetchPaySlip(pid: any) {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payrolldata/listpayrolldata${
        !this.user.isManager ? '/ess' : ''
      }`,
      { periodId: pid, employeeId: this.props.selectorData.id },
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        //const _data = this.GroupPayrollData(response)
        if (response.length > 0) {
          const _detailed = this.DetailedPayData(response);

          const earning = _detailed.filter((x: any) => x.gtypeid === 0);
          const deducts = _detailed.filter((x: any) => x.gtypeid === 1);
          const netpays = _detailed.filter((x: any) => x.gtypeid === 2);
          const payment = _detailed.filter((x: any) => x.gtypeid === 3);
          let paydetails: any;
          if (payment.length > 0) {
            paydetails = payment[0].Data;
          }
          this.setState({
            earning: earning,
            deduction: deducts,
            netpay: netpays,
            paydetails: paydetails,
          });
        } else {
          this.setState({
            earning: [],
            deduction: [],
            netpay: [],
            paydetails: [],
          });
        }
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  handleMenuClick = (e: any) => {
    if (parseInt(e.element.id) === 101) {
      let periodname = (document.getElementById('payslip-period') as any).value;
      let title = (document.getElementById('maintitle') as any).textContent;
      const excelExportProperties = {
        multipleExport: {
          type: 'AppendToSheet',
          blankRows: 2,
        },
        header: {
          headerRows: 4,
          rows: [
            {
              cells: [
                {
                  colSpan: this.earninggrid.columns.length,
                  value: 'Payslip',
                  style: {
                    fontColor: '#C67878',
                    fontSize: 20,
                    hAlign: 'Center',
                    bold: true,
                    underline: true,
                  },
                },
              ],
            },
            {
              cells: [
                {
                  colSpan: this.earninggrid.columns.length,
                  value: periodname,
                  style: {
                    fontColor: '#C67878',
                    fontSize: 14,
                    bold: true,
                    underline: true,
                  },
                },
              ],
            },
            {
              cells: [
                {
                  colSpan: this.earninggrid.columns.length,
                  value: title,
                  style: { fontColor: '#C67878', fontSize: 14, bold: true },
                },
              ],
            },
          ],
        },
      };
      //this.earninggrid.excelExport();
      if (this.bankdetailgrid != null) {
        this.earninggrid
          .excelExport(excelExportProperties, true)
          .then((wb1: any) => {
            this.deductiongrid
              .excelExport(excelExportProperties, true, wb1)
              .then((wb2: any) => {
                this.netpaygrid
                  .excelExport(excelExportProperties, true, wb2)
                  .then((wb3: any) => {
                    this.bankdetailgrid.excelExport(
                      excelExportProperties,
                      false,
                      wb3
                    );
                  });
              });
          });
      } else {
        this.earninggrid
          .excelExport(excelExportProperties, true)
          .then((wb1: any) => {
            this.deductiongrid
              .excelExport(excelExportProperties, true, wb1)
              .then((wb2: any) => {
                this.netpaygrid.excelExport(excelExportProperties, false, wb2);
              });
          });
      }
    } else if (parseInt(e.element.id) === 102) {
      let periodname = (document.getElementById('payslip-period') as any).value;
      let title = (document.getElementById('maintitle') as any).textContent;
      const exportProperties = {
        multipleExport: {
          type: 'AppendToPage',
          blankRows: 1,
        },
        header: {
          contents: [
            {
              position: { x: 0, y: 50 },
              style: { textBrushColor: '#000000', fontSize: 20 },
              type: 'Text',
              value: 'Payslip',
            },
            {
              position: { x: 0, y: 70 },
              style: { textBrushColor: '#000000', fontSize: 15 },
              type: 'Text',
              value: periodname,
            },
            {
              position: { x: 0, y: 90 },
              style: { textBrushColor: '#000000', fontSize: 15 },
              type: 'Text',
              value: title,
            },
          ],
          fromTop: 0,
          height: 200,
        },
      };

      if (this.bankdetailgrid != null) {
        this.earninggrid.pdfExport(exportProperties, true).then((wb1: any) => {
          this.deductiongrid
            .pdfExport(exportProperties, true, wb1)
            .then((wb2: any) => {
              this.netpaygrid
                .pdfExport(exportProperties, true, wb2)
                .then((wb3: any) => {
                  this.bankdetailgrid.pdfExport(exportProperties, false, wb3);
                });
            });
        });
      } else {
        this.earninggrid.pdfExport(exportProperties, true).then((wb1: any) => {
          this.deductiongrid
            .pdfExport(exportProperties, true, wb1)
            .then((wb2: any) => {
              this.netpaygrid.pdfExport(exportProperties, false, wb2);
            });
        });
      }
    } else if (parseInt(e.element.id) === 201) {
      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payrolldata/payslip${
          !this.user.isManager ? '/ess' : ''
        }`,
        {
          periodId: this.selectedperiodid,
          employeeId: this.props.selectorData.id,
        },
        Common.getToken() || '',
        null,
        this.token.tenant
      )
        .then((response: any) => {
          return response.data;
        })
        .then((response: any) => {
          if (response.length > 0) {
            let a: any = window.open('', '');

            a.document.write(
              Common.GetHTMLReportHeaderStart({
                reportTitle: 'Payslip Report',
                companyName: this.user.tenantName,
              })
            );
            a.document.write(
              '<div class="mb-20 font-bold text-center">' +
                (document.getElementById('payslip-period') as any).value +
                '</div>'
            );
            a.document.write(Common.GetHTMLReportHeaderEnd());
            a.document.write(response);
            let ftr: string =
              '</td></tr></tbody></table></div></body></document>';
            a.document.write(Common.GetHTMLReportFooter());
            a.document.close();

            //a.print();
          } else {
          }
        })
        .catch((error: any) => {
          console.error(error);
        })
        .finally(() => {});
    }
  };

  private handleExcelExportClick(args: any) {
    if (args.target.id == 'Eexport') {
      let periodname = (document.getElementById('payslip-period') as any).value;
      let title = (document.getElementById('maintitle') as any).textContent;
      const excelExportProperties = {
        multipleExport: {
          type: 'AppendToSheet',
          blankRows: 2,
        },
        header: {
          headerRows: 4,
          rows: [
            {
              cells: [
                {
                  colSpan: this.earninggrid.columns.length,
                  value: 'Payslip',
                  style: {
                    fontColor: '#C67878',
                    fontSize: 20,
                    hAlign: 'Center',
                    bold: true,
                    underline: true,
                  },
                },
              ],
            },
            {
              cells: [
                {
                  colSpan: this.earninggrid.columns.length,
                  value: periodname,
                  style: {
                    fontColor: '#C67878',
                    fontSize: 14,
                    bold: true,
                    underline: true,
                  },
                },
              ],
            },
            {
              cells: [
                {
                  colSpan: this.earninggrid.columns.length,
                  value: title,
                  style: { fontColor: '#C67878', fontSize: 14, bold: true },
                },
              ],
            },
          ],
        },
      };
      //this.earninggrid.excelExport();
      if (this.bankdetailgrid != null) {
        this.earninggrid
          .excelExport(excelExportProperties, true)
          .then((wb1: any) => {
            this.deductiongrid
              .excelExport(excelExportProperties, true, wb1)
              .then((wb2: any) => {
                this.netpaygrid
                  .excelExport(excelExportProperties, true, wb2)
                  .then((wb3: any) => {
                    this.bankdetailgrid.excelExport(
                      excelExportProperties,
                      false,
                      wb3
                    );
                  });
              });
          });
      } else {
        this.earninggrid
          .excelExport(excelExportProperties, true)
          .then((wb1: any) => {
            this.deductiongrid
              .excelExport(excelExportProperties, true, wb1)
              .then((wb2: any) => {
                this.netpaygrid.excelExport(excelExportProperties, false, wb2);
              });
          });
      }
    }
  }

  render() {
    const templateBankName = (e: any) => {
      return (
        <>
          {e.groupName} - {e.Name}{' '}
          {e.percentage > 0 ? `(${e.percentage}%)` : ''}
        </>
      );
    };

    return (
      <>
        <div className='row'>
          <div className='row'>
            <div
              className='col-12'
              style={{ display: 'flex', position: 'relative' }}
            >
              <div
                className='col-11'
                style={{
                  width: '99%',
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'center',
                }}
              >
                <PeriodSelector
                  key={this.state.periodselectorkey}
                  id='payslip-period'
                  handleInputChange={this.handleChange.bind(this)}
                  _jObject={this.props.selectorData}
                  showEmployeePeriodsOnly={false}
                  showLastPeriod={true}
                  alttext={'No Period Defined'}
                />
              </div>
              <div style={{ position: 'relative', right: '30px' }}>
                <span
                  id='Eexport'
                  onClick={this.handleExcelExportClick.bind(this)}
                  style={{
                    fontSize: '15px',
                    cursor: 'pointer',
                    padding: '10px',
                    borderRadius: '4px',
                    fontWeight: 400,
                    boxShadow: 'none',
                    border: '1px solid rgba(0, 0, 0, 0.12)',
                  }}
                  className='e-tbar-btn e-btn fa-regular fa-download'
                ></span>
              </div>
              <div
                style={{ position: 'absolute', right: '0px', top: '9px' }}
                id='moption'
              >
                <i
                  id='moreoptions'
                  className='fas fa-ellipsis-v'
                  style={{ fontSize: '20px', color: 'gray', cursor: 'pointer' }}
                ></i>
              </div>
            </div>
          </div>
          <ContextMenuComponent
            id={Math.random().toString() + 'contextmenu'}
            ref={(sc) => (this.cMenu = sc as ContextMenuComponent)}
            // items={this.state._menuitems}
            items={this.cntxitems}
            select={this.handleMenuClick}
            target='#moreoptions'
          />
          <div className='row' id='payslip-style'>
            <div className='control-pane col-12 h-100'>
              <div className='control-section row h-100'>
                <div className='employee-payslip col-md-7'>
                  <div className='row'>
                    <div className='payslip-container col-12'>
                      <div className='row'>
                        {this.state.earning && (
                          <TreeGridComponent
                            id='earninggrid'
                            dataSource={this.state.earning}
                            treeColumnIndex={0}
                            childMapping='Data'
                            height='auto'
                            selectionSettings={{
                              type: 'Multiple',
                              mode: 'Both',
                            }}
                            ref={(grd: any) => (this.earninggrid = grd)}
                            queryCellInfo={this.onBindingInfo.bind(this)}
                            allowExcelExport={true}
                            allowPdfExport={true}
                          >
                            <ColumnsDirective>
                              <ColumnDirective
                                field='Name'
                                headerText=''
                                width='40%'
                              ></ColumnDirective>
                              <ColumnDirective
                                field='total'
                                headerText=''
                                width='20%'
                                textAlign='Right'
                                // format={`c${
                                //   this.user.settings.formats.Decimal.includes(
                                //     '.'
                                //   )
                                //     ? this.user.settings.formats.Decimal.split(
                                //         '.'
                                //       ).pop().length
                                //     : 2
                                // }`}
                                format={`${this.user.currencySign} 0.${
                                  this.user.settings.formats.Decimal.includes(
                                    '.'
                                  )
                                    ? '0'.repeat(
                                        this.user.settings.formats.Decimal.split(
                                          '.'
                                        ).pop().length
                                      )
                                    : '0'.repeat(2)
                                }`}
                                type='Number'
                              ></ColumnDirective>
                            </ColumnsDirective>
                            <Inject services={[ExcelExport, PdfExport]} />
                          </TreeGridComponent>
                        )}
                      </div>
                      <div className='row'>
                        {this.state.deduction.length > 0 && (
                          <TreeGridComponent
                            id='deductiongrid'
                            dataSource={this.state.deduction}
                            treeColumnIndex={0}
                            childMapping='Data'
                            height='auto'
                            queryCellInfo={this.onBindingInfo.bind(this)}
                            ref={(grd: any) => (this.deductiongrid = grd)}
                            allowExcelExport={true}
                            allowPdfExport={true}
                          >
                            <ColumnsDirective>
                              <ColumnDirective
                                field='Name'
                                headerText=''
                                width='40%'
                              ></ColumnDirective>
                              <ColumnDirective
                                field='total'
                                headerText=''
                                width='20%'
                                textAlign='Right'
                                // format={`c${
                                //   this.user.settings.formats.Decimal.includes(
                                //     '.'
                                //   )
                                //     ? this.user.settings.formats.Decimal.split(
                                //         '.'
                                //       ).pop().length
                                //     : 2
                                // }`}
                                format={`${this.user.currencySign} 0.${
                                  this.user.settings.formats.Decimal.includes(
                                    '.'
                                  )
                                    ? '0'.repeat(
                                        this.user.settings.formats.Decimal.split(
                                          '.'
                                        ).pop().length
                                      )
                                    : '0'.repeat(2)
                                }`}
                                type='Number'
                              ></ColumnDirective>
                            </ColumnsDirective>
                            <Inject services={[ExcelExport, PdfExport]} />
                          </TreeGridComponent>
                        )}
                      </div>
                      <div className='row'>
                        {this.state.netpay.length && (
                          <TreeGridComponent
                            id='netpaygrid'
                            dataSource={this.state.netpay}
                            height='auto'
                            queryCellInfo={this.onBindingInfo.bind(this)}
                            ref={(grd: any) => (this.netpaygrid = grd)}
                            allowExcelExport={true}
                            allowPdfExport={true}
                          >
                            <ColumnsDirective>
                              <ColumnDirective
                                field='Name'
                                headerText=''
                                width='40%'
                              ></ColumnDirective>
                              <ColumnDirective
                                field='total'
                                headerText=''
                                width='20%'
                                textAlign='Right'
                                // format={`c${
                                //   this.user.settings.formats.Decimal.includes(
                                //     '.'
                                //   )
                                //     ? this.user.settings.formats.Decimal.split(
                                //         '.'
                                //       ).pop().length
                                //     : 2
                                // }`}
                                format={`${this.user.currencySign} 0.${
                                  this.user.settings.formats.Decimal.includes(
                                    '.'
                                  )
                                    ? '0'.repeat(
                                        this.user.settings.formats.Decimal.split(
                                          '.'
                                        ).pop().length
                                      )
                                    : '0'.repeat(2)
                                }`}
                                type='Number'
                              ></ColumnDirective>
                            </ColumnsDirective>
                            <Inject services={[ExcelExport, PdfExport]} />
                          </TreeGridComponent>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='employer-payslip col-md-5 '>
                  <div className='row'>
                    <div className='payslip-container col-12'>
                      {this.state.paydetails && (
                        <TreeGridComponent
                          id='bankdetailgrid'
                          dataSource={this.state.paydetails}
                          //treeColumnIndex={0}
                          childMapping='Data'
                          height='calc(100vh - 598px)'
                          ref={(grd: any) => (this.bankdetailgrid = grd)}
                          allowExcelExport={true}
                          allowPdfExport={true}
                        >
                          <ColumnsDirective>
                            <ColumnDirective
                              field='Name'
                              headerText='Bank(s)'
                              width='50%'
                              template={templateBankName}
                            ></ColumnDirective>
                            <ColumnDirective
                              field='groupName'
                              visible={false}
                            />
                            <ColumnDirective
                              field='percentage'
                              visible={false}
                            />

                            <ColumnDirective
                              field='accountNo'
                              headerText='Account'
                              width='25%'
                              textAlign='Right'
                            ></ColumnDirective>

                            <ColumnDirective
                              field='amount'
                              headerText='Amount'
                              width='25%'
                              textAlign='Right'
                              format={`${this.user.currencySign} 0.${
                                this.user.settings.formats.Decimal.includes('.')
                                  ? '0'.repeat(
                                      this.user.settings.formats.Decimal.split(
                                        '.'
                                      ).pop().length
                                    )
                                  : '0'.repeat(2)
                              }`}
                            ></ColumnDirective>
                          </ColumnsDirective>
                          <Inject services={[ExcelExport, PdfExport]} />
                        </TreeGridComponent>
                      )}
                    </div>
                  </div>
                  <div className='p-1 d-none'>
                    <i>
                      <b>* Note: &nbsp;</b>Bank account with the lowest priority
                      rank takes all the remaining amount
                    </i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default NewPayslip;
